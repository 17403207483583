import { Injectable } from '@angular/core';
import { DataApiService, DataOptions, HttpOptions, BaseService } from '@xpo-ltl/data-api';
import { ConfigManagerService } from '@xpo-ltl/config-manager';

import {
	ListArchivedShipmentsRqst,
	ListArchivedShipmentsResp,
	RestoreArchivedShipmentRqst,
	RestoreArchivedShipmentResp,
	GetOdsShipmentResp,
	GetOdsShipmentQuery,
	ListOdsShipmentsRqst,
	ListOdsShipmentsResp,
	ListShipmentsByCustomerAccountResp,
	ListShipmentsByCustomerAccountQuery,
	ListOdsShipmentHistTraceEventsRqst,
	ListOdsShipmentHistTraceEventsResp,
	ListParentShipmentsForOdsShipmentIdsRqst,
	ListParentShipmentsForOdsShipmentIdsResp
} from './api-shipmentods';

import {
	GetHealthCheckResp,
	User
} from '@xpo-ltl/sdk-common';

import { Observable } from 'rxjs';

@Injectable()
export class ShipmentOdsApiService extends BaseService {
	private static ShipmentOdsApiEndpoint = 'shipmentodsApiEndpoint';

	constructor(private dataApiService: DataApiService, private configManager: ConfigManagerService) {
		super();
	}

	/**
	* Health check URL. Responds with success message if the service is running.
	*/
	public healthCheck(
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<GetHealthCheckResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/health-check'),
			{
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* List of resources.
	*/
	public options(
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/options'),
			{
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Details of user invoking the API.
	*/
	public loggedInUser(
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<User> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/appusers/logged-in-user'),
			{
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Lists archived shipments based on one or more PROs that are provided as input.
	* <br/>  
	* <br/>Inputs
	* <br/>- Shipment PRO number(s)
	* <br/>Outputs
	* <br/>- List of pickup dates for PRO and recall status message(s).
	*/
	public listArchivedShipments(request: ListArchivedShipmentsRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListArchivedShipmentsResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/archived-shipments/list'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* Restores archived shipments based on one or more PROs that are provided as input.
	* <br/>  
	* <br/>Inputs
	* <br/>- Shipment PRO number(s)
	* <br/>Outputs
	* <br/>- If shipment already exists in DB2 then 
	* <br/>      response status code = 403 , message: Shipment already exists in DB2. No recall required.
	* <br/>- if Shipment data not found in source (ODS - RPT1 Oracle DB) then
	* <br/>      response status code = 404, message: Shipment not found in ODS (RPT1). Can not recall shipment.
	* <br/>- if Shipment parties don't exist in DB2 CIS tables then 
	* <br/>	  response status code = 404, message: Shipment parties not found in CIS DB2. Can not recall shipment.
	* <br/>- else
	* <br/>    Restore Archived Shipment by calling the Shipment Proxy.
	* <br/>	If successful the
	* <br/>	    response status code = 201 (Created), message: Shipment restored successfully.
	* <br/>	else 
	* <br/>	    response status code = 400, message: Shipment restore failed.
	* <br/>
	*/
	public restoreArchivedShipments(request: RestoreArchivedShipmentRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<RestoreArchivedShipmentResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/archived-shipments/restore'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* Get details of a Shipment for a given Pro Number or Shipment Instance Id.  
	* <br/>Inputs
	* <br/>- Shipment pro number or instance id is required
	* <br/>Outputs
	* <br/>- Shipment details are returned along with related pros and shipment trace/history events.
	* <br/>	
	*/
	public getOdsShipment(
							   queryParams: GetOdsShipmentQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<GetOdsShipmentResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/ods-shipments'),
			{
				queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Lists Ods shipments based on one or more PROs that are provided as input.
	* <br/>  
	* <br/>Inputs
	* <br/>- Shipment PRO number(s)
	* <br/>Outputs
	* <br/>- List of Shipment details for input PROs.
	*/
	public listOdsShipments(request: ListOdsShipmentsRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListOdsShipmentsResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/ods-shipments/list'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* List of Shipments from ODS database for input customer accounts.
	* <br/>		Minimum 1 customer account is required. The service will return a list of Shipments (ProNumber, Pickup Date and ShipmentId) along with the Shipment party
	* <br/>		where the typeCd returned is the Shipper or Consignee and the cisCustNbr is the input cisCustNbr.  
	* <br/>Inputs
	* <br/>- Min. 1 and upto 10 CIS Cust Number.
	* <br/>Outputs
	* <br/>- List of Shipments (shipment id, proNbr and pickupDate) for each input cisCustNbr.
	* <br/>  If the shipment delivery qualifier is not Z (final delivered) then return the shipment in the list.
	* <br/>  If the shipment delivery qualifier is Z (final delivered) then include the shipment only if it was delivered in last 7 days.
	* <br/>	
	*/
	public listShipmentsByCustomerAccount(
							   queryParams: ListShipmentsByCustomerAccountQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListShipmentsByCustomerAccountResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/ods-shipments/customer'),
			{
				queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Lists Ods shipment, operations and admin events for the shipment instance id provided as input.
	* <br/>Inputs
	* <br/>- Shipment instance id
	* <br/>Outputs
	* <br/>- List of Shipment, operations and admin events for the shipment instance id provided as input.
	*/
	public listOdsShipmentHistTraceEvents(request: ListOdsShipmentHistTraceEventsRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListOdsShipmentHistTraceEventsResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/ods-shipments/hist-trace-events'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* Lists Parent Ods shipment, if exists, for each of the Shipment instance Ids that are provided as input.
	* <br/>  
	* <br/>Inputs
	* <br/>- Shipment instance id(s)
	* <br/>Outputs
	* <br/>- List of Shipment details for Parent Ods shipment, if exists, for each of the Shipment instance Ids that are provided as input.
	*/
	public listParentShipmentsForOdsShipmentIds(request: ListParentShipmentsForOdsShipmentIdsRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<ListParentShipmentsForOdsShipmentIdsResp> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/ods-shipments/list-parent-shipments'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}


	protected getEndPoint(): string {
		return this.configManager.getSetting(ShipmentOdsApiService.ShipmentOdsApiEndpoint);
	}
}
