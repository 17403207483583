/**
 * Pricing API
 * Version: 1.0
 * Build: Manual
 */

import {
	CountInfo,
	FieldInfo,
	File,
	DataValidationError,
	Comment,
	ListInfo,
	AuditInfo,
	MonetaryAmount,
	ClassRate,
	AcctId,
	Code,
	CrmAccount,
	CrmAccountOwner,
	Address,
	Weight,
	Distance,
	Volume,
	EmployeeId,
	PartyBasic,
	Density,
	Document,
	Dimensions,
	AccessorialOverride,
	NmfcItemOverride,
	ContactInfo
} from '@xpo-ltl/sdk-common';

export class CreateSalesRfpAutoGriRqst {
	/**
	 * GRI information
	 */
	gri:SalesRfpGri = undefined;
}
export class CreateSalesRfpAutoGriResp {
	/**
	 * GRI information
	 */
	gri:SalesRfpGri = undefined;
}
/**
 * This request will create a new SalesRFP by providing the RFP header information
 */
export class CreateSalesRfpRqst {
	/**
	 * Key Sales RFP information
	 */
	salesRfp:SalesRfp = undefined;
	/**
	 * Sales Rfp Id of the source from where to copy the sales rfp details
	 */
	sourceSalesRfpId:string = undefined;
	/**
	 * source from where to copy the sales rfp account location information. Eg. PA (Pricing Agreement), RFP(Sales Rfp) or ALL
	 */
	salesRfpAcctSource:string = undefined;
}
/**
 * Contains basic information related to a place where our customer does business with us. 
 */
export class CreateSalesRfpResp {
	/**
	 * The database identifier of this SalesRFP
	 */
	salesRfpId:string = undefined;
}
/**
 * Request message to reassign sales rfps from one user to another
 */
export class ReassignSalesRfpRqst {
	/**
	 * List of Sales Rfp Ids to be assigned to new owner
	 */
	rfpOwner:RfpOwner[] = undefined;
	/**
	 * This is the from user role  (Sales/Pricing Analyst/Nass/Dos/PricingManger) to transfer
	 */
	fromOwnerRole:string = undefined;
	/**
	 * Employee id of the new owner of the Sales Rfps
	 */
	toOwner:string = undefined;
}
export class ReassignSalesRfpResp {
	/**
	 * Count of the Sales Rfps reassigned
	 */
	reassignedCount:CountInfo = undefined;
}
/**
 * Gets the unmapped shipment file from sales rfp shipment file that is stored in DMS
 */
export class GetUnmappedSupportingDocRqst {
	/**
	 * unique id of the Sales Rfp Supporting document
	 */
	rfpSupportingDocId:string = undefined;
	/**
	 * unique id of the Sales Rfp
	 */
	salesRfpId:string = undefined;
}
export class GetUnmappedSupportingDocResp {
	fields:FieldInfo[] = undefined;
	records:Record[] = undefined;
}
/**
 * The service will create a csv file with data from various Sales Rfp tables to create an audit file that will be downloaded by Sales force for auditing the Sales Rfps.
 */
export class GetSalesRfpAuditFileResp {
	rfpAuditFile:File = undefined;
}
/**
 * Request message to valdate a Sales Rfp before submitting to Pricing
 */
export class ValidateSalesRfpRqst {
	/**
	 * Unique Identifier of Sales Rfp
	 */
	salesRfpId:string = undefined;
}
/**
 * Response 
 */
export class ValidateSalesRfpResp {
	/**
	 * list of warning messages
	 */
	warningMsgs:DataValidationError[] = undefined;
	/**
	 * list of error messages
	 */
	errorMsgs:DataValidationError[] = undefined;
}
/**
 * Get sales rfp counts that meet certain criteria.
 */
export class GetSalesRfpCountsRqst {
	/**
	 * identifies the level of detail to be included in the response counts
	 */
	levelOfDetail:string = undefined;
	/**
	 * Identifier of the Customer with whom the agreement is being or was negotiated. This will uniquely identify the customer in the CIS component.
	 */
	negotiatingCustInstId:string = undefined;
	/**
	 * This is the business key at the time of negotiation for this customer. Note that it will not be kept in synch with the master customer database.
	 */
	negotiatingCustMadCd:string = undefined;
	/**
	 * The date the Sales RFP is due from Pricing back to Sales
	 */
	dueDate:Date = undefined;
	/**
	 * Requested Effective Date for the Agreement if the request is approved.
	 */
	proposedEffDate:Date = undefined;
	/**
	 * Proposed Expiration Date for the Agreement if the request is approved.
	 */
	proposedExpDate:Date = undefined;
	/**
	 * The ID of the Pricing Analyst
	 */
	pricingAnalystId:string = undefined;
	/**
	 * The ID of the owner of sales RFP.
	 */
	ownerId:string = undefined;
	/**
	 * The ID of the owner of sales RFP, the owner transfers to the party that is actively working on the RFP.
	 */
	currentOwnerId:string = undefined;
	/**
	 * Start date  to filter the Sales RFP
	 */
	startDate:Date = undefined;
	/**
	 * End date to filter the Sales RFP
	 */
	endDate:Date = undefined;
	/**
	 * Sales Region code of the Sales RFP
	 */
	salesRegionCd:string = undefined;
}
/**
 * Gets sales rfp counts that meet certain criteria.
 */
export class GetSalesRfpCountsResp {
	/**
	 * Pricing Agreement Monitor record counts
	 */
	counts:CountInfo[] = undefined;
}
/**
 * Updates the previous agreement details of a given sales rfp
 */
export class UpdateSalesRfpAgreementDtlRqst {
	/**
	 * unique identifier of sales rfp
	 */
	salesRfpId:string = undefined;
	/**
	 * unique identifier of the proposal
	 */
	proposalId:string = undefined;
}
/**
 * Gets sales rfp counts that meet certain criteria.
 */
export class UpdateSalesRfpAgreementDtlResp {
	/**
	 * Pricing Agreement Monitor record counts
	 */
	salesRfpDetail:SalesRfpHdr = undefined;
}
/**
 * The operation creates the Shipment rfp supporting document from dms and then maps the field names and records from the document to return the records.
 */
export class CreateSalesRfpShipmentSupportingDocsRqst {
	/**
	 * unique identifier of sales rfp
	 */
	salesRfpId:string = undefined;
	/**
	 * unique identifier of the supporting document
	 */
	rfpSupportingDocId:string = undefined;
	/**
	 * List of column fields to be mapped
	 */
	fieldMapping:FieldInfo[] = undefined;
}
export class DeleteSalesRfpCommentsRqst {
	salesRfpId:string = undefined;
	prcProposalInstId:string = undefined;
	commentId:string = undefined;
	commentTypeCd:string = undefined;
	commentSubTypeCd:string = undefined;
}
/**
 * This request will create a Sales Rfp comment for an input Sales Rfp Id
 */
export class CreateSalesRfpCommentsRqst {
	/**
	 * unique identifier for Sales Rfp
	 */
	salesRfpId:string = undefined;
	/**
	 * unique identifier for Pricing Proposal
	 */
	prcProposalInstId:string = undefined;
	comment:Comment = undefined;
}
export class CreateSalesRfpCommentsResp {
	salesRfpId:string = undefined;
	/**
	 * unique identifier of the comment created.
	 */
	commentId:string = undefined;
}
/**
 * Retrieves all the comments associated to a Sales Rfp
 */
export class ListSalesRfpCommentsRqst {
	/**
	 * unique id of the sales rfp record
	 */
	salesRfpId:string = undefined;
	/**
	 * unique identifier of Pricing Proposal
	 */
	prcProposalInstId:string = undefined;
	commentTypeCd:string = undefined;
	commentSubTypeCd:string = undefined;
	/**
	 * pagination information to retrieve list
	 */
	listInfo:ListInfo = undefined;
}
export class ListSalesRfpCommentsResp {
	salesRfpId:string = undefined;
	comments:SalesRfpComment[] = undefined;
	listInfo:ListInfo = undefined;
}
/**
 * Retrieves all the comments associated to a Pricing Proposal
 */
export class ListPricingProposalCommentsRqst {
	/**
	 * unique id of the pricing proposal record
	 */
	prcProposalInstId:string = undefined;
	commentTypeCd:string = undefined;
	/**
	 * pagination information to retrieve list
	 */
	listInfo:ListInfo = undefined;
}
export class ListPricingProposalCommentsResp {
	prcProposalInstId:string = undefined;
	comments:SalesRfpComment[] = undefined;
	listInfo:ListInfo = undefined;
}
/**
 * This request will update an existing Sales Rfp comment for an input Sales Rfp Id
 */
export class UpdateSalesRfpCommentsRqst {
	salesRfpId:string = undefined;
	commentId:string = undefined;
	comment:Comment = undefined;
}
export class CreateSalesRfpAuditLogResp {
	salesRfpId:string = undefined;
}
export class ListSalesRfpAuditLogsResp {
	auditLogDetails:RfpAuditLog[] = undefined;
	listInfo:ListInfo = undefined;
}
/**
 * Request message to retrieve all the audit log information for a given sales rfp
 */
export class ListSalesRfpAuditLogsRqst {
	salesRfpId:string = undefined;
	listInfo:ListInfo = undefined;
}
/**
 * Request message for creating a Sales Rfp Audit log record for an input SalesRfpId
 */
export class CreateSalesRfpAuditLogRqst {
	salesRfpId:string = undefined;
	auditLog:RfpAuditLog[] = undefined;
}
/**
 * This request will update an existing Sales Rfp. Updates include create/update/delete of children records.
 */
export class UpdateSalesRfpRqst {
	salesRfp:SalesRfp = undefined;
	prcProposalInstId:string = undefined;
}
/**
 * Updates the Status of a given sales rfp
 */
export class UpdateSalesRfpStatusRqst {
	/**
	 * unique identifier of sales rfp
	 */
	salesRfpId:string = undefined;
	/**
	 * Status code of the Sales Rfp to be updated
	 */
	rfpStatusCd:string = undefined;
	/**
	 * Rfp header data to be updated
	 */
	salesRfpHdr:SalesRfpHdr = undefined;
}
/**
 * Creates Sales Rfp Lanes for input Sales Rfp Id with input Lane data
 */
export class CreateSalesRfpLanesRqst {
	/**
	 * The database identifier of this SalesRFP
	 */
	salesRfpId:string = undefined;
	salesRfpLanes:PricingLane[] = undefined;
}
export class CreateSalesRfpLanesResp {
	salesRfpId:string = undefined;
}
/**
 * List sales rfp requests that meet certain criteria.
 */
export class ListSalesRfpAcctsRqst {
	/**
	 * The ID of a sales RFP
	 */
	salesRfpId:string = undefined;
	/**
	 * unique id of the parent account
	 */
	parentAcctInstId:string = undefined;
	listInfo:ListInfo = undefined;
}
/**
 * List sales rfp requests that meet certain criteria. Returns 1 or more sales rfp headers
 */
export class ListSalesRfpAcctsResp {
	/**
	 * The ID of a customer associated with the RFP
	 */
	rfpAccounts:RfpAcct[] = undefined;
	/**
	 * The total number of rows in the list.
	 */
	listInfo:ListInfo = undefined;
}
/**
 * Create references in the SalesRFP for the customer locations associated with it.
 */
export class CreateSalesRfpAcctsRqst {
	/**
	 * The ID of a sales RFP
	 */
	salesRfpId:string = undefined;
	/**
	 * The sales RFP Account references
	 */
	salesRfpAccts:RfpAcct[] = undefined;
}
/**
 * The primary keys for the sales rfp 
 */
export class CreateSalesRfpAcctsResp {
	/**
	 * The database identifier of this SalesRFP
	 */
	salesRfpId:string = undefined;
}
/**
 * Deletes Sales Rfp Accounts rulesets for input Sales Rfp Id
 */
export class DeleteSalesRfpAcctsRulesetRqst {
	/**
	 * Unique identifier of sales rfp
	 */
	salesRfpId:string = undefined;
}
export class DeleteSalesRfpAcctsRulesetResp {
	/**
	 * Unique identifier of sales rfp
	 */
	salesRfpId:string = undefined;
}
/**
 * Retrieves a list of Rulesets for each of the Account associated with input sales rfp
 */
export class ListSalesRfpAcctsRulesetRqst {
	/**
	 * unique identifier of sales rfp
	 */
	salesRfpId:string = undefined;
	/**
	 * pagination information to retrieve list
	 */
	listInfo:string = undefined;
}
export class ListSalesRfpAcctsRulesetResp {
	rfpAcctRuleset:RfpAcctRuleset[] = undefined;
	/**
	 * The total number of rows in the list.
	 */
	listInfo:ListInfo = undefined;
}
/**
 * Creates the rulesets for each of the accounts associated to the input Sales RFP id
 */
export class CreateSalesRfpAcctsRulesetRqst {
	/**
	 * Unqiue identifier of Sales Rfp
	 */
	salesRfpId:string = undefined;
	agreementDetail:PricingAgreement = undefined;
}
/**
 * Creates the rulesets for each of the accounts associated to the input Sales RFP id
 */
export class CreateSalesRfpAcctsRulesetResp {
	/**
	 * Unqiue identifier of Sales Rfp
	 */
	salesRfpId:string = undefined;
	/**
	 * unique identifier of the Rfp Audit Header record
	 */
	rfpAuditHeaderId:string = undefined;
}
/**
 * Request Message to update the sales rfp audit header
 */
export class UpdateSalesRfpAuditHeaderRqst {
	/**
	 * Unique identifier of sales rfp record
	 */
	salesRfpId:string = undefined;
	/**
	 * Unique identifier of the Sales Rfp Audit Header record
	 */
	rfpAuditHeaderId:string = undefined;
	/**
	 * Valid values are true and false. If not provided then no email notification will be sent.
	 */
	emailNotify:boolean = undefined;
	/**
	 * Rfp Audit Header data to be updated
	 */
	rfpAuditHeader:SalesRfpAuditHeader = undefined;
}
/**
 * Request Message to get the sales rfp audit header
 */
export class GetSalesRfpAuditHeaderRqst {
	salesRfpId:string = undefined;
}
/**
 * Respond Message for sales rfp audit header
 */
export class GetSalesRfpAuditHeaderResp {
	rfpAuditHeader:SalesRfpAuditHeader = undefined;
}
/**
 * Creates Contact details for a given sales rfp
 */
export class CreateSalesRfpContactDetailsRqst {
	/**
	 * unique identifier of sales rfp
	 */
	salesRfpId:string = undefined;
	rfpContactDetails:SalesRfpContact = undefined;
}
/**
 * Creates Contact details for a given sales rfp
 */
export class CreateSalesRfpContactDetailsResp {
	rfpContactDetails:SalesRfpContact = undefined;
}
/**
 * Updates Contact details for a given sales rfp and Rfp Contact id
 */
export class UpdateSalesRfpContactDetailsRqst {
	/**
	 * unique identifier of sales rfp
	 */
	salesRfpId:string = undefined;
	/**
	 * unique identifier of Sales Rfp Contact
	 */
	rfpContactId:string = undefined;
	rfpContactDetails:SalesRfpContact = undefined;
}
/**
 * Retrieves Contact details for a given sales rfp
 */
export class GetSalesRfpContactDetailsRqst {
	/**
	 * unique identifier of sales rfp
	 */
	salesRfpId:string = undefined;
}
export class GetSalesRfpContactDetailsResp {
	rfpContactDetails:SalesRfpContact = undefined;
}
/**
 * Print Cancel Pricing Agreement Letter Rqst
 */
export class PrintCancelPricingAgreementLetterRqst {
	/**
	 * unique identifier of sales rfp
	 */
	salesRfpId:string[] = undefined;
	/**
	 * unique identifier of contactrfp
	 */
	contactId:string[] = undefined;
	actionType:string = undefined;
	docId:string = undefined;
	letterDate:Date = undefined;
	noticeDate:Date = undefined;
}
/**
 * Print Cancel Pricing Agreement Letter response
 */
export class PrintCancelPricingAgreementLetterResp {
	cancelPricingAgreementLetter:File = undefined;
	docId:string = undefined;
}
/**
 * Deletes one or all Sales Rfp Support Documents. If Sales Rfp Id is input then all supporting documents can be deleted if a supporting document id is input then 1 document is deleted
 */
export class DeleteSalesRfpSupportingDocsRqst {
	salesRfpId:string = undefined;
	rfpSupportingDocId:string = undefined;
}
/**
 * Request message to retrieve a list of Sales Rfp Support documents based on input Sales Rfp Id
 */
export class ListSalesRfpSupportingDocsRqst {
	salesRfpId:string = undefined;
	prcProposalInstId:string = undefined;
	listInfo:ListInfo = undefined;
}
/**
 * Response message to retrieve a list of Sales Rfp Support documents based on input Sales Rfp Id
 */
export class ListSalesRfpSupportingDocsResp {
	supportingDocs:ListSalesRfpSupportingDocsResp_SalesRfpSupportingDocument[] = undefined;
	listInfo:ListInfo = undefined;
}
/**
 * Request message to retrieve a l Sales Rfp Support document based on input Sales Rfp Id and document id
 */
export class GetSalesRfpSupportingDocRqst {
	salesRfpId:string = undefined;
	rfpSupportingDocId:string = undefined;
	prcProposalInstId:string = undefined;
}
/**
 * Response message to retrieve a list of Sales Rfp Support documents based on input Sales Rfp Id
 */
export class GetSalesRfpSupportingDocResp {
	supportingDoc:SalesRfpSupportingDocument = undefined;
}
/**
 * Adds Sales Rfp support documents to DMS and inserts rows in Sales Rfp Supporting documents
 */
export class CreateSalesRfpSupportingDocsRqst {
	salesRfpId:string = undefined;
	prcProposalInstId:string = undefined;
	/**
	 * indicates if the document is restricted or not
	 */
	restrictedInd:boolean[] = undefined;
	/**
	 * type of document to be uploaded
	 */
	docTypeCd:string[] = undefined;
	/**
	 * list of files to be uploaded
	 */
	docFiles:File[] = undefined;
}
export class CreateSalesRfpSupportingDocsResp {
	rfpDocument:SalesRfpSupportingDocument[] = undefined;
}
/**
 * Retrieves the Document file for input Sales Rfp Identifier and the supporting doc identifier
 */
export class GetSalesRfpSupportingDocFileRqst {
	salesRfpId:string = undefined;
	rfpSupportingDocId:string = undefined;
}
export class GetSalesRfpSupportingDocFileResp {
	supportingDocFile:File = undefined;
}
/**
 * Retrieves a zip file containing multiple Document files for input Sales Rfp Identifier and the supporting doc identifiers
 */
export class GetSalesRfpSupportingDocsFileRqst {
	salesRfpId:string = undefined;
	rfpSupportingDocId:string[] = undefined;
}
export class GetSalesRfpSupportingDocsFileResp {
	supportingDocsFile:File = undefined;
}
/**
 * Inserts the Geo Pricing Lanes for a given Sales Rfp and Geo Pricing Group
 */
export class CreateSalesRfpGeoAreaPricingLanesRqst {
	/**
	 * unique id of the Sales Rfp
	 */
	salesRfpId:string = undefined;
	geoAreaPricingLanes:GeoAreaPricingLane[] = undefined;
}
/**
 * Inserts the Geo Pricing Lanes for a given Sales Rfp and Geo Pricing Group
 */
export class CreateSalesRfpGeoAreaPricingLanesResp {
	/**
	 * unique id of the Pricing lane id
	 */
	geoAreaPrcLaneId:string = undefined;
}
/**
 * Updates the Geo Pricing Lanes for a given Sales Rfp and Geo Pricing Group
 */
export class UpdateSalesRfpGeoAreaPricingLanesRqst {
	/**
	 * unique id of the Sales Rfp
	 */
	salesRfpId:string = undefined;
	/**
	 * unique id of Geo Area Pricing Lane record
	 */
	geoAreaPrcLaneId:string = undefined;
	geoAreaPricingLanes:GeoAreaPricingLane = undefined;
}
/**
 * Retrieves the Geo Pricing Lanes for a given Sales Rfp and Geo Pricing Group
 */
export class ListSalesRfpGeoAreaPricingLanesRqst {
	/**
	 * unique id of the Sales Rfp
	 */
	salesRfpId:string = undefined;
	listInfo:ListInfo = undefined;
}
/**
 * Retrieves the Geo Pricing Lanes for a given Sales Rfp and Geo Pricing Group
 */
export class ListSalesRfpGeoAreaPricingLanesResp {
	/**
	 * unique id of the Sales Rfp
	 */
	salesRfpId:string = undefined;
	geoAreaPricingLanes:GeoAreaPricingLane[] = undefined;
	/**
	 * pagination information for list retrieval
	 */
	listInfo:ListInfo = undefined;
}
/**
 * Deletes the Geo Pricing Lanes for a given Sales Rfp and Geo Pricing Group
 */
export class DeleteSalesRfpGeoAreaPricingLanesRqst {
	/**
	 * unique id of the Sales Rfp
	 */
	salesRfpId:string = undefined;
	/**
	 * unique id of geo area pricing lane record
	 */
	geoAreaPrcLaneId:string = undefined;
}
/**
 * Creates the Sales Rfp Obi plan data for a given Sales Rfp id.
 */
export class CreateSalesRfpObiPlansRqst {
	/**
	 * unique id of Sales Rfp
	 */
	salesRfpId:string = undefined;
	obiPlans:RfpObi[] = undefined;
}
/**
 * Creates the Sales Rfp Obi plan data for a given Sales Rfp id.
 */
export class CreateSalesRfpObiPlansResp {
	/**
	 * unique id of Sales Rfp
	 */
	salesRfpId:string = undefined;
	obiPlans:RfpObi[] = undefined;
}
/**
 * Deletes one or all the Sales Rfp Obi plan data for a given Sales Rfp id
 */
export class DeleteSalesRfpObiPlansRqst {
	/**
	 * unique id of the Sales Rfp
	 */
	salesRfpId:string = undefined;
	/**
	 * unique id of the sales rfp obi plan
	 */
	rfpObiPlanId:string = undefined;
}
/**
 * Retrieves the Sales Rfp Obi plan data for a given Sales Rfp id
 */
export class ListSalesRfpObiPlansRqst {
	/**
	 * unique id of the Sales Rfp
	 */
	salesRfpId:string = undefined;
	listInfo:ListInfo = undefined;
}
/**
 * Updates the Sales Rfp Obi plan data for a given Sales Rfp id and Obi plan id
 */
export class ListSalesRfpObiPlansResp {
	/**
	 * unique id of Sales Rfp
	 */
	salesRfpId:string = undefined;
	obiPlans:RfpObi[] = undefined;
	listInfo:ListInfo = undefined;
}
/**
 * Updates the Sales Rfp Obi plan data for a given Sales Rfp id and Obi plan id
 */
export class UpdateSalesRfpObiPlansRqst {
	/**
	 * unique id of Sales Rfp
	 */
	salesRfpId:string = undefined;
	/**
	 * unique id of the sales rfp obi plan
	 */
	rfpObiPlanId:string = undefined;
	obiPlan:RfpObi = undefined;
}
/**
 * Updates the Sales Rfp Obi plan data for a given Sales Rfp id and Obi plan id
 */
export class UpdateSalesRfpObiPlansResp {
	/**
	 * unique id of Sales Rfp
	 */
	salesRfpId:string = undefined;
	obiPlan:RfpObi = undefined;
}
/**
 * Creates sales rfp commodity record for a given sales rfp
 */
export class CreateSalesRfpCommodityRqst {
	/**
	 * unique identifier of sales rfp
	 */
	salesRfpId:string = undefined;
	/**
	 * Sales Rfp Commodity details
	 */
	rfpCommodity:PricingCommodity[] = undefined;
}
/**
 * Creates sales rfp commodity record for a given sales rfp
 */
export class CreateSalesRfpCommodityResp {
	/**
	 * unique identifier of sales rfp
	 */
	salesRfpId:string = undefined;
}
/**
 * Retrieves a list of sales rfp commodities for a given sales rfp
 */
export class ListSalesRfpCommoditiesRqst {
	/**
	 * unique identifier of sales rfp
	 */
	salesRfpId:string = undefined;
	/**
	 * pagination information to retrieve list
	 */
	listInfo:ListInfo = undefined;
}
export class ListSalesRfpCommoditiesResp {
	/**
	 * unique identifier of sales rfp
	 */
	salesRfpId:string = undefined;
	/**
	 * sales rfp commodity detail
	 */
	rfpCommodity:PricingCommodity[] = undefined;
	listInfo:ListInfo = undefined;
}
/**
 * Given a Sales Rfp Identifier the service will create Lading Dimensions record based on input data
 */
export class CreateSalesRfpLadingDimensionsRqst {
	/**
	 * unique identifier of sales rfp
	 */
	salesRfpId:string = undefined;
	rfpLadingDimensions:PricingLadingDimension[] = undefined;
}
export class CreateSalesRfpLadingDimensionsResp {
	salesRfpId:string = undefined;
}
/**
 * Retrieves a list of Lading Dimensions for an input Sales Rfp Id
 */
export class ListSalesRfpLadingDimensionsRqst {
	/**
	 * Unique identifier of Sales Rfp
	 */
	salesRfpId:string = undefined;
	/**
	 * pagination information to retrieve list
	 */
	listInfo:ListInfo = undefined;
}
export class ListSalesRfpLadingDimensionsResp {
	salesRfpId:string = undefined;
	rfpLadingDimensions:PricingLadingDimension[] = undefined;
	listInfo:ListInfo = undefined;
}
/**
 * Creates Sales Rfp Pricing Analysis for a given Sales Rfp
 */
export class CreateSalesRfpAgrmntPerformanceRqst {
	/**
	 * unique identifier of the Sales Rfp
	 */
	salesRfpId:string = undefined;
	/**
	 * Pricing Analysis data to be persisted
	 */
	rfpAgrmntPerformance:RfpAgrmntPerformance = undefined;
}
export class CreateSalesRfpAgrmntPerformanceResp {
	/**
	 * unique identifier of the Sales Rfp
	 */
	salesRfpId:string = undefined;
	rfpAgrmntPerformance:RfpAgrmntPerformance = undefined;
}
/**
 * Updates Sales Rfp Pricing Analysis data for a given Sales Rfp and Pricing Analysis
 */
export class UpdateSalesRfpAgrmntPerformanceRqst {
	/**
	 * unique identifier of sales rfp
	 */
	salesRfpId:string = undefined;
	/**
	 * unique identifier of Sales Rfp Pricing Analysis
	 */
	rfpRequestAnalysisId:string = undefined;
	/**
	 * Details about the Pricing Analysis
	 */
	agrmntPeformanceDetail:RfpAgrmntPerformance = undefined;
}
/**
 * Retrieves Sales Rfp Pricing Analysis for a given Sales Rfp and optional Pricing Analysis Id
 */
export class GetSalesRfpAgrmntPerformanceRqst {
	/**
	 * unique identifier of the Sales Rfp
	 */
	salesRfpId:string = undefined;
}
export class GetSalesRfpAgrmntPerformanceResp {
	/**
	 * Pricing Analysis data to be persisted
	 */
	rfpAgrmntPeformance:RfpAgrmntPerformance = undefined;
}
/**
 * Deletes Sales Rfp Pricing Analysis for a given Sales Rfp and optional Pricing Analysis Id
 */
export class DeleteSalesRfpAgrmntPerformanceRqst {
	/**
	 * unique identifier of the Sales Rfp
	 */
	salesRfpId:string = undefined;
}
/**
 * Retrieve the Pricing Analyst assigned to the Sales Rfp
 */
export class GetPricingAnalystAssignmentRqst {
	/**
	 * Pricing Analyst Assignment Id
	 */
	analystAssignmentId:string = undefined;
}
export class GetPricingAnalystAssignmentResp {
	/**
	 * Details of the Pricing Analyst assigned to the Sales Rfp
	 */
	assignedAnalyst:PricingAnalystAssignment = undefined;
}
/**
 * Assigns a PricingAnalyst to a given AE/SAM,  District and Region
 */
export class CreatePricingAnalystAssignmentRqst {
	pricingAnalystAssignments:PricingAnalystAssignment[] = undefined;
}
export class CreatePricingAnalystAssignmentResp {
	pricingAnalystAssignments:PricingAnalystAssignment[] = undefined;
}
/**
 * Retrieves a list of Pricing Analyst Assignments based on input criteria
 */
export class ListPricingAnalystAssignmentsRqst {
	/**
	 * Identifies the type of assignment for the pricing analyst
	 */
	assignmentTypeCd:string = undefined;
	/**
	 * The employee ID of the Account Executive (Local or National)
	 */
	acctExecEmployeeId:string = undefined;
	/**
	 * The employee ID of the National Account Sales Supervisor
	 */
	nasSupervisorEmployeeId:string = undefined;
	/**
	 * The employee ID of the National Account Sales Specialist
	 */
	nasSpecialistEmployeeId:string = undefined;
	/**
	 * A geographical region (now called District) for which an account executive is responsible of (i.e. RCA)
	 */
	salesRegionCode:string = undefined;
	/**
	 * A geographical area for a location (now called Region) (i.e. CCA)
	 */
	areaCode:string = undefined;
	/**
	 * The type of pricing agreement (i.e. P360, In-Cycle)
	 */
	agreementTypeCode:string = undefined;
	/**
	 * The type of request for pricing (i.e. Renegotiation, New Opportunity)
	 */
	rfpTypeCode:string = undefined;
	/**
	 * The date the assignment record was effective
	 */
	effectiveDate:Date = undefined;
	/**
	 * The date when the assignment record expired
	 */
	expirationDate:Date = undefined;
	/**
	 * Pagination information to retrieve list
	 */
	listInfo:ListInfo = undefined;
}
export class ListPricingAnalystAssignmentsResp {
	pricingAnalystAssignments:PricingAnalystAssignment[] = undefined;
	listInfo:ListInfo = undefined;
}
/**
 * Updates the PricingAnalystAssignment record for an input PricingAnalystAssignment Id 
 */
export class UpdatePricingAnalystAssignmentRqst {
	analystAssignmentId:string = undefined;
	analystAssignmentDetail:PricingAnalystAssignment = undefined;
}
/**
 * Deletes a PricingAnalystAssignment record for an input PricingAnalystAssignmentId
 */
export class DeletePricingAnalystAssignmentRqst {
	analystAssignmentId:string = undefined;
}
/**
 * Inserts or updates Pricing Analysis Header record based on input data
 */
export class CreatePricingProposalHeaderRqst {
	pricingProposalHdrInfo:PricingProposalHdr = undefined;
	/**
	 * Indicator to trigger the Pricing Proposal Analysis Flow
	 */
	triggerProposalAnlysFlow:boolean = undefined;
	/**
	 * unique id of the Sales Rfp
	 */
	salesRfpId:string = undefined;
}
/**
 * Returns Pricing Proposal header identifier
 */
export class CreatePricingProposalHeaderResp {
	pricingProposalInstId:string = undefined;
}
/**
 * Inserts or updates Pricing Analysis Header record based on input data
 */
export class UpdatePricingProposalHeaderRqst {
	pricingProposalHdrInfo:PricingProposalHdr = undefined;
}
export class UpdatePricingProposalRqst {
	prcProposalInstId:string = undefined;
	/**
	 * Override indicator for update
	 */
	overrideInd:boolean = undefined;
	pricingProposal:PricingProposal = undefined;
}
/**
 * Input message for getting the Pricing Analyst detail
 */
export class GetPricingAnalystDetailsRqst {
	pricingAnalystEmplId:string = undefined;
}
/**
 * Pricing Analyst Details
 */
export class GetPricingAnalystDetailsResp {
	pricingAnalystDetails:PricingAdminPersonnel = undefined;
}
/**
 * Input message for getting the list of Pricing Analysts
 */
export class ListPricingAnalystsRqst {
	managerEmplId:string = undefined;
	employeeRoleType:string[] = undefined;
	listInfo:ListInfo = undefined;
}
/**
 * List of Pricing Analysts matching the criteria
 */
export class ListPricingAnalystsResp {
	pricingAnalysts:PricingAdminPersonnel[] = undefined;
}
/**
 * Inserts Pricing Proposal Accessorial records for a given Pricing Proposal Id
 */
export class CreatePricingProposalAccessorialRqst {
	prcProposalInstId:string = undefined;
	accessorialInfo:PricingAccessorial[] = undefined;
}
/**
 * Returns Pricing Proposal header identifier
 */
export class CreatePricingProposalAccessorialResp {
	prcProposalInstId:string = undefined;
}
/**
 * Creates the Pricing Proposal Geo Pricing records for an input Pricing Proposal Id
 */
export class CreatePricingProposalGeoPricingRqst {
	/**
	 * unique identifier of Pricing Proposal record
	 */
	prcProposalInstId:string = undefined;
	geoPricingGroup:GeoAreaPricingGroup[] = undefined;
}
/**
 * Creates the Pricing Proposal Geo Pricing records for an input Pricing Proposal Id
 */
export class CreatePricingProposalGeoPricingResp {
	prcProposalInstId:string = undefined;
	geoPricingGroup:GeoAreaPricingGroup[] = undefined;
}
/**
 * Updates the Pricing Proposal Geo Pricing records for an input Pricing Proposal Id and Geo Pricing Id
 */
export class UpdatePricingProposalGeoPricingRqst {
	prcProposalInstId:string = undefined;
	geoPricingGroup:GeoAreaPricingGroup[] = undefined;
}
/**
 * Updates the Pricing Proposal Geo Pricing records for an input Pricing Proposal Id and Geo Pricing Id
 */
export class UpdatePricingProposalGeoPricingResp {
	prcProposalInstId:string = undefined;
	geoPricingGroup:GeoAreaPricingGroup[] = undefined;
	auditInfo:AuditInfo = undefined;
}
/**
 * Retrieves a list of Pricing Proposal Geo Pricing records for an input Pricing Proposal Id
 */
export class ListPricingProposalGeoPricingRqst {
	/**
	 * unique id of Pricing Proposal
	 */
	prcProposalInstId:string = undefined;
	/**
	 * pagination information to retrieve list
	 */
	listInfo:ListInfo = undefined;
}
/**
 * Retrieves a list of Pricing Proposal Geo Pricing records for an input Pricing Proposal Id
 */
export class ListPricingProposalGeoPricingResp {
	/**
	 * unique id of Pricing Proposal
	 */
	prcProposalInstId:string = undefined;
	geoPricings:GeoAreaPricingGroup[] = undefined;
	listInfo:ListInfo = undefined;
}
/**
 * Deletes on or all Pricing Proposal Geo Pricing records for an input Pricing Proposal Id and optional Geo Pricing Id
 */
export class DeletePricingProposalGeoPricingRqst {
	/**
	 * unique identifier of Pricing Proposal record
	 */
	prcProposalInstId:string = undefined;
	/**
	 * unique identifier of GeoAreaPricing group record
	 */
	geoAreaPrcId:string = undefined;
}
/**
 * Creates discounts for the Geo Pricing Lanes baesd on the input data.
 */
export class CreateGeoPricingLaneDiscountsRqst {
	/**
	 * unique db identifier of the Geo Pricing Lane record. Discounts are associated with a Geo Pricing Lane
	 */
	geoPricingLaneId:string = undefined;
	prcProposalInstId:string = undefined;
	discountDetails:ProposalDiscount[] = undefined;
	auditInfo:AuditInfo = undefined;
}
/**
 * Response message of CreateGeoPricingLaneDiscounts
 */
export class CreateGeoPricingLaneDiscountsResp {
	/**
	 * unique db identifier of the Geo Pricing Lane record. Discounts are associated with a Geo Pricing Lane
	 */
	geoPricingLaneId:string = undefined;
	discountDetails:ProposalDiscount[] = undefined;
	auditInfo:AuditInfo = undefined;
}
/**
 * Deletes 1 or all geo pricing lane discounts for an input geo pricing lane
 */
export class DeleteGeoPricingLaneDiscountsRqst {
	/**
	 * unique db identifier of the GeoPricingLane record
	 */
	geoPricingLaneId:string = undefined;
	prcProposalInstId:string = undefined;
	/**
	 * unique db identifier of the GeoPricingLaneDiscount record
	 */
	geoPricingLaneDiscountId:string = undefined;
}
/**
 * Creates discounts for the Geo Pricing Lanes baesd on the input data.
 */
export class UpdateGeoPricingLaneDiscountsRqst {
	/**
	 * unique db identifier of the Geo Pricing Lane record. Discounts are associated with a Geo Pricing Lane
	 */
	geoPricingLaneId:string = undefined;
	prcProposalInstId:string = undefined;
	/**
	 * unique db identifier of the Geo Pricing Lane Discount record. Discounts are associated with a Geo Pricing Lane
	 */
	geoPricingLaneDiscountId:string = undefined;
	discountDetails:ProposalDiscount = undefined;
	auditInfo:AuditInfo = undefined;
}
/**
 * Response message of CreateGeoPricingLaneDiscounts
 */
export class UpdateGeoPricingLaneDiscountsResp {
	/**
	 * unique db identifier of the Geo Pricing Lane record. Discounts are associated with a Geo Pricing Lane
	 */
	geoPricingLaneId:string = undefined;
	discountDetails:ProposalDiscount[] = undefined;
	auditInfo:AuditInfo = undefined;
}
/**
 * Creates discounts for the Geo Pricing Lanes baesd on the input data.
 */
export class ListGeoPricingLaneDiscountsRqst {
	/**
	 * unique db identifier of the Geo Pricing Lane record. Discounts are associated with a Geo Pricing Lane
	 */
	geoPricingLaneId:string = undefined;
	prcProposalInstId:string = undefined;
	listInfo:ListInfo = undefined;
}
/**
 * Response message of CreateGeoPricingLaneDiscounts
 */
export class ListGeoPricingLaneDiscountsResp {
	discountDetails:ProposalDiscount[] = undefined;
	listInfo:ListInfo = undefined;
}
/**
 * Input message to create the Geo Pricing Discount and Absolute Minimum charge amount record
 */
export class CreateGeoPricingDiscountAmcSummaryRqst {
	/**
	 * unique identifier of the Pricing Proposal
	 */
	prcProposalInstId:string = undefined;
	geoDiscountAmcInfo:GeoDiscountAmcSummary = undefined;
}
/**
 * Response message to create the Geo Pricing Discount and Absolute Minimum charge amount record
 */
export class CreateGeoPricingDiscountAmcSummaryResp {
	/**
	 * unique identifier of the Pricing Proposal
	 */
	prcProposalInstId:string = undefined;
	geoDiscountAmcInfo:GeoDiscountAmcSummary = undefined;
}
/**
 * Input message to create the Geo Pricing Discount and Absolute Minimum charge amount record
 */
export class UpdateGeoPricingDiscountAmcSummaryRqst {
	/**
	 * unique identifier of the Pricing Proposal
	 */
	prcProposalInstId:string = undefined;
	/**
	 * unique identifier of the geo pricing discount and amc summary record
	 */
	geoDiscAmcSummaryId:string = undefined;
	geoDiscountAmcInfo:GeoDiscountAmcSummary = undefined;
}
/**
 * Input message to retrieve the Geo Pricing Discount and Absolute Minimum charge amount record
 */
export class ListGeoPricingDiscountAmcSummaryRqst {
	/**
	 * unique identifier of the Pricing Proposal
	 */
	prcProposalInstId:string = undefined;
	/**
	 * pagination information to retrieve list
	 */
	listInfo:ListInfo = undefined;
}
/**
 * Response message to retrieve the Geo Pricing Discount and Absolute Minimum charge amount record
 */
export class ListGeoPricingDiscountAmcSummaryResp {
	/**
	 * unique identifier of the Pricing Proposal
	 */
	prcProposalInstId:string = undefined;
	geoDiscountAmcInfo:GeoDiscountAmcSummary[] = undefined;
	/**
	 * pagination information to retrieve list
	 */
	listInfo:ListInfo = undefined;
}
/**
 * Input message to delete the Geo Pricing Discount and Absolute Minimum charge amount record
 */
export class DeleteGeoPricingDiscountAmcSummaryRqst {
	/**
	 * unique identifier of the Pricing Proposal
	 */
	prcProposalInstId:string = undefined;
	/**
	 * unique identifier of the geo pricing discount and amc summary record
	 */
	geoDiscAmcSummaryId:string = undefined;
}
/**
 * Request message to create the GeoPricingLaneAMC record
 */
export class CreateGeoPricingLaneAmcsRqst {
	prcProposalInstId:string = undefined;
	geoPricingLaneId:string = undefined;
	amcDetails:PricingAmc[] = undefined;
	auditInfo:AuditInfo = undefined;
}
/**
 * Response message
 */
export class CreateGeoPricingLaneAmcsResp {
	geoPricingLaneId:string = undefined;
	amcDetails:GeoPricingLaneAmc[] = undefined;
	auditInfo:AuditInfo = undefined;
}
/**
 * Request message to create the GeoPricingLaneAMC record
 */
export class UpdateGeoPricingLaneAmcsRqst {
	geoPricingLaneId:string = undefined;
	prcProposalInstId:string = undefined;
	amcDetails:GeoPricingLaneAmc = undefined;
	auditInfo:AuditInfo = undefined;
}
/**
 * Response message
 */
export class UpdateGeoPricingLaneAmcsResp {
	geoPricingLaneId:string = undefined;
	amcDetails:GeoPricingLaneAmc = undefined;
	auditInfo:AuditInfo = undefined;
}
/**
 * Retrieve a list of Absolute Minimum Charge records for a given geo pricing lane
 */
export class ListGeoPricingLaneAmcsRqst {
	/**
	 * unique db identifier of the geo pricing lane record
	 */
	geoPricingLaneId:string = undefined;
	prcProposalInstId:string = undefined;
	/**
	 * pagination information
	 */
	listInfo:ListInfo = undefined;
}
/**
 * Retrieve a list of Absolute Minimum Charge records for a given geo pricing lane
 */
export class ListGeoPricingLaneAmcsResp {
	/**
	 * unique db identifier of the geo pricing lane record
	 */
	geoPricingLaneId:string = undefined;
	amcDetails:GeoPricingLaneAmc[] = undefined;
	/**
	 * pagination information
	 */
	listInfo:ListInfo = undefined;
}
/**
 * Deletes one all all Geo Pricing Lane Amc records for a given Geo Pricing Lane id
 */
export class DeleteGeoPricingLaneAmcsRqst {
	/**
	 * unique db identifier of geo pricing lane
	 */
	geoPricingLaneId:string = undefined;
	prcProposalInstId:string = undefined;
	/**
	 * unique db identifier of geo pricing lane amc
	 */
	geoPricingLaneAmcId:string = undefined;
}
/**
 * Retreive the FAK Adjustment percent based on input Actual NMFC Class, FAK Class, effective date and expiry date
 */
export class GetPricingAnalysisFakAdjustmentRqst {
	actualNmfcClass:string = undefined;
	fakNmfcClass:string = undefined;
	effDate:Date = undefined;
	expDate:Date = undefined;
}
export class GetPricingAnalysisFakAdjustmentResp {
	fakAdjPct:number = undefined;
}
/**
 * Retrieves FSC discount percent based on input fuel price per gallon and effective date
 */
export class GetPricingAnalysisFscDiscountRqst {
	fuelPricePerGallon:MonetaryAmount = undefined;
	effDate:Date = undefined;
	expDate:Date = undefined;
}
export class GetPricingAnalysisFscDiscountResp {
	fscDiscountPct:number = undefined;
}
/**
 * Retrieves the fuel price per gallon based on input date.
 */
export class GetFuelPriceAmountRqst {
	effDate:Date = undefined;
}
export class GetFuelPriceAmountResp {
	fuelPricePerGallon:MonetaryAmount = undefined;
}
/**
 * This service returns the LOWER_BOUND, UPPER_BOUND, OPTIMAL CDI from ADMIN_Analysis_CDI_Factor based on the input sales volume, naics, region. One row will be returned based on the input data.
 */
export class GetPricingAnalysisCdiFactorRqst {
	custSalesVolCd:string = undefined;
	naicsCd:string = undefined;
	salesRegionCd:string = undefined;
	effDate:Date = undefined;
	expDate:Date = undefined;
}
export class GetPricingAnalysisCdiFactorResp {
	cdiFactor:AnalysisFactorCdi = undefined;
}
/**
 * Input message to retrieve sales rfp details
 */
export class GetSalesRfpRqst {
	salesRfpId:string = undefined;
	levelOfDetail:string = undefined;
}
/**
 * Retrieves Sales Rfp details based on input Sales Rfp Id
 */
export class GetSalesRfpResp {
	salesRfp:SalesRfp = undefined;
}
/**
 * Request message to return the Class Rates based on input Pricing Agreement Instance Id and Ruleset sequence number
 */
export class GetClassRatesByPricingAgrmtAndRulesetRqst {
	/**
	 * Pricing Agreement Instance Id
	 */
	agreementId:string = undefined;
	/**
	 * Ruleset Sequence Number
	 */
	rulesetNbr:number = undefined;
	/**
	 * Shipment Information required for retrieving the class rates
	 */
	shipmentInfo:RulesetShipment = undefined;
	/**
	 * Party role: Values are S (Shipper), C(Consignee), B(bill to)
	 */
	partyTypeCode:string = undefined;
}
export class GetClassRatesByPricingAgrmtAndRulesetResp {
	classRate:ClassRate = undefined;
}
/**
 * Request message to return the Class Rates based on input Customer Id and Shipment data
 */
export class GetClassRatesByCustomerIdRqst {
	/**
	 * Customer Account Instance Id for which to find the Pricing Agreement and class rates
	 */
	acctInstId:string = undefined;
	/**
	 * Party role: Values are S (Shipper), C(Consignee), B(bill to)
	 */
	partyTypeCode:string = undefined;
	/**
	 * Shipment Information required for retrieving the class rates
	 */
	shipmentInfo:RulesetShipment = undefined;
}
export class GetClassRatesByCustomerIdResp {
	/**
	 * Information about the class rates from the customer specific pricing agreement ruleset
	 */
	classRate:ClassRate = undefined;
}
/**
 * List of Customer Pricing Pricing Agreements for input Customer Identifier
 */
export class ListAcctPricingAgreementRqst {
	/**
	 * Acct Id of the customer
	 */
	acctInstId:string[] = undefined;
	/**
	 * Acct Id of the negotiating customer
	 */
	leadAcctId:string = undefined;
	/**
	 * pagination information for list retrieval
	 */
	listInfo:ListInfo = undefined;
}
/**
 * List of Customer Accounts with their Pricing Agreement Identifier Information
 */
export class ListAcctPricingAgreementResp {
	pricingAgreementsByAcct:PricingAgreementByAcct[] = undefined;
	listInfo:ListInfo = undefined;
}
export class ListPricingAgreementAcctsResp {
	agreementAccts:AcctId[] = undefined;
	listInfo:ListInfo = undefined;
}
/**
 * Retrieves a list of Customer locations (Accounts) associated to an input Pricing Agreement
 */
export class ListPricingAgreementAcctsRqst {
	/**
	 * Customer Account Id
	 */
	leadAcctId:string = undefined;
	/**
	 * Version number of the pricing agreement
	 */
	agreementAcctVersion:string = undefined;
	/**
	 * The sequence number within the version of pricing agreement
	 */
	agreementSeqNbr:number = undefined;
	/**
	 * pagination information to retrieve list
	 */
	listInfo:ListInfo = undefined;
}
/**
 * Request message to get details of input pricing codes
 */
export class ListPricingCodesByTypeRqst {
	categoryCode:string[] = undefined;
	subCategoryCode:string[] = undefined;
	listInfo:ListInfo = undefined;
}
/**
 * Response message containing details of each of the input pricing code
 */
export class ListPricingCodesByTypeResp {
	codeDetails:Code[] = undefined;
	listInfo:ListInfo = undefined;
}
/**
 * Input message to create a Pricing Code
 */
export class CreatePricingCodeRqst {
	codeDetails:Code = undefined;
}
/**
 * Input message to delete a Pricing Code
 */
export class DeletePricingCodeRqst {
	pricingCodeId:string = undefined;
}
/**
 * Input message to create a Pricing Code
 */
export class CreatePricingCodeResp {
	/**
	 * unique db identifier of the pricing code
	 */
	pricingCodeId:string = undefined;
}
/**
 * Input message to create a Pricing Code
 */
export class UpdatePricingCodeRqst {
	codeDetails:Code = undefined;
	pricingCodeId:string = undefined;
}
/**
 * List of All LTL Accessorials 
 */
export class ListGlobalAccessorialsResp {
	globalAccessorials:AccessorialService[] = undefined;
	listInfo:ListInfo = undefined;
}
/**
 * List of All LTL Accessorials 
 */
export class ListGlobalAccessorialsRqst {
	listInfo:ListInfo = undefined;
}
/**
 * Request message for list of pricing analysts for a given pricing manager id
 */
export class ListPricingAnalystsByMgrIdRqst {
	pricingMgrEmplId:string = undefined;
	ListInfo:ListInfo = undefined;
}
/**
 * Request message to update an existing leaf shipment based on proposal instance id
 */
export class UpdatePricingLeafShipmentRqst {
	leafShipment:PricingLeafShipment = undefined;
}
/**
 * Request message to update Pricing eaf shipments in bulk
 */
export class BulkUpdatePricingLeafShipmentRqst {
	leafShipments:PricingLeafShipment[] = undefined;
}
/**
 * Input message to retrieve a list of Pricing Analysis Leaf Shipments based on input Pricing Proposal Id
 */
export class ListPricingLeafShipmentsRqst {
	prcProposalInstId:string = undefined;
	listInfo:ListInfo = undefined;
}
/**
 * Creates PricingLeafShipments by reading the s and running them thru the segmentation model
 */
export class SegmentPricingLeafShipmentsRqst {
	prcProposalInstId:string = undefined;
}
/**
 * Enriches the Pricing Leaf Shipments with Geo and NTD data from BI Extract. Input is Pricing proposal id
 */
export class EnrichPricingLeafShipmentsRqst {
	prcProposalInstId:object = undefined;
}
/**
 * Creates PricingLeafShipments by reading the PricingAnalysisShipments and running them thru the segmentation model
 */
export class SegmentPricingLeafShipmentsResp {
	prcProposalInstId:string = undefined;
}
/**
 * List of Pricing Leaf shipments for a given Pricing Proposal Id
 */
export class ListPricingLeafShipmentsResp {
	leafShipments:PricingLeafShipment[] = undefined;
	listInfo:ListInfo = undefined;
}
export class ListPricingAnalystsByMgrIdResp {
	pricingAnalystDetails:PricingAdminPersonnel[] = undefined;
	listInfo:ListInfo = undefined;
}
/**
 * Input message to retrieve 1 or more Pricing Proposal Headers
 */
export class GetPricingProposalHeaderRqst {
	prcProposalInstId:string = undefined;
}
/**
 * Retrieves a list of Pricing Proposal Header records based on input 
 */
export class GetPricingProposalHeaderResp {
	pricingProposalHeader:PricingProposalHdr = undefined;
}
/**
 * Retrieves a list of Pricing Proposal Header records based on input 
 */
export class ListPricingProposalHeadersResp {
	pricingProposalHeaders:PricingProposalHdr[] = undefined;
	listInfo:ListInfo = undefined;
}
/**
 * Input message to retrieve 1 or more Pricing Proposal Headers
 */
export class ListPricingProposalHeadersRqst {
	salesRfpId:string = undefined;
	analysisTypeCd:string = undefined;
	proposalStatCd:string = undefined;
	pricingAnalystId:string = undefined;
	employeeRoleType:string[] = undefined;
	listInfo:ListInfo = undefined;
}
/**
 * Request message to retrieve Pricing Proposal Details for a given Pricing Proposal Id
 */
export class GetPricingProposalRqst {
	prcProposalInstId:string = undefined;
	/**
	 * identifies the level of detail to be included in the response
	 */
	levelOfDetail:string[] = undefined;
	excludeHdr:boolean = undefined;
}
/**
 * Retrieves a list of Pricing Proposal Header records based on input 
 */
export class GetPricingProposalResp {
	pricingProposal:PricingProposal = undefined;
}
/**
 * Returns the pricing agreements either associated with the negotiating customer, which is a corporate account, or for the list of one or more accounts which are P2D or Bil2 accounts. The negotiating customer will be used to find pricing agreement for which it is the owner. The list of accounts will be used to search the rulesets where they are referenced and find the pricing agreements to which those rulesets belong.		
 */
export class GetPricingAgreementLiabilityDetailsRqst {
	/**
	 * Acct Id of the customer
	 */
	acctInstId:string = undefined;
	/**
	 * Sequence number of the agreement
	 */
	seqNbr:string = undefined;
	/**
	 * Version number of the agreement
	 */
	versionNbr:string = undefined;
	/**
	 * Agreement instance id
	 */
	agreementId:string = undefined;
}
/**
 * Returns the pricing agreements either associated with the negotiating customer, which is a corporate account, or for the list of one or more accounts which are P2D or Bil2 accounts. The negotiating customer will be used to find pricing agreement for which it is the owner. The list of accounts will be used to search the rulesets where they are referenced and find the pricing agreements to which those rulesets belong.
 * List of Customer Accounts with their Pricing Agreement Identifier Information
 */
export class GetPricingAgreementLiabilityDetailsResp {
	/**
	 * The liability pricing agreement details.
	 */
	liabilityPricingAgreement:PricingAgreement = undefined;
}
/**
 * Sales Rfp Id returned after successful creation
 */
export class CreateSalesRfpAccessorialsResp {
	salesRfpId:string = undefined;
}
/**
 * Input message to create Sales Rfp accessorials for a given sales rfp
 */
export class CreateSalesRfpAccessorialsRqst {
	salesRfpId:string = undefined;
	salesRfpAccessorials:PricingAccessorial[] = undefined;
}
/**
 * List of Sales Rfp Accessorials for a given Sales Rfp Identifier
 */
export class ListSalesRfpAccessorialsRqst {
	salesRfpId:string = undefined;
	listInfo:ListInfo = undefined;
}
/**
 * List of Sales Rfp Accessorials for a given Sales Rfp Identifier
 */
export class ListSalesRfpAccessorialsResp {
	salesRfpAccessorials:PricingAccessorial[] = undefined;
	listInfo:ListInfo = undefined;
}
/**
 * List sales rfp requests that meet certain criteria.
 */
export class ListSalesRfpRqst {
	/**
	 * Identifier of the Customer with whom the agreement is being or was negotiated. This will uniquely identify the customer in the CIS component.
	 */
	negotiatingCustInstId:string = undefined;
	/**
	 * This is the business key at the time of negotiation for this customer. Note that it will not be kept in synch with the master customer database.
	 */
	negotiatingCustMadCd:string = undefined;
	/**
	 * The date the Sales RFP is due from Pricing back to Sales
	 */
	dueDate:Date = undefined;
	/**
	 * Requested Effective Date for the Agreement if the request is approved.
	 */
	proposedEffDate:Date = undefined;
	/**
	 * Proposed Expiration Date for the Agreement if the request is approved.
	 */
	proposedExpDate:Date = undefined;
	/**
	 * The ID of the Pricing Analyst
	 */
	pricingAnalystId:string = undefined;
	/**
	 * The ID of the owner of sales RFP.
	 */
	ownerId:string = undefined;
	/**
	 * The ID of the owner of sales RFP, the owner transfers to the party that is actively working on the RFP.
	 */
	currentOwnerId:string = undefined;
	listInfo:ListInfo = undefined;
	/**
	 * Start date  to filter the Sales RFP
	 */
	startDate:Date = undefined;
	/**
	 * End date to filter the Sales RFP
	 */
	endDate:Date = undefined;
	/**
	 * Sales Region code of the Sales RFP
	 */
	salesRegionCd:string = undefined;
	/**
	 * Sales District code of the Sales RFP
	 */
	salesDistrictCd:string = undefined;
}
/**
 * List sales rfp requests that meet certain criteria. Returns 1 or more sales rfp headers
 */
export class ListSalesRfpResp {
	/**
	 * The Sales RFP header info that meets the input criteria.
	 */
	salesRfp:SalesRfp[] = undefined;
	/**
	 * The total number of rows in the list.
	 */
	listInfo:ListInfo = undefined;
}
export class ListSalesRfpLifeCycleRqst {
	listInfo:ListInfo = undefined;
	acctId:string = undefined;
	dateCriteria:string = undefined;
	startDate:Date = undefined;
	endDate:Date = undefined;
	/**
	 * The sales region code based on the sales agent's region code at RFP creation time
	 */
	salesRegionCd:string = undefined;
	/**
	 * Area Code of sales rfp owner
	 */
	areaCode:string = undefined;
	reasonCd:string = undefined;
	/**
	 * Current status of the request (Sales or Pricing). Sample values: Approved, Canceled, NewRFP, InSales, InPricing, InPricingAnalysis
	 */
	rfpStatusCd:string = undefined;
	/**
	 * Current status of the pricing proposal (from 'not started' thru 'accepted'. Sample values (not complete): Not Started, Analysis in Progress, Under Review, Submitted to Sales, On-Hold, Cancelled, Accepted, Rejected, Countered
	 */
	proposalStatCd:string = undefined;
	/**
	 * audit status for the sales rfp once agreement is created
	 */
	auditStatus:string = undefined;
	rulesetBuildStatus:string = undefined;
}
export class ListSalesRfpLifeCycleResp {
	salesRfp:SalesRfp[] = undefined;
	listInfo:ListInfo = undefined;
}
/**
 * Build sales rfp customer shipments details for a given sales rfp id and create customer shipments using sales rfp constraints and constraint end points
 */
export class BuildSalesRfpCustomerShipmentsRqst {
	/**
	 * unique identifier of sales rfp
	 */
	salesRfpId:string = undefined;
}
/**
 * Build sales rfp customer shipments details for a given sales rfp id and create customer shipments using sales rfp constraints and constraint end points
 */
export class BuildSalesRfpCustomerShipmentsResp {
	/**
	 * unique identifier of sales rfp
	 */
	salesRfpId:string = undefined;
}
/**
 * Updates sales rfp customer shipment details for a given sales rfp id and customer shipment file id
 */
export class UpdateSalesRfpCustomerShipmentsRqst {
	salesRfpId:string = undefined;
	custShipmentFileId:string = undefined;
	rfpCustomerShipment:CustomerShipmentsFile = undefined;
}
/**
 * Build input hierarchy shipment file for a given sales rfp id.
 */
export class BuildInputHierarchyShipmentFileRqst {
	salesRfpId:string = undefined;
}
export class BuildInputHierarchyShipmentFileResp {
	inputHierarchyFile:InputHierarchyFile = undefined;
}
/**
 * Retrieves a list of sales rfp constraints for a given Sales Rfp Id and type (optional)
 */
export class ListSalesRfpConstraintsRqst {
	/**
	 * Unique Identifier of Sales Rfp
	 */
	salesRfpId:string = undefined;
	sourceType:string = undefined;
	/**
	 * Pagination information to retrieve list
	 */
	listInfo:ListInfo = undefined;
}
export class ListSalesRfpConstraintsResp {
	salesRfpConstraints:SalesRfpConstraint[] = undefined;
	listInfo:ListInfo = undefined;
}
/**
 * Deletes an existing Sales Rfp Constraint for a given Sales Rfp Id and Rfp Constraint Id
 */
export class DeleteSalesRfpConstraintRqst {
	salesRfpId:string = undefined;
	rfpConstraintId:string = undefined;
}
/**
 * Updates an existing Sales Rfp Constraint for an input Sales Rfp Id and Rfp Constraint id
 */
export class UpdateSalesRfpConstraintRqst {
	salesRfpId:string = undefined;
	rfpConstraintId:string = undefined;
	salesRfpConstraint:SalesRfpConstraint = undefined;
}
/**
 * Creates a Sales Rfp Constraint record for a given Sales Rfp Id
 */
export class CreateSalesRfpConstraintRqst {
	salesRfpId:string = undefined;
	salesRfpConstraint:SalesRfpConstraint = undefined;
}
export class CreateSalesRfpConstraintResp {
	salesRfpId:string = undefined;
	salesRfpConstraint:SalesRfpConstraint = undefined;
}
/**
 * Request message to update User Preference
 */
export class UpdateUserPreferenceRqst {
	/**
	 * employeeId for User Preference
	 */
	employeeId:string = undefined;
	/**
	 * Application Key for User Preference
	 */
	appKey:string = undefined;
	/**
	 * User Preference
	 */
	userPreference:UserPreference = undefined;
}
/**
 * Request message to retrieve User Preference
 */
export class GetUserPreferenceRqst {
	/**
	 * employeeId for User Preference
	 */
	employeeId:string = undefined;
	/**
	 * Application Key for User Preference
	 */
	appKey:string = undefined;
}
export class GetUserPreferenceResp {
	userPreference:UserPreference = undefined;
}
/**
 * Request message to get the account information from the CRM System
 */
export class GetCrmAccountInfoRqst {
	accountId:string = undefined;
}
export class GetCrmAccountInfoResp {
	accountInfo:CrmAccount = undefined;
	accountOwner:CrmAccountOwner[] = undefined;
	parentAccount:CrmAccount = undefined;
	ultimateAccountParent:CrmAccount = undefined;
}
/**
 * Request message to get the account information from the CRM System
 */
export class ListCrmAccountInfoRqst {
	accountId:string[] = undefined;
	listInfo:ListInfo = undefined;
}
export class ListCrmAccountInfoResp {
	accountInfo:ListCrmAccountInfoResp_CrmAccount[] = undefined;
	listInfo:ListInfo = undefined;
}
/**
 * Response message
 */
export class CreatePricingAnalysisShipmentResp {
	prcProposalInstId:object = undefined;
}
/**
 * Input message to create Pricing Analysis Shipments records
 */
export class CreatePricingAnalysisShipmentRqst {
	analysisShipments:PricingAnalysisShipmentHdr[] = undefined;
}
/**
 * Input message to updae Pricing Analysis Shipments records
 */
export class UpdatePricingAnalysisShipmentRqst {
	analysisShipments:PricingAnalysisShipment[] = undefined;
	intraAmc:string = undefined;
	interAmc:string = undefined;
	intlAmc:string = undefined;
	enableAnalysis:boolean = undefined;
	adjustPricingType:string = undefined;
}
/**
 * Input message to retrieve a list of Pricing Analysis Shipments for a given Pricing Proposal Id
 */
export class ListPricingAnalysisShipmentsRqst {
	prcProposalInstId:string = undefined;
	listInfo:ListInfo = undefined;
}
/**
 * Input message to retrieve a list of Pricing Analysis Shipments for a given Pricing Proposal Id
 */
export class ListPricingAnalysisShipmentsResp {
	analysisShipments:PricingAnalysisShipment[] = undefined;
	listInfo:ListInfo = undefined;
}
/**
 * Input message to delete one or more Analysis shipments for a given Pricing Proposal Id
 */
export class DeletePricingAnalysisShipmentsRqst {
	/**
	 * Unique identifier of Pricing Proposal
	 */
	prcProposalInstId:string = undefined;
	prcAnalysisShipmentId:string[] = undefined;
}
/**
 * Updates Analysis Shipments from a CSV file
 */
export class UpdatePricingAnalysisShipmentFromCsvRqst {
	prcProposalInstId:string = undefined;
	docFiles:File[] = undefined;
	docMappingInfo:string = undefined;
}
/**
 * Input message to create Shipment Accessorial records for a given Pricing Analysis Shipment record
 */
export class CreatePricingAnalysisShipmentAccessorialsRqst {
	prcProposalInstId:string = undefined;
	prcAnlysShipmentId:string = undefined;
	shipmentAccessorials:PricingAnalysisShipmentAccessorial[] = undefined;
	enableAnalysis:boolean = undefined;
}
export class CreatePricingAnalysisShipmentAccessorialsResp {
	prcAnlysShipmentId:string = undefined;
}
/**
 * Input message to retrieve a list of Shipment Accessorial records for a given Pricing Analysis Shipment and Pricing Proposal Id
 */
export class ListPricingAnalysisShipmentAccessorialsRqst {
	prcProposalInstId:string = undefined;
	prcAnlysShipmentId:string = undefined;
	listInfo:ListInfo = undefined;
}
export class ListPricingAnalysisShipmentAccessorialsResp {
	prcAnlysShipmentId:string = undefined;
	shipmentAccessorials:PricingAnalysisShipmentAccessorial[] = undefined;
	listInfo:ListInfo = undefined;
}
/**
 * Input message to update Shipment Accessorial records for a given Pricing Analysis Shipment record
 */
export class UpdatePricingAnalysisShipmentAccessorialsRqst {
	prcProposalInstId:string = undefined;
	enableAnalysis:boolean = undefined;
	shipmentAccessorials:PricingAnalysisShipmentAccessorial[] = undefined;
	groupShipmentAccsrl:PricingGroupShipmentAccsrl = undefined;
}
/**
 * Input message to update Shipment Accessorial records for a given Pricing Analysis Shipment record
 */
export class DeletePricingAnalysisShipmentAccessorialsRqst {
	prcProposalInstId:string = undefined;
	prcAnlysShipmentId:string = undefined;
	prcAnlysShipmentAccsrlId:string = undefined;
}
/**
 * Input message to create Shipment Accessorial records for a given Pricing Analysis Shipment record
 */
export class CreatePricingProposalAccessorialsRqst {
	prcProposalInstId:string = undefined;
	proposalAccessorial:PricingAccessorial[] = undefined;
}
export class CreatePricingProposalAccessorialsResp {
	prcProposalInstId:string = undefined;
}
/**
 * Input message to retrieve a list of Shipment Accessorial records for a given Pricing Analysis Shipment and Pricing Proposal Id
 */
export class ListPricingProposalAccessorialsRqst {
	prcProposalInstId:string = undefined;
	listInfo:ListInfo = undefined;
}
export class ListPricingProposalAccessorialsResp {
	prcProposalInstId:string = undefined;
	proposalAccessorials:PricingProposalAccessorial[] = undefined;
	listInfo:ListInfo = undefined;
}
/**
 * Input message to update Shipment Accessorial records for a given Pricing Analysis Shipment record
 */
export class UpdatePricingProposalAccessorialsRqst {
	prcProposalInstId:string = undefined;
	pricingProposalAccessorial:PricingProposalAccessorial[] = undefined;
}
/**
 * Input message to update Shipment Accessorial records for a given Pricing Analysis Shipment record
 */
export class DeletePricingProposalAccessorialsRqst {
	prcProposalInstId:string = undefined;
	prcProposalAccsrlId:string = undefined;
}
/**
 * Input message to create the Pricing Proposal Customer Hierarchy record for a given Pricing Proposal Identifier
 */
export class CreatePricingProposalCustHierarchyRqst {
	prcProposalInstId:string = undefined;
	updateShipment:boolean = undefined;
	custHierarchyInfo:PricingProposalCustomerHierarchy[] = undefined;
}
export class CreatePricingProposalCustHierarchyResp {
	prcProposalInstId:string = undefined;
}
/**
 * Input message to update the Pricing Proposal Customer Hierarchy record for an input Pricing Proposal Identifier and input Prc Proposal Cust Hierarchy Identifier
 */
export class UpdatePricingProposalCustHierarchyRqst {
	prcProposalInstId:string = undefined;
	prcProposalCustHierarchyId:string = undefined;
	custHierarchyInfo:PricingProposalCustomerHierarchy = undefined;
}
export class UpdatePricingProposalCustHierarchiesRqst {
	prcProposalInstId:string = undefined;
	updateShipment:boolean = undefined;
	custHierarchyInfo:PricingProposalCustomerHierarchy[] = undefined;
}
/**
 * Input message to delete the Pricing Proposal Customer Hierarchy record for an input Pricing Proposal Identifier and input Prc Proposal Cust Hierarchy Identifier
 */
export class DeletePricingProposalCustHierarchyRqst {
	prcProposalInstId:string = undefined;
	prcProposalCustHierarchyId:string = undefined;
}
/**
 * Retrieves a list of all the Customer hierarchy records for input Pricing Proposal Id
 */
export class ListPricingProposalCustHierarchyRqst {
	prcProposalInstId:string = undefined;
	listInfo:ListInfo = undefined;
}
/**
 * Input message to create the Pricing Proposal Customer Hierarchy record for a given Pricing Proposal Identifier
 */
export class ListPricingProposalCustHierarchyResp {
	custHierarchyInfo:PricingProposalCustomerHierarchy[] = undefined;
	listInfo:ListInfo = undefined;
}
/**
 * Downloadable PDF file response that includes data from Sales Rfp Pricing Agreement Expectaton. Includes the projected and actual OR for each of the pricing agreement
 */
export class GetPricingAgreementMonitorFileResp {
	agreementMonitorFile:File = undefined;
}
/**
 * Retrieves a list of Pricing Agreements based on input Account Id or Pricing Agreement Id
 */
export class ListPricingAgreementsRqst {
	/**
	 * Customer location Account Identifier
	 */
	acctInstId:string = undefined;
	/**
	 * unique identifier of the Pricing Agreement
	 */
	agreementId:string = undefined;
	/**
	 * pagination information to retrieve list
	 */
	listInfo:ListInfo = undefined;
}
export class ListPricingAgreementsResp {
	pricingAgreement:PricingAgreement[] = undefined;
	listInfo:ListInfo = undefined;
}
/**
 * Creates a Pricing Agreement for a Customer Account
 */
export class CreatePricingAgreementRqst {
	/**
	 * Details of Pricing Agreement to be created
	 */
	pricingAgreement:PricingAgreement = undefined;
}
export class CreatePricingAgreementResp {
	/**
	 * unique identifier of the Pricing Agreement
	 */
	agreementId:string = undefined;
}
/**
 * Updates a Pricing Agreement for a Customer Account
 */
export class UpdatePricingAgreementRqst {
	/**
	 * unique identifier of Pricing agreement
	 */
	agreementId:string = undefined;
	/**
	 * Details of Pricing Agreement to be updated
	 */
	pricingAgreement:PricingAgreement = undefined;
}
/**
 * Creates Pricing Agreement Expectations data for input Pricing Agreement Id
 */
export class CreatePricingAgeementExpectationRqst {
	/**
	 * unique identifier of Pricing Agreement
	 */
	agreementId:string = undefined;
	pricingAgreementExpectation:PricingAgreementExpectation = undefined;
}
export class CreatePricingAgreementExpectationResp {
	/**
	 * Unique identifier of Pricing Agreement Expectation
	 */
	agreementExpectationId:string = undefined;
}
export class ListPricingAgreementExpectationsRqst {
	/**
	 * unique identifier of Pricing agreement
	 */
	agreementId:string = undefined;
	/**
	 * The instance ID of the customer that negotiated this pricing agreement
	 */
	leadAcctId:string = undefined;
	/**
	 * The sequence number within the version of pricing agreement
	 */
	agreementSeqNbr:string = undefined;
	/**
	 * The version of the  pricing agreement
	 */
	agreementCustVersion:string = undefined;
	/**
	 * pagination information to retrieve list
	 */
	listInfo:ListInfo = undefined;
}
/**
 * Retrieves a list of Pricing Agreement Expectations for a given Pricing Agreement Id
 */
export class ListPricingAgreementExpectationsResp {
	pricingAgreementExpectation:PricingAgreementExpectation[] = undefined;
	/**
	 * pagination information
	 */
	listInfo:ListInfo = undefined;
}
/**
 * Request message to retrieve details about Pricing Agreement Expectation based on its Id
 */
export class GetPricingAgreementExpectationRqst {
	/**
	 * unique identifier of the Pricing Agreement Expectation
	 */
	agreementExpectationId:string = undefined;
	/**
	 * unique identifier of Pricing agreement
	 */
	agreementId:string = undefined;
}
export class GetPricingAgreementExpectationResp {
	pricingAgreementExpectation:PricingAgreementExpectation = undefined;
}
/**
 * Retrieves a list of Pricing Agreement Shipment summary records for a given Pricing agreement id
 */
export class ListPricingAgreementShipmentSummRqst {
	/**
	 * unique identifier of Pricing agreement
	 */
	agreementId:string = undefined;
	/**
	 * account id of the negotiating customer
	 */
	leadAcctId:string = undefined;
	/**
	 * pagination information to retrieve list
	 */
	listInfo:ListInfo = undefined;
}
export class ListPricingAgreementShipmentSummResp {
	agreementShipmentSumm:AgreementShipmentSummary[] = undefined;
	listInfo:ListInfo = undefined;
}
/**
 * This service returns the count of shipments specified for a given customer or sequence or version
 */
export class GetPricingAgreementShipmentSummRqst {
	/**
	 * The instance ID of the customer that negotiated this pricing agreement
	 */
	leadAcctId:string = undefined;
	/**
	 * The sequence number within the version of pricing agreement
	 */
	agreementSeqNbr:string = undefined;
	/**
	 * The version of the  pricing agreement
	 */
	agreementCustVersion:string = undefined;
	/**
	 * This captures the count of number of months to look back for getting the shipment summary
	 */
	prevMonthCount:string = undefined;
}
/**
 * This service returns the count of shipments specified for a given customer or sequence or version
 */
export class GetPricingAgreementShipmentSummResp {
	agreementShipmentSumm:ShipmentSummary = undefined;
}
/**
 * Request message to retrieve Pricing Agreement details as a PDF file
 */
export class GetPricingAgreementReportFileRqst {
	leadAcctId:string = undefined;
	agreementAcctVersion:string = undefined;
	agreementSeqNbr:string = undefined;
	/**
	 * eg. PDF
	 */
	reportType:string = undefined;
}
export class GetPricingAgreementReportFileResp {
	reportFile:File = undefined;
}
/**
 * Request message to get a list of Pricing Analysis Workflow details
 */
export class ListPricingAnalysisWorkflowProgressRqst {
	prcProposalInstId:string = undefined;
	extractTypeCd:string = undefined;
	extractStatusCd:string = undefined;
	/**
	 * retrieve latest flow indicator
	 */
	latestFlow:boolean = undefined;
	/**
	 * Active Indicator
	 */
	active:boolean = undefined;
	listInfo:ListInfo = undefined;
}
/**
 * Request message to get a list of Pricing Analysis Workflow details
 */
export class ListPricingAnalysisWorkflowProgressResp {
	workflowsByProposalId:PricingAnalysisFlow[] = undefined;
	listInfo:ListInfo = undefined;
}
/**
 * Creates Pricing Analysis flow records based on input data for a given Pricing Proposal Id
 */
export class CreatePricingAnalysisWorkFlowRqst {
	/**
	 * unique id of Pricing Proposal
	 */
	prcProposalInstId:string = undefined;
	analysisFlowInfo:PricingAnalysisFlow = undefined;
}
/**
 * Creates Pricing Analysis flow records based on input data for a given Pricing Proposal Id
 */
export class CreatePricingAnalysisWorkFlowResp {
	/**
	 * unique id of Pricing Proposal
	 */
	prcProposalInstId:string = undefined;
}
/**
 * Updates Pricing Analysis flow records based on input data for a given Pricing Proposal Id and AnalysisFlowInstId
 */
export class UpdatePricingAnalysisWorkflowRqst {
	/**
	 * unique id of Pricing Proposal
	 */
	prcProposalInstId:string = undefined;
	analysisFlowInfo:PricingAnalysisFlow = undefined;
}
/**
 * Deletes one or all Pricing Analysis workflow records based on input Pricing Proposal Id and optional Analysis flow instance id
 */
export class DeletePricingAnalysisWorkflowRqst {
	/**
	 * unique id of Pricing Proposal
	 */
	prcProposalInstId:string = undefined;
	/**
	 * unique id of Pricing Analysis Workflow
	 */
	analysisFlowInstId:string = undefined;
}
/**
 * Retrieve a single result based on a unique Workflow name
 */
export class GetPricingExtractWorkflowRqst {
	workflowNm:string = undefined;
}
/**
 * Retrieve a single result based on a unique Workflow name
 */
export class GetPricingExtractWorkflowResp {
	Workflow:Workflow = undefined;
	auditInfo:AuditInfo = undefined;
}
/**
 * Retrieve a single result based on a ExtractWorkflowId and StepSeqNbr
 */
export class GetPricingExtractWorkflowStepRqst {
	prcExtractWorkflowId:string = undefined;
	stepSeqNbr:string = undefined;
}
/**
 * Retrieve a single result based on a ExtractWorkflowId and StepSeqNbr
 */
export class GetPricingExtractWorkflowStepResp {
	workflowStep:WorkflowStep = undefined;
	auditInfo:AuditInfo = undefined;
}
/**
 * Retrieve a list of all rating tariffs along with their allowed weight breaks. List can be filtered by passing one or more tariff codes
 */
export class ListRatingTariffWeightBreaksRqst {
	/**
	 * Rating tariff code
	 */
	tariffCd:string[] = undefined;
	listInfo:ListInfo = undefined;
}
/**
 * Retrieve a list of all rating tariffs along with their allowed weight breaks. List can be filtered by passing one or more tariff codes
 */
export class ListRatingTariffWeightBreaksResp {
	/**
	 * Rating tariff code
	 */
	ratingTariffWeightBreaks:RatingTariffWgtBreak[] = undefined;
	listInfo:ListInfo = undefined;
}
/**
 * Returns the summary of shipments for a requested pricing group. The inputs are:  a valid Pricing Proposal ID, at least one pricing group category indicators and pricing group category priority. Service can accept more than one group category indicators/priority.
 */
export class CalculatePricingGroupSummaryShipmentsRqst {
	/**
	 * Unique identifier of Pricing Proposal
	 */
	prcProposalInstId:string = undefined;
	/**
	 * Indicates the pricing grouping category. Valid values are:
	 *  'GROUP' - group by Group Name, Orig Group, Dest Group of the shipments
	 *  'TRAFFIC' - group by Traffic Type (International, Interstate, Intrastate) of the shipments
	 *  'BILLING' - group by Application/Billing Type (Inbound, Outbound, 3rd Party) of the shipments
	 *  'WEIGHT' - group by Weight Breaks (L5C, 5C, 1M, 2M, etc) of the shipments
	 *  'CLASS' - group by Commodity Class (50, 55, 60, 65, etc) of the shipments
	 *  'SHIPMENT' - group by shipment id/pro number of the shipments
	 */
	categoryType:string[] = undefined;
	newAmc:string = undefined;
	/**
	 * Input for Pricing Adjustments
	 */
	discAdjustment:string = undefined;
	adjustmentData:PricingAdjustment = undefined;
	/**
	 * pagination information to retrieve list
	 */
	listInfo:ListInfo = undefined;
}
export class CalculatePricingGroupSummaryShipmentsResp {
	pricingGroupSummaryDetails:PricingGroupSummaryShipment[] = undefined;
	/**
	 * pagination information to retrieve list
	 */
	listInfo:ListInfo = undefined;
}
export class CalculatePricingGroupShipmentAccsrlRqst {
	prcProposalInstId:string = undefined;
	adjustmentData:PricingAdjustment = undefined;
	listInfo:ListInfo = undefined;
}
export class CalculatePricingGroupShipmentAccsrlResp {
	listInfo:ListInfo = undefined;
	pricingGroupSummaryDetails:PricingGroupShipmentAccsrl[] = undefined;
}
/**
 * Creates a GeoPricingGroup record. The Geo Pricing Group contains a list of members that are included and optionally, a list of members that are to be excluded from the Pricing Group.
 */
export class CreateGeoPricingGroupsRqst {
	/**
	 * Details about the GeoPricingGroup to be created along with its Included and Excluded members
	 */
	geoPricingGroupInfo:GeoPricingGroup[] = undefined;
}
export class CreateGeoPricingGroupsResp {
	/**
	 * Unique Identifier of the GeoPricingGroup
	 */
	geoPricingGroupId:string = undefined;
}
/**
 * Updates a GeoPricingGroup record. The Geo Pricing Group contains a list of members that are included and optionally, a list of members that are to be excluded from the Pricing Group.
 */
export class UpdateGeoPricingGroupRqst {
	/**
	 * Unique id of the Geo Pricing Group Record
	 */
	geoPricingGroupId:string = undefined;
	geoPricingGroupInfo:GeoPricingGroup = undefined;
}
/**
 * Retrieves a list of all the GeoPricingGroups and, depending upon the level of detail, their members
 */
export class ListGeoPricingGroupsRqst {
	/**
	 * pagination information to retrieve list
	 */
	listInfo:ListInfo = undefined;
	/**
	 * unique db identifier of Geo Pricing Group record
	 */
	geoPricingGroupId:string[] = undefined;
	/**
	 * Unique id of the Customer Record or Customer MAD Code
	 */
	custAcctId:string = undefined;
}
export class ListGeoPricingGroupsResp {
	/**
	 * pagination information
	 */
	listInfo:ListInfo = undefined;
	/**
	 * list of geo pricing groups and their member details
	 */
	geoPricingGroups:GeoPricingGroup[] = undefined;
}
/**
 * Retrieves a list of all GeoPricingGroupMembers for a given GeoPricingGroupIdentifier
 */
export class ListGeoPricingGroupMembersRqst {
	/**
	 * unique identifier of GeoPricingGroup
	 */
	geoPricingGroupId:string = undefined;
	/**
	 * pagination information to retrieve list
	 */
	listInfo:ListInfo = undefined;
}
export class ListGeoPricingGroupMembersResp {
	/**
	 * pagination information
	 */
	listInfo:ListInfo = undefined;
	/**
	 * GeoPricingGroup detail
	 */
	geoPricingGroup:GeoPricingGroup = undefined;
}
/**
 * Creates 1 or more GeoPricingGroupMember records for input GeoPricingGroupIdentifier
 */
export class CreateGeoPricingGroupMembersRqst {
	/**
	 * unique identifier of the GeoPricingGroup record
	 */
	geoPricingGroupId:object = undefined;
	/**
	 * GeoPricingGroupMembers to be included in the group
	 */
	geoPricingGroupMembers:GeoPricingGroupMember[] = undefined;
	/**
	 * GeoPricingGroupMembers to be excluded from the group
	 */
	geoPricingGroupExclMbr:GeoPricingGroupMember[] = undefined;
}
/**
 * Creates 1 or more GeoPricingGroupMember records for input GeoPricingGroupIdentifier
 */
export class CreateGeoPricingGroupMembersResp {
	/**
	 * unique identifier of the GeoPricingGroup record
	 */
	geoPricingGroupId:object = undefined;
}
/**
 * Deletes 1 GeoPricingGroup and all its members for input GeoPricingGroupIdentifier
 */
export class DeleteGeoPricingGroupRqst {
	/**
	 * unique identifier of the GeoPricingGroup record
	 */
	geoPricingGroupId:string = undefined;
}
/**
 * Deletes 1 or all GeoPricingGroupMembers for input GeoPricingGroupIdentifier and optional GeoPricingGroupMemberId
 */
export class DeleteGeoPricingGroupMembersRqst {
	/**
	 * unique identifier of the GeoPricingGroup record
	 */
	geoPricingGroupId:string = undefined;
	/**
	 * unique identifier of GeoPricingGroupMember
	 */
	geoPricingGroupMemberId:string = undefined;
}
/**
 * Deletes 1 or all GeoPricingGroupExcludedMembers for input GeoPricingGroupIdentifier and optional GeoPricingGroupExclMbrId
 */
export class DeleteGeoPricingGroupExclMbrRqst {
	/**
	 * unique identifier of the GeoPricingGroup record
	 */
	geoPricingGroupId:string = undefined;
	/**
	 * unique identifier of GeoPricingGroupExcludedMember
	 */
	geoPricingGroupExclMbrId:string = undefined;
}
/**
 * Creates a geo pricing group lane
 */
export class CreateGeoPricingGroupLanesRqst {
	/**
	 * Geo Pricing Group Lane information
	 */
	geoPricingGroupLane:GeoPricingGroupLane[] = undefined;
}
export class CreateGeoPricingGroupLanesResp {
	geoPricingGroupLane:GeoPricingGroupLane[] = undefined;
}
/**
 * Retrieves a list of Geo Pricing Group Lane records
 */
export class ListGeoPricingGroupLanesRqst {
	/**
	 * pagination information to retrieve list
	 */
	listInfo:ListInfo = undefined;
	/**
	 * customer account number
	 */
	acctInstId:string = undefined;
	/**
	 * customer account Mad code
	 */
	acctMadCd:string = undefined;
	/**
	 * unique identifier of the Geo Pricing Lanes
	 */
	geoLaneIds:string[] = undefined;
}
export class ListGeoPricingGroupLanesResp {
	geoPricingGroupLanes:GeoPricingGroupLane[] = undefined;
	listInfo:ListInfo = undefined;
}
/**
 * Updates a geo pricing group lane record based on the input data
 */
export class UpdateGeoPricingGroupLanesRqst {
	geoPricingGroupLaneId:string = undefined;
	/**
	 * Geo Pricing Group Lane information
	 */
	geoPricingGroupLane:GeoPricingGroupLane = undefined;
}
/**
 * Deletes a geo pricing group lane and its members
 */
export class DeleteGeoPricingGroupLanesRqst {
	/**
	 * unique identifier of the Geo Pricing Group Lane record
	 */
	geoPricingGroupLaneId:string = undefined;
	/**
	 * unique identifier of the geo pricing group lane member record
	 */
	geoPricingGroupLaneMbrId:string = undefined;
}
export class CalculatePricingGroupShipmentAmcRqst {
	prcProposalInstId:string = undefined;
	intraAmc:string = undefined;
	interAmc:string = undefined;
	intlAmc:string = undefined;
	listInfo:ListInfo = undefined;
}
export class CalculatePricingGroupShipmentAmcResp {
	pricingGroupSummaryDetails:PricingGroupShipmentAmc[] = undefined;
	listInfo:ListInfo = undefined;
}
/**
 * Creates Pricing Analysis Geo Group Header record for a given Pricing Proposal Id
 */
export class CreatePricingAnlysGeoGroupHeadersRqst {
	/**
	 * unique identifier of Pricing Proposal
	 */
	prcProposalInstId:string = undefined;
	/**
	 * analysis geo group header data to be persisted
	 */
	pricingAnlysGeoGroup:PricingAnalysisGeoGroupHdr[] = undefined;
}
export class CreatePricingAnlysGeoGroupHeadersResp {
	prcProposalInstId:string = undefined;
}
/**
 * Updates Pricing Analysis Geo Group Header record for a given Pricing Proposal Id
 */
export class UpdatePricingAnlysGeoGroupHeaderRqst {
	/**
	 * unique identifier of Pricing Proposal
	 */
	prcProposalInstId:string = undefined;
	/**
	 * unique identifier of Pricing Analysis Geo Group Header record
	 */
	prcAnalysGeoGroupId:string = undefined;
	/**
	 * analysis geo group header data to be persisted
	 */
	pricingAnlysGeoGroup:PricingAnalysisGeoGroupHdr = undefined;
}
/**
 * Deletes Pricing Analysis Geo Group Header record for a given Pricing Proposal Id
 */
export class DeletePricingAnlysGeoGroupHeaderRqst {
	/**
	 * unique identifier of Pricing Proposal
	 */
	prcProposalInstId:string = undefined;
	/**
	 * unique identifier of Pricing Analysis Geo Group Header record
	 */
	prcAnalysGeoGroupId:string = undefined;
}
/**
 * Retrieves Pricing Analysis Geo Group Header record for a given Pricing Proposal Id
 */
export class ListPricingAnlysGeoGroupHeadersRqst {
	/**
	 * unique identifier of Pricing Proposal
	 */
	prcProposalInstId:string = undefined;
	listInfo:ListInfo = undefined;
}
export class ListPricingAnlysGeoGroupHeadersResp {
	pricingAnlysGeoGroupHdr:PricingAnalysisGeoGroupHdr[] = undefined;
	listInfo:ListInfo = undefined;
}
/**
 * Create pricing analyis geo group historical shipments absolute minimum charge record
 */
export class CreatePricingAnlysGeoGroupBiAmcsRqst {
	/**
	 * unique id of Pricing Proposal
	 */
	prcProposalInstId:string = undefined;
	/**
	 * unique id of Pricing Analysis Geo Group
	 */
	prcAnalysGeoGroupId:string = undefined;
	biAmcDetails:GeoGroupAmcTariff = undefined;
}
/**
 * Create pricing analyis geo group historical shipments absolute minimum charge record
 */
export class CreatePricingAnlysGeoGroupBiAmcsResp {
	/**
	 * unique id of the Geo Group BI Amc record
	 */
	geoGroupBiAmcId:string = undefined;
}
/**
 * Deletes Pricing Analysis Geo Group BI AMC records for input pricing proposal and geo group bi amc id
 */
export class DeletePricingAnlysGeoGroupBiAmcsRqst {
	/**
	 * unique id of Pricing Proposal
	 */
	prcProposalInstId:string = undefined;
	/**
	 * unique id of Pricing Analysis Geo Group
	 */
	prcAnalysGeoGroupId:string = undefined;
	/**
	 * unique id of Pricing Analysis geo group BI AMC record
	 */
	geoGroupBiAmcId:string = undefined;
}
/**
 * Create pricing analyis geo group historical shipments absolute minimum charge record
 */
export class UpdatePricingAnlysGeoGroupBiAmcsRqst {
	/**
	 * unique id of Pricing Proposal
	 */
	prcProposalInstId:string = undefined;
	/**
	 * unique id of Pricing Analysis Geo Group
	 */
	prcAnalysGeoGroupId:string = undefined;
	/**
	 * unique id of Pricing Analysis geo group BI AMC record
	 */
	geoGroupBiAmcId:string = undefined;
	/**
	 * details of the BI AMC data to be persisted
	 */
	biAmcDetails:GeoGroupAmcTariff = undefined;
}
/**
 * Retrieve a list of Pricing Analysis Geo Group BI AMC records for a given pricing proposal id and pricing analysis geo group id
 */
export class ListPricingAnlysGeoGroupBiAmcsRqst {
	/**
	 * unique Id of Pricing Proposal
	 */
	prcProposalInstId:string = undefined;
	/**
	 * unique id of Pricing Analysis Geo Group
	 */
	prcAnalysGeoGroupId:string = undefined;
	listInfo:ListInfo = undefined;
}
/**
 * Create pricing analyis geo group historical shipments absolute minimum charge record
 */
export class ListPricingAnlysGeoGroupBiAmcsResp {
	/**
	 * unique id of Pricing Analysis geo group BI AMC record
	 */
	geoGroupId:string = undefined;
	/**
	 * details of the BI AMC data to be persisted
	 */
	biAmcDetails:GeoGroupBiAmc[] = undefined;
	listInfo:ListInfo = undefined;
}
/**
 * Create pricing analyis geo group historical shipments absolute minimum charge record
 */
export class CreatePricingAnlysGeoGroupCmAmcsRqst {
	/**
	 * unique id of Pricing Proposal
	 */
	prcProposalInstId:string = undefined;
	/**
	 * unique id of Pricing Analysis Geo Group
	 */
	prcAnalysGeoGroupId:string = undefined;
	cmAmcDetails:GeoGroupAmcTariff = undefined;
}
/**
 * Create pricing analyis geo group historical shipments absolute minimum charge record
 */
export class CreatePricingAnlysGeoGroupCmAmcsResp {
	/**
	 * unique id of the Geo Group BI Amc record
	 */
	geoGroupCmAmcId:string = undefined;
}
/**
 * Deletes Pricing Analysis Geo Group BI AMC records for input pricing proposal and geo group bi amc id
 */
export class DeletePricingAnlysGeoGroupCmAmcsRqst {
	/**
	 * unique id of Pricing Proposal
	 */
	prcProposalInstId:string = undefined;
	/**
	 * unique id of Pricing Analysis Geo Group
	 */
	prcAnalysGeoGroupId:string = undefined;
	/**
	 * unique id of Pricing Analysis geo group Cost Model AMC record
	 */
	geoGroupCmAmcId:string = undefined;
}
/**
 * Create pricing analyis geo group historical shipments absolute minimum charge record
 */
export class UpdatePricingAnlysGeoGroupCmAmcsRqst {
	/**
	 * unique id of Pricing Proposal
	 */
	prcProposalInstId:string = undefined;
	/**
	 * unique id of Pricing Analysis Geo Group
	 */
	prcAnalysGeoGroupId:string = undefined;
	/**
	 * unique id of Pricing Analysis geo group BI AMC record
	 */
	geoGroupCmAmcId:string = undefined;
	/**
	 * details of the CM AMC data to be persisted
	 */
	cmAmcDetails:GeoGroupAmcTariff = undefined;
}
/**
 * Retrieve a list of Pricing Analysis Geo Group BI AMC records for a given pricing proposal id and pricing analysis geo group id
 */
export class ListPricingAnlysGeoGroupCmAmcsRqst {
	/**
	 * unique Id of Pricing Proposal
	 */
	prcProposalInstId:string = undefined;
	/**
	 * unique id of Pricing Analysis Geo Group
	 */
	prcAnalysGeoGroupId:string = undefined;
	listInfo:ListInfo = undefined;
}
/**
 * Create pricing analyis geo group historical shipments absolute minimum charge record
 */
export class ListPricingAnlysGeoGroupCmAmcsResp {
	/**
	 * unique id of Pricing Analysis geo group BI AMC record
	 */
	geoGroupId:string = undefined;
	/**
	 * details of the BI AMC data to be persisted
	 */
	cmAmcDetails:GeoGroupCmAmc[] = undefined;
	listInfo:ListInfo = undefined;
}
/**
 * creates the pricing analysis  porter shipment-cost model  not rated with absolute minimum charge record. Based on the input pricing proposal inst id, pricing analysis geo group id, pricing analysis geo group porter shipment-cost model tariff record. 
 */
export class CreatePricingAnlysGeoGroupCmTariffsRqst {
	/**
	 * unique identifier of the Pricing Proposal
	 */
	prcProposalInstId:string = undefined;
	/**
	 * Unique identifier for Pricing Analysis Group-Level record
	 */
	prcAnalysGeoGroupId:string = undefined;
	cmTariffDetails:GeoGroupAmcTariff = undefined;
}
export class CreatePricingAnlysGeoGroupCmTariffsResp {
	/**
	 * unique identifier of the Pricing Analysis Geo Group Cost Model Tariff
	 */
	geoGroupCmTariffId:string = undefined;
}
/**
 * Deletes Pricing Analysis Geo Group Cost Model Tariff records for input pricing proposal and geo group bi amc id
 */
export class DeletePricingAnlysGeoGroupCmTariffsRqst {
	/**
	 * unique id of Pricing Proposal
	 */
	prcProposalInstId:string = undefined;
	/**
	 * unique id of Pricing Analysis Geo Group
	 */
	prcAnalysGeoGroupId:string = undefined;
	/**
	 * unique id of Pricing Analysis geo group Cost Model Tariff record
	 */
	geoGroupCmTariffId:string = undefined;
}
/**
 * Retrieve a list of Pricing Analysis Geo Group Cost Model Tariff records for a given pricing proposal id and pricing analysis geo group id
 */
export class ListPricingAnlysGeoGroupCmTariffsRqst {
	/**
	 * unique Id of Pricing Proposal
	 */
	prcProposalInstId:string = undefined;
	/**
	 * unique id of Pricing Analysis Geo Group
	 */
	prcAnalysGeoGroupId:string = undefined;
	listInfo:ListInfo = undefined;
}
export class ListPricingAnlysGeoGroupCmTariffsResp {
	cmTariffDetails:GeoGroupCmTariff[] = undefined;
	listInfo:ListInfo = undefined;
}
/**
 * Updates pricing analyis geo group cost model shipments tariff record
 */
export class UpdatePricingAnlysGeoGroupCmTariffRqst {
	/**
	 * unique id of Pricing Proposal
	 */
	prcProposalInstId:string = undefined;
	/**
	 * unique id of Pricing Analysis Geo Group
	 */
	prcAnalysGeoGroupId:string = undefined;
	/**
	 * unique id of Pricing Analysis geo group Cost Model tariff record
	 */
	geoGroupCmTariffId:string = undefined;
	/**
	 * details of the BI AMC data to be persisted
	 */
	cmTariffDetails:GeoGroupAmcTariff = undefined;
}
/**
 * This service creates the pricing analysis historical not rated with absolute minimum charge record. Based on the input, PRC_ANLYS_GEO_GROUP_BI_TARIFF table is persisted
 */
export class CreatePricingAnlysGeoGroupBiTariffsRqst {
	/**
	 * unique identifier of the Pricing Proposal
	 */
	prcProposalInstId:string = undefined;
	/**
	 * Unique identifier for Pricing Analysis Group-Level record
	 */
	prcAnalysGeoGroupId:string = undefined;
	biTariffDetails:GeoGroupAmcTariff = undefined;
}
export class CreatePricingAnlysGeoGroupBiTariffsResp {
	/**
	 * unique identifier of the Pricing Analysis Geo Group Cost Model Tariff
	 */
	geoGroupBiTariffId:string = undefined;
}
/**
 * Deletes Pricing Analysis Geo Group Cost Model Tariff records for input pricing proposal and geo group bi amc id
 */
export class DeletePricingAnlysGeoGroupBiTariffsRqst {
	/**
	 * unique id of Pricing Proposal
	 */
	prcProposalInstId:string = undefined;
	/**
	 * unique id of Pricing Analysis Geo Group
	 */
	prcAnalysGeoGroupId:string = undefined;
	/**
	 * unique id of Pricing Analysis geo group Cost Model Tariff record
	 */
	geoGroupBiTariffId:string = undefined;
}
/**
 * Retrieve a list of Pricing Analysis Geo Group Cost Model Tariff records for a given pricing proposal id and pricing analysis geo group id
 */
export class ListPricingAnlysGeoGroupBiTariffsRqst {
	/**
	 * unique Id of Pricing Proposal
	 */
	prcProposalInstId:string = undefined;
	/**
	 * unique id of Pricing Analysis Geo Group
	 */
	prcAnalysGeoGroupId:string = undefined;
	listInfo:ListInfo = undefined;
}
export class ListPricingAnlysGeoGroupBiTariffsResp {
	biTariffDetails:GeoGroupBiTariff[] = undefined;
	listInfo:ListInfo = undefined;
}
/**
 * Updates pricing analyis geo group cost model shipments tariff record
 */
export class UpdatePricingAnlysGeoGroupBiTariffRqst {
	/**
	 * unique id of Pricing Proposal
	 */
	prcProposalInstId:string = undefined;
	/**
	 * unique id of Pricing Analysis Geo Group
	 */
	prcAnalysGeoGroupId:string = undefined;
	/**
	 * unique id of Pricing Analysis geo group Cost Model tariff record
	 */
	geoGroupBiTariffId:string = undefined;
	/**
	 * details of the BI AMC data to be persisted
	 */
	biTariffDetails:GeoGroupAmcTariff = undefined;
}
/**
 * Create pricing analyis geo group historical shipments absolute minimum charge record
 */
export class CreatePricingAnlysGeoGroupDiscAmcsRqst {
	/**
	 * unique id of Pricing Proposal
	 */
	prcProposalInstId:string = undefined;
	/**
	 * unique id of Pricing Analysis Geo Group
	 */
	prcAnalysGeoGroupId:string = undefined;
	discAmcDetails:GeoGroupDiscountAmc = undefined;
}
/**
 * Create pricing analyis geo group historical shipments absolute minimum charge record
 */
export class CreatePricingAnlysGeoGroupDiscAmcsResp {
	/**
	 * unique id of the Geo Group BI Amc record
	 */
	geoGroupDiscAmcId:string = undefined;
}
/**
 * Deletes Pricing Analysis Geo Group Discount AMC records for input pricing proposal and geo group bi amc id
 */
export class DeletePricingAnlysGeoGroupDiscAmcsRqst {
	/**
	 * unique id of Pricing Proposal
	 */
	prcProposalInstId:string = undefined;
	/**
	 * unique id of Pricing Analysis Geo Group
	 */
	prcAnalysGeoGroupId:string = undefined;
	/**
	 * unique id of Pricing Analysis geo group Discount AMC record
	 */
	geoGroupDiscAmcId:string = undefined;
}
/**
 * Retrieve a list of Pricing Analysis Geo Group BI AMC records for a given pricing proposal id and pricing analysis geo group id
 */
export class ListPricingAnlysGeoGroupDiscAmcsRqst {
	/**
	 * unique Id of Pricing Proposal
	 */
	prcProposalInstId:string = undefined;
	/**
	 * unique id of Pricing Analysis Geo Group
	 */
	prcAnalysGeoGroupId:string = undefined;
	listInfo:ListInfo = undefined;
}
/**
 * Create pricing analyis geo group historical shipments absolute minimum charge record
 */
export class ListPricingAnlysGeoGroupDiscAmcsResp {
	/**
	 * details of the BI AMC data to be persisted
	 */
	discAmcDetails:GeoGroupDiscountAmc[] = undefined;
	listInfo:ListInfo = undefined;
}
/**
 * Create pricing analyis geo group historical shipments absolute minimum charge record
 */
export class UpdatePricingAnlysGeoGroupDiscAmcsRqst {
	/**
	 * unique id of Pricing Proposal
	 */
	prcProposalInstId:string = undefined;
	/**
	 * unique id of Pricing Analysis Geo Group
	 */
	prcAnalysGeoGroupId:string = undefined;
	/**
	 * unique id of Pricing Analysis geo group DiscountAMC record
	 */
	geoGroupDiscAmcId:string = undefined;
	/**
	 * details of the Duscount AMC data to be persisted
	 */
	discAmcDetails:GeoGroupDiscountAmc = undefined;
}
/**
 * Input message to create Pricing Proposal FAK (class override)  records for a given Geo Area Pricing Identifier
 */
export class CreateGeoPricingLaneFaksRqst {
	/**
	 * unique identifier of Pricing Proposal record
	 */
	prcProposalInstId:string = undefined;
	/**
	 * unique identifier of Geo Area Pricing record
	 */
	geoPrcLaneId:string = undefined;
	proposalFak:PricingFak = undefined;
}
export class CreateGeoPricingLaneFaksResp {
	/**
	 * unique identifier of Geo Pricing Lane record
	 */
	geoPrcLaneId:string = undefined;
}
/**
 * Input message to update Pricing Proposal FAK (class override)  records for a given Geo Area Pricing Identifier and Pricing Proposal Fak identifier
 */
export class UpdateGeoPricingLaneFaksRqst {
	/**
	 * unique identifier of Pricing Proposal record
	 */
	prcProposalInstId:string = undefined;
	/**
	 * unique identifier of Geo Area Pricing record
	 */
	geoPrcLaneId:string = undefined;
	/**
	 * unique identifier of pricing proposal fak override record
	 */
	pricingProposalFakId:string = undefined;
	proposalFak:PricingFak = undefined;
}
/**
 * Input message to delete Pricing Proposal FAK (class override)  records for a given Geo Area Pricing Identifier and Pricing Proposal Fak identifier
 */
export class DeleteGeoPricingLaneFaksRqst {
	/**
	 * unique identifier of Pricing Proposal record
	 */
	prcProposalInstId:string = undefined;
	/**
	 * unique identifier of Geo Area Pricing record
	 */
	geoPrcLaneId:string = undefined;
	/**
	 * unique identifier of pricing proposal fak override record
	 */
	pricingProposalFakId:string = undefined;
}
export class GetGeoPricingLaneFaksRqst {
	prcProposalInstId:string = undefined;
	geoPrcLaneId:string = undefined;
}
export class GetGeoPricingLaneFaksResp {
	geoPrcLaneId:string = undefined;
	fakOverrides:PricingFak = undefined;
}
/**
 * Deletes FAK override records by IDs.
 */
export class DeleteGeoPricingLaneFakOverridesRqst {
	prcProposalInstId:string = undefined;
	geoPrcLaneId:string = undefined;
	prcFakHdrId:string = undefined;
	overrideIds:string[] = undefined;
}
/**
 * Input message to create the Pricing Proposal Geo Pricing Lane record
 */
export class CreatePricingProposalGeoPricingLanesRqst {
	/**
	 * unique identifier of Pricing Proposal Instance Id
	 */
	prcProposalInstId:string = undefined;
	/**
	 * geo area pricing lane records to be persisted
	 */
	geoPricingLanes:GeoAreaPricingLane[] = undefined;
}
export class CreatePricingProposalGeoPricingLanesResp {
	/**
	 * unique id of Pricing Proposal Instance Id
	 */
	prcProposalInstId:string = undefined;
}
/**
 * Retrieves a list of Pricing Proposal Geo Pricing Lanes for a given Pricing Proposal Identifier
 */
export class ListPricingProposalGeoPricingLanesRqst {
	prcProposalInstId:string = undefined;
	/**
	 * pagination information to retrieve list
	 */
	listInfo:ListInfo = undefined;
}
export class ListPricingProposalGeoPricingLanesResp {
	geoPricingLanes:GeoAreaPricingLane[] = undefined;
	listInfo:ListInfo = undefined;
}
/**
 * Input message to update the Pricing Proposal Geo Pricing Lane record
 */
export class DeletePricingProposalGeoPricingLanesRqst {
	/**
	 * unique identifier of Pricing Proposal Instance Id
	 */
	prcProposalInstId:string = undefined;
	geoAreaPrcLaneId:string = undefined;
}
/**
 * Input message to update the Pricing Proposal Geo Pricing Lane record
 */
export class UpdatePricingProposalGeoPricingLanesRqst {
	/**
	 * unique identifier of Pricing Proposal Instance Id
	 */
	prcProposalInstId:string = undefined;
	geoAreaPrcLaneId:string = undefined;
	/**
	 * geo area pricing lane records to be persisted
	 */
	geoPricingLane:GeoAreaPricingLane = undefined;
}
/**
 * Request message to recalculate the FAK amounts for a given proposal id.
 */
export class CalculatePricingProposalFaksRqst {
	/**
	 * input data
	 */
	fakData:FakAdjustment = undefined;
	prcProposalInstId:string = undefined;
}
/**
 * Response message to recalculate the FAK amounts for a given proposal id.
 */
export class CalculatePricingProposalFaksResp {
	pricingProposal:PricingProposal = undefined;
}
/**
 * Creates the Pricing Proposal Ruleset Matrix record based on the input parameters. It includes Origin and destination state, country, postal code range, amc amounts and tier type etc.
 */
export class CreatePricingProposalRulesetMatrixRqst {
	/**
	 * unique identifier of Pricing Proposal Record
	 */
	prcProposalInstId:string = undefined;
	proposalRulesetMatrix:PricingProposalRulesetMatrix[] = undefined;
}
/**
 * Creates the Pricing Proposal Ruleset Matrix record based on the input parameters. It includes Origin and destination state, country, postal code range, amc amounts and tier type etc.
 */
export class CreatePricingProposalRulesetMatrixResp {
	/**
	 * unique identifier of Pricing Proposal Record
	 */
	prcProposalInstId:string = undefined;
	proposalRulesetMatrix:PricingProposalRulesetMatrix[] = undefined;
}
/**
 * Retrieves Pricing Proposal Ruleset Matrix records for an input Pricing Proposal Instance Id
 */
export class ListPricingProposalRulesetMatrixRqst {
	/**
	 * unique identifier of Pricing Proposal record
	 */
	prcProposalInstId:string = undefined;
	/**
	 * pagination information to retrieve list
	 */
	listInfo:ListInfo = undefined;
}
/**
 * Creates the Pricing Proposal Ruleset Matrix record based on the input parameters. It includes Origin and destination state, country, postal code range, amc amounts and tier type etc.
 */
export class ListPricingProposalRulesetMatrixResp {
	/**
	 * unique identifier of Pricing Proposal Record
	 */
	prcProposalInstId:string = undefined;
	proposalRulesetMatrix:PricingProposalRulesetMatrix[] = undefined;
	listInfo:ListInfo = undefined;
}
/**
 * Deletes 1 or all Pricing Proposal Ruleset Matrix data based on input mandatory Pricing Proposal Inst Id and optional Pricing proposal ruleset matrix id
 */
export class DeletePricingProposalRulesetMatrixRqst {
	/**
	 * unique identifier of Pricing Proposal record
	 */
	prcProposalInstId:string = undefined;
	/**
	 * unique identifier of Pricing Proposal Ruleset Matrix
	 */
	prcProposalRulesetMatrixId:string = undefined;
}
/**
 * Updates the Pricing Proposal Ruleset Matrix record based on the input parameters. It includes Origin and destination state, country, postal code range, amc amounts and tier type etc.
 */
export class UpdatePricingProposalRulesetMatrixRqst {
	/**
	 * unique identifier of Pricing Proposal Record
	 */
	prcProposalInstId:string = undefined;
	/**
	 * unique identifier of Pricing Proposal Ruleset Matrix Record
	 */
	prcProposalRulesetMatrixId:string = undefined;
	proposalRulesetMatrix:PricingProposalRulesetMatrix = undefined;
}
/**
 * Retrieves a list of all the Pricing Segment Model records
 */
export class ListPricingModelSegmentsRqst {
	/**
	 * Pagination information to retrieve list
	 */
	listInfo:ListInfo = undefined;
}
export class ListPricingModelSegmentsResp {
	modelSegments:PricingModelSegment[] = undefined;
	/**
	 * pagination information
	 */
	listInfo:ListInfo = undefined;
}
export class ListPricingProposalWorkQueueRqst {
	/**
	 * Employee Id of the Pricing Analyst that is assigned this pricing analysis
	 */
	pricingAnalystId:string = undefined;
	/**
	 * Current status of the pricing proposal
	 */
	proposalStatCd:string[] = undefined;
	/**
	 * Identifies the type of pricing analysis
	 */
	analysisTypeCd:string = undefined;
	acctId:string = undefined;
	dateCriteria:string = undefined;
	startDate:Date = undefined;
	endDate:Date = undefined;
	listInfo:ListInfo[] = undefined;
}
export class ListPricingProposalWorkQueueResp {
	pricingProposalWorkQueue:PricingProposalWorkQueue[] = undefined;
	listInfo:ListInfo = undefined;
}
/**
 * Creates the Pricing Proposal TMS File record based on the input parameters. 
 */
export class CreatePricingProposalTmsFileRqst {
	/**
	 * unique identifier of Pricing Proposal Record
	 */
	prcProposalInstId:string = undefined;
	proposalTmsFile:PricingProposalTms[] = undefined;
}
/**
 * Creates the Pricing Proposal Ruleset Matrix record based on the input parameters. It includes Origin and destination state, country, postal code range, amc amounts and tier type etc.
 */
export class CreatePricingProposalTmsFileResp {
	/**
	 * unique identifier of Pricing Proposal Record
	 */
	prcProposalInstId:string = undefined;
	proposalTmsFile:PricingProposalTms[] = undefined;
}
/**
 * Retrieves Pricing Proposal Ruleset Matrix records for an input Pricing Proposal Instance Id
 */
export class ListPricingProposalTmsFileRqst {
	/**
	 * unique identifier of Pricing Proposal record
	 */
	prcProposalInstId:string = undefined;
	/**
	 * pagination information to retrieve list
	 */
	listInfo:ListInfo = undefined;
}
/**
 * Creates the Pricing Proposal Ruleset Matrix record based on the input parameters. It includes Origin and destination state, country, postal code range, amc amounts and tier type etc.
 */
export class ListPricingProposalTmsFileResp {
	/**
	 * unique identifier of Pricing Proposal Record
	 */
	prcProposalInstId:string = undefined;
	proposalTmsFile:PricingProposalTms[] = undefined;
	listInfo:ListInfo = undefined;
}
/**
 * Deletes 1 or all Pricing Proposal Ruleset Matrix data based on input mandatory Pricing Proposal Inst Id and optional Pricing proposal ruleset matrix id
 */
export class DeletePricingProposalTmsFileRqst {
	/**
	 * unique identifier of Pricing Proposal record
	 */
	prcProposalInstId:string = undefined;
	/**
	 * unique identifier of Pricing Proposal Tms file record
	 */
	prcProposalTmsFileId:string = undefined;
}
/**
 * Updates the Pricing Proposal Ruleset Matrix record based on the input parameters. It includes Origin and destination state, country, postal code range, amc amounts and tier type etc.
 */
export class UpdatePricingProposalTmsFileRqst {
	/**
	 * unique identifier of Pricing Proposal Record
	 */
	prcProposalInstId:string = undefined;
	/**
	 * unique identifier of Pricing Proposal Ruleset Matrix Record
	 */
	prcProposalTmsFileId:string = undefined;
	proposalTmsFile:PricingProposalTms = undefined;
}
/**
 * Given a unique Sales Rfp Id, build and return a sales rfp pdf file
 */
export class GetSalesRfpReportFileRqst {
	/**
	 * Unique id of sales rfp
	 */
	salesRfpId:string = undefined;
	/**
	 * Report type requested. Eg. PDF
	 */
	reportType:string = undefined;
}
export class GetSalesRfpReportFileResp {
	reportFile:File = undefined;
}
/**
 * Create a sales rfp customer shipments details for a given sales rfp id
 */
export class CreateSalesRfpCustomerShipmentsRqst {
	/**
	 * unique identifier of the Sales Rfp
	 */
	salesRfpId:string = undefined;
	rfpCustomerShipmentHdr:CustomerShipmentsFileHdr = undefined;
	rfpCustomerShipments:CustomerShipmentsFile[] = undefined;
}
export class CreateSalesRfpCustomerShipmentsResp {
	/**
	 * unique identifier of sales rfp
	 */
	salesRfpId:string = undefined;
}
/**
 * Deletes sales rfp customer shipments for the specified sales rfp id
 */
export class DeleteSalesRfpCustomerShipmentsRqst {
	/**
	 * unique identifier of sales rfp
	 */
	salesRfpId:string = undefined;
}
/**
 * Gets list of Sales Rfp Customer Shipment for a given sales rfp id
 */
export class ListSalesRfpCustomerShipmentsRqst {
	/**
	 * unique identifier of sales rfp
	 */
	salesRfpId:string = undefined;
	/**
	 * pagination information to retrieve list
	 */
	listInfo:ListInfo = undefined;
}
export class ListSalesRfpCustomerShipmentsResp {
	salesRfpId:string = undefined;
	rfpCustomerShipments:CustomerShipmentsFile[] = undefined;
	listInfo:ListInfo = undefined;
}
/**
 * Request message to retrieve list of Proposal AMCs and Discounts for a given Pricing Proposal Id
 */
export class ListPricingProposalAmcDiscountsRqst {
	/**
	 * unique identifier of Pricing Proposal
	 */
	prcProposalInstId:string = undefined;
	/**
	 * Pagination Information to retrieve list
	 */
	listInfo:ListInfo = undefined;
}
/**
 * List of Pricing Proposal AMCs and Discounts
 */
export class ListPricingProposalAmcDiscountsResp {
	/**
	 * Details about Pricing Proposal AMCs and Discounts
	 */
	proposalAmcDiscount:ProposalAmcDiscount[] = undefined;
	listInfo:ListInfo = undefined;
}
/**
 * This service will store user details in pricing system. If the details are not there it will create otherwise it will update the existing details
 */
export class CreatePricingAdminPersonnelRqst {
	pricingAdminPersonnelDetails:PricingAdminPersonnel = undefined;
}
/**
 * This service will store user details in pricing system. If the details are not there it will create otherwise it will update the existing details
 */
export class CreatePricingAdminPersonnelResp {
	pricingAdminPersonnelDetails:PricingAdminPersonnel = undefined;
}
export class DeletePricingAdminPersonnelRqst {
	/**
	 * unique identifier of the Pricing Admin Personnel record
	 */
	adminPersonnelId:string[] = undefined;
}
/**
 * Request message to retrieve a list of all the Aggregate Model Constraint Templates
 */
export class ListAggregateModelConstraintTemplatesRqst {
	prcProposalInstId:string = undefined;
	aggrModelTemplateId:string = undefined;
	listInfo:ListInfo = undefined;
}
/**
 * Response message 
 */
export class ListAggregateModelConstraintTemplatesResp {
	aggrModelConstraintTemplates:AggregateModelConstraintTemplate[] = undefined;
	listInfo:ListInfo = undefined;
}
/**
 * Request message to create the Aggregate Model Geo Staging Record
 */
export class CreatePricingProposalAggrModelGeoStagingsRqst {
	prcProposalInstId:string = undefined;
	aggrModelGeoStagings:AggregateModelGeoStaging[] = undefined;
}
export class CreatePricingProposalAggrModelGeoStagingsResp {
	prcProposalInstId:string = undefined;
}
/**
 * Request message to update the Aggregate Model Geo Staging Record
 */
export class UpdatePricingProposalAggrModelGeoStagingsRqst {
	prcProposalInstId:string = undefined;
	aggrModelGeoStagings:AggregateModelGeoStaging[] = undefined;
}
/**
 * Request message to retrieve list of Aggregate Model Geo Staging data based on input Pricing Proposal Id or Geo Pricing Group Lane Id
 */
export class ListPricingProposalAggrModelGeoStagingsRqst {
	prcProposalInstId:string = undefined;
	geoPricingGroupLaneId:string = undefined;
	listInfo:ListInfo = undefined;
}
export class ListPricingProposalAggrModelGeoStagingsResp {
	aggrModelGeoStagings:AggregateModelGeoStaging[] = undefined;
	listInfo:ListInfo = undefined;
}
/**
 * Retrieves a list of Geo Pricing Group Lanes for a given Pricing Proposal Identifier
 */
export class ListGeoPricingGroupLanesForProposalRqst {
	prcProposalInstId:string = undefined;
	/**
	 * pagination information to retrieve list
	 */
	listInfo:ListInfo = undefined;
}
export class ListGeoPricingGroupLanesForProposalResp {
	geoPricingGroupLanes:GeoPricingGroupLane[] = undefined;
	listInfo:ListInfo = undefined;
}
/**
 * Retrieves a list of Geo Pricing Groups for a given Pricing Proposal Identifier
 */
export class ListGeoPricingGroupsForProposalRqst {
	prcProposalInstId:string = undefined;
	/**
	 * pagination information to retrieve list
	 */
	listInfo:ListInfo = undefined;
}
export class ListGeoPricingGroupsForProposalResp {
	geoPricingGroups:GeoPricingGroup[] = undefined;
	listInfo:ListInfo = undefined;
}
/**
 * Retrieves a list of Geo Pricing Group Members for a given Pricing Proposal Identifier
 */
export class ListGeoPricingGroupMembersForProposalRqst {
	prcProposalInstId:string = undefined;
	/**
	 * pagination information to retrieve list
	 */
	listInfo:ListInfo = undefined;
}
export class ListGeoPricingGroupMembersForProposalResp {
	geoPricingGroupMembers:GeoPricingGroupMember[] = undefined;
	listInfo:ListInfo = undefined;
}
/**
 * Retrieves a list of Excluded Geo Pricing Group Members for a given Pricing Proposal Identifier
 */
export class ListGeoPricingGroupExclMembersForProposalRqst {
	prcProposalInstId:string = undefined;
	/**
	 * pagination information to retrieve list
	 */
	listInfo:ListInfo = undefined;
}
export class ListGeoPricingGroupExclMembersForProposalResp {
	geoPricingGroupExclMbrs:GeoPricingGroupMember[] = undefined;
	listInfo:ListInfo = undefined;
}
/**
 * Input message to create the Proposed Strategy Records
 */
export class CreatePricingAdminProposedStrategyRqst {
	proposedStrategy:PricingAdminProposedStrategy = undefined;
}
/**
 * Response message to create the Proposed Strategy Records
 */
export class CreatePricingAdminProposedStrategyResp {
	proposedStrategy:PricingAdminProposedStrategy = undefined;
}
/**
 * Input message to update and existing Proposed Strategy Record
 */
export class UpdatePricingAdminProposedStrategyRqst {
	proposedStrategy:PricingAdminProposedStrategy = undefined;
}
/**
 * Input message to retrieve list of all Proposed Strategy Records
 */
export class ListPricingAdminProposedStrategyRqst {
	/**
	 * pagination information to retrieve list
	 */
	listInfo:ListInfo = undefined;
}
/**
 * Response message to retrieve list of all Proposed Strategy Records
 */
export class ListPricingAdminProposedStrategyResp {
	proposedStrategies:PricingAdminProposedStrategy[] = undefined;
}
/**
 * Input message to delete an existing Proposed Strategy record
 */
export class DeletePricingAdminProposedStrategyRqst {
	adminProposedStrategyPctId:string = undefined;
}
/**
 * Input message to create the Proposed Strategy Records
 */
export class CreatePricingAdminProposedIncreaseLevelRqst {
	proposedIncreaseLevel:PricingAdminProposedIncrLevel = undefined;
}
/**
 * Response message to create the Proposed Strategy Records
 */
export class CreatePricingAdminProposedIncreaseLevelResp {
	proposedIncreaseLevel:PricingAdminProposedIncrLevel = undefined;
}
/**
 * Input message to update and existing Proposed Strategy Record
 */
export class UpdatePricingAdminProposedIncreaseLevelRqst {
	proposedIncreaseLevel:PricingAdminProposedIncrLevel = undefined;
}
/**
 * Input message to retrieve list of all Proposed Strategy Records
 */
export class ListPricingAdminProposedIncreaseLevelRqst {
	/**
	 * pagination information to retrieve list
	 */
	listInfo:ListInfo = undefined;
}
/**
 * Response message to retrieve list of all Proposed Strategy Records
 */
export class ListPricingAdminProposedIncreaseLevelResp {
	proposedIncreaseLevel:PricingAdminProposedIncrLevel[] = undefined;
	/**
	 * pagination information to retrieve list
	 */
	listInfo:ListInfo = undefined;
}
/**
 * Input message to delete an existing Proposed Strategy record
 */
export class DeletePricingAdminProposedIncreaseLevelRqst {
	adminProposedIncrLevelId:string = undefined;
}
/**
 * Input message to create the Proposed Strategy Records
 */
export class CreatePricingAdminFscPctLookupRqst {
	fscPctLookup:PricingAdminFscPctLookup = undefined;
}
/**
 * Response message to create the Proposed Strategy Records
 */
export class CreatePricingAdminFscPctLookupResp {
	fscPctLookup:PricingAdminFscPctLookup = undefined;
}
/**
 * Input message to update and existing Proposed Strategy Record
 */
export class UpdatePricingAdminFscPctLookupRqst {
	fscPctLookup:PricingAdminFscPctLookup = undefined;
}
/**
 * Input message to retrieve list of all Proposed Strategy Records
 */
export class ListPricingAdminFscPctLookupRqst {
	/**
	 * pagination information to retrieve list
	 */
	listInfo:ListInfo = undefined;
}
/**
 * Response message to retrieve list of all Proposed Strategy Records
 */
export class ListPricingAdminFscPctLookupResp {
	fscPctLookup:PricingAdminFscPctLookup[] = undefined;
	listInfo:ListInfo = undefined;
}
/**
 * Input message to delete an existing Proposed Strategy record
 */
export class DeletePricingAdminFscPctLookupRqst {
	adminFscPctLookupId:string = undefined;
}
/**
 * Input message to create the Proposed Strategy Records
 */
export class CreatePricingAdminFakAdjustPctRqst {
	fakAdjustPct:PricingAdminFakAdjustmentPct = undefined;
}
/**
 * Response message to create the Proposed Strategy Records
 */
export class CreatePricingAdminFakAdjustPctResp {
	fakAdjustPct:PricingAdminFakAdjustmentPct = undefined;
}
/**
 * Input message to update and existing Proposed Strategy Record
 */
export class UpdatePricingAdminFakAdjustPctRqst {
	fakAdjustPct:PricingAdminFakAdjustmentPct = undefined;
}
/**
 * Input message to retrieve list of all Proposed Strategy Records
 */
export class ListPricingAdminFakAdjustPctRqst {
	/**
	 * pagination information to retrieve list
	 */
	listInfo:ListInfo = undefined;
}
/**
 * Response message to retrieve list of all Proposed Strategy Records
 */
export class ListPricingAdminFakAdjustPctResp {
	fakAdjustPct:PricingAdminFakAdjustmentPct[] = undefined;
	listInfo:ListInfo = undefined;
}
/**
 * Input message to delete an existing Proposed Strategy record
 */
export class DeletePricingAdminFakAdjustPctRqst {
	/**
	 * unique identifier of Pricing Admin Fak Adjustment Pct record
	 */
	adminFakAdjustPctId:string = undefined;
}
/**
 * Request message to create the Pricing Agreement Monitor Parameter Record
 */
export class CreatePricingAgreementMonitorParameterRqst {
	paMonitorParameters:PricingAgreementMonitorParameter = undefined;
}
/**
 * Request message to retrieve monitor parameter details by its Identifier
 */
export class CreatePricingAgreementMonitorParameterResp {
	/**
	 * Unique identifier of the Pricing Agreement Monitor Parameter record
	 */
	agreementMonitorParameterId:string = undefined;
}
/**
 * Request message to update an existing Pricing Agreement Monitor Parameter record
 */
export class UpdatePricingAgreementMonitorParameterRqst {
	paMonitorParameter:PricingAgreementMonitorParameter = undefined;
}
/**
 * Request message to retrieve monitor parameter details by its Identifier
 */
export class GetPricingAgreementMonitorParameterRqst {
	/**
	 * Unique identifier of the Pricing Agreement Monitor Parameter record
	 */
	agreementMonitorParameterId:string = undefined;
}
/**
 * Request message to get an existing Pricing Agreement Monitor Parameter record
 */
export class GetPricingAgreementMonitorParameterResp {
	/**
	 * Pricing Agreement Monitor Parameter
	 */
	pricingAgreementMonitorParameter:PricingAgreementMonitorParameter = undefined;
}
/**
 * Request message to retrieve the list of all Pricing Agreement Monitor Parameter records
 */
export class ListPricingAgreementMonitorParameterRqst {
	/**
	 * pagination information to retrieve the list
	 */
	listInfo:ListInfo = undefined;
}
export class ListPricingAgreementMonitorParameterResp {
	/**
	 * pagination information to retrieve the list
	 */
	listInfo:ListInfo = undefined;
	/**
	 * Details about sales Pricing Agreement Monitor Parameters
	 */
	paMonitorParameters:PricingAgreementMonitorParameter[] = undefined;
}
/**
 * Request message to delete monitor parameter details by its Identifier
 */
export class DeletePricingAgreementMonitorParameterRqst {
	/**
	 * Unique identifier of the Pricing Agreement Monitor Parameter record
	 */
	agreementMonitorParameterId:string = undefined;
}
/**
 * Request message to retrieve Pricing Agreement Monitor Counts
 */
export class GetPricingAgreementMonitorCountsRqst {
	/**
	 * identifies the level of detail to be included in the response counts
	 */
	levelOfDetail:string = undefined;
	/**
	 * employee id of the PAM owner
	 */
	ownerId:string = undefined;
}
export class GetPricingAgreementMonitorCountsResp {
	/**
	 * Pricing Agreement Monitor record counts
	 */
	counts:CountInfo[] = undefined;
}
/**
 * Request message to create the Pricing Agreement Monitor Action Record
 */
export class CreatePricingAgreementMonitorActionRqst {
	/**
	 * Unique identifier of the Pricing Agreement Monitor record
	 */
	pricingAgreementMonitorId:string = undefined;
	paMonitorAction:PricingAgreementMonitorAction = undefined;
}
/**
 * Response message
 */
export class CreatePricingAgreementMonitorActionResp {
	/**
	 * Unique identifier of the Pricing Agreement Monitor Action record
	 */
	agreementMonitorActionId:string = undefined;
	/**
	 * Sales Rfp id of the rfp created for the specified action taken
	 */
	salesRfpId:object = undefined;
}
/**
 * Request message for the list of all Valid Actions for a Pricing Agreement Monitor Records
 */
export class ListPAMValidActionsRqst {
	/**
	 * Unique identifier of the Pricing Agreement Monitor record
	 */
	agreementMonitorId:string = undefined;
	/**
	 * pagination information to retrieve the list
	 */
	listInfo:ListInfo = undefined;
	actionSource:string = undefined;
}
export class ListPAMValidActionsResp {
	/**
	 * pagination information to retrieve the list
	 */
	listInfo:ListInfo = undefined;
	/**
	 * Valid Actions for Pricing Agreement Monitor
	 */
	agreementMonitorValidActions:PricingAgreementMonitorValidAction[] = undefined;
}
/**
 * Request to get a list of Audit Next Revision Agreements
 */
export class ListPricingAgreementNextRevisionAuditRqst {
	leadAcctId:number = undefined;
	rfpReqDate:Date = undefined;
	period:number = undefined;
	listInfo:ListInfo = undefined;
}
/**
 * Request message to get a list of recent Pricing AgreementAudit records
 */
export class ListPricingAgreementRecentAuditRqst {
	loginUserId:string = undefined;
	/**
	 * pagination information to retrieve list
	 */
	listInfo:ListInfo = undefined;
}
/**
 * Request message to get a list of Pricing AgreementAudit records
 */
export class ListPricingAgreementAuditRqst {
	/**
	 * pagination information to retrieve list
	 */
	listInfo:ListInfo = undefined;
	/**
	 * Start date of the date range to search.
	 */
	fromDate:Date = undefined;
	/**
	 * End date of the date range to search.
	 */
	toDate:Date = undefined;
	/**
	 * Current status of the Pricing Agreement Audit record
	 */
	auditStatus:string = undefined;
	/**
	 * Unique identifier of the Sales Rfp
	 */
	salesRfpId:string = undefined;
	/**
	 * Customer Account Id for which to search the Pricing Audit records
	 */
	custAcctId:string = undefined;
	/**
	 * Employee Id for whom the list details are get
	 */
	employeeId:string = undefined;
}
export class ListPricingAgreementAuditResp {
	/**
	 * Pagination information to retrieve list
	 */
	listInfo:ListInfo = undefined;
	agreementAuditList:PricingAgreementAudit[] = undefined;
}
/**
 * Request message to update the Pricing Agreement Audit Header record
 */
export class UpdatePricingAgreementAuditHeaderRqst {
	auditHeader:PricingAgreementAuditHdr[] = undefined;
	/**
	 * Type of update to be performed. Eg. Reassignment
	 */
	updateType:string = undefined;
}
/**
 * Request message to update the Pricing Agreement Audit Header record
 */
export class UpdatePricingAgreementAuditHeaderResp {
	/**
	 * List of Agreement Audit Headers that were not updated
	 */
	errorList:AgreementAuditId[] = undefined;
}
/**
 * Request message to get the details of the Pricing Agreement Audit based on its Identifier
 */
export class GetPricingAgreementAuditDetailRqst {
	/**
	 * Unique identifier of the Pricing Agreement Audit Header record
	 */
	agreementAuditHdrId:string = undefined;
}
export class GetPricingAgreementAuditDetailResp {
	auditDetail:PricingAgreementAudit = undefined;
}
export class CreatePricingAgreementAuditCommentRqst {
	auditComment:PricingAgreementAuditComment = undefined;
	/**
	 * type of document to be uploaded
	 */
	docTypeCd:string[] = undefined;
	/**
	 * list of files to be uploaded
	 */
	docFiles:File[] = undefined;
}
export class CreatePricingAgreementAuditCommentResp {
	auditComment:PricingAgreementAuditComment = undefined;
}
export class CreatePricingAgreementAuditIssueRqst {
	auditIssue:PricingAgreementAuditIssue = undefined;
}
export class CreatePricingAgreementAuditIssueResp {
	auditIssue:PricingAgreementAuditIssue = undefined;
}
export class UpdatePricingAgreementAuditIssueRqst {
	auditIssue:PricingAgreementAuditIssue = undefined;
	auditIssueComment:PricingAgreementAuditComment = undefined;
	/**
	 * Audit event log id to update the status
	 */
	agreementAuditEventLogId:string = undefined;
}
export class UpdatePricingAgreementAuditIssueResp {
	auditIssue:PricingAgreementAuditIssue = undefined;
	auditIssueComment:PricingAgreementAuditComment = undefined;
}
export class DeletePricingAgreementAuditIssueRqst {
	/**
	 * Unique identifier of the Agreement Audit Issue record
	 */
	agreementAuditIssueId:string = undefined;
	/**
	 * Unique identifier of the Pricing Agreement Audit Header record
	 */
	agreementAuditHdrId:string = undefined;
}
export class DeletePricingAgreementAuditIssueResp {
	/**
	 * Unique identifier of the Agreement Audit Issue record
	 */
	agreementAuditIssueId:string = undefined;
	/**
	 * This is status allowed, based on this status the button will be displayed
	 */
	statusAllowed:string = undefined;
}
export class DeletePricingAgreementAuditEventLogRqst {
	agreementAuditEventLogId:string = undefined;
}
export class CreatePricingAgreementAuditDocumentRqst {
	/**
	 * type of document to be uploaded
	 */
	docTypeCd:string[] = undefined;
	/**
	 * list of files to be uploaded
	 */
	docFiles:File[] = undefined;
	/**
	 * Unique identifier of the Pricing Agreement Audit Header record
	 */
	agreementAuditHdrId:string = undefined;
	/**
	 * Unique identifier of the Agreement Audit Comment
	 */
	agreementAuditCommentId:string = undefined;
	/**
	 * Unique identifier of the Agreement Audit Issue record
	 */
	agreementAuditIssueId:string = undefined;
}
export class CreatePricingAgreementAuditDocumentResp {
	auditIssue:PricingAgreementAuditIssue = undefined;
	auditIssueComment:PricingAgreementAuditComment = undefined;
}
export class GetPricingAgreementAuditDocumentRqst {
	agreementAuditHdrId:string = undefined;
	agreementAuditCommentId:string = undefined;
	agreementAuditIssueId:string = undefined;
}
export class GetPricingAgreementAuditDocumentResp {
	agreementAuditDocFile:File = undefined;
}
export class ListPricingAccessorialsByTypeRqst {
	/**
	 * Accessorial Type. Eg. Ratequote
	 */
	typeCode:string = undefined;
	listInfo:ListInfo = undefined;
}
export class ListPricingAccessorialsByTypeResp {
	accessorials:AccessorialService[] = undefined;
	listInfo:ListInfo = undefined;
}
export class ListPricingAccessorialsApplIndRqst {
	listInfo:ListInfo = undefined;
	typeCode:string = undefined;
}
export class ListPricingAccessorialsApplIndResp {
	accessorials:AccessorialService[] = undefined;
	listInfo:ListInfo = undefined;
}
/**
 * Request message to create the Pricing Agreement Audit Parameter Record
 */
export class CreatePricingAgreementAuditParameterRqst {
	paAuditParameters:PricingAgreementAuditParameter[] = undefined;
}
/**
 * Request message to retrieve audit parameter details by its Identifier
 */
export class CreatePricingAgreementAuditParameterResp {
	/**
	 * Unique identifier of the Pricing Agreement Audit Parameter record
	 */
	agreementAuditParameterId:string = undefined;
}
/**
 * Request message to update an existing Pricing Agreement Audit Parameter record
 */
export class UpdatePricingAgreementAuditParameterRqst {
	paAuditParameter:PricingAgreementAuditParameter = undefined;
}
/**
 * Request message to retrieve audit parameter details by its Identifier
 */
export class GetPricingAgreementAuditParameterRqst {
	/**
	 * Unique identifier of the Pricing Agreement Audit Parameter record
	 */
	agreementAuditParameterId:string = undefined;
}
/**
 * Request message to get an existing Pricing Agreement Audit Parameter record
 */
export class GetPricingAgreementAuditParameterResp {
	/**
	 * Pricing Agreement Audit Parameter
	 */
	pricingAgreementAuditParameter:PricingAgreementAuditParameter = undefined;
}
/**
 * Request message to retrieve the list of all Pricing Agreement Audit Parameter records
 */
export class ListPricingAgreementAuditParameterRqst {
	/**
	 * pagination information to retrieve the list
	 */
	listInfo:ListInfo = undefined;
	/**
	 * Start Effective Date for Active Audit Parameters
	 */
	startEffDate:Date = undefined;
	/**
	 * EndEffective Date for Active Audit Parameters
	 */
	endEffDate:Date = undefined;
}
export class ListPricingAgreementAuditParameterResp {
	/**
	 * pagination information to retrieve the list
	 */
	listInfo:ListInfo = undefined;
	/**
	 * Details about sales Pricing Agreement Audit Parameters
	 */
	paAuditParameters:PricingAgreementAuditParameter[] = undefined;
}
/**
 * Request message to delete audit parameter details by its Identifier
 */
export class DeletePricingAgreementAuditParameterRqst {
	/**
	 * Unique identifier of the Pricing Agreement Audit Parameter record
	 */
	agreementAuditParameterId:string = undefined;
}
export class ListPricingAgreementMonitorRunDateRqst {
	listInfo:ListInfo = undefined;
}
export class ListPricingAgreementMonitorRunDateResp {
	/**
	 * List of dates when the PAM was run
	 */
	pamRunDate:Date[] = undefined;
	listInfo:ListInfo = undefined;
}
/**
 * Request message to re-assign the PAM record
 */
export class ReassignPricingAgreementMonitorRqst {
	/**
	 * Employee id of the Current Owner of the PAM record
	 */
	pamOwner:PAMOwner[] = undefined;
	/**
	 * This is the from user role  (Sales/Pricing Analyst/Nass/Dos/PricingManger) to transfer
	 */
	fromOwnerRole:string = undefined;
	/**
	 * Employee id of the new Owner of the PAM record
	 */
	toOwnerId:string = undefined;
}
export class ReassignPricingAgreementMonitorResp {
	/**
	 * Count of PAM records reassigned
	 */
	reassignedCount:CountInfo = undefined;
}
/**
 * Request message to create the Pricing Agreement Monitor Record
 */
export class CreatePricingAgreementMonitorRqst {
	paMonitors:PricingAgreementMonitor[] = undefined;
}
/**
 * Request message to retrieve monitor details by its Identifier
 */
export class CreatePricingAgreementMonitorResp {
	/**
	 * Pricing Agreement Monitor Create results
	 */
	pricingAgreementMonitorResults:CreatePricingAgreementMonitorResult[] = undefined;
}
/**
 * Result from Create Pricing Agreement Monitor Request
 */
export class CreatePricingAgreementMonitorResult {
	/**
	 * Unique identifier of the Pricing Agreement Monitor record
	 */
	pricingAgreementMonitorId:string = undefined;
	/**
	 * Result message for the Pricing Agreement Monitor Create
	 */
	message:string = undefined;
}
/**
 * Request message to update an existing Pricing Agreement Monitor record
 */
export class UpdatePricingAgreementMonitorRqst {
	paMonitor:PricingAgreementMonitor = undefined;
	pricingAgreementMonitorId:string = undefined;
}
/**
 * Request message to retrieve monitor details by its Identifier
 */
export class GetPricingAgreementMonitorRqst {
	/**
	 * Unique identifier of the Pricing Agreement Monitor record
	 */
	pricingAgreementMonitorId:string = undefined;
}
/**
 * Request message to get an existing Pricing Agreement Monitor record
 */
export class GetPricingAgreementMonitorResp {
	/**
	 * Pricing Agreement Monitor
	 */
	pricingAgreementMonitor:PricingAgreementMonitor = undefined;
}
/**
 * Request message to retrieve the list of all Pricing Agreement Monitor records
 */
export class ListPricingAgreementMonitorRqst {
	/**
	 * pagination information to retrieve the list
	 */
	listInfo:ListInfo = undefined;
	/**
	 * the year and month the PAM record was ran for
	 */
	startYearMonth:Date = undefined;
	/**
	 * pricing type code: Local or National
	 */
	pricingTypeCd:string = undefined;
	/**
	 * The instance ID of the customer that negotiated the pricing agreement for this PAM record
	 */
	leadAcctId:string = undefined;
	/**
	 * The version of the pricing agreement for this PAM record
	 */
	agreementCustVersion:string = undefined;
	/**
	 * The sequence number within the version of pricing agreement for this PAM record
	 */
	agreementSeqNbr:string = undefined;
	/**
	 * Sales District
	 */
	salesDistrict:string = undefined;
	/**
	 * Sales Region
	 */
	salesRegion:string = undefined;
	/**
	 * Employee Id of the Sales person who owns this Monitor record
	 */
	salesOwnerEmployeeId:string = undefined;
	/**
	 * Status of the Pricing Agreement Monitor record
	 */
	statusCd:string = undefined;
	/**
	 * Pricing Agreement Monitor Type
	 */
	typeCd:string = undefined;
	/**
	 * the year and month the PAM record was ran for
	 */
	endYearMonth:Date = undefined;
}
export class ListPricingAgreementMonitorResp {
	/**
	 * pagination information to retrieve the list
	 */
	listInfo:ListInfo = undefined;
	/**
	 * Details about sales Pricing Agreement Monitor records
	 */
	paMonitors:PricingAgreementMonitor[] = undefined;
	/**
	 * Action allowed for this list
	 */
	actionAllowed:string = undefined;
}
/**
 * Request message to retrieve the list of all Pricing Agreement Monitor Groups
 */
export class GetPricingAgreementMonitorRegionRollupReportRqst {
	/**
	 * the year and month the PAM record was ran for
	 */
	yearMonth:Date = undefined;
	/**
	 * status: Open or Closed
	 */
	statusCd:string = undefined;
	/**
	 * Employee Id for the report requester
	 */
	requesterId:string = undefined;
}
export class GetPricingAgreementMonitorRegionRollupReportResp {
	/**
	 * Pricing Agreement Monitor Region Rollup Report
	 */
	pricingAgreementMonitorRegionRollupReport:PricingAgreementMonitorRegionRollupReport = undefined;
}
/**
 * Input message to run the Input hierarchy model for a given Sales Rfp Id
 */
export class RunInputHierarchyModelRqst {
	/**
	 * Unique id of the Sales Rfp
	 */
	salesRfpId:object = undefined;
}
/**
 * Input message to run the Aggregation model for a given Pricing Proposal Id and Aggregate Model Template Id
 */
export class RunAggregationModelRqst {
	aggrModelTemplateId:string = undefined;
	/**
	 * Unique id for the proposal
	 */
	prcProposalInstId:string = undefined;
}
/**
 * Input message to run the Leaf Model for a given Pricing Proposal Id
 */
export class RunLeafModelRqst {
	/**
	 * Unique id for the proposal
	 */
	prcProposalInstId:string = undefined;
}
/**
 * Request message to get a list of counts for Pricing Navigation Bar
 */
export class ListPricingNavigationBarCountsRqst {
	listInfo:ListInfo = undefined;
	/**
	 * Employee Id of the owner
	 */
	ownerId:string = undefined;
}
export class ListPricingNavigationBarCountsResp {
	navigationBarCount:CountInfo[] = undefined;
	listInfo:ListInfo = undefined;
}
/**
 * List of Customer Pricing Agreements Doc for input Customer number
 */
export class ListPricingAgreementDocRqst {
	/**
	 * Acct Id of the negotiating customer
	 */
	custAcctId:number = undefined;
	/**
	 * Pricing agreement details customer sequence and version
	 */
	pricingAgreementId:string = undefined;
	/**
	 * Account code of the negotiating customer
	 */
	custAcctCd:string = undefined;
	/**
	 * pagination information for list retrieval
	 */
	listInfo:ListInfo = undefined;
}
/**
 * List of Pricing Agreement Doc details with pricing agreement version details, doc header and doc type Identifier Information
 */
export class ListPricingAgreementDocResp {
	/**
	 * Customer account pricing agreement doc details
	 */
	pricingAgreementDocHdrDetail:PricingAgreementDocHdr[] = undefined;
	custDetails:AcctId[] = undefined;
	listInfo:ListInfo = undefined;
}
/**
 * Update pricing agreement document and type details
 */
export class UpdatePricingAgreementDocHdrRqst {
	/**
	 * pricing agreement document header details
	 */
	agreementDocHdrDetail:PricingAgreementDocHdr = undefined;
}
/**
 * Update pricing agreement document header details
 */
export class UpdatePricingAgreementDocHdrResp {
	/**
	 * agreement document header details
	 */
	agreementDocHdrDetail:PricingAgreementDocHdr = undefined;
}
/**
 * Request for create pricing agreement doc header
 */
export class CreatePricingAgreementDocHdrRqst {
	/**
	 * Pricing agreement doc header details
	 */
	agreementDocHdrDetail:PricingAgreementDocHdr = undefined;
}
/**
 * Response for create pricing agreement doc header details
 */
export class CreatePricingAgreementDocHdrResp {
	/**
	 * Agreement  document header details
	 */
	agreementDocHdrDetail:PricingAgreementDocHdr = undefined;
}
/**
 * Request for upload pricing agreement doc type
 */
export class CreatePricingAgreementDocumentRqst {
	/**
	 * Agreement Document ID
	 */
	agreementDocHdrId:number = undefined;
	/**
	 * Document type
	 */
	docTypeCd:string[] = undefined;
	/**
	 * Document contents
	 */
	docFiles:File[] = undefined;
}
export class CreatePricingAgreementDocumentResp {
	agreementDocHdrDetail:PricingAgreementDocHdr = undefined;
}
/**
 * Request for download pricing agreement document
 */
export class GetPricingAgreementDocumentRqst {
	agreementDocHdrId:number = undefined;
	docTypeId:number = undefined;
	oracleDocId:number = undefined;
}
/**
 * Reaponse to get the down load pricing agreement document
 */
export class GetPricingAgreementDocumentResp {
	agreementDocTyp:PricingAgreementDocType = undefined;
	agreementDocFile:File = undefined;
}
/**
 * Request for download pricing agreement documents
 */
export class GetPricingAgreementDocumentsRqst {
	agreementDocHdrId:number = undefined;
	docTypeId:number[] = undefined;
}
/**
 * Response to get the down load pricing agreement documents
 */
export class GetPricingAgreementDocumentsResp {
	agreementDocFile:File = undefined;
}
/**
 * Request for getting the pricing agreement document Header Details
 */
export class GetPricingAgreementDocHdrRqst {
	agreementDocHdrId:number = undefined;
}
/**
 * Response to get pricing agreement document header details
 */
export class GetPricingAgreementDocHdrResp {
	/**
	 * agreement document header details
	 */
	agreementDocHdrDetail:PricingAgreementDocHdr = undefined;
	pricingAgreements:string[] = undefined;
}
/**
 * Request for Delete  pricing agreement documents
 */
export class DeletePricingAgreementDocumentRqst {
	agreementDocHdrId:number = undefined;
	docTypeId:number = undefined;
}
/**
 * Request for getting the list of corp Customer  and Pricing Agreements
 */
export class SearchPricingAgreementCustomerRqst {
	/**
	 * Acct Id of the negotiating customer
	 */
	custAcctId:number = undefined;
	/**
	 * pagination information for list retrieval
	 */
	listInfo:ListInfo = undefined;
}
/**
 * Respose of list of corp customers and agreement for the specified customer ID
 */
export class SearchPricingAgreementCustomerResp {
	/**
	 * Customer account pricing agreements
	 */
	pricingAgreements:string[] = undefined;
	/**
	 * list of Corp customers
	 */
	custDetails:AcctId[] = undefined;
	listInfo:ListInfo = undefined;
}
/**
 * Request for Delete  pricing agreement document Header
 */
export class DeletePricingAgreementDocHdrRqst {
	agreementDocHdrId:number = undefined;
}
/**
 * List of Customers (customer hierarchy) for input Customer number/code
 */
export class ListPrcCustomerHierarchyRqst {
	/**
	 * Acct Id of the negotiating customer
	 */
	custAcctId:number = undefined;
	/**
	 * Account code of the negotiating customer
	 */
	custAcctCd:string = undefined;
	/**
	 * pagination information for list retrieval
	 */
	listInfo:ListInfo = undefined;
}
/**
 * List of Customer (customer hierarchy) details including customenr name, address, credti status, type and primary location
 */
export class ListPrcCustomerHierarchyResp {
	custDetails:AccountDetail[] = undefined;
	listInfo:ListInfo = undefined;
}
export class AccessorialCharge {
	/**
	 * The minimum units for which this charge applies.
	 */
	minUnitCount:number = undefined;
	/**
	 * The maximum units for which this charge applies
	 */
	maxUnitCount:number = undefined;
	/**
	 * This is the rate at which the accessorial service is charged. This is mutually exclusive with ratePct and will not be specified if ratePct is specified.
	 */
	rateAmt:MonetaryAmount = undefined;
	/**
	 * This specfies the percentage to be used for calculating the charges for the accessorial service. Mutually exclusive with rateAmt
	 */
	ratePct:number = undefined;
	/**
	 * sequence number in which the charge should appear
	 */
	seqNbr:number = undefined;
}
export class AccessorialAppIndicator {
	/**
	 * unique identifier
	 */
	accessorialSvcApplIndId:string = undefined;
	/**
	 * Name of the application.
	 */
	applName:string = undefined;
	indicatorKey:string = undefined;
	indicatorValue:string = undefined;
}
/**
 * Information about global accessorials and how to calculate their charge amounts
 */
export class AccessorialService {
	/**
	 * This code also identifies the accessorial charge.
	 */
	accessorialCd:string = undefined;
	accessorialDesc:string = undefined;
	/**
	 * Date the Accessorial charge was effective.
	 */
	effDate:Date = undefined;
	/**
	 * Date the Accessorial charge was expired.
	 */
	expDate:Date = undefined;
	/**
	 * Name of the rules tariff applicable to the rule set.
	 */
	rulesTariffName:string = undefined;
	/**
	 * 1- Flat Rate per UOM
	 * 2 - % of Specified Charge type
	 */
	chargeTypeCd:string = undefined;
	/**
	 * This is the unit of measure for specifying the maximum and minimum limits for the accessorial service.
	 */
	accessorialUom:string = undefined;
	/**
	 * Specifies the type of charge which is used for calculating the charges for the accessorial service using the RATE_PCT.
	 */
	pctChgTypeCd:string = undefined;
	/**
	 * Describes whether the accessorial is applicable at Origin or Destination or Both
	 */
	termCreditCd:string = undefined;
	/**
	 * This indicates if the charges for the accessorial service are tiered by weight or other units of measure.
	 */
	tieredInd:boolean = undefined;
	negotiableInd:boolean = undefined;
	/**
	 * This indicates if the charges for the accessorial service are tiered by weight or other units of measure.
	 */
	minChgAmt:MonetaryAmount = undefined;
	/**
	 * This is the maximum charge amount that will be charged if the accessorial service was performed.
	 */
	maxChgAmt:MonetaryAmount = undefined;
	/**
	 * Indicates whether the charge for the accesorial service is discountable or not.
	 */
	accessorialDiscInd:boolean = undefined;
	/**
	 * Indicates whether the AC is a literal or not.
	 */
	literalInd:boolean = undefined;
	/**
	 * Each Accessorial is identified by Item number in CNWY199 guide
	 */
	itemText:string = undefined;
	accessorialAppInd:AccessorialAppIndicator[] = undefined;
	accessorialChg:AccessorialCharge[] = undefined;
}
/**
 * Basic customer account info
 */
export class AccountDetail {
	acctId:AcctId = undefined;
	address:Address = undefined;
	Indentation:string = undefined;
	primaryLocation:boolean = undefined;
	Owner:string = undefined;
}
export class AggregateModelConstraintTemplate {
	aggrModelConstrTemplateId:string = undefined;
	aggrModelTemplateId:string = undefined;
	auditInfo:AuditInfo = undefined;
	constraintDimension:string = undefined;
	constraintSequence:number = undefined;
	constraintType:string = undefined;
	dimensionLowerLimit:number = undefined;
	dimensionScope:string = undefined;
	dimensionUpperLimit:number = undefined;
	priority:number = undefined;
}
/**
 * Information about Geo Pricing Group Lane keys that define the set of Geo information to be considered by the Aggregation Model for the current Proposal
 */
export class AggregateModelGeoStaging {
	/**
	 * Identifier of the Aggr Model Geo Staging record
	 */
	aggrModelGeoStagingId:string = undefined;
	auditInfo:AuditInfo = undefined;
	/**
	 * Identifier of the Geo Pricing Group Lane record
	 */
	geoPricingGroupLaneId:string = undefined;
	isActiveInd:boolean = undefined;
	/**
	 * Identifier of the Pricing Proposal record
	 */
	prcProposalInstId:string = undefined;
}
export class AggregateModelTemplate {
	aggrModelConstraintTemplate:AggregateModelConstraintTemplate = undefined;
	/**
	 * unique identifier of aggregate model template
	 */
	aggrModelTemplateId:string = undefined;
	amcType:string = undefined;
	auditInfo:AuditInfo = undefined;
	destinationGeo:string = undefined;
	discountGroup:string = undefined;
	discountType:string = undefined;
	originGeo:string = undefined;
	pricingMatchType:string = undefined;
	weightBreakPricing:string = undefined;
}
/**
 * Identifier of the Pricing Agreement Audit
 */
export class AgreementAuditId {
	/**
	 * Unique identifier of the Pricing Agreement Audit Header record
	 */
	agreementAuditHdrId:string = undefined;
}
/**
 * Information about Shipment summary for a given Pricing Agreement
 */
export class AgreementShipmentSummary {
	pricingAgreement:PricingAgreement = undefined;
	shipmentSummary:ShipmentSummary[] = undefined;
}
export class AgrmtAcct {
	acctId:string = undefined;
	acctPartyRole:string = undefined;
}
/**
 * Factors involved in the calculation of CDI
 */
export class AnalysisFactorCdi {
	/**
	 * Sales Volume code value
	 */
	custSalesVolCd:string = undefined;
	/**
	 * NAICS code value
	 */
	naicsCd:string = undefined;
	/**
	 * salesRegionCd
	 */
	salesRegionCd:string = undefined;
	/**
	 * Recommended lower bound discount percentage for this combination of sales volume, naics and sales region.
	 */
	lowerBoundCDIPct:number = undefined;
	/**
	 * Recommended upper bound discount percentage for this combination of sales volume, naics and sales region.
	 */
	upperBoundCDIPct:number = undefined;
	/**
	 * Recommended optimal discount percentage for this combination of sales volume, naics and sales region.
	 */
	optimalCDIPct:number = undefined;
}
/**
 * Factors involved in the calculation of FAK Discount Impact
 */
export class AnalysisFactorFakDi {
	/**
	 * actual commodity class
	 */
	actualNmfcClass:string = undefined;
	/**
	 * as rated commodity class
	 */
	fakNmfcClass:string = undefined;
	/**
	 * Indicates the factor to be used in the calculation of the fak discount impact
	 */
	fakAdjPct:number = undefined;
}
/**
 * Factors involved in the calculation of FSC Discount Impact
 */
export class AnalysisFactorFscDi {
	/**
	 * FSC price per gallon lower range
	 */
	fscPerGalLowerBoundAmt:MonetaryAmount = undefined;
	/**
	 * FSC price per gallon lower range
	 */
	fscPerGalUpperBoundAmt:MonetaryAmount = undefined;
	/**
	 * Indicates the factor to be used in the calculation of the fsc discount impact
	 */
	fscDiscountPct:number = undefined;
}
/**
 * Commodity class overrides in the Pricing Agreement
 */
export class ClassOverride {
	fakOverrideId:string = undefined;
	/**
	 * Commodity NMFC class
	 */
	commodityClass:string = undefined;
	/**
	 * NMFC item that along with class uniquely defines the type of Freight
	 */
	commodityNmfcItem:string = undefined;
	/**
	 * FAK that overrides the NMFC class code and NMFC Item
	 */
	commodityFak:string = undefined;
	/**
	 * percent of business done by this commodity
	 */
	pctOfBusiness:number = undefined;
	/**
	 * Discount Impact for shipments with FSC by Class
	 */
	classDiscountImpactFsc:number = undefined;
	/**
	 * Discount Impact for shipments without FSC by Class
	 */
	classDiscountImpactNoFsc:number = undefined;
}
/**
 * The variable parameters that are included in the standard Pricing Agreement
 */
export class ContractBoilerPlate {
	/**
	 * This contains the amount per pound of liability this customer is asking for.
	 */
	liabilityLimit:MonetaryAmount = undefined;
	/**
	 * Payment terms if other than that standard corporate
	 */
	paymentTerms:string = undefined;
	/**
	 * Length of pricing agreement if other than one year if the contract is signed agreement
	 */
	pricingTerms:string = undefined;
	/**
	 * Any other comments to be included in the contract
	 */
	boilerPlateComments:string = undefined;
}
/**
 * File layout  of representative shipments composed of lanes, commodities, weights, density for a customer, maybe based on actual historical shipments or derived shipments. 
 */
export class CustomerShipment {
	/**
	 * Type of Billing - B (Both), S(Shipper), C(Consignee)
	 */
	billingTypeCd:string = undefined;
	/**
	 * The commodity class without FAK
	 */
	shipmentActualClass:string = undefined;
	/**
	 * Delivery date for shipment
	 */
	shipmentDeliveryDate:Date = undefined;
	/**
	 * Density of shipment
	 */
	shipmentDensity:number = undefined;
	/**
	 * The unit of measure used to represent the density - eg. kilograms per cubic meter or pounds per cubic foot.
	 */
	shipmentDensityUom:string = undefined;
	/**
	 * SIC code for destination location
	 */
	shipmentDestSic:string = undefined;
	shipmentDestStateCd:string = undefined;
	/**
	 * Zip code for dest location
	 */
	shipmentDestZip:string = undefined;
	/**
	 * Identifying number for sample shipment
	 */
	shipmentId:string = undefined;
	/**
	 * SIC code for originating location
	 */
	shipmentOrigSic:string = undefined;
	shipmentOrigStateCd:string = undefined;
	/**
	 * Zip code for origin location
	 */
	shipmentOrigZip:string = undefined;
	/**
	 * Number of lading units that are pallets
	 */
	shipmentPallets:number = undefined;
	/**
	 * The perrcentage of business sharing the same characteristics as this sample shipment
	 */
	shipmentPctOfBusiness:number = undefined;
	/**
	 * Pickup date for shipment
	 */
	shipmentPickupDate:Date = undefined;
	/**
	 * Number of lading units
	 */
	shipmentPieceCount:number = undefined;
	/**
	 * Weight of shipment
	 */
	shipmentWeight:Weight = undefined;
	/**
	 * Standard tariff revenue for a shipment of this type ???
	 */
	tariffRevenue:MonetaryAmount = undefined;
	shipmentDistance:Distance = undefined;
}
/**
 * File of representative shipments composed of lanes, commodities, weights, density for a customer, maybe based on actual historical shipments or derived shipments. 
 */
export class CustomerShipmentsFile {
	custShipmentFileId:string = undefined;
	/**
	 * defines if from BI historical, derived, provided by customer
	 */
	shipmentFileSrc:string = undefined;
	/**
	 * customer shipments
	 */
	custShipments:CustomerShipment = undefined;
}
/**
 * Header information for Customer Shipment file
 */
export class CustomerShipmentsFileHdr {
	/**
	 * unique identifier of the Rfp Customer Shipment file Header record
	 */
	rfpCustShipmentFileHdrId:string = undefined;
	/**
	 * unique identifier from the source of the customer shipment file
	 */
	sourceId:string = undefined;
	/**
	 * count of number of records from the source system
	 */
	sourceRecordCount:number = undefined;
	/**
	 * type of source
	 */
	sourceType:string = undefined;
}
/**
 * Associates a discount percentage with a particular segment of a Variable discountTypeCd - by Weight Break, by Traffic type or lane-based
 */
export class DiscountPctByVarSegment {
	/**
	 * Code for the variable discount segment - example intrastate, interstate, international, L5C, 5C, 1M, 2M
	 */
	discountSegmentTypeCd:string = undefined;
	/**
	 * Discount percentage for the particular variable discount segment
	 */
	discountPct:MonetaryAmount = undefined;
}
export class FakAdjustment {
	requestedFscDiscountPct:number = undefined;
	fscAmountPerGal:number = undefined;
	classOverrides:ClassOverride[] = undefined;
	requestedTariffDiscountPct:number = undefined;
}
/**
 * geographic area that shares common pricing elements, including tariffs, discounts, AMCs, FAK
 */
export class GeoAreaPricingGroup {
	/**
	 * unique id of geo area pricing
	 */
	geoAreaPrcId:string = undefined;
	/**
	 * A textual identification of a grouping of rates and discounts
	 */
	pricingGroupName:string = undefined;
	/**
	 * The lanes in this pricing
	 */
	geoPricingLanes:GeoAreaPricingLane = undefined;
	/**
	 * Identifies the rating type for the group of rates and discount - tariff, commodity
	 */
	rateTypeCd:string = undefined;
	/**
	 * Identifies the RTAR code or RTAR name for the tariff for a group of rates and discounts.
	 */
	ratingTariffName:string = undefined;
	/**
	 * This field allows the sales gent to enter a text describing the tariff when they do not know the RTAR or tariff name in our system
	 */
	ratingTariffDesc:string = undefined;
	/**
	 * Name of the rules tariff applicable for this RFP.
	 */
	rulesTariffName:string = undefined;
	rulesTariffDesc:string = undefined;
	/**
	 * Name of the tariff that governs how the fuel surcharge will be applied for this customer..
	 */
	fscTariffName:string = undefined;
	fscTariffDesc:string = undefined;
	/**
	 * The discount against the fuel surcharge that is being asked for.
	 */
	requestedFscDiscountPct:number = undefined;
	/**
	 * A code that qualifies a request by service of transportation.
	 */
	serviceTypeCd:string = undefined;
	/**
	 * Indicator if the rates and discounts apply to third party shipments
	 */
	thirdPartyInd:boolean = undefined;
	/**
	 * The requested FAKs for the customer
	 */
	requestedFak:PricingFak[] = undefined;
	/**
	 * Captures the type of Absolute Minimum Charge offered: Single, Variable By Lane Type
	 */
	amcTypeCd:string = undefined;
	/**
	 * Captures the type of Discount offered. Single, Variable By Weight, Variable By Lane Type
	 */
	discountTypeCd:string = undefined;
	auditInfo:AuditInfo = undefined;
}
/**
 * geographic area that shares common pricing elements, including tariffs, discounts, AMCs, FAK
 */
export class GeoAreaPricingLane {
	/**
	 * unique id of geo area pricing lane record for sales rfp
	 */
	geoAreaPrcLaneId:string = undefined;
	/**
	 * unique id of geo area pricing lane record for pricing proposal
	 */
	geoPricingLaneId:string = undefined;
	/**
	 * geographic scope for the lane
	 */
	lane:Lane = undefined;
	/**
	 * Proposed pricing factors for some or all of the lading units described in this proosal. The lading units referenced are in the request type and referenced here by id.
	 */
	ladingUnitBasedPricing:LadingUnitBasedPricing[] = undefined;
	/**
	 * requested discount for this lane
	 */
	requestedDiscount:PricingDiscount[] = undefined;
	/**
	 * requested AMC for this lane
	 */
	requestedAmc:PricingAmc[] = undefined;
	/**
	 * parts of the defined area that are excluded from this pricing lane
	 */
	geoAreaLaneExclusions:Lane[] = undefined;
	/**
	 * textual identification of a grouping of rates and discounts
	 */
	pricingGroupName:string = undefined;
	/**
	 * identifies the rating type for the group of rates and discount - tariff and commodity
	 */
	rateTypeCd:string = undefined;
	/**
	 * identifies the RTAR code or RTAR name for the tariff for a group of rates and discounts
	 */
	ratingTariffName:string = undefined;
	/**
	 * textual description of rating tariff name
	 */
	ratingTariffDesc:string = undefined;
	/**
	 * name of the rules tariff applicable for this rfp
	 */
	rulesTariffName:string = undefined;
	rulesTariffDesc:string = undefined;
	/**
	 * Name of the tariff that governs how the fuel surcharge will be applied for this customer.
	 */
	fscTariffName:string = undefined;
	fscTariffDesc:string = undefined;
	/**
	 * the discount against fuel surcharge that is being asked for
	 */
	requestedFscDiscountPct:number = undefined;
	/**
	 * A code that qualifies a request by service of transportation.
	 */
	serviceTypeCd:string = undefined;
	/**
	 * Indicator if the rates and discounts apply to third party shipments
	 */
	thirdPartyInd:boolean = undefined;
	/**
	 * Captures the type of Absolute Minimum Charge offered: Single, Variable By Lane Type
	 */
	amcTypeCd:string = undefined;
	/**
	 * Captures the type of Discount offered. Single, Variable By Weight, Variable By Lane Type
	 */
	discountTypeCd:string = undefined;
	requestedFaks:PricingFak = undefined;
	proposedFaks:PricingFak[] = undefined;
	auditInfo:AuditInfo = undefined;
}
/**
 * Captures the Discount and Absolute Min Charge information for Origin and Destination geo areas
 */
export class GeoDiscountAmcSummary {
	/**
	 * unique identifier of the Geo Discount Amc Summary record
	 */
	geoDiscAmcSummaryId:string = undefined;
	/**
	 * origin state code
	 */
	origStateCd:string = undefined;
	/**
	 * unique identifier of the origin group
	 */
	origGroupId:string = undefined;
	/**
	 * Origin group name
	 */
	origGroupName:string = undefined;
	/**
	 * destination state code
	 */
	destStateCd:string = undefined;
	/**
	 * unique identifier of destination group
	 */
	destGroupId:string = undefined;
	/**
	 * destination group name
	 */
	destGroupName:string = undefined;
	/**
	 * current discount percentage
	 */
	currDiscountPct:number = undefined;
	currAmcAmt:MonetaryAmount = undefined;
	/**
	 * proposed discount percentage
	 */
	proposedDiscountPct:number = undefined;
	proposedAmcAmt:MonetaryAmount = undefined;
	auditInfo:AuditInfo = undefined;
}
/**
 * Geo Group AMC and Tariff data sourced from Cost Model and BI
 */
export class GeoGroupAmcTariff {
	/**
	 * Averaged Accessorial amount of the shipments within the group from BI
	 */
	accessorialTariffAmt:MonetaryAmount = undefined;
	/**
	 * Averaged Corporate linehaul charge amount of the shipments within the group from BI
	 */
	corpLnhaulChrg:MonetaryAmount = undefined;
	/**
	 * Averaged freight revenue of shipments within the group  from BI
	 */
	freightRevenueAmt:MonetaryAmount = undefined;
	/**
	 * Averaged Fuel Surcharge amount of the shipments within the group from BI
	 */
	fscRevenueAmt:MonetaryAmount = undefined;
	/**
	 * Average Off-bill incentive amount of the shipments within the group from BI
	 */
	obiTariffAmt:MonetaryAmount = undefined;
	/**
	 * Averaged total miles of the shipments within the group  from BI
	 */
	shipmentDistance:Distance = undefined;
	/**
	 * Number of shipments not rated with Absolute Minimum Charge within the group  from BI
	 */
	shipmentTariffCount:number = undefined;
	/**
	 * Averaged tariff amount of the shipments within the group  from BI
	 */
	shipmentTariffAmt:MonetaryAmount = undefined;
	/**
	 * Total weight of the shipment unit of measurement
	 */
	shipmentVolume:Volume = undefined;
	/**
	 * Total weight of the shipment
	 */
	shipmentWeight:Weight = undefined;
	/**
	 * Average total cost amount of the shipments within the group from BI
	 */
	totalTariffCostAmt:MonetaryAmount = undefined;
	auditInfo:AuditInfo = undefined;
}
/**
 * Geo Group BI AMC information
 */
export class GeoGroupBiAmc {
	/**
	 * unique id of Geo Group BI AMC record
	 */
	geoGroupBiAmcId:string = undefined;
	biAmcDetails:GeoGroupAmcTariff = undefined;
}
/**
 * Geo Group Historical shipments information
 */
export class GeoGroupBiTariff {
	/**
	 * unique id of Geo Group Bi Tariff record
	 */
	geoGroupBiTariffId:string = undefined;
	biTariffDetails:GeoGroupAmcTariff = undefined;
}
/**
 * Geo Group Cost Model rated AMC information
 */
export class GeoGroupCmAmc {
	/**
	 * unique id of Geo Group Cost Model rated AMC record
	 */
	geoGroupCmAmcId:string = undefined;
	cmAmcDetails:GeoGroupAmcTariff = undefined;
}
/**
 * Geo Group Cost Model Tariff information
 */
export class GeoGroupCmTariff {
	/**
	 * unique id of Geo Group CM Tariff record
	 */
	geoGroupCmTariffId:string = undefined;
	cmTariffDetails:GeoGroupAmcTariff = undefined;
}
export class GeoGroupDiscountAmc {
	/**
	 * 3 char ISO currency Code
	 */
	amcCurrencyCd:string = undefined;
	averageCurrentAmcAmt:MonetaryAmount = undefined;
	averageCurrentDiscountPct:number = undefined;
	geoGroupDiscountAmcBase:GeoGroupDiscountAmcBase = undefined;
	/**
	 * unique identifier of Geo Group Discount AMC
	 */
	geoGroupDiscAmcId:string = undefined;
	auditInfo:AuditInfo = undefined;
}
export class GeoGroupDiscountAmcBase {
	/**
	 * Indicator to show whether the AMC was increased or decreased
	 */
	amcIncrDecrInd:string = undefined;
	/**
	 * Percent of discount increase
	 */
	discountIncrPct:number = undefined;
	/**
	 * New Absolute Minimum Charge amount of shipments within the group on pricing analysis
	 */
	newAmcAmt:MonetaryAmount = undefined;
	/**
	 * Proposed Discount Amt
	 */
	newDiscountAmt:MonetaryAmount = undefined;
	newDiscountPct:number = undefined;
	/**
	 * New Net revenue amount of shipments rated with AMC within the group based on pricing analysis
	 */
	newNetRevenueAmcAmt:MonetaryAmount = undefined;
	/**
	 * New Net revenue amount of shipments within the group based on pricing analysis
	 */
	newNetRevenueAmt:MonetaryAmount = undefined;
	/**
	 * New Net revenue amount of shipments not rated with AMC within the group based on pricing analysis
	 */
	newNetRevenueTariffAmt:MonetaryAmount = undefined;
	/**
	 * Indicator to show whether the Tariff was increased or decreased
	 */
	tariffIncrDecrInd:string = undefined;
}
/**
 * Geo Group FFO information sourced from BI and Cost Model
 */
export class GeoGroupFFO {
	biFfoPupCapacityPerDay:number = undefined;
	biFfoShipmentPct:number = undefined;
	biFfoShipmentCount:number = undefined;
	cmFfoPupCapacityPerDay:number = undefined;
	cmFfoShipmentPct:number = undefined;
	cmFfoShipmentCount:number = undefined;
}
/**
 * Geo Group Lane Score Data
 */
export class GeoGroupLaneScore {
	cdiPctBetterLocal:number = undefined;
	cdiPctBetterNational:number = undefined;
	/**
	 * Overall lane score National value
	 */
	currOverallLaneScoreNat:number = undefined;
	hssTotalShipmentVolPct:number = undefined;
	macroScorePct:number = undefined;
	orPctBetter:number = undefined;
	orPctBetterLocal:number = undefined;
	orPctBetterNational:number = undefined;
	orPctScore:number = undefined;
	prpsdCdiPctBetterNational:number = undefined;
	prpsdCdiPctBetterLocal:number = undefined;
	prpsdOrScorePct:number = undefined;
	prpsdOverallLaneScorePct:number = undefined;
	totalShipmentPct:number = undefined;
	hssLaneScorePct:number = undefined;
	prpsdOrPctBetterNational:number = undefined;
}
/**
 * Information about Geo Pricing group and their included and excluded members
 */
export class GeoPricingGroup {
	/**
	 * unique db identifier of Geo Pricing Group record
	 */
	geoPricingGroupId:string = undefined;
	/**
	 * name of the geo pricing group
	 */
	geoPricingGroupName:string = undefined;
	/**
	 * customer account MAD Code
	 */
	acctMadCd:string = undefined;
	/**
	 * customer account instance id
	 */
	acctInstId:string = undefined;
	groupMembers:GeoPricingGroupMember[] = undefined;
	exclGroupMembers:GeoPricingGroupMember[] = undefined;
	auditInfo:AuditInfo = undefined;
}
/**
 * Information about predefined global group lane or a custom customer-specific group lane when user creates a new group lane using the orig/dest group 
 */
export class GeoPricingGroupLane {
	/**
	 * unique identifier of the Geo Pricing Group Lane record
	 */
	geoPricingGroupLaneId:string = undefined;
	/**
	 * Name of the Geo Pricing Group Lane
	 */
	geoPricingGroupLaneName:string = undefined;
	/**
	 * Notes captured when creating the lane
	 */
	note:string = undefined;
	/**
	 * Customer Account Instance Id that this Geo Pricing Group Lane record is applicable for.
	 */
	acctInstId:string = undefined;
	/**
	 * Customer Account MAD Code that this Geo Pricing Group Lane record is applicable for.
	 */
	acctMadCd:string = undefined;
	/**
	 * Date when the the Geo Pricing Group Lane record is effective
	 */
	effectiveDate:Date = undefined;
	/**
	 * Date when the the Geo Pricing Group Lane record expires
	 */
	expiryDate:Date = undefined;
	laneMember:GeoPricingGroupLaneMember = undefined;
	auditInfo:AuditInfo = undefined;
}
/**
 * Information about the member groups within predefined lane
 */
export class GeoPricingGroupLaneMember {
	/**
	 * unique identifier of the geo pricing group lane member
	 */
	geoPricingGroupLaneMbrId:string = undefined;
	/**
	 * identifier of the parent Geo Pricing Lane record
	 */
	geoPricingGroupLaneId:string = undefined;
	/**
	 * identifier of the Geo Pricing Group record used as the Origin
	 */
	origGeoPricingGroupId:string = undefined;
	/**
	 * Name of the Geo Pricing Group record used as the Origin
	 */
	origGeoPricingGroupName:string = undefined;
	/**
	 * identifier of the Geo Pricing Group record used as the destination
	 */
	destGeoPricingGroupId:string = undefined;
	/**
	 * Name of the Geo Pricing Group record used as the destination
	 */
	destGeoPricingGroupName:string = undefined;
}
/**
 * Information about the members of the Geo Pricing Group
 */
export class GeoPricingGroupMember {
	/**
	 * unique db identifier of Geo Pricing Group record
	 */
	geoPricingGroupId:string = undefined;
	/**
	 * unique db identifier of the Geo Pricing Group Member
	 */
	geoPricingGroupMemberId:string = undefined;
	/**
	 * unique db identifier of the excluded geo pricing group member
	 */
	geoPricingGroupExclMbrId:string = undefined;
	groupTypeCd:string = undefined;
	locationCd:string = undefined;
	/**
	 * lower range of the postal code
	 */
	lowZipCd:string = undefined;
	/**
	 * higher range of the postal code
	 */
	highZipCd:string = undefined;
	stateCd:string = undefined;
	countryCd:string = undefined;
}
/**
 * Information about the AMCs for a given Geo Pricing Lane
 */
export class GeoPricingLaneAmc {
	/**
	 * unique db id of geo pricing lane amc record
	 */
	geoPricingLaneAmcId:string = undefined;
	amcInfo:PricingAmc = undefined;
}
export class InputHierarchyFile {
	class:string = undefined;
	custRevenue:string = undefined;
	/**
	 * text field
	 */
	density:string = undefined;
	destGeoType:string = undefined;
	origGeoType:string = undefined;
	salesRfpId:string = undefined;
	/**
	 * text field
	 */
	weight:string = undefined;
}
/**
 * Lading Unit characteristics
 */
export class LadingUnitBasedPricing {
	/**
	 * An Id that identifies an instance in the  LadingUnitType array. That type will have the characteristics of a particular lading unit.
	 */
	pricingLadingUnitId:string = undefined;
	/**
	 * What the begin and end range is based on for this pricing - Pallets, Volume, Density, Weight, Piece Count.
	 */
	rangeTypeCode:string = undefined;
	/**
	 * Unit of measure for the range type. Unit of Measure for the Range Type
	 * if Range type Code = Weight then UOM code = LB or KG
	 */
	rangeUom:string = undefined;
	/**
	 * If range type is Pallet then this is the Minimum Pallet count value
	 * 
	 * If range type is Weight then this is the Minimum Weight value
	 * 
	 * If range type is Piece Count then this is the Minimum Piece Count value
	 */
	beginRangeValue:number = undefined;
	/**
	 * If range type is Pallet then this is the Maximum Pallet count value
	 * 
	 * If range type is Weight then this is the Maximum Weight value
	 * 
	 * If range type is Piece Count then this is the Maximum Piece Count value
	 */
	endRangeValue:number = undefined;
	/**
	 * Minimum charge amount that is applicable for this tier
	 */
	minChargeAmt:MonetaryAmount = undefined;
	/**
	 * 1. Rate Per Unit
	 * 2. Lumpsum rate for all units
	 * 3. Base Rate
	 */
	rateTypeCd:string = undefined;
	/**
	 * The rate per unit
	 */
	ratePerUnitAmt:MonetaryAmount = undefined;
	/**
	 * This will have a value when  rateTypeCd is BaseRate
	 */
	baseRateAmt:MonetaryAmount = undefined;
	/**
	 * Applies when the rateTypeCd is BaseRate ??? need a better definition
	 */
	baseRateUnitCount:number = undefined;
	/**
	 * Indicates how the FSC charge should be priced
	 */
	fscRateTypeCd:string = undefined;
}
/**
 * Defined by an origin area to a destination area. 
 */
export class Lane {
	/**
	 * unique id of the excluded lane record
	 */
	geoLaneExclId:string = undefined;
	/**
	 * A descriptive designation for the scope of the origin area. Could be a zip, state, country or some group of zips, etc.
	 */
	origAreaTypeCd:string = undefined;
	/**
	 * A descriptive name of the area if not a single zip, state or country
	 */
	origArea:string = undefined;
	/**
	 * State code for where the customers shipments originate.
	 */
	origStateCd:string = undefined;
	/**
	 * Postal code for where the customers shipments originate. This field may accommodate zip3, zip5 or zip6 for Canada
	 */
	origPostalCd:string = undefined;
	/**
	 * minimum in a range of postal codes
	 */
	origLowPostalCd:string = undefined;
	/**
	 * maximum in a range of postal codes
	 */
	origHighPostalCd:string = undefined;
	/**
	 * Counry code for where the customers shipments originate.
	 */
	origCountryCd:string = undefined;
	/**
	 * A descriptive designation for the scope of the destination area. Could be a zip, state, country or some group of zips, etc.
	 */
	destAreaTypeCd:string = undefined;
	/**
	 * A descriptive name of the area if not a single zip, state or country
	 */
	destArea:string = undefined;
	/**
	 * State code for where the customers shipments are destined.
	 */
	destStateCd:string = undefined;
	/**
	 * This field may accommodate zip3, zip5 or zip6 for Canada shipments for the destination of shipments.
	 */
	destPostalCd:string = undefined;
	/**
	 * minimum in a range of postal codes
	 */
	destLowPostalCd:string = undefined;
	/**
	 * maximum in a range of postal codes
	 */
	destHighPostalCd:string = undefined;
	/**
	 * Country code for where the customers shipments are destined.
	 */
	destCountryCd:string = undefined;
	/**
	 * unique identifier of the Geo Prigin Group
	 */
	origGroupId:string = undefined;
	/**
	 * Pricing Group Name used as origin point
	 */
	origGroupName:string = undefined;
	origGroupMembers:GeoPricingGroupMember[] = undefined;
	/**
	 * unique identifier of the Geo Prigin Group
	 */
	destGroupId:string = undefined;
	/**
	 * Pricing Group Name used as destination point
	 */
	destGroupName:string = undefined;
	destGroupMembers:GeoPricingGroupMember[] = undefined;
}
/**
 * Pricing Agreement expectations are stored in this object. Initial expectation for the specific version is created when the corresponding pricing request's rulesets are approved. User has option to modify the expectations during Monitor and adjust process. On each modification new record will be created and old expectations will be expired and the history is maintained in this object itself.
 * 
 * 
 * As an Account Executive / Pricing Analyst, I want to be able to view expectations for the entire agreement (at the suffix level) so that when I monitor the agreement, I will be using complete expectations information for the agreement.
 * As a Sales / Pricing Manager, I want to be able to modify the Projected OR and Projected Revenue for the Pricing Agreement so that I can adjust expectations that were originally defined incorrectly.
 * Expectation records are read-only and visible to all Pricing and all Sales profiles.
 * 
 * 
 * 
 * 
 * 
 */
export class PaePricingAgreementExpectation {
	/**
	 * Identifier of the Customer with whom the agreement is being or was negotiated. This will uniquely identify the customer in the CIS component.
	 */
	negotiatingCustInstId:string = undefined;
	/**
	 * A pricing agreement is considered to be a contract with a customer that may be renegotiated, changed, and respecified over time. Whenever the contract is renegotiated the Version Number is incremented and the effective date is changed to reflect the current negotiations.
	 */
	pricingAgrmtCustVer:number = undefined;
	/**
	 * If a customer has more than one pricing agreement at a time, each agreement is assigned a numeric suffix. Usually there is one suffix per customer but some have more. Each customer-suffix combination has its own set of version numbers starting with 1.
	 */
	pricingAgrmtSeqNbr:number = undefined;
	/**
	 * Expectation details are valid up to this date. Once the details are modified it will be effective from new date.
	 */
	effectiveDate:Date = undefined;
	/**
	 * Expectation details are valid up to this date. Once the details are modified it will be effective from new date. Expiration dates for an expectation: a pricing agreement expiration record should have the same expiration date as the Pricing Agreement.
	 */
	expiryDate:Date = undefined;
	/**
	 * An unique number generated by the source system used to identify a RFP.
	 */
	salesRfpId:string = undefined;
	/**
	 * This is the unique database key of the agreement.
	 */
	agreementId:string = undefined;
	/**
	 * Revenue prior to the concluded renegotiation.  It will be frozen when the Pricing Analyst begins analysis.
	 * 
	 * It is a snapshot of last 6 months Revenue average before 'Analysis Start Date' the inital date is the anlysis start date. It is referred as Monthly Revenue At Start of Analysis in SFDC.
	 * 
	 * This is copied from SFDC Pricing Request when the expectation is initially created. It is not modified as part of the Expectation, it is read-only.
	 */
	monthlyInitialRevenueAmt:MonetaryAmount = undefined;
	/**
	 * Current pricing Operating Ratio O/R (%) for this account.
	 */
	currentOr:number = undefined;
	/**
	 * It is projected target improvement percent based on current Operating ratio entered by user.
	 */
	profitImprvmntTargetPct:number = undefined;
	/**
	 * Projected O/R (%) in the Expectations section of the Pricing Agreement can only be changed by Pricing Managers/Directors.
	 * 
	 * This can be a calculated amount or manually entered
	 */
	projectedOr:number = undefined;
	/**
	 * This is the Projected Yield (with Fuel) is used in Integrated Yield Management.
	 */
	projectedYieldWithFuelAmt:number = undefined;
	/**
	 * This is the expected Percent of shipments rated with AMC based on which the pricing request was approved.
	 */
	amcShipmentRevAmt:MonetaryAmount = undefined;
	/**
	 * This is an average of the freight class code
	 */
	avgFreightClassCd:string = undefined;
	/**
	 * This is the expected Average Pickup Stop Time based on which the pricing agreement was created.
	 */
	avgPickupStopElapsedTime:number = undefined;
	/**
	 * This is the expected Average Delivery Stop Time based on which the pricing agreement was created.
	 */
	avgDeliveryStopElapsedTime:number = undefined;
	/**
	 * This is the expected average Density of freight, based on which the pricing agreement was created.
	 */
	avgDensity:number = undefined;
	/**
	 * This is the expected average weight per shipment, based on which the pricing agreement was created.
	 */
	avgShipmentWeight:Weight = undefined;
	/**
	 * This will indicate whether the pricing is lane based or not: values are Y or N.
	 */
	laneBasedPricingInd:string = undefined;
	/**
	 * User will enter the comments once the expectation data modified before new version of the pricing agreement
	 */
	commentText:string = undefined;
}
/**
 * Accessorial information being considered for this RFP
 */
export class PricingAccessorial {
	accessorialId:string = undefined;
	/**
	 * The code representing the accessorial
	 */
	accessorialCd:string = undefined;
	/**
	 * Description of the accessorial
	 */
	accessorialDesc:string = undefined;
	/**
	 * The unit for this accessorial
	 */
	accessorialUom:string = undefined;
	/**
	 * The minimum required based on the UOM for this accessorial type
	 */
	accessorialMinUnits:number = undefined;
	/**
	 * The minimum required based on the UOM for this accessorial type
	 */
	accessorialMaxUnits:number = undefined;
	accessorialChgAmt:MonetaryAmount = undefined;
	/**
	 * An amount that will override the default amount for this accessorial
	 */
	accessorialOverrideChg:MonetaryAmount = undefined;
	/**
	 * This field overrides the default percentage used for calculating the accessorial service.
	 */
	accessorialOverrideChgPct:number = undefined;
	/**
	 * True if this accessorial will not be charged in spite of the circumstances normally requiring it.
	 */
	accessorialWaivedInd:boolean = undefined;
	/**
	 * The percentage of the customers total shipments that will qualify for this accessorial.
	 */
	accessorialQualifyPct:number = undefined;
	auditInfo:AuditInfo = undefined;
}
export class PricingAdjustment {
	instIds:string[] = undefined;
	adjustAmt:number = undefined;
	adjustPct:number = undefined;
	clusterIds:string[] = undefined;
	uom:string = undefined;
}
/**
 * Information about Fak adjustment percentage
 */
export class PricingAdminFakAdjustmentPct {
	/**
	 * unique identifier of Pricing Admin Fak Adjustment Pct record
	 */
	adminFakAdjustPctId:string = undefined;
	/**
	 * Fak Class
	 */
	fakClass:string = undefined;
	/**
	 * actual NMFC class
	 */
	actualClass:string = undefined;
	fakAdjustmentPct:number = undefined;
	/**
	 * effective date of the record
	 */
	effDate:Date = undefined;
	/**
	 * expiry date of the record
	 */
	expDate:Date = undefined;
	auditInfo:AuditInfo = undefined;
}
/**
 * Information about Fuel Surcharge Percentage
 */
export class PricingAdminFscPctLookup {
	adminFscPctLookupId:string = undefined;
	/**
	 * Beginning range of Fuel Price Per Gallon
	 */
	fuelPerGalBgnRng:MonetaryAmount = undefined;
	/**
	 * Ending range of Fuel Price Per Gallon
	 */
	fuelPerGalEndRng:MonetaryAmount = undefined;
	effDate:Date = undefined;
	expDate:Date = undefined;
	auditInfo:AuditInfo = undefined;
}
/**
 * Information about the Pricing Analysts and their Managers
 */
export class PricingAdminPersonnel {
	/**
	 * unique identifier of the Pricing Admin Personnel record
	 */
	adminPersonnelId:string = undefined;
	/**
	 * Alternate Employee Id of the Employee in HR system
	 */
	alternateEmployeeId:string = undefined;
	/**
	 * Alternate Employee Id of the Manager in HR system
	 */
	alternateManagerEmployeeId:string = undefined;
	auditInfo:AuditInfo = undefined;
	/**
	 * employee district code as defined in the HR system
	 */
	districtCode:string = undefined;
	emailAddr:string = undefined;
	/**
	 * Employee full name as defined in the HR system
	 */
	employeeFullName:string = undefined;
	/**
	 * Employee id as defined in the HR system
	 */
	employeeId:string = undefined;
	/**
	 * Employee Role as defined in the HR system
	 */
	employeeRoleType:string = undefined;
	/**
	 * Employee Title as defined in the HR system
	 */
	employeeTitle:string = undefined;
	/**
	 * Indicates user has admin access. If the value is ï¿½Yï¿½ the user have access to  update any screen in the system
	 */
	isAdminUserInd:string = undefined;
	/**
	 * Employee Id of the Manager in the HR System
	 */
	managerEmployeeId:string = undefined;
	/**
	 * Full Name of the Manager in the HR System
	 */
	managerFullName:string = undefined;
	/**
	 * employee region code as defined in the HR system
	 */
	regionCode:string = undefined;
	sicCode:string = undefined;
	/**
	 * Indicates if the employee is still active
	 */
	activeInd:boolean = undefined;
	/**
	 * Employee termination date
	 */
	terminationDate:Date = undefined;
	/**
	 * Additional Roles performed by this employee (like Pricing analyst does Auditor or Ruleset Builder)
	 */
	additionalRoleType:string = undefined;
	reportsToEmployeeId:string = undefined;
	/**
	 * Netwirk userr Id
	 */
	ntUserId:string = undefined;
}
/**
 * Information about Proposed Increase Level
 */
export class PricingAdminProposedIncrLevel {
	adminProposedIncrLevelId:string = undefined;
	prpsIncreaseLevelPctMin:number = undefined;
	prpsIncreaseLevelPctMax:number = undefined;
	result:string = undefined;
	effDate:Date = undefined;
	expDate:Date = undefined;
	auditInfo:AuditInfo = undefined;
}
/**
 * Information about Proposed Strategy
 */
export class PricingAdminProposedStrategy {
	adminProposedStrategyPctId:string = undefined;
	proposedStrategyPctMin:number = undefined;
	proposedStrategyPctMax:number = undefined;
	result:string = undefined;
	effDate:Date = undefined;
	expDate:Date = undefined;
	auditInfo:AuditInfo = undefined;
}
export class PricingAgreement {
	/**
	 * Values :
	 * 1 - LTL Pricing
	 * 2 - Spot Quote
	 * 3 - Pallet Pricing
	 */
	paTypeCd:string = undefined;
	/**
	 * This is the unique database key of the agreement.
	 */
	agreementId:string = undefined;
	/**
	 * unique id of Sales Rfp
	 */
	salesRfpId:string = undefined;
	/**
	 * Type of Sales Rfp associated with the Pricing agreement
	 */
	salesRfpTypeCd:string = undefined;
	/**
	 * Request date of Sales Rfp associated wih the agreement
	 */
	salesRfpRequstDate:Date = undefined;
	/**
	 * The version of the  pricing agreement
	 */
	agreementCustVersion:string = undefined;
	/**
	 * The sequence number within the version of pricing agreement
	 */
	agreementSeqNbr:string = undefined;
	/**
	 * The instance ID of the customer that negotiated this pricing agreement
	 */
	leadAcctId:string = undefined;
	/**
	 * This is the primary location used for the agreement
	 */
	primaryAcctId:string = undefined;
	/**
	 * Desc: This identifies the type of contract (this pricing agreement) for the customer.
	 * 
	 * Valid Values:
	 * T - Third Party Logistics (3PL) contract
	 * N - National contract
	 * L - Local contract
	 * O - Other contract
	 */
	paAcctTypeCd:string = undefined;
	agreementTypeCd:string = undefined;
	/**
	 * Date the Pricing Agreement was effective
	 */
	effectiveDate:Date = undefined;
	/**
	 * Date the pricing agreement will expire or expired
	 */
	expiryDate:Date = undefined;
	/**
	 * Identifies if the current pricing agreement has OBI Program
	 */
	obiInd:boolean = undefined;
	/**
	 * Format type of Pricing agreement - Internal or External
	 */
	formatType:string = undefined;
	signatureReqdInd:boolean = undefined;
	/**
	 * Renegotiation date of Pricing agreement
	 */
	agreementRenegDate:Date = undefined;
	/**
	 * unique identifier of the Pricing Agreement from the source system
	 */
	sourceAgrmtId:string = undefined;
	thirdPartyLogisticsTypeCd:string = undefined;
	pricingTypeCd:string = undefined;
	activeIndicator:boolean = undefined;
	auditInfo:AuditInfo = undefined;
	pricingDcmntLeadPaId:string = undefined;
	pricingAgreementEventType:string = undefined;
	pricingAudit:PricingAgreementAuditHdr = undefined;
	/**
	 * Agreement Account primary location owner
	 */
	agreementAccountOwner:EmployeeId = undefined;
	strategyType:string = undefined;
	liabilityDetails:PricingAgreementDocLiabHdr = undefined;
	acctDetails:AcctId = undefined;
}
/**
 * List of Customer Accounts associated with a Pricing Agreement
 */
export class PricingAgreementAcct {
	pricingAgreement:PricingAgreement = undefined;
	acctInfo:AcctId[] = undefined;
}
/**
 * Details about the Pricing Agreement Audit
 */
export class PricingAgreementAudit {
	/**
	 * Audit Header details
	 */
	agreementAuditHdr:PricingAgreementAuditHdr = undefined;
	/**
	 * Sales Rfp details associated with the Pricing Audit
	 */
	salesRfpHdr:SalesRfpHdr = undefined;
	/**
	 * Basic details about the customer account
	 */
	accountDetail:AcctId = undefined;
	/**
	 * Pricing Agreement details
	 */
	agreementDetail:PricingAgreement = undefined;
	/**
	 * Pricing Proposal Header Details
	 */
	proposalHdr:PricingProposalHdr = undefined;
}
/**
 * Information about the Pricing Agreement Audit
 */
export class PricingAgreementAuditHdr {
	/**
	 * Unique identifier of the Pricing Agreement Audit Header record
	 */
	agreementAuditHdrId:string = undefined;
	/**
	 * Unique identifier of the parent Pricing Agreement record to which this audit record belongs
	 */
	agreementId:string = undefined;
	/**
	 * Employee Id of the Ruleset builder
	 */
	rulesetBuilderEmpId:EmployeeId = undefined;
	/**
	 * Current status of the Pricing Agreement Audit record
	 */
	auditStatus:string = undefined;
	/**
	 * Date when the Pricing Agreement Audit was completed
	 */
	completeDate:Date = undefined;
	/**
	 * Due date for the Current Audit Status to be completed
	 */
	statusDueDate:Date = undefined;
	/**
	 * Employee Id of the Auditor
	 */
	auditorEmpId:EmployeeId = undefined;
	/**
	 * Date when the Pricing Agreement was tagged for Audit
	 */
	tagDate:Date = undefined;
	maxRevenueAmt:MonetaryAmount = undefined;
	/**
	 * Employee Id of the Pricing Analyst responsible for this Pricing Agreement
	 */
	pricingAnalystEmpId:EmployeeId = undefined;
	/**
	 * Describes the type of audit work
	 */
	auditWorkTypeCd:string = undefined;
	comments:PricingAgreementAuditComment[] = undefined;
	/**
	 * Header Event Log
	 */
	headerEventLog:PricingAgreementAuditEventLog[] = undefined;
	issues:PricingAgreementAuditIssue[] = undefined;
	auditInfo:AuditInfo = undefined;
	/**
	 * Date when the Pricing Agreement Audit was started
	 */
	startDate:Date = undefined;
	/**
	 * Priority of the Audit
	 */
	priority:string = undefined;
	/**
	 * Number of dayes overdue or  outstanding
	 */
	dueDateText:string = undefined;
	/**
	 * number of document error
	 */
	documentErrorCount:number = undefined;
	/**
	 * number of ruleset error
	 */
	rulesetErrorCount:number = undefined;
	/**
	 * number of miscellaneous error
	 */
	miscErrorCount:number = undefined;
	/**
	 * Ruleset open issue count
	 */
	rulesetOpenCount:number = undefined;
	/**
	 * Update pf audit header record allowed or not
	 */
	statusAllowed:string = undefined;
	nextRevisionCount:number = undefined;
	pricingAnalystMgrId:EmployeeId = undefined;
	auditorMgrId:EmployeeId = undefined;
	rulesetBuilderMgrId:EmployeeId = undefined;
}
/**
 * Information about the supporting documents for Pricing Agreement Audit
 */
export class PricingAgreementAuditDoc {
	/**
	 * Unique identifier of the Agreement Audit Document record
	 */
	agreementAuditDocId:string = undefined;
	/**
	 * Unique identifier of the Agreement Audit Comment
	 */
	agreementAuditCommentId:string = undefined;
	/**
	 * Unique identifier of the Pricing Agreement Audit Header record
	 */
	agreementAuditHdrId:string = undefined;
	/**
	 * Type of Document
	 */
	docTypeCd:string = undefined;
	/**
	 * URL for the document to fetch from DMS system
	 */
	docUrl:string = undefined;
	/**
	 * Document file name in the DMS system
	 */
	docFile:string = undefined;
	auditInfo:AuditInfo = undefined;
}
/**
 * Comments related to Pricing Agreement Audit
 */
export class PricingAgreementAuditComment {
	/**
	 * Unique identifier of the Agreement Audit Comment
	 */
	agreementAuditCommentId:string = undefined;
	/**
	 * Unique identifier of the Pricing Agreement Audit Header record
	 */
	agreementAuditHdrId:string = undefined;
	/**
	 * Unique identifier of the Agreement Audit Issue record
	 */
	agreementAuditIssueId:string = undefined;
	/**
	 * Source for the Audit Comments - Analyst, Auditor, Internal, Manager, Ruleset Builder etc.
	 */
	commentSource:string = undefined;
	/**
	 * Actual comment text
	 */
	commentText:string = undefined;
	auditInfo:AuditInfo = undefined;
	/**
	 * Document Type Code
	 */
	docTypeCd:string = undefined;
	/**
	 * Document Url
	 */
	docUrl:string = undefined;
	/**
	 * Document File
	 */
	docFile:string = undefined;
	/**
	 * Agreement Audit Event Log Id
	 */
	agreementAuditEventLogId:string = undefined;
	/**
	 * Name of the employee who created the record
	 */
	createdBy:string = undefined;
	createdTimestamp:Date = undefined;
}
/**
 * Information about milestone events for Audit
 */
export class PricingAgreementAuditEventLog {
	/**
	 * Unique identifier of the Pricing Agreement Audit Event Log record
	 */
	agreementAuditEventLogId:string = undefined;
	/**
	 * Unique identifier of the Pricing Agreement Audit Header record
	 */
	agreementAuditHdrId:string = undefined;
	/**
	 * Unique identifier of the Agreement Audit Issue record
	 */
	agreementAuditIssueId:string = undefined;
	/**
	 * Type of event. Eg. HdrStatus or IssueStatus
	 */
	eventTypeCd:string = undefined;
	/**
	 * Sub Type Code for the event
	 */
	eventSubTypeCd:string = undefined;
	/**
	 * Value for the eventTypeCd
	 */
	eventStatus:string = undefined;
	eventTranCd:string = undefined;
	eventData:string = undefined;
	/**
	 * The date and time when the event occurred
	 */
	eventOccurredTimestamp:Date = undefined;
	statusReasonCd:string = undefined;
	eventCreatedBy:string = undefined;
	/**
	 * Due date for the event status
	 */
	eventDueDate:Date = undefined;
	auditInfo:AuditInfo = undefined;
	/**
	 * Comment  associated to the issue audit log
	 */
	logComment:PricingAgreementAuditComment[] = undefined;
	/**
	 * previous event status value
	 */
	prevEventStatus:string = undefined;
}
/**
 * Information about Issues related to Pricing Agreement Audit
 */
export class PricingAgreementAuditIssue {
	/**
	 * Unique identifier of the Agreement Audit Issue record
	 */
	agreementAuditIssueId:string = undefined;
	/**
	 * Unique identifier of the Pricing Agreement Audit Header record
	 */
	agreementAuditHdrId:string = undefined;
	/**
	 * Date when the Audit Issue was reported
	 */
	startDate:Date = undefined;
	/**
	 * Identifies the type of Audit Issue
	 */
	issueType:string = undefined;
	/**
	 * Date when the Audit Issue was closed
	 */
	closeDate:Date = undefined;
	/**
	 * Brief description of the issue
	 */
	description:string = undefined;
	/**
	 * Identifies the category to which the Issue type belongs
	 */
	issueCategory:string = undefined;
	/**
	 * Ruleset number for which the Audit issue was recorded
	 */
	rulesetNumber:string = undefined;
	/**
	 * Ruleset type for which the Audit issue was recorded
	 */
	rulesetType:string = undefined;
	auditInto:AuditInfo = undefined;
	/**
	 * Audit Issue Event Log
	 */
	issueEventLog:PricingAgreementAuditEventLog[] = undefined;
	/**
	 * Audit Issue Detail
	 */
	issueDetail:string = undefined;
	/**
	 * Audit issue Commemts
	 */
	issueComments:PricingAgreementAuditComment[] = undefined;
	/**
	 * These are the allowed issue status fo r the current status
	 */
	allowedIssueStatus:string = undefined;
	/**
	 * Audit Issue current Status
	 */
	issueStatus:string = undefined;
	/**
	 * Status of audit header allowed
	 */
	statusAllowed:string = undefined;
}
/**
 * Basic Information about a Pricing Agreement
 */
export class PricingAgreementBasic {
	/**
	 * This is the unique database key of the agreement.
	 */
	agreementId:string = undefined;
	/**
	 * The version of the  pricing agreement
	 */
	agreementCustVersion:string = undefined;
	/**
	 * The sequence number within the version of pricing agreement
	 */
	agreementSeqNbr:string = undefined;
	/**
	 * The instance ID of the customer that negotiated this pricing agreement
	 */
	leadAcctId:string = undefined;
	/**
	 * Date the Pricing Agreement was effective
	 */
	effectiveDate:Date = undefined;
	/**
	 * Date the pricing agreement will expire or expired
	 */
	expiryDate:Date = undefined;
	/**
	 * Renegotiation date of Pricing agreement
	 */
	renegotiationDate:Date = undefined;
}
/**
 * List of Pricing Agreements associated with a Customer Account
 */
export class PricingAgreementByAcct {
	acctInstId:object = undefined;
	/**
	 * List of Pricing Agreements by input Account Instance Id
	 */
	acctPricingAgreement:PricingAgreement[] = undefined;
}
export class PricingAgreementDocHdr {
	acctInstId:number = undefined;
	acctMadCd:string = undefined;
	acctName:string = undefined;
	activatedByEmpId:string = undefined;
	activationDate:Date = undefined;
	agreementCustVersion:number = undefined;
	agreementDocHdrId:number = undefined;
	agreementDocLiabHdr:PricingAgreementDocLiabHdr = undefined;
	agreementDocType:PricingAgreementDocType[] = undefined;
	agreementDocInd:PricingAgreementDocInd[] = undefined;
	agreementSeqNbr:number = undefined;
	agreementTypeCd:string = undefined;
	approvalStep:string = undefined;
	auditInfo:AuditInfo = undefined;
	companySignedBy:string = undefined;
	companySignedDate:Date = undefined;
	companySignedTitle:string = undefined;
	contact1City:string = undefined;
	contact1Country:string = undefined;
	contact1Name:string = undefined;
	contact1PostalCode:string = undefined;
	contact1State:string = undefined;
	contact1Street:string = undefined;
	contact1Title:string = undefined;
	contact2City:string = undefined;
	contact2Country:string = undefined;
	contact2Name:string = undefined;
	contact2PostalCode:string = undefined;
	contact2State:string = undefined;
	contact2Street:string = undefined;
	contact2Title:string = undefined;
	contractDuration:number = undefined;
	currentExecutiveReviewer:string = undefined;
	description:string = undefined;
	dueDate:Date = undefined;
	effectiveDate:Date = undefined;
	expiryDate:Date = undefined;
	fak:string = undefined;
	legalReviewerEmpId:string = undefined;
	liabilityLimits:string = undefined;
	ltlDamageLiability:string = undefined;
	ndaTermEndDate:Date = undefined;
	nonStandardTerms:string = undefined;
	outstandingDays:number = undefined;
	paymentTerm:string = undefined;
	potentialMonthlyRevenueAmt:MonetaryAmount = undefined;
	previousStatus:string = undefined;
	pricingAgreementEffDate:Date = undefined;
	pricingAgreementExpDate:Date = undefined;
	pricingAgreementNumber:string = undefined;
	pricingAnalystEmpId:string = undefined;
	promisedMonthlyRevenueAmt:MonetaryAmount = undefined;
	renegotiationDate:Date = undefined;
	requestDate:Date = undefined;
	requesterEmpId:string = undefined;
	signatoryEmpId:string = undefined;
	status:string = undefined;
	statusCategory:string = undefined;
	subCategory:string = undefined;
	terminationDate:Date = undefined;
	terminationNoticeDays:number = undefined;
	terminationReason:string = undefined;
	terminationRequesterId:string = undefined;
	terminationRequestStatus:string = undefined;
	totalAgreementValue:MonetaryAmount = undefined;
	type:string = undefined;
	whoSignsFirst:string = undefined;
	pricingAgreement:PricingAgreement = undefined;
	buttons:string[] = undefined;
}
export class PricingAgreementDocInd {
	agreementDocIndId:number = undefined;
	indicatorCode:string = undefined;
	auditInfo:AuditInfo = undefined;
}
export class PricingAgreementDocType {
	agreementDocTypeId:number = undefined;
	docTypeCode:string = undefined;
	docFile:string = undefined;
	docUrl:string = undefined;
	docTags:string = undefined;
	auditInfo:AuditInfo = undefined;
}
/**
 * Information about the Customer expectation in Pricing Agreement
 */
export class PricingAgreementExpectation {
	/**
	 * if (Monthly_Revenue_At_Start_of_Analysis > 0, Projected_Operating_Ratio - Current_Operating_Ratio ,0)
	 */
	orChangePct:number = undefined;
	/**
	 * unique identifier of the Pricing Agreement Expectation
	 */
	agreementExpectationId:string = undefined;
	/**
	 * This is the expected Percent of shipments rated with AMC based on which the pricing request was approved.
	 */
	amcShipmentRevPct:number = undefined;
	/**
	 * Audit Information
	 */
	auditInfo:AuditInfo = undefined;
	/**
	 * This is the expected Average Delivery Stop Time based on which the pricing agreement was creaated.
	 */
	averageDeliveryStopTime:number = undefined;
	/**
	 * This is the expected average Density of freight, based on which the pricing agreement was created.
	 */
	averageDensity:number = undefined;
	/**
	 * This is the expected Average Pickup Stop Time based on which the pricing agreement was created.
	 */
	averagePickupStopTime:number = undefined;
	/**
	 * This is the expected average weight per shipment, based on which the pricing agreement was created.
	 */
	averageWeightPerShipment:Weight = undefined;
	/**
	 * User will enter the comments once the expectation data modified before new version of the pricing agreement
	 */
	comments:string = undefined;
	/**
	 * Current pricing Operating Ratio of this account.
	 */
	currentOr:number = undefined;
	/**
	 * Effective date of the pricing agreement expectation
	 */
	effectiveDate:Date = undefined;
	/**
	 * Expectation details are valid up to this date. Once the details are modified it will be effective from new date.
	 */
	expiryDate:Date = undefined;
	/**
	 * This will indicate whether the pricing is lane based or not: Valid values are Y or N
	 */
	laneBasedPricingInd:string = undefined;
	/**
	 * Revenue prior to the concluded renegotiation. It will be frozen when the Pricing Analyst begins analysis. Snapshot of last 6 months Revenue average before Analysis Start Date
	 */
	monthlyInitialRevAmt:MonetaryAmount = undefined;
	/**
	 * This is the expected percent price increase from the previous agreement.
	 */
	priceChangePct:number = undefined;
	/**
	 * Profit improvement target
	 */
	profitImprvmntTargetPct:number = undefined;
	/**
	 * This represents the expected discount Impact for this customer once the new pricing is applied.
	 */
	projectedDiscountImpactPct:number = undefined;
	/**
	 * Projected_Profit - Monthly_Revenue_At_Start_of_Analysis(1- Current O/R)
	 */
	projectedMnthProfitImprvmntAmt:MonetaryAmount = undefined;
	/**
	 * Expected revenue loss as a result of the renegotiation
	 * 
	 * (1) If no change in revenue then 0,
	 * (2) A negative number if growth is expected,
	 * (3) A positive number if a reduction is expected
	 * 
	 * Mo Revenue At Start of Analysis ($) - Projected Monthly Revenue ($)
	 */
	projectedMnthRevChngAmt:MonetaryAmount = undefined;
	/**
	 * Projected Monthly Revenue($) + Mo Cost Change ($)
	 */
	projectedMonthlyCost:MonetaryAmount = undefined;
	/**
	 * Cost associated with the revenue that we expect to lose
	 * 
	 * (1) If no change in cost is ï¿½0ï¿½,
	 * (2) A negative number if growth is expected,
	 * (3) A positive number if a reduction is expected
	 */
	projectedMonthlyCostChange:MonetaryAmount = undefined;
	/**
	 * Expected Promise Amount after the offer, this will be different from the promise amount captured at the sales RFP creation.
	 * Calculated as (Mo Revenue at Start of Analysis ($) ï¿½  Mo Revenue Change ($)) * (1 + Price Change (%)) or can be manually entered.
	 */
	projectedMonthlyRevAmt:MonetaryAmount = undefined;
	/**
	 * This represents the expected Discount Impact for this customer once the new pricing is applied.
	 * Calculated as Projected Mo Cost ($)/'Projected Mo Revenue ($) or manually enter.
	 */
	projectedOr:number = undefined;
	/**
	 * Calculated as Projected Mo Revenue ($) - Projected Mo Cost ($)
	 */
	projectedProfitAmt:MonetaryAmount = undefined;
	/**
	 * Proj_Month_profit_Improvement/ Monthly_Revenue_At_Start_of_Analysis
	 */
	projectedProfitImprovementPct:number = undefined;
	/**
	 * This represents the Projected Yield (Net Fuel) and is used for integrated yield management
	 */
	projectedYieldNetFuel:number = undefined;
	/**
	 * This is the Projected Yield (with Fuel) is used in Integrated Yield Management.
	 */
	projectedYieldWithFuel:number = undefined;
	monthlyPromiseAmt:MonetaryAmount = undefined;
	monthlyPotentialAmt:MonetaryAmount = undefined;
}
/**
 * Pricing Agreement Monitor Region Rollup Report
 */
export class PricingAgreementMonitorRegionRollupReport {
	/**
	 * Report requester information
	 */
	requester:EmployeeId = undefined;
	/**
	 * Sum total of all the PAM record counts for all regions and all districts
	 */
	total:PricingAgreementMonitorCounts = undefined;
	/**
	 * Pam Regions
	 */
	regions:PricingAgreementMonitorRegion[] = undefined;
}
export class PricingAgreementMonitorEventLog {
	monitorEventLogId:string = undefined;
	eventTypeCd:string = undefined;
	eventSubTypeCd:string = undefined;
	eventStatus:string = undefined;
	eventTranCd:string = undefined;
	eventCreatedBy:string = undefined;
	eventData:string = undefined;
	eventOccuredTimestamp:Date = undefined;
	statusReasonCd:string = undefined;
}
/**
 * Pricing Agreement Monitor basic information
 */
export class PricingAgreementMonitorBasic {
	/**
	 * PAM Name
	 */
	agreementMonitorName:string = undefined;
	/**
	 * Unique identifier of the Pricing Agreement Monitor record
	 */
	pricingAgreementMonitorId:string = undefined;
	/**
	 * Agreement Detail
	 */
	pricingAgreement:PricingAgreementBasic = undefined;
	accountDetails:PartyBasic = undefined;
}
/**
 * Pricing Agreement Monitor
 */
export class PricingAgreementMonitor {
	/**
	 * Actions taken for this Monitor record
	 */
	action:PricingMonitorAction[] = undefined;
	actionAllowed:string = undefined;
	/**
	 * PAM Name
	 */
	agreementMonitorName:string = undefined;
	/**
	 * Monthly Revenue Amount
	 */
	revenueAmt:MonetaryAmount = undefined;
	/**
	 * Date when the monitor record was closed
	 */
	closeDate:Date = undefined;
	/**
	 * Cost associated with the customer's shipments
	 */
	cost:MonetaryAmount = undefined;
	/**
	 * Director Of Sales Employee Id
	 */
	dosEmployee:EmployeeId = undefined;
	eventLogs:PricingMonitorEventLog[] = undefined;
	/**
	 * Off Bill Incentive Amount
	 */
	offBilIncentiveAmt:MonetaryAmount = undefined;
	/**
	 * Operating Ratio
	 */
	operatingRatio:number = undefined;
	/**
	 * Unique identifier of the Pricing Agreement Monitor record
	 */
	pricingAgreementMonitorId:string = undefined;
	/**
	 * Pricing Analyst Id
	 */
	pricingAnalyst:EmployeeId = undefined;
	/**
	 * Pricing Manager Id
	 */
	pricingManager:EmployeeId = undefined;
	/**
	 * Profit Amount
	 */
	profitAmt:MonetaryAmount = undefined;
	/**
	 * Reasons why the Monitor record was created
	 */
	reason:PricingMonitorReason[] = undefined;
	/**
	 * List of SalesRfps associated with the Monitor record
	 */
	salesRfps:SalesRfpHdr[] = undefined;
	/**
	 * Sales District
	 */
	salesDistrict:string = undefined;
	/**
	 * Employee Id of the Sales person who owns this Monitor record
	 */
	salesOwnerEmployee:EmployeeId = undefined;
	/**
	 * Sales Region
	 */
	salesRegion:string = undefined;
	/**
	 * Sequence Number of the Pricing Agreement Monitor record
	 */
	sequenceNbr:number = undefined;
	/**
	 * Status
	 */
	status:string = undefined;
	/**
	 * Type of Pricing Agreement Monitor record: O/R or Rev or MON
	 */
	typeCode:string = undefined;
	/**
	 * Version Number of the Pricing Agreement Monitor
	 */
	versionNbr:string = undefined;
	/**
	 * Year and Month when the Monitor record is created
	 */
	yearMonth:Date = undefined;
	/**
	 * Agreement Detail
	 */
	pricingAgreement:PricingAgreement = undefined;
	/**
	 * Pricing Agreement Expectation
	 */
	expectation:PricingAgreementExpectation = undefined;
	/**
	 * NASS Employee information
	 */
	nassEmployee:EmployeeId = undefined;
	createDate:Date = undefined;
	accountDetails:AcctId = undefined;
	monitorParameter:PricingAgreementMonitorParameter = undefined;
	monitorParameterTier:PricingAgreementMonitorParameterTier = undefined;
}
/**
 * Pricing Agreement Monitor Valid Action
 */
export class PricingAgreementMonitorValidAction {
	/**
	 * Action Source: Pam, Batch, or OneCRM
	 */
	actionSource:string = undefined;
	/**
	 * Action Type: Pricing, or Sales
	 */
	actionType:string = undefined;
	/**
	 * Unique identifier of the Pricing Agreement Monitor record
	 */
	actionName:string = undefined;
}
/**
 * Pricing Agreement Rollup Record
 */
export class PricingAgreementMonitorRollup {
	/**
	 * PAM Name
	 */
	agreementMonitorName:string = undefined;
	/**
	 * Unique identifier of the Pricing Agreement Monitor record
	 */
	pricingAgreementMonitorId:string = undefined;
	/**
	 * Pricing Agreement Monitor Type
	 */
	typeCode:string = undefined;
	/**
	 * Negotiating Custustomer Mad Code from Pricing Agreement related to this PAM
	 */
	negotiatingCustMadCd:string = undefined;
	/**
	 * Pricing Agreement Monitor Status Code
	 */
	statusCd:string = undefined;
	/**
	 * Pam Counts
	 */
	counts:PricingAgreementMonitorCounts = undefined;
	/**
	 * Rfp Status associated to the PAM
	 */
	rfpStatus:string = undefined;
}
export class PricingMonitorReason {
	/**
	 * unique identifier of the Agreement Monitor Reason record
	 */
	agreementMonitorReasonId:string = undefined;
	/**
	 * Why the monitor record was created for this Pricing Agreement. One of the following values:
	 * Monthly Revenue Percentage Attainment
	 * O/R - Projected vs. Actual Difference
	 * O/R Maximum
	 * Monthly Profit Loss
	 */
	createReason:string = undefined;
}
export class PricingMonitorEventLog {
	monitorEventLogId:string = undefined;
	eventTypeCd:string = undefined;
	eventSubTypeCd:string = undefined;
	eventStatus:string = undefined;
	eventTranCd:string = undefined;
	eventCreatedBy:string = undefined;
	eventData:string = undefined;
	eventOccuredTimestamp:Date = undefined;
	statusReasonCd:string = undefined;
}
export class PricingMonitorAction {
	/**
	 * unique identifier of the agreement monitor action record
	 */
	agreementMonitorActionId:string = undefined;
	/**
	 * Action taken by the Pricing Analyst as a result of this monitor record. One of the following values
	 * Seasonal Business
	 * Pricing Request - Cancel Pricing
	 * Pricing Request - Renegotiation
	 * Request Expectations Adjustment
	 */
	actionTaken:string = undefined;
	/**
	 * Type of Action
	 * Sales or Pricing or SalesForce
	 */
	actionType:string = undefined;
	/**
	 * Comments added as part of the action taken.
	 */
	commentText:string = undefined;
	actionSource:string = undefined;
	/**
	 * Set number for the action. This is used for grouping actions belonging to a set
	 */
	actionSetNbr:number = undefined;
	/**
	 * Date on which this action created
	 */
	createdDate:Date = undefined;
	/**
	 * User who took this action
	 */
	CreatedBy:EmployeeId = undefined;
}
export class PricingAgreementDocLiabDtl {
	agreementLiabilityDetailId:string = undefined;
	agreementLiabilityHdrId:string = undefined;
	auditInfo:AuditInfo = undefined;
	commodityClass:string = undefined;
	liabilityPerPound:MonetaryAmount = undefined;
}
export class PricingAgreementDocLiabHdr {
	agreementDocId:string = undefined;
	agreementId:string = undefined;
	agreementLiabilityHdrId:string = undefined;
	approvalRequiredInd:boolean = undefined;
	approvedBy:string = undefined;
	auditInfo:AuditInfo = undefined;
	caFlatLiabilityPerLb:MonetaryAmount = undefined;
	caMaxLiabilityAmt:MonetaryAmount = undefined;
	claimsNotifyMaxDays:number = undefined;
	householdGoodsLiabilityPerLb:MonetaryAmount = undefined;
	inboundCollectInd:boolean = undefined;
	inboundPrepaidInd:boolean = undefined;
	internationalInd:boolean = undefined;
	interstateInd:boolean = undefined;
	intrastateInd:boolean = undefined;
	liabilityComments:string = undefined;
	liabilityTermsCreator:string = undefined;
	maxLiabilityAmt:MonetaryAmount = undefined;
	outboundCollectInd:boolean = undefined;
	outboundPrepaidInd:boolean = undefined;
	specialHandlingInd:boolean = undefined;
	specialHandlingMaxAmt:MonetaryAmount = undefined;
	specialHandlingMinAmt:MonetaryAmount = undefined;
	stdLiabilityInd:boolean = undefined;
	thirdPartyInd:boolean = undefined;
	usFlatLiabilityPerLb:MonetaryAmount = undefined;
	agreementDocLiabDtl:PricingAgreementDocLiabDtl[] = undefined;
	proposalHdrId:string = undefined;
}
/**
 * Pricing Agreement Monitor Region
 */
export class PricingAgreementMonitorRegion {
	/**
	 * Region Name
	 */
	name:string = undefined;
	/**
	 * Pam Counts
	 */
	regionTotal:PricingAgreementMonitorCounts = undefined;
	/**
	 * Pam Districts
	 */
	districts:PricingAgreementMonitorDistrict[] = undefined;
}
/**
 * Pricing Agreement Monitor District
 */
export class PricingAgreementMonitorDistrict {
	/**
	 * District Name
	 */
	name:string = undefined;
	/**
	 * Pam Counts
	 */
	districtTotal:PricingAgreementMonitorCounts = undefined;
	/**
	 * Pam Owners
	 */
	owners:PricingAgreementMonitorOwner[] = undefined;
}
export class PAMOwner {
	/**
	 * Unique identifier of the Pricing Agreement Monitor record
	 */
	pricingAgreementMonitorId:string = undefined;
	/**
	 * Employee Id of the owner
	 */
	ownerId:object = undefined;
}
/**
 * Pricing Agreement Monitor Owner
 */
export class PricingAgreementMonitorOwner {
	/**
	 * Owner
	 */
	owner:EmployeeId = undefined;
	/**
	 * Pam Counts
	 */
	counts:PricingAgreementMonitorCounts = undefined;
	/**
	 * Pam Types
	 */
	pamTypes:PricingAgreementMonitorTypeSummary[] = undefined;
}
/**
 * Pricing Agreement Monitor Summary
 */
export class PricingAgreementMonitorTypeSummary {
	/**
	 * Type of Pricing Agreement Monitor record: O/R or Rev or MON
	 */
	typeCode:string = undefined;
	/**
	 * Pam Counts
	 */
	counts:PricingAgreementMonitorCounts = undefined;
	/**
	 * Pam Records
	 */
	pamRecords:PricingAgreementMonitorRollup[] = undefined;
}
/**
 * Pricing Agreement Monitor Counts
 */
export class PricingAgreementMonitorCounts {
	/**
	 * Total number for Revenue records
	 */
	revCount:number = undefined;
	/**
	 * Total number for all records
	 */
	totalCount:number = undefined;
	/**
	 * Total number for Operating Ratio records
	 */
	orCount:number = undefined;
	/**
	 * Cost associated with the customer's shipments
	 */
	cost:MonetaryAmount = undefined;
	/**
	 * Monthly Revenue Amount
	 */
	revenueAmt:MonetaryAmount = undefined;
	/**
	 * Off Bill Incentive Amount
	 */
	offBillIncentiveAmt:MonetaryAmount = undefined;
	/**
	 * Profit Amount
	 */
	profitAmt:MonetaryAmount = undefined;
	/**
	 * Operating Ratio
	 */
	operatingRatio:number = undefined;
	/**
	 * Amount of revenue not attained
	 */
	revNotAttainedAmt:MonetaryAmount = undefined;
}
/**
 * Information about the parameters for Pricing Agreement Monitor and Expectations
 */
export class PricingAgreementMonitorParameter {
	/**
	 * Unique identifier of the Pricing Agreement Monitor Parameter record
	 */
	agreementMonitorParameterId:string = undefined;
	/**
	 * information about the user who is creating or updating the record and the date time it was created or updated
	 */
	auditInfo:AuditInfo = undefined;
	/**
	 * Start effective date for a parameter used to monitor pricing agreement expectations.
	 */
	effectiveDate:Date = undefined;
	/**
	 * End effective date for a parameter used to monitor pricing agreement expectations.
	 */
	expiryDate:Date = undefined;
	/**
	 * Operating ratio value checked. Any agreements with an OR greater than this value will have a monitor record created.
	 */
	maxOperatingRatio:number = undefined;
	/**
	 * The minimum monthly revenue the Account must have in order to be monitored.
	 */
	minMonthlyActualRev:MonetaryAmount = undefined;
	/**
	 * The minimum projected monthly revenue the Account must have in order to be monitored.
	 */
	minMonthlyProjectedRev:MonetaryAmount = undefined;
	/**
	 * The minimum OR the Account must have in order to be monitored.
	 */
	minOperatingRatio:number = undefined;
	/**
	 * The monthly profit loss the Account must have in order to be monitored.
	 */
	monthlyProfitLossAmt:MonetaryAmount = undefined;
	/**
	 * The difference between the Projected OR and Actual OR. If the difference is greater than this number a monitor record is created
	 */
	orChangePct:number = undefined;
	/**
	 * The percentage of projected revenue attained. If the percentage is below this number, a monitor record is created.
	 */
	projectedRevAttainmentPct:number = undefined;
	/**
	 * Type of Pricing: Local or National
	 */
	pricingTypeCd:string = undefined;
	parameterTiers:PricingAgreementMonitorParameterTier[] = undefined;
}
/**
 * Information about the parameters for Pricing Agreement Monitor Tiers
 */
export class PricingAgreementMonitorParameterTier {
	/**
	 * Unique identifier of the Pricing Agreement Monitor Parameter Tier Record
	 */
	agreementMonitorParameterTierId:string = undefined;
	/**
	 * The minimum monthly projected revenue for this tier
	 */
	minMonthlyProjectedRev:MonetaryAmount = undefined;
	/**
	 * The maximum monthly projected revenue for this tier
	 */
	maxMonthlyProjectedRev:MonetaryAmount = undefined;
	/**
	 * Revenue Attainment Percentage for this Tier
	 */
	revAttainmentPct:number = undefined;
}
/**
 * Pricing Agreement Monitor Action Details
 */
export class PricingAgreementMonitorAction {
	/**
	 * Unique identifier of the Pricing Agreement Monitor Action record
	 */
	agreementMonitorActionId:string = undefined;
	/**
	 * information about the user who is creating or updating the record and the date time it was created or updated
	 */
	auditInfo:AuditInfo = undefined;
	/**
	 * Action Type, Either 'Sales' or 'Pricing'
	 */
	actionType:string = undefined;
	/**
	 * Source the request is coming from, ie. OneCRM
	 */
	actionSource:string = undefined;
	/**
	 * Which action was taken
	 */
	actionTaken:string = undefined;
	/**
	 * Any comments related to this action
	 */
	commentText:string = undefined;
}
/**
 * Information about the parameters for Pricing Agreement Audit
 */
export class PricingAgreementAuditParameter {
	/**
	 * Unique identifier of the Pricing Agreement Audit Parameter record
	 */
	agreementAuditParameterId:string = undefined;
	/**
	 * information about the user who is creating or updating the record and the date time it was created or updated
	 */
	auditInfo:AuditInfo = undefined;
	/**
	 * Start effective date for a parameter used to audit pricing agreement expectations.
	 */
	effectiveDate:Date = undefined;
	/**
	 * End effective date for a parameter used to audit pricing agreement expectations.
	 */
	expiryDate:Date = undefined;
	/**
	 * Audit Percent
	 */
	auditPct:number = undefined;
	/**
	 * Pricing Analyst Id
	 */
	pricingAnalyst:EmployeeId = undefined;
}
/**
 * The absolute minimum charge with the scope for this AMC
 */
export class PricingAmc {
	/**
	 * unique id of the pricing amc record
	 */
	pricingAmcId:string = undefined;
	/**
	 * The absolute minimum charge amount
	 */
	amcAmt:MonetaryAmount = undefined;
	/**
	 * The scope for this amc. Interstate, Intrastate, International
	 */
	amcScopeIndicators:PricingScope = undefined;
}
/**
 * Shipment information used for the analysis that lead to the proposal. 
 */
export class PricingAnalysisFlow {
	/**
	 * Unique identifier of extract
	 */
	analysisFlowInstId:string = undefined;
	/**
	 * The workflow to follow for this analysis flow
	 */
	workflow:Workflow = undefined;
	/**
	 * The type of extract.
	 */
	extractTypeCd:string = undefined;
	/**
	 * The id of the proposal that is being analyzed.
	 */
	prcProposalInstId:string = undefined;
	/**
	 * Status of the extract
	 */
	extractStatusCd:string = undefined;
	/**
	 * The current step of the workflow.
	 */
	analysisFlowCurrentStep:number = undefined;
	/**
	 * Comments related to the extract
	 */
	extractComment:string = undefined;
	/**
	 * customer account instance id
	 */
	acctInstId:string = undefined;
	/**
	 * customer account associated with the extract
	 */
	acctMadCd:string = undefined;
	/**
	 * The begin date for the extract
	 */
	extractStartDateTime:Date = undefined;
	/**
	 * The end date for the extract
	 */
	extractEndDateTime:Date = undefined;
	/**
	 * Label name  to be used for input field
	 */
	prompt1:string = undefined;
	/**
	 * Label name  to be used for input field
	 */
	prompt2:string = undefined;
	/**
	 * Label name  to be used for input field
	 */
	prompt3:string = undefined;
	/**
	 * Label name  to be used for input field
	 */
	prompt4:string = undefined;
	/**
	 * Label name  to be used for input field
	 */
	prompt5:string = undefined;
	/**
	 * Label name  to be used for input field
	 */
	prompt6:string = undefined;
	/**
	 * Label name  to be used for input field
	 */
	prompt7:string = undefined;
	/**
	 * Label name  to be used for input field
	 */
	prompt8:string = undefined;
	/**
	 * Label name  to be used for input field
	 */
	prompt9:string = undefined;
	auditInfo:AuditInfo = undefined;
}
/**
 * Pricing Analysis State/Zip Geo Group header information
 */
export class PricingAnalysisGeoGroupHdr {
	amcCostAmt:MonetaryAmount = undefined;
	auditInfo:AuditInfo = undefined;
	clusterDefinition:string = undefined;
	clusterId:string = undefined;
	commentNote:string = undefined;
	destCountryCd:string = undefined;
	destGeoPrcGrpLaneMbrId:string = undefined;
	destGeoPrcGrpLaneMbrNm:string = undefined;
	destStateCd:string = undefined;
	effRevIncrAmt:MonetaryAmount = undefined;
	geoPricingGroupLaneId:string = undefined;
	geoPricingGroupLaneNm:string = undefined;
	/**
	 * Recommended discount output of the leaf model
	 */
	leafRecommendedDisc:number = undefined;
	/**
	 * Averaged Market Discount Impact percentage output of the leaf model
	 */
	leafRecommendedMarketCdi:number = undefined;
	/**
	 * Operating Ratio based on proposed pricing for shipments  rated with AMC
	 */
	newOrAmcPct:number = undefined;
	/**
	 * Operating Ratio based on proposed pricing for shipments not rated with AMC
	 */
	newOrTariffPct:number = undefined;
	origCountryCd:string = undefined;
	origGeoPrcGrpLaneMbrId:string = undefined;
	origGeoPrcGrpLaneMbrNm:string = undefined;
	origStateCd:string = undefined;
	/**
	 * Proposed Overall percentage increase based on new discount percentage from pricing analysis
	 */
	overallIncreasePrpsPct:number = undefined;
	prcAnlysGeoGroupBiAmc:GeoGroupBiAmc = undefined;
	prcAnlysGeoGroupBiTariff:GeoGroupBiTariff = undefined;
	prcAnlysGeoGroupCmAmc:GeoGroupCmAmc = undefined;
	prcAnlysGeoGroupCmTariff:GeoGroupCmTariff = undefined;
	prcAnlysGeoGroupDiscAmc:GeoGroupDiscountAmc = undefined;
	prcAnlysGeoGroupFfo:GeoGroupFFO = undefined;
	/**
	 * Captures how many months worth of shipment data is used for analysis
	 */
	prcAnlysGeoGroupId:string = undefined;
	prcAnlysGeoGroupLaneScore:GeoGroupLaneScore = undefined;
	prcProposalInstId:string = undefined;
	pricingAnlysGeoGroupHdrBase:PricingAnalysisGeoGroupHdrBase = undefined;
	prpsdMidIncrRevAmt:MonetaryAmount = undefined;
	statusCd:string = undefined;
}
/**
 * Pricing Analysis State/Zip Geo Group header information
 */
export class PricingAnalysisGeoGroupHdrBase {
	/**
	 * Current Operating Ratio percentage of shipments  rated with AMC included  in the group for pricing analysis
	 */
	amcOrPct:number = undefined;
	/**
	 * Current profit amount of shipments  rated with AMC included  in the group for pricing analysis
	 */
	amcProfitAmt:MonetaryAmount = undefined;
	averageCurrAmcAmt:MonetaryAmount = undefined;
	averageCurrDiscPct:number = undefined;
	/**
	 * Averaged total weight of shipment data included  in the group for pricing analysis
	 */
	averageDensity:Density = undefined;
	/**
	 * Averaged total miles of shipment data included  in the group for pricing analysis
	 */
	averageDist:Distance = undefined;
	/**
	 * Averaged total weight of shipment data included  in the group for pricing analysis
	 */
	averageWeight:Weight = undefined;
	/**
	 * Average CTS599 discount impact of the shipments within the group
	 */
	cts599ImpactPct:number = undefined;
	/**
	 * Proposed percentage increase upper bound
	 */
	incrLevelRngPctHighValue:number = undefined;
	/**
	 * Proposed percentage increase lower bound
	 */
	incrLevelRngPctLowValue:number = undefined;
	/**
	 * Proposed percentage increase mid bound
	 */
	incrLevelRngPctMidValue:number = undefined;
	/**
	 * Total net revenue amount of shipment data  rated with AMC that are included  in the group for pricing analysis
	 */
	netAmcRevAmt:MonetaryAmount = undefined;
	/**
	 * Total net revenue amount of shipment data included  in the group for pricing analysis
	 */
	netRevAmt:MonetaryAmount = undefined;
	/**
	 * Total net revenue amount of shipment data not rated with AMC that are included  in the group for pricing analysis
	 */
	netTariffRevAmt:MonetaryAmount = undefined;
	/**
	 * New Operating Ratio
	 */
	newOrPct:number = undefined;
	/**
	 * Current Operating Ratio percentage
	 */
	orPct:number = undefined;
	/**
	 * Overall Increase Percentage (Current Net Revenue / New Net Revenue)
	 */
	overallIncrPct:number = undefined;
	/**
	 * Current profit amount of shipments not rated with AMC included  in the group for pricing analysis
	 */
	profitAmt:MonetaryAmount = undefined;
	/**
	 * Market Discount Impact output of the leaf model
	 */
	recmdMarketCdi:number = undefined;
	/**
	 * Market Discount Pct output of the leaf model
	 */
	recmdDiscount:number = undefined;
	/**
	 * Total number of shipments rated with AMC included  in the group for pricing analysis
	 */
	shipmentAmcCount:number = undefined;
	/**
	 * Total number of shipments included  in the group for pricing analysis
	 */
	shipmentCount:number = undefined;
	/**
	 * Total number of shipments not rated with AMC included  in the group for pricing analysis
	 */
	shipmentTariffCount:number = undefined;
	/**
	 * Current Operating Ratio percentage of shipments not rated with AMC included  in the group for pricing analysis
	 */
	tariffOrPct:number = undefined;
	/**
	 * Averaged tariff profit amount of the shipments within the group
	 */
	tariffProfitAmt:MonetaryAmount = undefined;
	/**
	 * Current total cost of shipments rated with AMC included  in the group for pricing analysis
	 */
	totalAmcCostAmt:MonetaryAmount = undefined;
	/**
	 * Current total cost amount of shipmentsincluded  in the group for pricing analysis
	 */
	totalCostAmt:MonetaryAmount = undefined;
	totalTariffCostAmt:MonetaryAmount = undefined;
	/**
	 * (Net Revenue Amount/Avg. Weight * Shipment Count) * 100
	 */
	totalYieldAmt:MonetaryAmount = undefined;
	averageRatedClassCd:string = undefined;
	averageActualClassCd:string = undefined;
	/**
	 * Averaged  Net Revenue based on current pricing agreement versus Market Price output of the leaf model
	 */
	leafCurrentVsMarketCdi:number = undefined;
	/**
	 * Averaged  Net Revenue based on proposed pricing versus Market Price output of the leaf model
	 */
	leafNewVsMarketCdi:number = undefined;
}
/**
 * Shipment information used for the analysis that lead to the proposal. 
 */
export class PricingAnalysisShipment {
	/**
	 * Actual class code
	 */
	actualClassCd:string = undefined;
	/**
	 * Actual revenue amount
	 */
	actualRevAmt:MonetaryAmount = undefined;
	/**
	 * Absolute Minimum Charge indicator
	 */
	amcInd:boolean = undefined;
	auditInfo:AuditInfo = undefined;
	/**
	 * True if the bil2 is the debtor
	 */
	bil2DebtorInd:boolean = undefined;
	/**
	 * Instance Id for the bil2
	 */
	bil2InstId:string = undefined;
	/**
	 * Mad code for the bil2
	 */
	bil2MadCd:string = undefined;
	/**
	 * Name of the bil2
	 */
	bil2Nm:string = undefined;
	bulkLiquidInd:boolean = undefined;
	/**
	 * CDI Percent Better Local
	 */
	cdiPctBetterLocal:number = undefined;
	/**
	 * CDI Percent Better National
	 */
	cdiPctBetterNational:number = undefined;
	cdiPctHighValue:number = undefined;
	cdiPctLowValue:number = undefined;
	clusterCdiPct:number = undefined;
	clusterDefinition:string = undefined;
	clusterDiscountPct:number = undefined;
	/**
	 * True if this is a collect shipment
	 */
	collectShipmentInd:boolean = undefined;
	commentNote:string = undefined;
	/**
	 * Country code for the consignee location
	 */
	consigneeCountryCd:string = undefined;
	/**
	 * True if the consignee is the debtor
	 */
	consigneeDebtorInd:boolean = undefined;
	/**
	 * Instance Id for the consignee
	 */
	consigneeInstId:string = undefined;
	/**
	 * Name of the consignee
	 */
	consigneeNm:string = undefined;
	costCdiPct:number = undefined;
	currentAmcAmt:MonetaryAmount = undefined;
	/**
	 * Corporate discount impact for the current pricing agreement
	 */
	currentCts599Impact:number = undefined;
	/**
	 * Discount Amount based on Discount Percentage from current pricing agreement
	 */
	currentDiscountAmt:MonetaryAmount = undefined;
	/**
	 * Discount Percentage from current pricing agreement
	 */
	currentDiscountPct:number = undefined;
	/**
	 * freight charge amount from current pricing agreement
	 */
	currentFreightChargeAmt:MonetaryAmount = undefined;
	/**
	 * FALSE	Fuel Surcharge amount based on fsc percentage and freight charge amount from current pricing agreement
	 */
	currentFscAmt:MonetaryAmount = undefined;
	/**
	 * Total charges based on discounted linehaul charges, FSC, accessorials , OBI from current pricing agreement
	 */
	currentNetRevWithObi:MonetaryAmount = undefined;
	/**
	 * Operating Ratio (cost over net revenue) from current pricing agreement
	 */
	currentOrWithObi:number = undefined;
	/**
	 * Indicates if rated with AMC  for the current pricing agreement
	 */
	currentRatedWithAmcInd:boolean = undefined;
	/**
	 * source of the shipment data
	 */
	dataSourceCd:string = undefined;
	destGeoPrcGrpLaneMbrId:string = undefined;
	destPrcGeoGrpLaneMbrNm:object = undefined;
	/**
	 * Discount Amount
	 */
	discountAmt:MonetaryAmount = undefined;
	discountSourceCd:string = undefined;
	division:string = undefined;
	/**
	 * FFN Lane Service Level Identifier
	 */
	ffnLaneServiceLevelId:string = undefined;
	geoPricingGroupLaneId:string = undefined;
	/**
	 * This shipment used Highway sub-service
	 */
	hssInd:boolean = undefined;
	/**
	 * HSS Lane Score Percent
	 */
	hssLaneScorePct:number = undefined;
	inboundInd:boolean = undefined;
	/**
	 * Include this shipment in the analysis
	 */
	includeForAnalysisInd:boolean = undefined;
	/**
	 * True if rated with Lineal Foot Pricing
	 */
	linealFtShpmtInd:boolean = undefined;
	/**
	 * Linehaul impact percent
	 */
	lnhImpactPct:number = undefined;
	/**
	 * Macro score percent
	 */
	macroScorePct:number = undefined;
	manualRatingReqdInd:boolean = undefined;
	marketCdiPct:number = undefined;
	marketDiscount:number = undefined;
	modelQualityCategory:string = undefined;
	modelQualityScore:number = undefined;
	/**
	 * Net revenue amount
	 */
	netRevAmt:MonetaryAmount = undefined;
	/**
	 * New AMC Amount
	 */
	newAmcAmt:MonetaryAmount = undefined;
	origGeoPrcGrpLaneMbrId:string = undefined;
	origPrcGeoGrpLaneMbrNm:string = undefined;
	/**
	 * OR Percent Better Local
	 */
	orPctBetterLocal:number = undefined;
	/**
	 * OR Percent Better National
	 */
	orPctBetterNational:number = undefined;
	/**
	 * OR Percent Score
	 */
	orPctScore:number = undefined;
	/**
	 * OR with OBI
	 */
	orWithObi:MonetaryAmount = undefined;
	/**
	 * Other revenue amount
	 */
	otherRevAmt:MonetaryAmount = undefined;
	outboundInd:boolean = undefined;
	/**
	 * Overall Increase Percent
	 */
	overallIncreasePct:number = undefined;
	/**
	 * Overall Increase Proposed Percent
	 */
	overallIncrProposedPct:number = undefined;
	/**
	 * Overall Lane Score Percent
	 */
	overallLaneScorePct:number = undefined;
	/**
	 * The account instance id for the main customer on the pricing agreement in effect for this shipment
	 */
	paAcctInstId:string = undefined;
	/**
	 * The account mad cd for the main customer on the pricing agreement in effect for this shipment
	 */
	paAcctMadCd:string = undefined;
	/**
	 * The account name for the main customer on the pricing agreement in effect for this shipment
	 */
	paAcctNm:string = undefined;
	palletCnt:number = undefined;
	/**
	 * calendar month of the pickup
	 */
	pickupMonthName:string = undefined;
	/**
	 * The text month and numeric year of the pickup date - June 2015
	 */
	pickupMonthYear:string = undefined;
	prcAnalysisShipmentId:string = undefined;
	/**
	 * Unique identifier for Proposal that is being analyzed.
	 */
	prcProposalInstId:string = undefined;
	pricingAnlysShipmentBase:PricingAnalysisShipmentBase = undefined;
	/**
	 * Proposed Accessorial Amount
	 */
	proposedAccessorialAmt:MonetaryAmount = undefined;
	/**
	 * Proposed AMC Amount
	 */
	proposedAmcAmt:MonetaryAmount = undefined;
	/**
	 * Proposed CDI Percent Better National
	 */
	proposedCdiPctBetterNation:number = undefined;
	/**
	 * Proposed Discount Amount
	 */
	proposedDiscountAmt:MonetaryAmount = undefined;
	/**
	 * Proposed Freight Charge Amount
	 */
	proposedFreightChgAmt:MonetaryAmount = undefined;
	/**
	 * Proposed FSC Amount
	 */
	proposedFscAmt:MonetaryAmount = undefined;
	/**
	 * Proposed Impact Type Code
	 */
	proposedImpactTypeCd:string = undefined;
	/**
	 * Proposed Increase Percent High Value
	 */
	proposedIncrPctHighValue:number = undefined;
	/**
	 * Proposed Increase Percent Low Value
	 */
	proposedIncrPctLowValue:number = undefined;
	/**
	 * Proposed OR Percent Better National
	 */
	proposedOrPctBetterNationa:number = undefined;
	/**
	 * Proposed OR Percent Score
	 */
	proposedOrPctScore:number = undefined;
	/**
	 * Proposed Overall Lane Score Percent
	 */
	proposedOvrllLaneScorePct:number = undefined;
	/**
	 * Indicates if rated with AMC  for the new pricing
	 */
	proposedRatedWithAmcInd:boolean = undefined;
	/**
	 * Rating Absolute Minimum Charge indicator
	 */
	ratingShipmentAmcInd:boolean = undefined;
	/**
	 * rating tariff description
	 */
	ratingTariffDesc:string = undefined;
	/**
	 * Identifies the actual rating tariff (RTAR) used to rate the shipment.
	 */
	ratingTariffId:string = undefined;
	segmentId:string = undefined;
	/**
	 * Unique identifier for Pricing Analysis Shipment-Level record
	 */
	shipInstId:string = undefined;
	/**
	 * Country code for the shipper location
	 */
	shipperCountryCd:string = undefined;
	/**
	 * True if the shipper is the debtor
	 */
	shipperDebtorInd:boolean = undefined;
	/**
	 * Instance ID for the shipper
	 */
	shipperInstId:string = undefined;
	/**
	 * Name of the shipper
	 */
	shipperNm:string = undefined;
	/**
	 * Applicable Discount Percentage
	 */
	shpmtAplcblDiscntPct:number = undefined;
	/**
	 * Actual commodity class
	 */
	shpmtAsEntrdAvgNmfcAdj:number = undefined;
	/**
	 * As Rated commodity class
	 */
	shpmtAsRtdAvgNmfcAdj:number = undefined;
	/**
	 * Average Cost Model Density
	 */
	shpmtAvgCostModelDensity:number = undefined;
	/**
	 * Total claim amount of the shipment
	 */
	shpmtClaimsAmt:MonetaryAmount = undefined;
	/**
	 * Total undiscounted linehaul charges, which is the commodity lines only (excludes ACs, FSC, discounts, etc.) when rated using corporate tariff CTS599
	 */
	shpmtCorpRtgPassLnhChg:MonetaryAmount = undefined;
	/**
	 * Cost Density Source Code
	 */
	shpmtCostDnstySrcCd:string = undefined;
	shpmtDeliveryDate:Date = undefined;
	/**
	 * Shipper to Consignee Miles
	 */
	shpmtDistance:Distance = undefined;
	/**
	 * Fuel Surcharge Percent used to calculate FSC amount
	 */
	shpmtEffFscPct:number = undefined;
	/**
	 * Total amount of Accessorials (without FSC) applied to the shipment
	 */
	shpmtInvcRtgPassAccsrlAmt:MonetaryAmount = undefined;
	/**
	 * Total Discount Amount of the shipment
	 */
	shpmtInvcRtgPassDiscnt:MonetaryAmount = undefined;
	/**
	 * Total amount of Fuel Surcharge applied to the shipment
	 */
	shpmtInvcRtgPassFsc:MonetaryAmount = undefined;
	/**
	 * Total undiscounted linehaul charges, which is the commodity lines only (excludes ACs, FSC, discounts, etc.) when rated using actual tariff from the applied pricing agreement ruleset
	 */
	shpmtInvcRtgPassLnhChg:MonetaryAmount = undefined;
	/**
	 * OBI Incentive Discount Adjustment Amount
	 */
	shpmtObiDiscntAdjAmt:MonetaryAmount = undefined;
	/**
	 * Total paid claim amount of the shipment
	 */
	shpmtPaidClaimsAmt:MonetaryAmount = undefined;
	shpmtPallets:number = undefined;
	shpmtPctOfBusiness:number = undefined;
	shpmtPieces:number = undefined;
	/**
	 * Total cost amount of the shipment
	 */
	shpmtTotCostAmt:MonetaryAmount = undefined;
	/**
	 * Total weight of the shipment
	 */
	shpmtWeight:Weight = undefined;
	statusCd:string = undefined;
	/**
	 * Standard number of days it should take to move a shipment from origin to destination
	 */
	stdTransitDays:number = undefined;
	/**
	 * Strategy Proposed
	 * Aggressive Growth (Less than 0%)
	 * Grow (0-2.9)
	 * Maintain (3-6.9)
	 * At Risk (7-10.9)
	 * Lose (11+)
	 */
	strategyProposed:string = undefined;
	/**
	 * Tariff Revenue amount
	 */
	tariffRevAmt:MonetaryAmount = undefined;
	thirdPartyInd:boolean = undefined;
	totalVolume:Volume = undefined;
	tripMiles:string = undefined;
	/**
	 * True if rated under a Volume Shipment Program
	 */
	vspShpmtInd:boolean = undefined;
	willingnessToPay:number = undefined;
	pricingAnlysGeoGroupHdrBase:PricingAnalysisGeoGroupHdrBase = undefined;
	geoGroupDiscountAmcBase:GeoGroupDiscountAmcBase = undefined;
	/**
	 * Proposed Fuel Surcharge Percent
	 */
	proposedFscPct:number = undefined;
}
export class PricingAnalysisShipmentAccessorial {
	/**
	 * unique identifir of pricing analysis shipment accessorial record
	 */
	prcAnlysShipmentAccsrlId:string = undefined;
	pronbr:string = undefined;
	shipmentWeight:Weight = undefined;
	increasePct:number = undefined;
	shipInstId:string = undefined;
	accessorialInfo:PricingAccessorial = undefined;
}
/**
 * Shipment information used for the analysis that lead to the proposal. 
 */
export class PricingAnalysisShipmentBase {
	/**
	 * Actual class code
	 */
	actualClassCd:string = undefined;
	/**
	 * Mad code for the bil2
	 */
	bil2MadCd:string = undefined;
	/**
	 * If PRC_ANALYSIS_SHIPMENT.INBOUND_IND = Y then Inbound
	 * If PRC_ANALYSIS_SHIPMENT.OUTBOUND_IND = Y then Outbound
	 * If PRC_ANALYSIS_SHIPMENT.THIRD_PARTY_IND = Y then Third Party
	 */
	billingTypeCd:string = undefined;
	/**
	 * If PRC_ANALYSIS_SHIPMENT.COLLEC_SHIPMENT_ID = 'Y' then COL else PPD
	 */
	chargeToCd:string = undefined;
	clusterId:string = undefined;
	/**
	 * Mad code for the consignee
	 */
	consigneeMadCd:string = undefined;
	/**
	 * State code for the consignee location
	 */
	consigneeStateCd:string = undefined;
	/**
	 * If PRC_ANALYSIS_SHIPMENT.CONSIGNEE_DEBTOR_IND = 'Y' then 'CONSIGNEE'.
	 * If PRC_ANALYSIS_SHIPMENT.SHIPPER_DEBTOR_IND = 'Y' then 'SHIPPER'
	 * If PRC_ANALYSIS_SHIPMENT.BIL_2_DEBTOR_IND = 'Y' then 'BILL-TO')
	 */
	debtorCd:string = undefined;
	/**
	 * destination geographic area that shares common pricing elements, including tariffs, discounts, AMCs, FAK - keep
	 */
	destGeoPrcGrpLaneMbrNm:string = undefined;
	geoPricingGroupLaneNm:string = undefined;
	/**
	 * origin geographic area that shares common pricing elements, including tariffs, discounts, AMCs, FAK
	 */
	origGeoPrcGrpLaneMbrNm:string = undefined;
	/**
	 * The pickup date of the shipment - 6/1/2015
	 */
	pickupDate:Date = undefined;
	/**
	 * The business key for the shipment-
	 */
	proNbr:string = undefined;
	/**
	 * Rated class code
	 */
	ratedClassCd:string = undefined;
	/**
	 * rating tariff short description
	 */
	ratingTariff:string = undefined;
	/**
	 * Mad code for the shipper
	 */
	shipperMadCd:string = undefined;
	/**
	 * State code of the shipper location
	 */
	shipperStateCd:string = undefined;
	/**
	 * traffic type code
	 */
	trafficTypeCd:string = undefined;
	/**
	 * weight break code
	 */
	weightBreakCd:string = undefined;
	shipmentInstIds:string[] = undefined;
	/**
	 * Origin sic for the shipment
	 */
	origSic:string = undefined;
	/**
	 * destination sic for the shipment
	 */
	destSic:string = undefined;
	/**
	 * Postal code for the consignee location
	 */
	consigneePostalCd:string = undefined;
	/**
	 * Postal code for the shipper location
	 */
	shipperPostalCd:string = undefined;
}
/**
 * Minimum Shipment information used for creating the Analysis Shipment records for a Proposal
 */
export class PricingAnalysisShipmentHdr {
	/**
	 * Unique identifier for Proposal that is being analyzed.
	 */
	prcProposalInstId:string = undefined;
	/**
	 * Unique identifier for Pricing Analysis Shipment-Level record
	 */
	shipInstId:string = undefined;
	/**
	 * The pickup date of the shipment - 6/1/2015
	 */
	pickupDate:Date = undefined;
	/**
	 * Postal code for the shipper location
	 */
	shipperPostalCd:string = undefined;
	/**
	 * Country code for the shipper location
	 */
	shipperCountryCd:string = undefined;
	/**
	 * Postal code for the consignee location
	 */
	consigneePostalCd:string = undefined;
	/**
	 * Country code for the consignee location
	 */
	consigneeCountryCd:string = undefined;
	/**
	 * Total weight of the shipment
	 */
	shpmtWeight:Weight = undefined;
	/**
	 * Tariff Revenue amount
	 */
	tariffRevAmt:MonetaryAmount = undefined;
	/**
	 * Actual class code
	 */
	actualClassCd:string = undefined;
	auditInfo:AuditInfo = undefined;
	shpmtPieces:number = undefined;
	shpmtPallets:number = undefined;
	shpmtDeliveryDate:Date = undefined;
	shpmtPctOfBusiness:number = undefined;
}
/**
 * A pricing analyst and the manager to whiom they report
 */
export class PricingAnalyst {
	/**
	 * Pricing analyst employee ID
	 */
	pricingAnalystEmplId:string = undefined;
	/**
	 * First and Last name of the pricing analyst
	 */
	pricingAnalystFullName:string = undefined;
	/**
	 * The employee id of the analyst's manager
	 */
	pricingMgrEmplId:string = undefined;
	/**
	 * The full name of the analyst's manager
	 */
	pricingMgrFullName:string = undefined;
}
/**
 * Stores the assignment for each Pricing Analyst by AE/SAM, District (formerly Sales Region), Region (formerly Area), Regional (formerly Local), Strategic (formerly National)
 */
export class PricingAnalystAssignment {
	/**
	 * Unique identifier for the analyst assignment record
	 */
	analystAssignmentId:string = undefined;
	/**
	 * Identifies the type of assignment for the pricing analyst.
	 * Valid Values:
	 * 1 - AE/SAM
	 * 2 - District (formerly Sales Region)
	 * 3 - Region (formerly Area )
	 * 4  - Regional (formerly Local)
	 * 5  - Strategic (formerly National)
	 * 6 -  Regional 3PL
	 * 7 -  Strategic 3PL
	 */
	assignmentTypeCd:string = undefined;
	/**
	 * The employee ID of the Account Executive (Local or National)
	 */
	acctExecEmployeeId:string = undefined;
	/**
	 * First Name and Last Name of the Account Executive (Local or National)
	 */
	acctExecFullName:string = undefined;
	/**
	 * The employee ID of the National Account Sales Supervisor
	 */
	nasSupervisorEmployeeId:string = undefined;
	/**
	 * First Name and Last Name of the National Account Sales Supervisor
	 */
	nasSupervisorFullName:string = undefined;
	/**
	 * The employee ID of the National Account Sales Specialist
	 */
	nasSpecialistEmployeeId:string = undefined;
	/**
	 * First Name and Last Name of the National Account Sales Specialist
	 */
	nasSpecialistFullName:string = undefined;
	/**
	 * A geographical region (now called District) for which an account executive is responsible of (i.e. RCA)
	 */
	salesRegionCode:string = undefined;
	/**
	 * A geographical area for a location (now called Region) (i.e. CCA)
	 */
	areaCode:string = undefined;
	/**
	 * The type of pricing agreement (i.e. P360, In-Cycle)
	 */
	agreementTypeCd:string = undefined;
	/**
	 * The type of request for pricing (i.e. Renegotiation, New Opportunity)
	 */
	rfpTypeCd:string = undefined;
	/**
	 * The employee ID of the Pricing Analyst
	 */
	analystEmployeeId:string = undefined;
	/**
	 * First Name and Last Name of the Pricing Analyst
	 */
	analystFullName:string = undefined;
	/**
	 * A value of 'Y' on this field will indicate that this is the primary analyst assigned to this assignment record. There could be more than one Pricing Analyst for a unique AE or a  Sales Region or an Area
	 */
	primaryAssignmentInd:boolean = undefined;
	/**
	 * The date the assignment record was effective
	 */
	effectiveDate:Date = undefined;
	/**
	 * The date when the code is expired
	 */
	expirationDate:Date = undefined;
	currentAnnualRevenue:MonetaryAmount = undefined;
	auditInfo:AuditInfo = undefined;
}
/**
 * Factors that influence the best grouping that will be proposed by the Pricing Analyst. For example: Can't accept discounts by zip 3 only state to state.
 */
export class PricingBestGroupingConstraints {
	/**
	 * A type code for the type of constraint
	 */
	constraintTypeCd:string[] = undefined;
	/**
	 * The value for this constraint
	 */
	constraintValue:string = undefined;
}
/**
 * Basic information about the commodity that is being shipped with class and percentage of business
 */
export class PricingCommodity {
	/**
	 * unique identifier of Pricing Commodity
	 */
	commodityId:string = undefined;
	/**
	 * The actual class for the commodity
	 */
	commodityNmfcClass:string = undefined;
	/**
	 * The NMFC item for the commodity
	 */
	commodityNmfcItem:string = undefined;
	/**
	 * Description of the commodity
	 */
	commodityDesc:string = undefined;
	/**
	 * The percentage of total business for the commodity
	 */
	commodityPctOfBusiness:number = undefined;
}
/**
 * Factors that justify the sales proposal. Other factors might be added not just competitor information, like proximity to service center
 */
export class PricingCompetingCarrier {
	/**
	 * The Standard Carrier Alpha Code (SCAC) of the competing carrier
	 */
	competingCarrierScac:string = undefined;
	/**
	 * Name of the competing carrier that currently does business with the customer
	 */
	competingCarrierName:string = undefined;
	/**
	 * This is the percentage of customer's total business the competing carrier gets.
	 */
	competingCarrierMarketShare:number = undefined;
	/**
	 * This is the discount the customer receives from the competing carrier.
	 */
	competingCarrierDiscount:MonetaryAmount = undefined;
	/**
	 * This describes the rate base the competing carrier offers the customer.
	 */
	competingCarrierRatebase:string = undefined;
	/**
	 * This describes the FAK if any offered by the competing carrier to the customer
	 */
	competingCarrierFak:number = undefined;
	/**
	 * This is the AMC amount the competing carrier offers the customer
	 */
	competingCarrierAmc:MonetaryAmount = undefined;
	/**
	 * This text identifies the lanes the competing carrier moves freight for the customer.
	 */
	competingCarrierLanes:Lane = undefined;
}
/**
 * The discount and scope of the discount
 */
export class PricingDiscount {
	/**
	 * unique id of the discount record
	 */
	pricingDiscountId:string = undefined;
	/**
	 * Type of Range: Eg. Pallet, Weight break or Piece Count, etc.
	 */
	rangeTypeCode:string = undefined;
	/**
	 * Unit of measure for the range type. Unit of Measure for the Range Type
	 * if Range type Code = Weight then UOM code = LB or KG
	 */
	rangeUom:string = undefined;
	/**
	 * If range type is Pallet then this is the Minimum Pallet count value
	 * 
	 * If range type is Weight Break then this is the Minimum Weight value
	 * 
	 * If range type is Piece Count then this is the Minimum Piece Count value
	 */
	beginRangeValue:number = undefined;
	/**
	 * If range type is Pallet then this is the Maximum Pallet count value
	 * 
	 * If range type is Weight Break then this is the Maximum Weight value
	 * 
	 * If range type is Piece Count then this is the Maximum Piece Count value
	 */
	endRangeValue:number = undefined;
	/**
	 * The discount amount for this   range
	 */
	discountAmt:MonetaryAmount = undefined;
	/**
	 * Discount Percent as requested by the Sales Person
	 */
	discountPct:number = undefined;
	/**
	 * The discount percentage off the amc
	 */
	amcDiscountPct:number = undefined;
	/**
	 * Applicable discount in %, when minimum charges are applied
	 */
	minChargeDiscountPct:number = undefined;
	/**
	 * The scope for this amc. Interstate, Intrastate, International
	 */
	discountScopeIndicators:PricingScope = undefined;
}
/**
 * FAK information for a Geo Pricing Group
 */
export class PricingFak {
	/**
	 * unique identifier of Pricing Proposal FAK Header record
	 */
	prcProposalFakHdrId:string = undefined;
	/**
	 * unique identifier of Sales Rfp requeste Fak record
	 */
	requestedFakId:string = undefined;
	/**
	 * Identifies the type (class) of commodity being shipped along with the FAK that overrides it.
	 */
	classFakOverrides:ClassOverride[] = undefined;
	/**
	 * Indicators that show the scope of the rates or discounts
	 */
	pricingScopeIndicators:PricingScope = undefined;
	auditInfo:AuditInfo = undefined;
	fakType:string = undefined;
}
export class PricingGroupShipmentAccsrl {
	accessorialCd:string = undefined;
	accessorialChgAmt:number = undefined;
	accessorialOverrideChgAmt:number = undefined;
	accessorialOverrideChgPct:number = undefined;
	accessorialUom:string = undefined;
	increasePct:number = undefined;
	shipmentCount:number = undefined;
	totalShipmentWeight:number = undefined;
	totalAccessorialAmt:number = undefined;
	avgAccessorialAmt:number = undefined;
	proposedTotalAccessorialAmt:number = undefined;
	shipmentAccsrlInstId:string[] = undefined;
}
export class PricingGroupShipmentAmc {
	trafficTypeCd:string = undefined;
	newAmcAmt:MonetaryAmount = undefined;
	totalProposedRevAmt:MonetaryAmount = undefined;
	avgProposedOrPct:number = undefined;
	shipmentCount:number = undefined;
}
export class PricingGroupSummaryShipment {
	pricingAnlysShipmentBase:PricingAnalysisShipmentBase = undefined;
	pricingAnlysShipmentAccsrl:PricingAnalysisShipmentAccessorial = undefined;
	prcAnlysGeoGroupDiscAmc:GeoGroupDiscountAmc = undefined;
	pricingAnlysGeoGroupHdrBase:PricingAnalysisGeoGroupHdrBase = undefined;
	prcAnlysGeoGroupBiAmc:GeoGroupBiAmc = undefined;
	prcAnlysGeoGroupBiTariff:GeoGroupBiTariff = undefined;
	prcAnlysGeoGroupCmAmc:GeoGroupCmAmc = undefined;
	prcAnlysGeoGroupCmTariff:GeoGroupCmTariff = undefined;
}
/**
 * Factors that justify the sales proposal. Other factors might be added not just competitor information, like proximity to service center
 */
export class PricingJustification {
	/**
	 * The customers reasons for seeking a different carrier than the one they are currently using. Example: Damages, Shortages, Billing or Invoicing Issues
	 */
	custExplicitNeedsCd:string[] = undefined;
	/**
	 * Various elements known about competing carrier's relationship with the customer
	 */
	competingCarrierInfo:PricingCompetingCarrier[] = undefined;
	/**
	 * links to documents or key values to retrieve documents
	 */
	supportingDocuments:Document[] = undefined;
}
/**
 * Information about the Lading unit dimension
 */
export class PricingLadingDimension {
	/**
	 * Unique identifier of the Lading dimension
	 */
	ladingDimensionId:string = undefined;
	ladingLength:number = undefined;
	ladingWidth:number = undefined;
	ladingHeight:number = undefined;
	ladingPctOfBusiness:number = undefined;
	/**
	 * The average weight for this lading unit
	 */
	ladingAvgWeight:Weight = undefined;
	/**
	 * Density of lading unit
	 */
	ladingAvgDensity:number = undefined;
}
/**
 * Lading Unit characteristics
 */
export class PricingLadingUnit {
	/**
	 * An Id that identifies this set of lading unit characteristics to be used in LadingUnitPricing
	 */
	ladingUnitId:string = undefined;
	/**
	 * The type of lading unit if known
	 */
	ladingUnitPackageTypeCd:string = undefined;
	/**
	 * Length, width and height of the lading unit
	 */
	ladingDimension:Dimensions = undefined;
	/**
	 * The average weight for this lading unit
	 */
	ladingAvgWeight:Weight = undefined;
	/**
	 * Density of lading unit
	 */
	ladingAvgDensity:number = undefined;
	/**
	 * average number of pieces of this lading unit
	 */
	ladingAvgPieceCount:number = undefined;
	/**
	 * The percentage of total business for the lading unit of these characteristics
	 */
	ladingUnitPctOfBusiness:number = undefined;
}
/**
 * Sets of lanes with commodity information for that set of lanes and the percentage of the customer's shipping business on that lane.
 */
export class PricingLane {
	/**
	 * an identifier for the lane
	 */
	laneId:string = undefined;
	/**
	 * defined by origin and destination areas
	 */
	lane:PricingLane_Lane[] = undefined;
	/**
	 * The actual commodity classes for the group of origin/destination lanes. The index of this array goes with the NMFCItem array
	 */
	laneExpectedNmfcClass:string[] = undefined;
	/**
	 * The actual commodity NMFC items for the group of origin/destination lanes.
	 */
	laneExpectedNmfcItem:string[] = undefined;
	/**
	 * The average freight class for the group of origin/destination lanes.
	 */
	laneExpectedAvgClass:number = undefined;
	/**
	 * The average density for the group of origin/destination lanes.
	 */
	laneExpectedAvgDensity:Density = undefined;
	/**
	 * The average weight for the group of origin/destination lanes
	 */
	laneExpectedAvgWeight:Weight = undefined;
	/**
	 * The average piece count for the group of origin/destination lanes
	 */
	laneExpectedAvgPieceCount:number = undefined;
	/**
	 * The percentage of total business for the group of origin/destination lanes
	 */
	laneExpectedPctOfBusiness:number = undefined;
}
/**
 * This is a view of shipment with factors used by the Pricing Statistical models
 */
export class PricingLeafShipment {
	/**
	 * Indicates if shipment is rated with Absolute Minimum Charge
	 */
	amcInd:boolean = undefined;
	/**
	 * Unique identifier for Pricing Analysis Shipment-Level record
	 */
	analysisShipInstId:string = undefined;
	/**
	 * The average NMFC commodity class
	 */
	avgClassCd:string = undefined;
	/**
	 * Indicates if shipment is bulk liquid
	 */
	bulkLiquidInd:boolean = undefined;
	/**
	 * Corporate Discount Impact output of the segmentation model
	 */
	cdiPct:number = undefined;
	/**
	 * Higher Corporate Discount Impact bound output of the segmentation model
	 */
	cdiPctHighValue:number = undefined;
	/**
	 * Lower Corporate Discount Impact bound output of the segmentation model
	 */
	cdiPctLowValue:number = undefined;
	classWeighted:string = undefined;
	/**
	 * Corporate Discount Impact to cluster output of the leaf model
	 */
	clusterCdiPct:number = undefined;
	/**
	 * Discount to cluster output of the leaf model
	 */
	clusterDiscountPct:number = undefined;
	/**
	 * Other notes
	 */
	comments:Comment = undefined;
	/**
	 * Cost Corporate Discount Impact
	 */
	costCdiPct:number = undefined;
	/**
	 * Total undiscounted linehaul charges, which is the commodity lines only (excludes ACs, FSC, discounts, etc.) when rated using corporate tariff CTS599
	 */
	cts599Amt:MonetaryAmount = undefined;
	/**
	 * Annual Customer Revenue
	 */
	custRevAmt:MonetaryAmount = undefined;
	/**
	 * Destination operational region
	 */
	destOpsRegionCd:string = undefined;
	/**
	 * unique id of the destination ops region
	 */
	destOpsRegionId:string = undefined;
	/**
	 * destinaton sic code
	 */
	destSicCd:string = undefined;
	/**
	 * Number of shipments with the same destination SIC
	 */
	destSicCnt:number = undefined;
	/**
	 * State where the  destination is located
	 */
	destStateCd:string = undefined;
	/**
	 * 1st character of destination zip code
	 */
	destZip1:string = undefined;
	/**
	 * Number of distinct first charater of the destination zip code
	 */
	destZip1Cnt:number = undefined;
	/**
	 * 1st two chars of dest zip code
	 */
	destZip2:string = undefined;
	/**
	 * Number of distinct first 2 charaters of the destination zip code
	 */
	destZip2Cnt:number = undefined;
	/**
	 * First 3 characters of the zip code for a US address or first 6 characters of the Canadian zip code of the address where the consignee is located
	 */
	destZip3Cd:string = undefined;
	/**
	 * First 5 characters of the zip code for a US address or first 6 characters of the Canadian zip code of the address where the consignee is located
	 */
	destZipCd:string = undefined;
	/**
	 * Number of distinct destination zip
	 */
	destZipCnt:number = undefined;
	/**
	 * XPO inbound revenue from NTD (National Traffic Db) for this destination zip
	 */
	destZipNtdCnwInbRevAmt:MonetaryAmount = undefined;
	/**
	 * Other company (non-XPO) outbound weight from NTD
	 */
	destZipNtdLtlInbWeight:Weight = undefined;
	/**
	 * Employee Range of the customer
	 */
	empRangeNm:string = undefined;
	/**
	 * Shipper to Consignee Miles
	 */
	laneDistance:Distance = undefined;
	/**
	 * Number of distinct regions
	 */
	laneRegionCnt:number = undefined;
	/**
	 * Region Name range
	 */
	laneRegionRangeNm:string = undefined;
	/**
	 * Origin and destination SIC
	 */
	laneSic:string = undefined;
	/**
	 * Number of distinct SIC
	 */
	laneSicCnt:number = undefined;
	/**
	 * Number of distinct states
	 */
	laneStateCnt:number = undefined;
	/**
	 * State Code range
	 */
	laneStateRangeNm:string = undefined;
	/**
	 * First character of the origin zip code and first character of the destination zip code range
	 */
	laneZip1:string = undefined;
	/**
	 * First 2 characters of the origin zip code and first 2 characters of the destination zip code range
	 */
	laneZip2:string = undefined;
	/**
	 * 1st 3 chars of origin and destination zip codes
	 */
	laneZip3:string = undefined;
	/**
	 * Number of distinct first 3 characters of the origin zip code and first 3 characters of the destination zip code range
	 */
	laneZip3Cnt:number = undefined;
	/**
	 * First 3 characters of the origin zip code and first 3 characters of the destination zip code range
	 */
	laneZip3LaneRangeNm:string = undefined;
	/**
	 * Origin zip code and destination zip code range
	 */
	laneZipRangeNm:string = undefined;
	/**
	 * Indicates if shipment is manually rated
	 */
	manualRatingRqrdInd:boolean = undefined;
	/**
	 * Market Discount Impact output of the leaf model
	 */
	marketCdiPct:number = undefined;
	/**
	 * Number of shipments per month of the customer
	 */
	monthlyShpmtCnt:number = undefined;
	/**
	 * XPO lane revenue from NTD (National Traffic Db)
	 */
	ntdCnwLaneRev:MonetaryAmount = undefined;
	/**
	 * Origin operational region
	 */
	origOpsRegionCd:string = undefined;
	/**
	 * unique id of the origin region
	 */
	origRegionId:string = undefined;
	/**
	 * Orgin sic code
	 */
	origSicCd:string = undefined;
	/**
	 * State where the origin is located
	 */
	origStateCd:string = undefined;
	/**
	 * First 5 characters of the zip code for a US address or first 6 characters of the Canadian zip code of the address where the shipper is located
	 */
	origZip:string = undefined;
	/**
	 * 1st char of origin zip code
	 */
	origZip1:string = undefined;
	/**
	 * Number of distinct first charater of the origin zip code
	 */
	origZip1Cnt:number = undefined;
	/**
	 * 1st 2 chars of origin zip code
	 */
	origZip2:string = undefined;
	/**
	 * Number of distinct first 2 charaters of the origin zip code
	 */
	origZip2Cnt:number = undefined;
	/**
	 * 1st 3 chars of origin zip code
	 */
	origZip3:string = undefined;
	/**
	 * Number of distinct first 3 charaters of the origin zip code
	 */
	origZip3Cnt:number = undefined;
	origZipNtdCnwOtbRevAmt:MonetaryAmount = undefined;
	/**
	 * XPO outbound shipment count from NTD (National Traffic Db) for this origin zip
	 */
	origZipNtdCnwOtbShpCnt:number = undefined;
	origZipNtdCnwOtbWeight:Weight = undefined;
	/**
	 * Other com[any (non-XPO) inbound revenue from NTD (National Traffic Db)
	 */
	origZipNtdInbRevAmt:MonetaryAmount = undefined;
	/**
	 * XPO inbound shipment count from NTD (National Traffic Db)
	 */
	origZipNtdInbShpmtCnt:number = undefined;
	/**
	 * XPO inbound shipment weight from NTD (National Traffic Db)
	 */
	origZipNtdInbWeight:Weight = undefined;
	origZipNtdLtlCnwInbRevAmt:MonetaryAmount = undefined;
	/**
	 * Other com[any (non-XPO) inbound shipment count from NTD (National Traffic Db)
	 */
	origZipNtdLtlInbShpCnt:number = undefined;
	/**
	 * Other company (non-XPO) outbound weight from NTD
	 */
	origZipNtdLtlInbWeight:Weight = undefined;
	origZipNtdLtlOtbRevAmt:MonetaryAmount = undefined;
	/**
	 * XPO outbound shipment count from NTD (National Traffic Db)
	 */
	origZipNtdOtbShpmtCnt:number = undefined;
	/**
	 * XPO outbound shipment weight from NTD (National Traffic Db)
	 */
	origZipNtdOtbWeight:Weight = undefined;
	/**
	 * Unique identifier for Pricing Leaf shipment record
	 */
	prcLeafShipmentId:string = undefined;
	/**
	 * Unique identifier for Proposal that is being analyzed.
	 */
	prcProposalInstId:string = undefined;
	/**
	 * Indicates if customer is public
	 */
	publicCompanyInd:boolean = undefined;
	/**
	 * Sales Volume Range of the customer
	 */
	salesVolumeRangeNm:string = undefined;
	/**
	 * Representative customer on this SEGMENT output of the segmentation model
	 */
	sampleCust:string = undefined;
	/**
	 * This is the SEGMENT a particular shipment would be assigned to. This is also an input to the LEAF MODELS because it is the unique id that tells you which Leaf Model to run
	 */
	segmentId:string = undefined;
	/**
	 * Identifier of the shipment (i.e PRO, unique proxy shipment key)
	 */
	shipmentId:string = undefined;
	/**
	 * Indicates if customer size is small
	 */
	smallBusinessInd:boolean = undefined;
	/**
	 * Status code
	 */
	statusCd:string = undefined;
	/**
	 * Tariff Revenue Amount of the shipment
	 */
	tariffRevAmt:MonetaryAmount = undefined;
	/**
	 * Total volume  (weight/density)
	 */
	totalVolume:Volume = undefined;
	/**
	 * Actual shipment weight
	 */
	weight:Weight = undefined;
	/**
	 * XPO inbound revenue from NTD (National Traffic Db) for this origin zip
	 */
	origZipNtdCnwInbRevAmt:MonetaryAmount = undefined;
}
/**
 * A definition of a particular workflow
 */
export class PricingModelSegment {
	/**
	 * unique identifier of Pricing Segment Model record
	 */
	prcSegmentModelId:string = undefined;
	/**
	 * This is the SEGMENT a particular shipment would be assigned to. This is also an input to the LEAF MODELS because it is the unique id that tells you which Leaf Model to run
	 */
	segmentId:string = undefined;
	/**
	 * Corporate Discount Impact
	 */
	cdiPct:number = undefined;
	/**
	 * Lower weight bound for a shipment to be assigned to this SEGMENT
	 */
	minWeight:Weight = undefined;
	/**
	 * Upper weight bound for a shipment to be assigned to this SEGMENT
	 */
	maxWeight:Weight = undefined;
	/**
	 * Lower class bound for a shipment to be assigned to this SEGMENT
	 */
	minNmfcClass:string = undefined;
	/**
	 * Upper class bound for a shipment to be assigned to this SEGMENT
	 */
	maxNmfcClass:string = undefined;
	/**
	 * Lower sic miles bound for a shipment to be assigned to this SEGMENT
	 */
	minSicDistance:Distance = undefined;
	/**
	 * Upper sic miles bound for a shipment to be assigned to this SEGMENT
	 */
	maxSicDistance:Distance = undefined;
	/**
	 * Lower annual customer revenue bound for all business to be assigned to this SEGMENT
	 */
	minCustRevAmt:MonetaryAmount = undefined;
	/**
	 * Lower annual customer revenue bound for all business to be assigned to this SEGMENT
	 */
	maxCustRevAmt:MonetaryAmount = undefined;
	/**
	 * Shipment variables used to run the segmentation model
	 */
	modelQualityScore:number = undefined;
	/**
	 * Quality category code of the segmentation model
	 */
	modelQualityCategory:string = undefined;
	/**
	 * Shipment variables used to run the segmentation model
	 */
	modelInputs:string = undefined;
}
/**
 * Pricing Proposal details
 */
export class PricingProposal {
	salesRfpHdr:SalesRfpHdr = undefined;
	proposalHeader:PricingProposalHdr = undefined;
	expectations:ProposalExpecatations = undefined;
	custHierarchy:PricingProposalCustomerHierarchy[] = undefined;
	geoDiscountAmcSummary:GeoDiscountAmcSummary[] = undefined;
	accessorial:PricingAccessorial[] = undefined;
	geoAreaPricingLane:GeoAreaPricingLane[] = undefined;
	rulesetMatrix:PricingProposalRulesetMatrix[] = undefined;
	tmsInfo:PricingProposalTms[] = undefined;
	agrmntPerformance:RfpAgrmntPerformance = undefined;
	rfpAuditHdr:SalesRfpAuditHeader = undefined;
	liabilityDetails:PricingAgreementDocLiabHdr = undefined;
}
/**
 * Accessorial information for Pricing Proposal
 */
export class PricingProposalAccessorial {
	/**
	 * unique identifier of Pricing Proposal Accessorial
	 */
	pricingProposalAccsrlId:string = undefined;
	accessorialInfo:PricingAccessorial = undefined;
}
export class PricingProposalCustomerHierarchy {
	/**
	 * unique identifier of the Pricing Proposal Customer Hierarchy record
	 */
	prcProposalCustHierarchyId:string = undefined;
	acctBasicInfo:AcctId = undefined;
	acctAddress:Address = undefined;
	auditInfo:AuditInfo = undefined;
	/**
	 * Value of ï¿½Yï¿½ if customer location is to be included in the analysis/proposal
	 */
	includeLocationInd:boolean = undefined;
}
/**
 * Pricing Analysis header information and summary of Pricing Analysis results
 */
export class PricingProposalHdr {
	aggrModelTemplateId:string = undefined;
	/**
	 * Identifier of the Current Pricing Agreement . The first qualifier in the customer agreement ID (e.g. 636714494-02-003)
	 */
	agreementAcctInstId:string = undefined;
	/**
	 * Current Pricing Agreement version number. The middle qualifier in the customer agreement ID (e.g. 636714494-02-003)
	 */
	agreementAcctVersion:string = undefined;
	/**
	 * Indicates when the new pricing agreement is candidate for monitor and adjust as set by the Pricing Analyst
	 */
	agreementMonitorRecordInd:string = undefined;
	/**
	 * Current Pricing Agreement suffix number. The last qualifier in the customer agreement ID e.g. (636714494-02-003)
	 */
	agreementSeqNbr:number = undefined;
	analysisCompleteDate:Date = undefined;
	analysisDueDate:Date = undefined;
	analysisStartDate:Date = undefined;
	/**
	 * Identifies the type of pricing analysis (i.e. IYM , Lane-Based )
	 */
	analysisTypeCd:string = undefined;
	/**
	 * identifies the type of pricing analysis work (i.e. New Opportunity, Renegotiate,Update Locations, Cancel Pricing, Additional Business, Monitor and Adjust, Revenue Neutral, In-Cycle Renegotiation, Out-of-Cycle Renegotiaton, Adhoc)
	 */
	analysisWorkTypeCd:string = undefined;
	auditInfo:AuditInfo = undefined;
	/**
	 * Current CTS599 Discount Impact percentage. Averaged based on period type (monthly, 6-month, annual)
	 */
	currentDiscountImpactPct:number = undefined;
	/**
	 * Current Operating Ratio percentage. Averaged based on period type (monthly, 6-month, annual)
	 */
	currentOrPct:number = undefined;
	/**
	 * current potential shipment revenue. Averaged based on period type (monthly, 6-month, annual)
	 */
	currentPotentialRevAmt:MonetaryAmount = undefined;
	/**
	 * current shipment revenue. Averaged based on period type (monthly, 6-month, annual)
	 */
	currentRevAmt:MonetaryAmount = undefined;
	/**
	 * Overall CTS599 Discount Impact for shipments with FAK and without FSC
	 */
	fakDiscImpactNoFsc:number = undefined;
	/**
	 * CTS599 Discount Impact for shipments with FAK and FSC
	 */
	fakDiscImpactWithFsc:number = undefined;
	/**
	 * Captures how many months worth of shipment data is used for analysis
	 */
	nbrOfPeriods:number = undefined;
	/**
	 * Identifier of the Customer with whom the agreement is being or was negotiated. This will uniquely identify the customer in the CIS component.
	 */
	negotiatingCustInstId:string = undefined;
	/**
	 * This is the business key at the time of negotiation for this customer. Note that it will not be kept in synch with the master customer database.
	 */
	negotiatingCustMadCd:string = undefined;
	/**
	 * Name and Address of the negotiating customer
	 */
	negotiatingCustNmAddr:Address = undefined;
	/**
	 * Specifies whether customer account is Pickup/Delivery, BillTo, Corporate Hierarchy
	 */
	negotiatingCustTypeCd:string = undefined;
	/**
	 * Overall CTS599 Discount Impact for shipments with no FAK and without FSC
	 */
	nonFakDiscImpactNoFsc:number = undefined;
	/**
	 * CTS599 Discount Impact for shipments with no FAK and FSC
	 */
	nonFakDiscImpactWithFsc:number = undefined;
	/**
	 * Identifies if the new pricing agreement has OBI Program
	 */
	obiInd:boolean = undefined;
	/**
	 * Offered discount percentage based on pricing analysis
	 */
	offeredDiscountPct:number = undefined;
	/**
	 * Overall CTS599 Discount Impact for shipments without FSC
	 */
	overallDiscImpactNoFsc:number = undefined;
	/**
	 * Overall CTS599 Discount Impact for shipments with FSC
	 */
	overallDiscImpactWithFsc:number = undefined;
	/**
	 * Overall percent of business of shipments with FAK
	 */
	overallFakPctOfBusiness:number = undefined;
	/**
	 * Overall percent of business of shipments with no  FAK
	 */
	overallNoFakPctOfBusiness:number = undefined;
	/**
	 * Describes how the amounts are calculated/averaged (i.e. monthly, 6-month, yearly)
	 */
	periodTypeCd:string = undefined;
	/**
	 * Unique id for the proposal
	 */
	prcProposalInstId:string = undefined;
	pricingAnalystId:string = undefined;
	/**
	 * Identifies the type of pricing contract (i.e. Regional, Strategic, Regional 3PL Broker, Strategic 3PL Customer-Specific)
	 */
	pricingTypeCd:string = undefined;
	/**
	 * Profit improvement reasons
	 */
	profitImprvmntDiscrReason:string = undefined;
	/**
	 * Profit Improvement Target Percentage
	 */
	profitImprovementTargetPct:number = undefined;
	/**
	 * Projected OR Change based on proposed pricing
	 */
	projectedChangeOrPct:number = undefined;
	/**
	 * Projected Cost based on proposed pricing
	 */
	projectedCostAmt:MonetaryAmount = undefined;
	/**
	 * Projected CTS599 Discount Impact based on proposed pricing
	 */
	projectedDiscountImpactPct:number = undefined;
	/**
	 * Projected freight revenue increase percentage. Averaged based on period type (monthly, 6-month, annual)
	 */
	projectedFrghtRevIncrPct:number = undefined;
	/**
	 * Projected operating income improvement percentage. Averaged based on period type (monthly, 6-month, annual)
	 */
	projectedOpIncImprvmntPct:number = undefined;
	/**
	 * Projected Operating Ratio based on proposed pricing
	 */
	projectedOrPct:number = undefined;
	/**
	 * Projected Price Change based on proposed pricing
	 */
	projectedPriceChgPct:number = undefined;
	/**
	 * Projected Profit Amount on based on proposed pricing
	 */
	projectedProfitAmt:MonetaryAmount = undefined;
	/**
	 * Projected Profit Improvement on based on proposed pricing
	 */
	projectedProfitImprvmntAmt:MonetaryAmount = undefined;
	/**
	 * Projected Profit Improvement percentage on based on proposed pricing
	 */
	projectedProfitImprvmntPct:number = undefined;
	/**
	 * Projected profit increase percentage. Averaged based on period type (monthly, 6-month, annual)
	 */
	projectedProfitIncrPct:number = undefined;
	/**
	 * Projected Revenue based on proposed pricing
	 */
	projectedRevAmt:MonetaryAmount = undefined;
	/**
	 * Projected Revenue Change based on proposed pricing
	 */
	projectedRevChgPct:number = undefined;
	/**
	 * Projected total revenue increase percentage. Averaged based on period type (monthly, 6-month, annual)
	 */
	projectedTotRevIncrPct:number = undefined;
	/**
	 * Projected Yield without FSC based on proposed pricing
	 */
	projectedYieldNoFsc:number = undefined;
	/**
	 * Projected Yield with FSC based on proposed pricing
	 */
	projectedYieldWithFsc:number = undefined;
	/**
	 * Average NMFC Freight class assumption for  this proposal
	 */
	proposalAvgClass:string = undefined;
	/**
	 * The average density assumption for  the proposal
	 */
	proposalAvgDensity:Density = undefined;
	/**
	 * The average weight assumption for  the proposal
	 */
	proposalAvgWeight:Weight = undefined;
	/**
	 * Current status of the pricing proposal (from 'not started' thru 'accepted'. Sample values (not complete): Not Started, Analysis in Progress, Under Review, Submitted to Sales, On-Hold, Cancelled, Accepted, Rejected, Countered
	 */
	proposalStatCd:string = undefined;
	/**
	 * Proposed discount percentage upper bound. Mainly for IYM simple analysis
	 */
	proposedDiscountHighPct:number = undefined;
	/**
	 * Proposed discount percentage lower bound. Mainly for IYM simple analysis
	 */
	proposedDiscountLowPct:number = undefined;
	/**
	 * Proposed discount percentage min. Mainly for lane-based analysis
	 */
	proposedDiscountMaxPct:number = undefined;
	/**
	 * Proposed discount percentage min. Mainly for lane-based analysis
	 */
	proposedDiscountMidPct:number = undefined;
	/**
	 * Proposed discount percentage min. Mainly for lane-based analysis
	 */
	proposedDiscountMinPct:number = undefined;
	/**
	 * Proposed discount percentageoptimal. Mainly for IYM simple analysis
	 */
	proposedDiscountOptimalPct:number = undefined;
	/**
	 * Unique identifier for Request for Pricing record
	 */
	salesRfpId:string = undefined;
	/**
	 * Shipment Revenue Percentage
	 */
	shipmentRevPct:number = undefined;
	thirdPartyLogisticsInd:string = undefined;
	thirdPartyLogisticsTypeCd:string = undefined;
	targetOrPct:string = undefined;
	manualCalcInd:boolean = undefined;
	analystMgrId:string = undefined;
	analystMgrName:string = undefined;
	pricingAnalystName:string = undefined;
	/**
	 * valid values are Y or N
	 */
	adminUserInd:string = undefined;
	dataSourceCd:string = undefined;
	startMoRevAmt:MonetaryAmount = undefined;
	startMoOrPct:number = undefined;
	recmdSingleDiscPct:number = undefined;
	proposedAmcShpmtPct:number = undefined;
	requestedDiscImpactPct:number = undefined;
	startMoCostAmt:MonetaryAmount = undefined;
	startMoProfitAmt:MonetaryAmount = undefined;
	/**
	 * Identifier for Pricing Analyst to send renegotiation letter at 40 days prior to Reneg Date
	 */
	sendRenegLetterTypeCode:string = undefined;
	forcedLetterDate:Date = undefined;
}
/**
 * Contains information about ruleset creation from Pricing Proposal data
 */
export class PricingProposalRulesetMatrix {
	/**
	 * Unique identifier for Pricing Proposal record
	 */
	prcProposalInstId:string = undefined;
	/**
	 * Unique identifier for Pricing Proposal Rule-Set Matrix record
	 */
	prcProposalRulesetMatrixId:string = undefined;
	/**
	 * Origin State Code
	 */
	origStateCd:string = undefined;
	/**
	 * Origin Country Code
	 */
	origCountryCd:string = undefined;
	/**
	 * Defines the low end of the Origin zip range for the lane
	 */
	origLowPostalCd:string = undefined;
	/**
	 * Defines the high end of the Origin zip range for the lane
	 */
	origHighPostalCd:string = undefined;
	/**
	 * Destination Country Code
	 */
	destCountryCd:string = undefined;
	/**
	 * Destination State Code
	 */
	destStateCd:string = undefined;
	/**
	 * Defines the low end of the Destination zip range for the lane
	 */
	destLowPostalCd:string = undefined;
	/**
	 * Defines the high end of the Destination zip range for the lane
	 */
	destHighPostalCd:string = undefined;
	/**
	 * Applicable Discount Percentage
	 */
	discountPct:number = undefined;
	/**
	 * Applicable discount in %, when minimum charges are applied
	 */
	minChargeDiscountPct:number = undefined;
	/**
	 * Absolute Minimum Charge Amount
	 */
	amcAmt:MonetaryAmount = undefined;
	pricingIndicators:PricingScope = undefined;
	/**
	 * Type of uom for the begin and end range value (i.e. WEIGHT)
	 */
	tierType:string = undefined;
	/**
	 * The lower limit of the range of weight (depends on Tier Type) that this discount record is applicable
	 */
	beginRangeValue:number = undefined;
	/**
	 * The upper limit of the range of weight (depends on Tier Type) that this discount record is applicable
	 */
	endRangeValue:number = undefined;
	auditInfo:AuditInfo = undefined;
}
/**
 * Contains information about the data created for Customer TMS from Pricing Proposal data
 */
export class PricingProposalTms {
	/**
	 * unique identifier of Pricing Proposal TMS file record
	 */
	prcProposalTmsFileId:string = undefined;
	/**
	 * Origin State Code
	 */
	origStateCd:string = undefined;
	/**
	 * Destination State Code
	 */
	destStateCd:string = undefined;
	/**
	 * Discount in decimal format
	 */
	discountPct:number = undefined;
	/**
	 * Minimum Charge Amount
	 */
	minChargeAmt:MonetaryAmount = undefined;
	/**
	 * SCAC code
	 */
	scacCd:string = undefined;
	auditInfo:AuditInfo = undefined;
}
export class PricingProposalWorkQueue {
	pricingProposalHdr:PricingProposalHdr = undefined;
	crmAccountOwner:CrmAccountOwner = undefined;
	salesRfp:SalesRfp = undefined;
	proposalOverallNote:SalesRfpComment = undefined;
	proposalComment:SalesRfpComment = undefined;
}
/**
 * The definition by indicator of the scope that a particular pricing element will apply to.
 */
export class PricingScope {
	/**
	 * Indicator if the rates and discounts apply to intrastate shipments
	 */
	intrastateInd:boolean = undefined;
	/**
	 * Indicator if the rates and discounts apply to interstate shipments
	 */
	interstateInd:boolean = undefined;
	/**
	 * Indicator if the rates and discounts apply to international shipments
	 */
	internationalInd:boolean = undefined;
	/**
	 * Indicator if the rates and discounts apply to outbound prepaid shipments
	 */
	outboundPrepaidInd:boolean = undefined;
	/**
	 * Indicator if the rates and discounts apply to inbound collect shipments
	 */
	inboundCollectInd:boolean = undefined;
	/**
	 * Indicator if the rates and discounts apply to outbound collect shipments
	 */
	outboundCollectInd:boolean = undefined;
	/**
	 * Indicator if the rates and discounts apply to inbound prepaid shipments
	 */
	inboundPrepaidInd:boolean = undefined;
	/**
	 * Indicator if the rates and discounts apply to third party shipments
	 */
	thirdPartyInd:boolean = undefined;
}
/**
 * Information about State-State AMCs and Discount
 */
export class ProposalAmcDiscount {
	prcProposalInstId:string = undefined;
	prcProposalAmcDiscountId:string = undefined;
	origGroupTypeCd:string = undefined;
	origGroupName:string = undefined;
	origGroupId:string = undefined;
	origStateCd:string = undefined;
	origCountryCd:string = undefined;
	destGroupTypeCd:string = undefined;
	destGroupName:string = undefined;
	destGroupId:string = undefined;
	destStateCd:string = undefined;
	destCountryCd:string = undefined;
	currentDiscountPct:number = undefined;
	newDiscountPct:number = undefined;
	currentAmcAmt:MonetaryAmount = undefined;
	newAmcAmt:MonetaryAmount = undefined;
	auditInfo:AuditInfo = undefined;
}
/**
 * Discount Information for a Pricing Proposal
 */
export class ProposalDiscount {
	/**
	 * unique db identifier of the geo pricing lane discount record
	 */
	geoPricingLaneDiscountId:string = undefined;
	pricingDiscount:PricingDiscount = undefined;
}
/**
 *  Proposal expectation details
 */
export class ProposalExpecatations {
	currentMonthlyCostAmt:MonetaryAmount = undefined;
	currentMonthlyProfitAmt:MonetaryAmount = undefined;
	monthlyRevenueReductionAmt:MonetaryAmount = undefined;
	monthlyCostReductionAmt:MonetaryAmount = undefined;
	monthlyOperatingIncomeImprovementAmt:MonetaryAmount = undefined;
	monthlyOperatingIncomeAmt:MonetaryAmount = undefined;
	strategy:StrategyDetails[] = undefined;
}
/**
 * Allowable weight break for Rating tariff Code
 */
export class RatingTariffWgtBreak {
	/**
	 * This is the tariff code or the RTAR for the default tariff that uses a specific set of rates described by the description text. For example the code CTS599 identifies the current Current CTS Corporate Tariff
	 */
	tariffCd:string = undefined;
	/**
	 * This describes the rates used by this tariff - for example Current CTS Corporrate Tariff or Yellow 1999 Rates etc.
	 */
	tariffDesc:string = undefined;
	baseTariff:string = undefined;
	agrmtPrintDesc:string = undefined;
	/**
	 * commodity class code
	 */
	classCd:string = undefined;
	rateSection:number = undefined;
	/**
	 * Indicates that the tariff is most commonly used
	 */
	commonlyUsedInd:boolean = undefined;
	/**
	 * number of weight breaks
	 */
	nbrOfEntries:number = undefined;
	/**
	 * 1st weight break
	 */
	wgtText1:string = undefined;
	/**
	 * 1st weight break low value
	 */
	wgtLow1:number = undefined;
	/**
	 * 2nd weight break
	 */
	wgtText2:string = undefined;
	/**
	 * 2nd weight break low value
	 */
	wgtLow2:number = undefined;
	/**
	 * 3rd weight break
	 */
	wgtText3:string = undefined;
	/**
	 * 3rd weight break low value
	 */
	wgtLow3:number = undefined;
	/**
	 * 4th weight break
	 */
	wgtText4:string = undefined;
	/**
	 * 4th weight break low value
	 */
	wgtLow4:number = undefined;
	/**
	 * 5th weight break
	 */
	wgtText5:string = undefined;
	wgtLow5:number = undefined;
	wgtText6:string = undefined;
	wgtLow6:number = undefined;
	wgtText7:string = undefined;
	wgtLow7:number = undefined;
	wgtText8:string = undefined;
	wgtLow8:number = undefined;
	wgtText9:string = undefined;
	wgtLow9:number = undefined;
	wgtText10:string = undefined;
	wgtLow10:number = undefined;
	wgtText11:string = undefined;
	wgtLow11:number = undefined;
	wgtText12:string = undefined;
	wgtLow12:number = undefined;
	wgtText13:string = undefined;
	wgtLow13:number = undefined;
	wgtText14:string = undefined;
	wgtLow14:number = undefined;
	wgtText15:string = undefined;
	wgtLow15:number = undefined;
}
/**
 * Unmapped document record type
 */
export class Record {
	data:string[] = undefined;
}
/**
 * Information about Sales Rfp and Its owner
 */
export class RfpOwner {
	/**
	 * The database identifier of this SalesRFP
	 */
	salesRfpId:string = undefined;
	/**
	 * Employee ID of current owner of sales rfp
	 */
	fromOwner:string = undefined;
}
/**
 * Basic customer account info
 */
export class RfpAcct {
	acctId:AcctId = undefined;
	address:Address = undefined;
	/**
	 * Effective date of the record
	 */
	effDate:Date = undefined;
	prcSourceAcctId:string = undefined;
	pricingSourceAcctMadCd:string = undefined;
}
/**
 * Information about Accounts and list of rulesets
 */
export class RfpAcctRuleset {
	account:RfpAcct = undefined;
	rulesets:Ruleset[] = undefined;
}
/**
 * Various factors used by or determined by the Pricing Analysis
 */
export class RfpAgrmntPerformance {
	auditInfo:AuditInfo = undefined;
	avgCostModelDensity:Density = undefined;
	avgFreightClass:string = undefined;
	avgRatedAsFreightClass:string = undefined;
	cnwy190FscRetenPct:number = undefined;
	cts599DiscImpactPctNetFsc:number = undefined;
	cts599DiscImpactPctNoObi:number = undefined;
	custMadCd:string = undefined;
	custName:string = undefined;
	distanceMilesPerShipment:number = undefined;
	effFscPct:number = undefined;
	netRevAmount:MonetaryAmount = undefined;
	netRevAmtNoObi:MonetaryAmount = undefined;
	netRevAmtNoObiPerShpmnt:MonetaryAmount = undefined;
	onTimeShipmentPct:number = undefined;
	orPctNoObi:number = undefined;
	paidClaimAmount:MonetaryAmount = undefined;
	paidClaimRatio:number = undefined;
	profitAmtNoObi:MonetaryAmount = undefined;
	/**
	 * unique identifier of the Rfp Agreement Performance record
	 */
	rfpAgrmntPerformanceId:string = undefined;
	rfpEndDate:Date = undefined;
	rfpStartDate:Date = undefined;
	shipmentCount:number = undefined;
	weightLbsPerShipment:number = undefined;
	yieldAmtNetFsc:MonetaryAmount = undefined;
	/**
	 * 
	 */
	yieldAmtNoObi:MonetaryAmount = undefined;
}
/**
 * The variable parameters that are included in the standard Pricing Agreement
 */
export class RfpAuditLog {
	eventTypeCd:string = undefined;
	eventSubTypeCd:string = undefined;
	eventStatus:string = undefined;
	/**
	 * Any other comments to be included in the contract
	 */
	eventOccurredTimestamp:Date = undefined;
	eventTranCd:string = undefined;
	eventCreatedBy:string = undefined;
	eventData:string = undefined;
	statusReasonCd:string = undefined;
}
/**
 * Commodity class overrides for the RFP
 */
export class RfpClassOverride {
	/**
	 * Commodity class and an overriding FAK
	 */
	commodityClass:ClassOverride = undefined;
	/**
	 * The percentage of business for this FAK
	 */
	FakPctOfBusiness:string = undefined;
}
/**
 * Off Bill Incentive program participation info
 */
export class RfpObi {
	/**
	 * unique id of the sales rfp obi plan
	 */
	rfpObiPlanId:string = undefined;
	/**
	 * Identifies the type of Off Bill discount program. End of Month, Volume Incentive Program, Load Allowance, Unload Allowance, Pallet
	 */
	obiPgm:string = undefined;
	/**
	 * Indicates whether to calculate incentive amounts based on gross or net revenue
	 */
	payOnGrossNetInd:string = undefined;
	grossNetAmt:MonetaryAmount = undefined;
	/**
	 * Indicates whether to qualify a shipment based on gross or net revenue
	 */
	qualifyOnGrossNetInd:string = undefined;
	/**
	 * A rate offered based on a unit of measure such as CWT
	 */
	incentiveRateAmt:MonetaryAmount = undefined;
	/**
	 * The unit of measure that the incentive rate is based on
	 */
	incentiveRateUom:string = undefined;
	/**
	 * Identifies if a single or multiple discount structure is associated with the OBI program
	 */
	discountTypeCd:string = undefined;
	/**
	 * Identifies the type of units for the range of discount rates
	 */
	discountUom:string = undefined;
	/**
	 * Discount percent when the discount type is Single
	 */
	singleDiscountPct:number = undefined;
	/**
	 * If the discountTypeCd is single, the amt will be here for some programs
	 */
	singleDiscountAmt:MonetaryAmount = undefined;
	/**
	 * The discount percentage per tier if the type is multiple
	 */
	discountTiers:RfpObiDiscTier[] = undefined;
	/**
	 * The origin location that a shipment must be pickup from to qualify for a pallet allowance
	 */
	origLoc:string = undefined;
}
/**
 * Off Bill Incentive program participation info
 */
export class RfpObiDiscTier {
	/**
	 * The minimum units, in a range, for which this discount rate applies
	 */
	discountTierMin:number = undefined;
	/**
	 * The maximum units, in a range, for which this discount rate applies
	 */
	discountTierMax:number = undefined;
	/**
	 * The program discount percentage to apply on qualified shipments
	 */
	discountPct:number = undefined;
	/**
	 * The program discount to apply on qualified shipments
	 */
	discount:MonetaryAmount = undefined;
}
export class Ruleset {
	/**
	 * billTo/3rd party or PnD
	 */
	rulesetTypeCd:string = undefined;
	/**
	 * indicates whether the ruleset  can be used as third party ruleset
	 */
	thirdPartyInd:boolean = undefined;
	agreementId:string = undefined;
	/**
	 * Ruleset Sequence Number
	 */
	rulesetNbr:number = undefined;
	ratingTariffName:string = undefined;
	/**
	 * An Accessorial charge may be overridden to be included in all shipments rated using the rule set or its rate or percentage may be overridden or it may be waived. Sometimes an accessorial may not be applicable to the rule set because this accessorial is not present in the customers rules tariff or was not negotiated for the modified 199 rules tariff.
	 * If the rules tariff is Customer specific and the AC service is included, it must exist on this table. If the Rules tariff is CNWY199, it will only exist on this table if AC service is overriden, waived or always applied."
	 */
	accessorialOverrides:AccessorialOverride[] = undefined;
	/**
	 * Desc: Stores the pricing class structures for rule sets. The class structure indicates if any class needs to be overridden and treated as another class for pricing purposes.
	 */
	classOverrides:ClassOverride[] = undefined;
	/**
	 * Desc: NMFC numbers for which the class is overridden to a different class value.
	 */
	nmfcOverrides:NmfcItemOverride[] = undefined;
}
/**
 * Shipment data to retrieve Rulesets 
 */
export class RulesetShipment {
	/**
	 * Second part of the unique identifier to a shipment. This is the date that the shipment was picked up.
	 */
	pkupDate:Date = undefined;
	/**
	 * Conditions agreed upon between parties to a transportation transaction regarding the type and payment of freight.
	 */
	chargeToCd:string = undefined;
	/**
	 * Total Piece count on the shipment
	 */
	totPiecesCnt:number = undefined;
	/**
	 * Total weight of the shipment
	 */
	totWeight:Weight = undefined;
	/**
	 * Shipment Origin details
	 */
	origin:Address = undefined;
	/**
	 * Shipment destination details
	 */
	destination:Address = undefined;
	/**
	 * Shipper Customer Account Instance Id
	 */
	shipperInstId:string = undefined;
	/**
	 * Consignee Customer Account Instance Id
	 */
	consigneeInstId:string = undefined;
	/**
	 * Bill 2 party Account Instance Id
	 */
	bill2InstId:string = undefined;
	/**
	 * Total number of pallets for the shipment
	 */
	palletCnt:number = undefined;
	/**
	 * Carrier information that we used for Advance Service
	 */
	advCarrierScac:string = undefined;
	/**
	 * Carrier information that we used for Beyond Service
	 */
	bydCarrierScac:string = undefined;
	/**
	 * Commodity Package code
	 */
	cmdtyPackageCd:string = undefined;
}
/**
 * Sales RFP is a solicitation made by a Sales agent to the pricing department for pricing that can be offered to a customer.
 */
export class SalesRfp {
	/**
	 * shipping related factors to be considered in the analysis
	 */
	agrmntPerformance:RfpAgrmntPerformance = undefined;
	/**
	 * The variable parameters that are included in the standard Pricing Agreement
	 */
	contractBoilerPlateParms:ContractBoilerPlate = undefined;
	/**
	 * File of representative shipments composed of lanes, commodities, weights, density for a customer, maybe based on actual historical shipments or derived shipments.
	 */
	custShipmentFile:CustomerShipmentsFile[] = undefined;
	/**
	 * geographic area that shares common pricing elements, including tariffs, discounts, AMCs, FAK
	 */
	geoPricingGroups:GeoAreaPricingLane[] = undefined;
	/**
	 * accessorials being considered in this RFP
	 */
	pricingAccessorials:PricingAccessorial[] = undefined;
	/**
	 * commodities for this customer with their percentage of business
	 */
	pricingCommodities:PricingCommodity[] = undefined;
	/**
	 * lanes grouped by shared commodity characteristics. There may be several such groupings
	 */
	pricingCommodityLanes:PricingLane[] = undefined;
	/**
	 * Factors that are influencing the proposal. Other factors might be added not just competitor information, like proximity to service center
	 */
	pricingJustification:PricingJustification = undefined;
	/**
	 * various lading unit dimensions
	 */
	pricingLadingUnits:PricingLadingDimension[] = undefined;
	/**
	 * Customer account locations in the scope of this rfp with basic info
	 */
	rfpAccts:RfpAcct[] = undefined;
	rfpAuditHdr:SalesRfpAuditHeader = undefined;
	/**
	 * A record of all actions taken on this RFP
	 */
	rfpAuditLog:RfpAuditLog[] = undefined;
	/**
	 * OBI plan participation  factors
	 */
	rfpObi:RfpObi[] = undefined;
	salesRfpContact:SalesRfpContact = undefined;
	salesRfpConstraint:SalesRfpConstraint[] = undefined;
	/**
	 * Key information for a SalesRFP
	 */
	salesRfpHdr:SalesRfpHdr = undefined;
	/**
	 * comments and exceptions during the RPF process including sales, pricing analyst, system
	 */
	workingComments:Comment[] = undefined;
	pricingProposalHdr:PricingProposalHdr = undefined;
	pricingAgreement:PricingAgreement = undefined;
}
/**
 * Contains information about the User assigned to work on Rfp
 */
export class SalesRfpAuditHeader {
	assignToRsBuilderInd:boolean = undefined;
	/**
	 * audit status for the sales rfp once agreement is created
	 */
	auditStatus:string = undefined;
	auditInfo:AuditInfo = undefined;
	/**
	 * unique identifier of the Rfp Audit Header record
	 */
	rfpAuditHeaderId:string = undefined;
	rsBuilderNotifyDate:Date = undefined;
	/**
	 * Date when the ruleset builder was assigned to the sales rfp
	 */
	rulesetBuilderAssignDate:Date = undefined;
	/**
	 * This is the date when the ruleset build is completed
	 */
	rsBuilderCompletedDate:Date = undefined;
	rulesetValidateDate:Date = undefined;
	rulesetBuildStatus:string = undefined;
	/**
	 * Employee Id of the user who is assigned to work on building ruleset for the Sales Rfp
	 */
	rulesetBuilderEmpId:string = undefined;
	rulesetBuilderName:string = undefined;
	salesRfpId:string = undefined;
	sourcePrcRfpId:string = undefined;
}
/**
 * Comments related to Sales Rfp
 */
export class SalesRfpComment {
	/**
	 * unique db identifier of a comment
	 */
	commentId:string = undefined;
	/**
	 * unique id of sales rfp
	 */
	salesRfpId:string = undefined;
	/**
	 * unique id of pricing proposal
	 */
	prcProposalInstId:string = undefined;
	comment:Comment = undefined;
}
/**
 * Captures the customer constraints related to a sales rfp
 */
export class SalesRfpConstraint {
	/**
	 * Type of AMC requested. 'SingAmc' - Single AMC or
	 * 'VarAmcByTraffic'
	 */
	amcTypeCode:string = undefined;
	/**
	 * The RFP average class of freight provided by sales, value zero indicates unknown value
	 */
	avgFreightClass:string = undefined;
	/**
	 * The RFP average density provided by sales, value zero indicates unknown value.
	 */
	avgShipmentDensity:Density = undefined;
	/**
	 * The RFP average shipment weight provided by sales, value zero indicates unknown value.
	 */
	avgShipmentWeight:Weight = undefined;
	/**
	 * Can customer accept different discounts for classes
	 */
	classDiscountInd:boolean = undefined;
	/**
	 * The number of divisions the customer has
	 */
	custDivisionsCount:number = undefined;
	/**
	 * Destination acceptable discounts that the customer can accept (Location, Zip1, Zip3, Zip5, State, Country, XPOBest)
	 */
	destAcceptableDiscounts:string = undefined;
	/**
	 * Where does the customer Ship to Zips/States/Regions
	 */
	destGroupId:string = undefined;
	/**
	 * Identifies if the customer has a limit to the number of discounts
	 */
	discountLimitInd:boolean = undefined;
	/**
	 * Type of Discount requested - values  are:
	 * simple     -- Speficic _Discount
	 * manual    --   Lane_Discount
	 * recommended -- LTL_Recommended_Discount  (RD template) -- RFP screen Lane Based Discounts or Lane Discount in WB
	 */
	discountType:string = undefined;
	/**
	 * 'VarDiscByWeight'
	 * 'VarDiscByTraffic'
	 * 'SingDiscount'
	 * 'VarDiscByApplication'
	 */
	discountSubType:string = undefined;
	fakInd:boolean = undefined;
	/**
	 * Identifies if the customer has geo grouping constraints
	 */
	groupingConstraintInd:boolean = undefined;
	/**
	 * Identifies if inbound pricing must match either outbound pricing or third party pricing
	 */
	inboundPricingMatchInd:boolean = undefined;
	/**
	 * Identifies if the customer can accept different discount for different lanes
	 */
	laneDifferentDiscountInd:boolean = undefined;
	/**
	 * The maximum number of discounts a customer can accept
	 */
	maxDiscountNbr:number = undefined;
	/**
	 * What are the maximum weight bands a customer can accept
	 */
	maxNbrOfWeightBands:number = undefined;
	/**
	 * The number of shipments per month shipped by the customer, value zero indicates unknown value
	 */
	monthlyShipmentCount:number = undefined;
	/**
	 * The number of inbound locations for the customer
	 */
	nbrOfInboundLocations:number = undefined;
	/**
	 * The number of outbound locations for the customer
	 */
	nbrOfOutboundLocations:number = undefined;
	/**
	 * Origin acceptable discounts that the customer can accept (Location, Zip1, Zip3, Zip5, State, Country, XPOBest)
	 */
	origAcceptableDiscounts:string = undefined;
	/**
	 * Where does the customer Ship from Zips/States/Regions
	 */
	origGroupId:string = undefined;
	/**
	 * Identifies if outbound pricing must match either inbound pricing or third party pricing
	 */
	outboundPricingMatchInd:boolean = undefined;
	/**
	 * The primary shipment application type outbound, inbound/both or unknown
	 */
	primShipmentApplType:string = undefined;
	rfpConstraintEndPoint:SalesRfpConstraintEndPoint[] = undefined;
	rfpConstraintId:string = undefined;
	/**
	 * Requested shipment data source to use for analysis either historical shipment, shipment file or none.
	 */
	rqstdAnlysDataSource:string = undefined;
	shipmentFileSrc:string = undefined;
	/**
	 * Number of shipments in the shipment file
	 */
	shipmentFileCount:number = undefined;
	/**
	 * The valid values are ZIP1, STATE, ZIP
	 */
	shippingFromType:string = undefined;
	/**
	 * The valid values are ZIP1, STATE, ZIP
	 */
	shippingToType:string = undefined;
	sourceType:string = undefined;
	/**
	 * Special Pricing Weight groups/Pallet/Pup
	 */
	specialPricingInd:boolean = undefined;
	/**
	 * Identifies if third party pricing must match either outbound pricing or inbound pricing
	 */
	thirdPartyPricingMatchInd:boolean = undefined;
	/**
	 * Can customer accept different discounts for weight bands
	 */
	wghtBandDiscountInd:boolean = undefined;
}
export class SalesRfpConstraintEndPoint {
	/**
	 * shipping from or shipping to
	 */
	applicationType:string = undefined;
	/**
	 * zip, zip1 or state
	 */
	type:string = undefined;
	/**
	 * either zip code or state code depending upon the type
	 */
	value:string = undefined;
}
/**
 * Contact Information for Sales Rfp in case of Cancellation of Pricing Agreement
 */
export class SalesRfpContact {
	/**
	 * unique id of sales rfp contact record
	 */
	rfpContactId:string = undefined;
	/**
	 * Audit Info details
	 */
	auditInfo:AuditInfo = undefined;
	contactInfo:ContactInfo = undefined;
	/**
	 * Company name of the Contact Person
	 */
	companyName:string = undefined;
	contactAddress:Address = undefined;
	/**
	 * Customer location SIC code
	 */
	locationSic:string = undefined;
	/**
	 * Customer location SIC phone number
	 */
	locationSicPhone:string = undefined;
	/**
	 * cancellation letter status, printed or not printed
	 */
	cancellationLetterStatus:string = undefined;
	/**
	 * Cancellation letter notice date
	 */
	cancellationNoticeDate:Date = undefined;
	/**
	 * Cancellation Letter Document ID to view the document
	 */
	cancellationLetterDocId:string = undefined;
	cancellationLetterDate:Date = undefined;
}
/**
 * Key information for a SalesRFP
 */
export class SalesRfpHdr {
	/**
	 * Area Code of the sales rfp owner
	 */
	areaCode:string = undefined;
	/**
	 * Type of Pricing Agreement
	 */
	agreementTypeCd:string = undefined;
	/**
	 * Date when the RFP is approved
	 */
	approvedDate:Date = undefined;
	/**
	 * Audit info details
	 */
	auditInfo:AuditInfo = undefined;
	/**
	 * This indicates that the RFP is for a Bid.
	 */
	bidInd:boolean = undefined;
	contractStatus:string = undefined;
	/**
	 * The ID of the individual or system who creates the Sales RFP
	 */
	creatorId:string = undefined;
	/**
	 * The ID of the owner of sales RFP, the owner transfers to the party that is actively working on the RFP.
	 */
	currentOwnerId:string = undefined;
	/**
	 * The customers total sales revenue from there NAICS code. ï¿½
	 */
	custAnnualSalesRev:MonetaryAmount = undefined;
	/**
	 * The TMS system a customer uses
	 */
	customerTms:string = undefined;
	/**
	 * The TMS version the customer uses
	 */
	customerTmsVersion:number = undefined;
	/**
	 * The date the Sales RFP is due from Pricing back to Sales
	 */
	dueDate:Date = undefined;
	/**
	 * This indicates this is in cycle sales rfp or not
	 */
	inCycleInd:boolean = undefined;
	mappedShipmentFileInd:boolean = undefined;
	/**
	 * This is the monthly promise amount for the customer
	 */
	monthlyPotentialAmt:MonetaryAmount = undefined;
	/**
	 * This is the monthly promise amount for the customer
	 */
	monthlyPromiseAmt:MonetaryAmount = undefined;
	/**
	 * ï¿½North American Industry Classification System (NAICS)ï¿½codesï¿½used to classify the primary business activity of a firm.
	 */
	naicsCd:string = undefined;
	/**
	 * Name of the Customer with whom the agreement is being or was negotiated.
	 */
	negotiatingCustAcctNm:string = undefined;
	/**
	 * Identifier of the Customer with whom the agreement is being or was negotiated. This will uniquely identify the customer in the CIS component.
	 */
	negotiatingCustInstId:string = undefined;
	/**
	 * This is the business key at the time of negotiation for this customer. Note that it will not be kept in synch with the master customer database.
	 */
	negotiatingCustMadCd:string = undefined;
	negotiatingCustTypeCd:string = undefined;
	negotiatingCustAddr:string = undefined;
	newPricingAgrmtCustId:string = undefined;
	newPricingAgrmtCustVer:number = undefined;
	newPricingAgrmtSeqNbr:number = undefined;
	/**
	 * The instance ID of the customer that negotiated this pricing agreement
	 */
	prevPricingAgrmtCustId:string = undefined;
	/**
	 * The version of the  pricing agreement
	 */
	prevPricingAgrmtCustVer:number = undefined;
	/**
	 * Identifies if the previous pricing agreement has OBI Program
	 */
	prevPricingObiInd:boolean = undefined;
	/**
	 * The sequence number within the version of pricing agreement
	 */
	prevPricingAgrmtSeqNbr:number = undefined;
	/**
	 * The ID of the Pricing Analyst
	 */
	pricingAnalystId:string = undefined;
	/**
	 * This contains the code that describes the type of pricing described by this RFP. Sample values: Regional, Strategic
	 */
	pricingTypeCd:string = undefined;
	/**
	 * Identifier of the primary customer location frequently the negotiating location associated with the RFP
	 */
	primaryAcctInstId:string = undefined;
	/**
	 * Primary customer location code associated with the RFP
	 */
	primaryAcctCode:string = undefined;
	/**
	 * assigns a priority to sales rfp Low, Medum, High
	 */
	priority:string = undefined;
	proposalExistsInd:boolean = undefined;
	/**
	 * Requested Effective Date for the Agreement if the request is approved.
	 */
	proposedEffDate:Date = undefined;
	/**
	 * Proposed Expiration Date for the Agreement if the request is approved.
	 */
	proposedExpDate:Date = undefined;
	reasonCd:string = undefined;
	/**
	 * This is the requested discount for the customer  (Enter Requested Discounts/XPO LTL pricing recommend discounts/Simple)
	 */
	requestedDiscountType:string = undefined;
	/**
	 * The SIC code based on the sales agent's SIC code at RFP creation time
	 */
	requestSic:string = undefined;
	/**
	 * The date the sales RFP is first submitted to Pricing
	 */
	rfpRequestDate:Date = undefined;
	/**
	 * Current status of the request (Sales or Pricing). Sample values: Approved, Canceled, NewRFP, InSales, InPricing, InPricingAnalysis
	 */
	rfpStatusCd:string = undefined;
	/**
	 * This contains the code that describes the type of RFP this will be. Sample values - NewOpportunity, Renegotiation, AdditionalBusiness, Updateocation, ChangeAC, CancelPricing
	 */
	rfpTypeCd:string = undefined;
	/**
	 * The ID of the Sales Agent
	 */
	salesAgentId:string = undefined;
	/**
	 * This identifies the sales program associated with the RFP.
	 */
	salesPgm:string = undefined;
	/**
	 * The sales region code based on the sales agent's region code at RFP creation time
	 */
	salesRegionCd:string = undefined;
	/**
	 * Sales District code of the Sales RFP
	 */
	salesDistrictCd:string = undefined;
	/**
	 * Unique Identifier of the opportunity in the CRM (salesforce) system
	 */
	sourceCrmOpportunityId:string = undefined;
	/**
	 * The database identifier of this SalesRFP
	 */
	salesRfpId:string = undefined;
	/**
	 * A code that qualifies a request by service of transportation.
	 */
	serviceTypeCd:string = undefined;
	/**
	 * Credit status of the negotiating customer
	 */
	negotiatingCustCredStatCd:string = undefined;
	/**
	 * Unique identifier of the Sales Rfp to create new Sales Rfp
	 */
	sourceSalesRfpId:string = undefined;
	/**
	 * true if RFP is for a 3PL
	 */
	thirdPartyLogisticsInd:boolean = undefined;
	/**
	 * Regional or Strategic
	 */
	thirdPartyLogisticsTypeCd:string = undefined;
	/**
	 * Indicates if the updates are allowed on this Sales Rfp
	 */
	updatesAllowedInd:boolean = undefined;
	/**
	 * Type of location update: Add or Delete
	 */
	updateLocationType:string = undefined;
	/**
	 * Tye of Action Allowed fo this sales rfp
	 */
	ActionAllowed:string = undefined;
	salesAgentName:string = undefined;
	creatorName:string = undefined;
	pricingAnalystName:string = undefined;
	currentOwnerName:string = undefined;
	renegotiationDate:Date = undefined;
	attachmentExist:boolean = undefined;
	/**
	 * unique identifier of Pricing Agreement Monitor
	 */
	pricingAgreementMonitorId:string = undefined;
	/**
	 * This is the pricing agreement monitor name, if the rfp is created out of PAM
	 */
	pricingAgreementMonitorName:string = undefined;
	/**
	 * This is the promise amount before request for expectation adjustment
	 */
	originalPromiseAmt:MonetaryAmount = undefined;
	/**
	 * This is the potential amount before request for expectation adjustment
	 */
	originalPotentialAmt:MonetaryAmount = undefined;
	/**
	 * Current monthly Reveune Amout derived from shipment summary
	 */
	currentMonthlyRevAmt:MonetaryAmount = undefined;
	/**
	 * Employee Id of the First Approver
	 */
	firstApproverId:string = undefined;
	/**
	 * Employee Id of the second approver
	 */
	secondApproverId:string = undefined;
}
/**
 * Information about Sales Rfp created from Auto General Rate Increase
 */
export class SalesRfpGri {
	/**
	 * The database identifier of this SalesRFP
	 */
	salesRfpId:string = undefined;
	/**
	 * % to increase the total charge
	 */
	rateIncrPct:string = undefined;
	/**
	 * The Employee Id of sales agent
	 */
	salesAgentId:string = undefined;
	/**
	 * The employee Id of the Requester
	 */
	requesterId:string = undefined;
	/**
	 * This is the monthly promise amount for the customer
	 */
	monthlyPromiseAmt:MonetaryAmount = undefined;
	/**
	 * This is the monthly promise amount for the customer
	 */
	monthlyPotentialAmt:MonetaryAmount = undefined;
	/**
	 * Projected Operating Ratio based on proposed pricing
	 */
	projectedOrPct:string = undefined;
	/**
	 * Information about the Pricing current Agreement for GRI
	 */
	pricingAgreement:PricingAgreementBasic = undefined;
}
/**
 * Information about the Supporting documents captured for a Sales Rfp
 */
export class SalesRfpSupportingDocument {
	/**
	 * unique identifier of the sales rfp supporting document record
	 */
	rfpSupportingDocId:string = undefined;
	/**
	 * unique identifier of sales rfp
	 */
	salesRfpId:string = undefined;
	/**
	 * unique identifier for Pricing Proposal
	 */
	prcProposalInstId:string = undefined;
	documentInfo:SalesRfpSupportingDocument_Document = undefined;
	/**
	 * status code when adding the document to the DMS
	 */
	statusCd:string = undefined;
	/**
	 * status message when ading the document to the DMS
	 */
	statusMsg:string = undefined;
}
/**
 * Information about the Customer Shipment summary for a given Pricing Agreement
 */
export class ShipmentSummary {
	/**
	 * unique identifier of the Pricing Agreement Shipment Summary
	 */
	agreementShipmentSummId:string = undefined;
	auditInfo:AuditInfo = undefined;
	currentOR:number = undefined;
	/**
	 * Off Billing Incentive amount for the current year
	 */
	currentYearObi:number = undefined;
	/**
	 * Shipment count for the current year
	 */
	currentYearShipmentCount:number = undefined;
	/**
	 * Total weight of the shipments shipped by customer in current year
	 */
	currentYearWeight:Weight = undefined;
	/**
	 * Cost Amount for the current year
	 */
	currYearCostAmt:MonetaryAmount = undefined;
	/**
	 * Revenue amount for the current year
	 */
	currYearRevAmt:MonetaryAmount = undefined;
	previousYearCostAmt:MonetaryAmount = undefined;
	/**
	 * off bill incentive amount from previous year
	 */
	previousYearObi:number = undefined;
	/**
	 * customers revenue amount from previous year
	 */
	previousYearRevAmt:MonetaryAmount = undefined;
	/**
	 * customers shipment count from previous year
	 */
	previousYearShipmentCount:number = undefined;
	/**
	 * Total weight of the customers shipment in previous year
	 */
	previousYearWeight:Weight = undefined;
	priorOR:number = undefined;
	/**
	 * Year and Month for the shipment summary
	 */
	yearMonth:Date = undefined;
	/**
	 * monthly revenue amount
	 */
	monthlyRevAmt:MonetaryAmount = undefined;
	/**
	 * Start date of shipment summary record used when calculating average revenue by 6 moths or 12 months
	 */
	startDate:Date = undefined;
	/**
	 * End date of shipment summary record used when calculating average revenue by 6 moths or 12 months
	 */
	endDate:Date = undefined;
}
/**
 *  Strategy Details from analysis shipments
 */
export class StrategyDetails {
	StrategyType:string = undefined;
	totalShipmentCount:number = undefined;
	totalWeight:Weight = undefined;
	averageWeight:Weight = undefined;
	averageMiles:Distance = undefined;
	freightRevenueIncreasePct:number = undefined;
	totalRevenueIncreasePct:number = undefined;
	profitIncreasePct:number = undefined;
	operatingIncomeImprovementPct:number = undefined;
}
/**
 * A definition of a User Preference
 */
export class UserPreference {
	/**
	 * The application this user preference is for
	 */
	appName:string = undefined;
	/**
	 * User Preference Data
	 */
	preference:string = undefined;
}
/**
 * A definition of a particular workflow
 */
export class Workflow {
	/**
	 * unique id of extract workflow
	 */
	prcExtractWorkflowId:string = undefined;
	/**
	 * Unique identifier of workflow  metadata
	 */
	workflowTypeCd:string = undefined;
	/**
	 * Name of workflow
	 */
	workflowNm:string = undefined;
	/**
	 * The steps that are part of this workflow
	 */
	workflowSteps:WorkflowStep[] = undefined;
}
/**
 * A definition of a particular workflow
 */
export class WorkflowStep {
	/**
	 * Order of workflow step
	 */
	stepSeqNbr:number = undefined;
	/**
	 * The type of extract for this step
	 */
	extractTypeCd:string = undefined;
	/**
	 * THe name of the extract type
	 */
	extractNm:string = undefined;
	prcExtractWorkflowId:string = undefined;
}
export class ListSalesRfpSupportingDocsResp_SalesRfpSupportingDocument extends SalesRfpSupportingDocument {
}
export class ListCrmAccountInfoResp_CrmAccount extends CrmAccount {
	accountOwner:CrmAccountOwner[] = undefined;
	parentAccount:CrmAccount = undefined;
	ultimateAccountParent:CrmAccount = undefined;
}
export class PricingLane_Lane extends Lane {
}
export class SalesRfpSupportingDocument_Document extends Document {
}

/**
 * Query parameters for getSalesRfp
 */
export class GetSalesRfpQuery {
	levelOfDetail: string = undefined;
}

/**
 * Query parameters for listSalesRFPs
 */
export class ListSalesRFPsQuery {
	ownerId: string = undefined;
	currentOwnerId: string = undefined;
	pricingAnalystId: string = undefined;
	negotiatingCustInstId: string = undefined;
	negotiatingCustMadCd: string = undefined;
	proposedEffDate: string = undefined;
	proposedExpDate: string = undefined;
	dueDate: string = undefined;
	startDate: string = undefined;
	endDate: string = undefined;
	salesRegionCd: string = undefined;
	salesDistrictCd: string = undefined;
	levelOfDetail: string = undefined;
	numberOfRows: number = undefined;
	startAt: number = undefined;
	fields: string[] = undefined;
	sortByFieldName: string[] = undefined;
	sortOrder: string[] = undefined;
}

/**
 * Query parameters for getSalesRfpCounts
 */
export class GetSalesRfpCountsQuery {
	levelOfDetail: string = undefined;
	ownerId: string = undefined;
	currentOwnerId: string = undefined;
	pricingAnalystId: string = undefined;
	negotiatingCustInstId: string = undefined;
	negotiatingCustMadCd: string = undefined;
	proposedEffDate: string = undefined;
	proposedExpDate: string = undefined;
	dueDate: string = undefined;
	startDate: string = undefined;
	endDate: string = undefined;
	salesRegionCd: string = undefined;
}

/**
 * Query parameters for listSalesRfpLifeCycles
 */
export class ListSalesRfpLifeCyclesQuery {
	acctId: string = undefined;
	dateCriteria: string = undefined;
	startDate: string = undefined;
	endDate: string = undefined;
	salesRegionCd: string = undefined;
	areaCode: string = undefined;
	rfpStatusCd: string = undefined;
	auditStatus: string = undefined;
	rulesetBuildStatus: string = undefined;
	proposalStatCd: string = undefined;
	reasonCd: string = undefined;
	levelOfDetail: string = undefined;
	numberOfRows: number = undefined;
	startAt: number = undefined;
	fields: string[] = undefined;
	sortByFieldName: string[] = undefined;
	sortOrder: string[] = undefined;
}

/**
 * Query parameters for updateSalesRfp
 */
export class UpdateSalesRfpQuery {
	prcProposalInstId: string = undefined;
}

/**
 * Query parameters for updateSalesRfpAuditHeader
 */
export class UpdateSalesRfpAuditHeaderQuery {
	emailNotify: string = undefined;
}

/**
 * Query parameters for listSalesRfpAccts
 */
export class ListSalesRfpAcctsQuery {
	parentAcctInstId: string = undefined;
	levelOfDetail: string = undefined;
	numberOfRows: number = undefined;
	startAt: number = undefined;
	fields: string[] = undefined;
	sortByFieldName: string[] = undefined;
	sortOrder: string[] = undefined;
}

/**
 * Query parameters for listSalesRfpAccessorials
 */
export class ListSalesRfpAccessorialsQuery {
	levelOfDetail: string = undefined;
	numberOfRows: number = undefined;
	startAt: number = undefined;
	fields: string[] = undefined;
	sortByFieldName: string[] = undefined;
	sortOrder: string[] = undefined;
}

/**
 * Query parameters for listGlobalAccessorials
 */
export class ListGlobalAccessorialsQuery {
	levelOfDetail: string = undefined;
	numberOfRows: number = undefined;
	startAt: number = undefined;
	fields: string[] = undefined;
	sortByFieldName: string[] = undefined;
	sortOrder: string[] = undefined;
}

/**
 * Query parameters for listPricingAccessorialsByType
 */
export class ListPricingAccessorialsByTypeQuery {
	typeCode: string = undefined;
	levelOfDetail: string = undefined;
	numberOfRows: number = undefined;
	startAt: number = undefined;
	fields: string[] = undefined;
	sortByFieldName: string[] = undefined;
	sortOrder: string[] = undefined;
}

/**
 * Query parameters for listPricingAccessorialsApplInd
 */
export class ListPricingAccessorialsApplIndQuery {
	levelOfDetail: string = undefined;
	numberOfRows: number = undefined;
	startAt: number = undefined;
	fields: string[] = undefined;
	sortByFieldName: string[] = undefined;
	sortOrder: string[] = undefined;
	typeCode: string = undefined;
}

/**
 * Query parameters for listSalesRfpAuditLogs
 */
export class ListSalesRfpAuditLogsQuery {
	levelOfDetail: string = undefined;
	numberOfRows: number = undefined;
	startAt: number = undefined;
	fields: string[] = undefined;
	sortByFieldName: string[] = undefined;
	sortOrder: string[] = undefined;
}

/**
 * Query parameters for createSalesRfpSupportingDocs
 */
export class CreateSalesRfpSupportingDocsQuery {
	prcProposalInstId: string = undefined;
}

/**
 * Query parameters for listSalesRfpSupportingDocs
 */
export class ListSalesRfpSupportingDocsQuery {
	prcProposalInstId: string = undefined;
	levelOfDetail: string = undefined;
	numberOfRows: number = undefined;
	startAt: number = undefined;
	fields: string[] = undefined;
	sortByFieldName: string[] = undefined;
	sortOrder: string[] = undefined;
}

/**
 * Query parameters for getSalesRfpSupportingDocsFile
 */
export class GetSalesRfpSupportingDocsFileQuery {
	rfpSupportingDocId: string[] = undefined;
}

/**
 * Query parameters for createSalesRfpComments
 */
export class CreateSalesRfpCommentsQuery {
	prcProposalInstId: string = undefined;
}

/**
 * Query parameters for listSalesRfpComments
 */
export class ListSalesRfpCommentsQuery {
	prcProposalInstId: string = undefined;
	commentTypeCd: string = undefined;
	commentSubTypeCd: string = undefined;
	levelOfDetail: string = undefined;
	numberOfRows: number = undefined;
	startAt: number = undefined;
	fields: string[] = undefined;
	sortByFieldName: string[] = undefined;
	sortOrder: string[] = undefined;
}

/**
 * Query parameters for deleteSalesRfpComments
 */
export class DeleteSalesRfpCommentsQuery {
	prcProposalInstId: string = undefined;
	commentId: string = undefined;
	commentTypeCd: string = undefined;
	commentSubTypeCd: string = undefined;
}

/**
 * Query parameters for listSalesRfpObiPlans
 */
export class ListSalesRfpObiPlansQuery {
	levelOfDetail: string = undefined;
	numberOfRows: number = undefined;
	startAt: number = undefined;
	fields: string[] = undefined;
	sortByFieldName: string[] = undefined;
	sortOrder: string[] = undefined;
}

/**
 * Query parameters for deleteSalesRfpObiPlans
 */
export class DeleteSalesRfpObiPlansQuery {
	rfpObiPlanId: string = undefined;
}

/**
 * Query parameters for listSalesRfpGeoAreaPricingLanes
 */
export class ListSalesRfpGeoAreaPricingLanesQuery {
	levelOfDetail: string = undefined;
	numberOfRows: number = undefined;
	startAt: number = undefined;
	fields: string[] = undefined;
	sortByFieldName: string[] = undefined;
	sortOrder: string[] = undefined;
}

/**
 * Query parameters for listSalesRfpCommodities
 */
export class ListSalesRfpCommoditiesQuery {
	levelOfDetail: string = undefined;
	numberOfRows: number = undefined;
	startAt: number = undefined;
	fields: string[] = undefined;
	sortByFieldName: string[] = undefined;
	sortOrder: string[] = undefined;
}

/**
 * Query parameters for listSalesRfpLadingDimensions
 */
export class ListSalesRfpLadingDimensionsQuery {
	levelOfDetail: string = undefined;
	numberOfRows: number = undefined;
	startAt: number = undefined;
	fields: string[] = undefined;
	sortByFieldName: string[] = undefined;
	sortOrder: string[] = undefined;
}

/**
 * Query parameters for listSalesRfpConstraints
 */
export class ListSalesRfpConstraintsQuery {
	sourceType: string = undefined;
	levelOfDetail: string = undefined;
	numberOfRows: number = undefined;
	startAt: number = undefined;
	fields: string[] = undefined;
	sortByFieldName: string[] = undefined;
	sortOrder: string[] = undefined;
}

/**
 * Query parameters for listSalesRfpCustomerShipments
 */
export class ListSalesRfpCustomerShipmentsQuery {
	levelOfDetail: string = undefined;
	numberOfRows: number = undefined;
	startAt: number = undefined;
	fields: string[] = undefined;
	sortByFieldName: string[] = undefined;
	sortOrder: string[] = undefined;
}

/**
 * Query parameters for listCrmAccount
 */
export class ListCrmAccountQuery {
	accountId: string[] = undefined;
	levelOfDetail: string = undefined;
	numberOfRows: number = undefined;
	startAt: number = undefined;
	fields: string[] = undefined;
	sortByFieldName: string[] = undefined;
	sortOrder: string[] = undefined;
}

/**
 * Query parameters for createPricingProposalHeader
 */
export class CreatePricingProposalHeaderQuery {
	salesRfpId: string = undefined;
	triggerProposalAnlysFlow: string = undefined;
}

/**
 * Query parameters for listPricingProposalHeaders
 */
export class ListPricingProposalHeadersQuery {
	salesRfpId: string = undefined;
	analysisTypeCd: string = undefined;
	proposalStatCd: string = undefined;
	pricingAnalystId: string = undefined;
	employeeRoleType: string[] = undefined;
	levelOfDetail: string = undefined;
	numberOfRows: number = undefined;
	startAt: number = undefined;
	fields: string[] = undefined;
	sortByFieldName: string[] = undefined;
	sortOrder: string[] = undefined;
}

/**
 * Query parameters for updatePricingProposal
 */
export class UpdatePricingProposalQuery {
	overrideInd: string = undefined;
}

/**
 * Query parameters for getPricingProposal
 */
export class GetPricingProposalQuery {
	levelOfDetail: string[] = undefined;
	excludeHdr: string = undefined;
}

/**
 * Query parameters for listPricingProposalWorkQueue
 */
export class ListPricingProposalWorkQueueQuery {
	pricingAnalystId: string = undefined;
	proposalStatCd: string[] = undefined;
	analysisTypeCd: string = undefined;
	acctId: string = undefined;
	dateCriteria: string = undefined;
	startDate: string = undefined;
	endDate: string = undefined;
	levelOfDetail: string = undefined;
	numberOfRows: number = undefined;
	startAt: number = undefined;
	fields: string[] = undefined;
	sortByFieldName: string[] = undefined;
	sortOrder: string[] = undefined;
}

/**
 * Query parameters for listPricingAnlysGeoGroupHeaders
 */
export class ListPricingAnlysGeoGroupHeadersQuery {
	levelOfDetail: string = undefined;
	numberOfRows: number = undefined;
	startAt: number = undefined;
	fields: string[] = undefined;
	sortByFieldName: string[] = undefined;
	sortOrder: string[] = undefined;
}

/**
 * Query parameters for listGeoPricingGroupLanes
 */
export class ListGeoPricingGroupLanesQuery {
	acctInstId: string = undefined;
	acctMadCd: string = undefined;
	geoLaneIds: string[] = undefined;
	levelOfDetail: string = undefined;
	numberOfRows: number = undefined;
	startAt: number = undefined;
	fields: string[] = undefined;
	sortByFieldName: string[] = undefined;
	sortOrder: string[] = undefined;
}

/**
 * Query parameters for deleteGeoPricingGroupLanes
 */
export class DeleteGeoPricingGroupLanesQuery {
	geoPricingGroupLaneMbrId: string = undefined;
}

/**
 * Query parameters for listGeoPricingGroups
 */
export class ListGeoPricingGroupsQuery {
	geoPricingGroupId: string[] = undefined;
	custAcctId: string = undefined;
	levelOfDetail: string = undefined;
	numberOfRows: number = undefined;
	startAt: number = undefined;
	fields: string[] = undefined;
	sortByFieldName: string[] = undefined;
	sortOrder: string[] = undefined;
}

/**
 * Query parameters for listPricingAnalysisWorkflowProgress
 */
export class ListPricingAnalysisWorkflowProgressQuery {
	extractTypeCd: string = undefined;
	extractStatusCd: string = undefined;
	latestFlow: string = undefined;
	active: string = undefined;
	levelOfDetail: string = undefined;
	numberOfRows: number = undefined;
	startAt: number = undefined;
	fields: string[] = undefined;
	sortByFieldName: string[] = undefined;
	sortOrder: string[] = undefined;
}

/**
 * Query parameters for getPricingExtractWorkflow
 */
export class GetPricingExtractWorkflowQuery {
	workflowNm: string = undefined;
}

/**
 * Query parameters for getPricingExtractWorkflowStep
 */
export class GetPricingExtractWorkflowStepQuery {
	stepSeqNbr: string = undefined;
}

/**
 * Query parameters for deletePricingAnalysisWorkFlow
 */
export class DeletePricingAnalysisWorkFlowQuery {
	analysisFlowInstId: string = undefined;
}

/**
 * Query parameters for listPricingAnalysts
 */
export class ListPricingAnalystsQuery {
	managerEmplId: string = undefined;
	employeeRoleType: string[] = undefined;
	levelOfDetail: string = undefined;
	numberOfRows: number = undefined;
	startAt: number = undefined;
	fields: string[] = undefined;
	sortByFieldName: string[] = undefined;
	sortOrder: string[] = undefined;
}

/**
 * Query parameters for deletePricingAdminPersonnel
 */
export class DeletePricingAdminPersonnelQuery {
	adminPersonnelId: string[] = undefined;
}

/**
 * Query parameters for updatePricingAnalysisShipments
 */
export class UpdatePricingAnalysisShipmentsQuery {
	intraAmc: string = undefined;
	interAmc: string = undefined;
	intlAmc: string = undefined;
	enableAnalysis: string = undefined;
	adjustPricingType: string = undefined;
}

/**
 * Query parameters for listPricingAnalysisShipments
 */
export class ListPricingAnalysisShipmentsQuery {
	levelOfDetail: string = undefined;
	numberOfRows: number = undefined;
	startAt: number = undefined;
	fields: string[] = undefined;
	sortByFieldName: string[] = undefined;
	sortOrder: string[] = undefined;
}

/**
 * Query parameters for listPricingLeafShipments
 */
export class ListPricingLeafShipmentsQuery {
	levelOfDetail: string = undefined;
	numberOfRows: number = undefined;
	startAt: number = undefined;
	fields: string[] = undefined;
	sortByFieldName: string[] = undefined;
	sortOrder: string[] = undefined;
}

/**
 * Query parameters for listPricingModelSegments
 */
export class ListPricingModelSegmentsQuery {
	levelOfDetail: string = undefined;
	numberOfRows: number = undefined;
	startAt: number = undefined;
	fields: string[] = undefined;
	sortByFieldName: string[] = undefined;
	sortOrder: string[] = undefined;
}

/**
 * Query parameters for deletePricingAnalysisShipments
 */
export class DeletePricingAnalysisShipmentsQuery {
	prcAnalysisShipmentId: string[] = undefined;
}

/**
 * Query parameters for listPricingAnlysGeoGroupBIAmcs
 */
export class ListPricingAnlysGeoGroupBIAmcsQuery {
	levelOfDetail: string = undefined;
	numberOfRows: number = undefined;
	startAt: number = undefined;
	fields: string[] = undefined;
	sortByFieldName: string[] = undefined;
	sortOrder: string[] = undefined;
}

/**
 * Query parameters for deletePricingAnlysGeoGroupBIAmcs
 */
export class DeletePricingAnlysGeoGroupBIAmcsQuery {
	geoGroupBiAmcId: string = undefined;
}

/**
 * Query parameters for listGeoPricingDiscountAmcSummary
 */
export class ListGeoPricingDiscountAmcSummaryQuery {
	levelOfDetail: string = undefined;
	numberOfRows: number = undefined;
	startAt: number = undefined;
	fields: string[] = undefined;
	sortByFieldName: string[] = undefined;
	sortOrder: string[] = undefined;
}

/**
 * Query parameters for deleteGeoPricingDiscountAmcSummary
 */
export class DeleteGeoPricingDiscountAmcSummaryQuery {
	geoDiscAmcSummaryId: string = undefined;
}

/**
 * Query parameters for listPricingAnlysGeoGroupBiTariffs
 */
export class ListPricingAnlysGeoGroupBiTariffsQuery {
	levelOfDetail: string = undefined;
	numberOfRows: number = undefined;
	startAt: number = undefined;
	fields: string[] = undefined;
	sortByFieldName: string[] = undefined;
	sortOrder: string[] = undefined;
}

/**
 * Query parameters for deletePricingAnlysGeoGroupBiTariff
 */
export class DeletePricingAnlysGeoGroupBiTariffQuery {
	geoGroupBiTariffId: string = undefined;
}

/**
 * Query parameters for listPricingAnlysGeoGroupCmTariffs
 */
export class ListPricingAnlysGeoGroupCmTariffsQuery {
	levelOfDetail: string = undefined;
	numberOfRows: number = undefined;
	startAt: number = undefined;
	fields: string[] = undefined;
	sortByFieldName: string[] = undefined;
	sortOrder: string[] = undefined;
}

/**
 * Query parameters for deletePricingAnlysGeoGroupCmTariff
 */
export class DeletePricingAnlysGeoGroupCmTariffQuery {
	geoGroupCmTariffId: string = undefined;
}

/**
 * Query parameters for listPricingAnlysGeoGroupCmAmcs
 */
export class ListPricingAnlysGeoGroupCmAmcsQuery {
	levelOfDetail: string = undefined;
	numberOfRows: number = undefined;
	startAt: number = undefined;
	fields: string[] = undefined;
	sortByFieldName: string[] = undefined;
	sortOrder: string[] = undefined;
}

/**
 * Query parameters for deletePricingAnlysGeoGroupCmAmcs
 */
export class DeletePricingAnlysGeoGroupCmAmcsQuery {
	geoGroupCmAmcId: string = undefined;
}

/**
 * Query parameters for listPricingProposalAccessorials
 */
export class ListPricingProposalAccessorialsQuery {
	levelOfDetail: string = undefined;
	numberOfRows: number = undefined;
	startAt: number = undefined;
	fields: string[] = undefined;
	sortByFieldName: string[] = undefined;
	sortOrder: string[] = undefined;
}

/**
 * Query parameters for listPricingProposalGeoPricingLanes
 */
export class ListPricingProposalGeoPricingLanesQuery {
	levelOfDetail: string = undefined;
	numberOfRows: number = undefined;
	startAt: number = undefined;
	fields: string[] = undefined;
	sortByFieldName: string[] = undefined;
	sortOrder: string[] = undefined;
}

/**
 * Query parameters for deletePricingProposalGeoPricingLanes
 */
export class DeletePricingProposalGeoPricingLanesQuery {
	geoAreaPrcLaneId: string = undefined;
}

/**
 * Query parameters for listGeoPricingLaneAmcs
 */
export class ListGeoPricingLaneAmcsQuery {
	levelOfDetail: string = undefined;
	numberOfRows: number = undefined;
	startAt: number = undefined;
	fields: string[] = undefined;
	sortByFieldName: string[] = undefined;
	sortOrder: string[] = undefined;
}

/**
 * Query parameters for deleteGeoPricingLaneAmcs
 */
export class DeleteGeoPricingLaneAmcsQuery {
	geoPricingLaneAmcId: string = undefined;
}

/**
 * Query parameters for listGeoPricingLaneDiscounts
 */
export class ListGeoPricingLaneDiscountsQuery {
	levelOfDetail: string = undefined;
	numberOfRows: number = undefined;
	startAt: number = undefined;
	fields: string[] = undefined;
	sortByFieldName: string[] = undefined;
	sortOrder: string[] = undefined;
}

/**
 * Query parameters for deleteGeoPricingLaneDiscounts
 */
export class DeleteGeoPricingLaneDiscountsQuery {
	geoPricingLaneDiscountId: string = undefined;
}

/**
 * Query parameters for createPricingAnalysisShipmentAccessorials
 */
export class CreatePricingAnalysisShipmentAccessorialsQuery {
	enableAnalysis: string = undefined;
}

/**
 * Query parameters for updatePricingAnalysisShipmentAccessorials
 */
export class UpdatePricingAnalysisShipmentAccessorialsQuery {
	enableAnalysis: string = undefined;
}

/**
 * Query parameters for listPricingAnalysisShipmentAccessorials
 */
export class ListPricingAnalysisShipmentAccessorialsQuery {
	levelOfDetail: string = undefined;
	numberOfRows: number = undefined;
	startAt: number = undefined;
	fields: string[] = undefined;
	sortByFieldName: string[] = undefined;
	sortOrder: string[] = undefined;
}

/**
 * Query parameters for deletePricingAnalysisShipmentAccessorials
 */
export class DeletePricingAnalysisShipmentAccessorialsQuery {
	prcAnlysShipmentAccsrlId: string = undefined;
}

/**
 * Query parameters for listPricingAnalystAssignments
 */
export class ListPricingAnalystAssignmentsQuery {
	assignmentTypeCd: string = undefined;
	acctExecEmployeeId: string = undefined;
	nasSupervisorEmployeeId: string = undefined;
	nasSpecialistEmployeeId: string = undefined;
	salesRegionCode: string = undefined;
	areaCode: string = undefined;
	agreementTypeCode: string = undefined;
	rfpTypeCode: string = undefined;
	effectiveDate: string = undefined;
	expirationDate: string = undefined;
	levelOfDetail: string = undefined;
	numberOfRows: number = undefined;
	startAt: number = undefined;
	fields: string[] = undefined;
	sortByFieldName: string[] = undefined;
	sortOrder: string[] = undefined;
}

/**
 * Query parameters for listPricingAnlysGeoGroupDiscAmcs
 */
export class ListPricingAnlysGeoGroupDiscAmcsQuery {
	levelOfDetail: string = undefined;
	numberOfRows: number = undefined;
	startAt: number = undefined;
	fields: string[] = undefined;
	sortByFieldName: string[] = undefined;
	sortOrder: string[] = undefined;
}

/**
 * Query parameters for deletePricingAnlysGeoGroupDiscAmcs
 */
export class DeletePricingAnlysGeoGroupDiscAmcsQuery {
	geoGroupDiscAmcId: string = undefined;
}

/**
 * Query parameters for createPricingProposalCustHierarchy
 */
export class CreatePricingProposalCustHierarchyQuery {
	updateShipment: string = undefined;
}

/**
 * Query parameters for listPricingProposalCustHierarchy
 */
export class ListPricingProposalCustHierarchyQuery {
	levelOfDetail: string = undefined;
	numberOfRows: number = undefined;
	startAt: number = undefined;
	fields: string[] = undefined;
	sortByFieldName: string[] = undefined;
	sortOrder: string[] = undefined;
}

/**
 * Query parameters for updatePricingProposalCustHierarchies
 */
export class UpdatePricingProposalCustHierarchiesQuery {
	updateShipment: string = undefined;
}

/**
 * Query parameters for calculatePricingGroupSummaryShipments
 */
export class CalculatePricingGroupSummaryShipmentsQuery {
	categoryType: string[] = undefined;
	discAdjustment: string = undefined;
	newAmc: string = undefined;
	levelOfDetail: string = undefined;
	numberOfRows: number = undefined;
	startAt: number = undefined;
	fields: string[] = undefined;
	sortByFieldName: string[] = undefined;
	sortOrder: string[] = undefined;
}

/**
 * Query parameters for calculatePricingGroupShipmentAmc
 */
export class CalculatePricingGroupShipmentAmcQuery {
	intraAmc: string = undefined;
	interAmc: string = undefined;
	intlAmc: string = undefined;
	levelOfDetail: string = undefined;
	numberOfRows: number = undefined;
	startAt: number = undefined;
	fields: string[] = undefined;
	sortByFieldName: string[] = undefined;
	sortOrder: string[] = undefined;
}

/**
 * Query parameters for calculatePricingGroupShipmentAccsrl
 */
export class CalculatePricingGroupShipmentAccsrlQuery {
	levelOfDetail: string = undefined;
	numberOfRows: number = undefined;
	startAt: number = undefined;
	fields: string[] = undefined;
	sortByFieldName: string[] = undefined;
	sortOrder: string[] = undefined;
}

/**
 * Query parameters for deleteGeoPricingLaneFaks
 */
export class DeleteGeoPricingLaneFaksQuery {
	pricingProposalFakId: string = undefined;
}

/**
 * Query parameters for deleteGeoPricingLaneFakOverrides
 */
export class DeleteGeoPricingLaneFakOverridesQuery {
	overrideIds: string[] = undefined;
}

/**
 * Query parameters for listPricingProposalRulesetMatrix
 */
export class ListPricingProposalRulesetMatrixQuery {
	levelOfDetail: string = undefined;
	numberOfRows: number = undefined;
	startAt: number = undefined;
	fields: string[] = undefined;
	sortByFieldName: string[] = undefined;
	sortOrder: string[] = undefined;
}

/**
 * Query parameters for deletePricingProposalRulesetMatrix
 */
export class DeletePricingProposalRulesetMatrixQuery {
	prcProposalRulesetMatrixId: string = undefined;
}

/**
 * Query parameters for listPricingProposalTmsFile
 */
export class ListPricingProposalTmsFileQuery {
	levelOfDetail: string = undefined;
	numberOfRows: number = undefined;
	startAt: number = undefined;
	fields: string[] = undefined;
	sortByFieldName: string[] = undefined;
	sortOrder: string[] = undefined;
}

/**
 * Query parameters for deletePricingProposalTmsFile
 */
export class DeletePricingProposalTmsFileQuery {
	prcProposalTmsFileId: string = undefined;
}

/**
 * Query parameters for listAggregateModelConstraintTemplates
 */
export class ListAggregateModelConstraintTemplatesQuery {
	levelOfDetail: string = undefined;
	numberOfRows: number = undefined;
	startAt: number = undefined;
	fields: string[] = undefined;
	sortByFieldName: string[] = undefined;
	sortOrder: string[] = undefined;
}

/**
 * Query parameters for listPricingProposalAggrModelGeoStagings
 */
export class ListPricingProposalAggrModelGeoStagingsQuery {
	levelOfDetail: string = undefined;
	numberOfRows: number = undefined;
	startAt: number = undefined;
	fields: string[] = undefined;
	sortByFieldName: string[] = undefined;
	sortOrder: string[] = undefined;
}

/**
 * Query parameters for listGeoPricingGroupsForProposal
 */
export class ListGeoPricingGroupsForProposalQuery {
	levelOfDetail: string = undefined;
	numberOfRows: number = undefined;
	startAt: number = undefined;
	fields: string[] = undefined;
	sortByFieldName: string[] = undefined;
	sortOrder: string[] = undefined;
}

/**
 * Query parameters for listGeoPricingGroupLanesForProposal
 */
export class ListGeoPricingGroupLanesForProposalQuery {
	levelOfDetail: string = undefined;
	numberOfRows: number = undefined;
	startAt: number = undefined;
	fields: string[] = undefined;
	sortByFieldName: string[] = undefined;
	sortOrder: string[] = undefined;
}

/**
 * Query parameters for listGeoPricingGroupMembersForProposal
 */
export class ListGeoPricingGroupMembersForProposalQuery {
	levelOfDetail: string = undefined;
	numberOfRows: number = undefined;
	startAt: number = undefined;
	fields: string[] = undefined;
	sortByFieldName: string[] = undefined;
	sortOrder: string[] = undefined;
}

/**
 * Query parameters for listGeoPricingGroupExclMembersForProposal
 */
export class ListGeoPricingGroupExclMembersForProposalQuery {
	levelOfDetail: string = undefined;
	numberOfRows: number = undefined;
	startAt: number = undefined;
	fields: string[] = undefined;
	sortByFieldName: string[] = undefined;
	sortOrder: string[] = undefined;
}

/**
 * Query parameters for listPricingProposalAmcDiscounts
 */
export class ListPricingProposalAmcDiscountsQuery {
	levelOfDetail: string = undefined;
	numberOfRows: number = undefined;
	startAt: number = undefined;
	fields: string[] = undefined;
	sortByFieldName: string[] = undefined;
	sortOrder: string[] = undefined;
}

/**
 * Query parameters for listPricingCodesByType
 */
export class ListPricingCodesByTypeQuery {
	categoryCode: string[] = undefined;
	subCategoryCode: string[] = undefined;
	levelOfDetail: string = undefined;
	numberOfRows: number = undefined;
	startAt: number = undefined;
	fields: string[] = undefined;
	sortByFieldName: string[] = undefined;
	sortOrder: string[] = undefined;
}

/**
 * Query parameters for listSalesRfpAcctsRuleset
 */
export class ListSalesRfpAcctsRulesetQuery {
	listInfo: string = undefined;
}

/**
 * Query parameters for listAcctPricingAgreement
 */
export class ListAcctPricingAgreementQuery {
	acctInstId: string[] = undefined;
	leadAcctId: string = undefined;
	levelOfDetail: string = undefined;
	numberOfRows: number = undefined;
	startAt: number = undefined;
	fields: string[] = undefined;
	sortByFieldName: string[] = undefined;
	sortOrder: string[] = undefined;
}

/**
 * Query parameters for listPricingAgreements
 */
export class ListPricingAgreementsQuery {
	acctInstId: string = undefined;
	agreementId: string = undefined;
	levelOfDetail: string = undefined;
	numberOfRows: number = undefined;
	startAt: number = undefined;
	fields: string[] = undefined;
	sortByFieldName: string[] = undefined;
	sortOrder: string[] = undefined;
}

/**
 * Query parameters for listPricingAgreementAccts
 */
export class ListPricingAgreementAcctsQuery {
	leadAcctId: string = undefined;
	agreementAcctVersion: string = undefined;
	agreementSeqNbr: number = undefined;
	levelOfDetail: string = undefined;
	numberOfRows: number = undefined;
	startAt: number = undefined;
	fields: string[] = undefined;
	sortByFieldName: string[] = undefined;
	sortOrder: string[] = undefined;
}

/**
 * Query parameters for listPricingAgreementExpectations
 */
export class ListPricingAgreementExpectationsQuery {
	leadAcctId: string = undefined;
	agreementSeqNbr: string = undefined;
	agreementCustVersion: string = undefined;
	levelOfDetail: string = undefined;
	numberOfRows: number = undefined;
	startAt: number = undefined;
	fields: string[] = undefined;
	sortByFieldName: string[] = undefined;
	sortOrder: string[] = undefined;
}

/**
 * Query parameters for listPricingAgreementShipmentSumm
 */
export class ListPricingAgreementShipmentSummQuery {
	leadAcctId: string = undefined;
	levelOfDetail: string = undefined;
	numberOfRows: number = undefined;
	startAt: number = undefined;
	fields: string[] = undefined;
	sortByFieldName: string[] = undefined;
	sortOrder: string[] = undefined;
}

/**
 * Query parameters for getPricingAgreementShipmentSumm
 */
export class GetPricingAgreementShipmentSummQuery {
	leadAcctId: string = undefined;
	agreementSeqNbr: string = undefined;
	agreementCustVersion: string = undefined;
	prevMonthCount: string = undefined;
}

/**
 * Query parameters for getPricingAgreementLiabilityDetails
 */
export class GetPricingAgreementLiabilityDetailsQuery {
	acctInstId: string = undefined;
	seqNbr: string = undefined;
	versionNbr: string = undefined;
	agreementId: string = undefined;
}

/**
 * Query parameters for getPricingAgreementReportFile
 */
export class GetPricingAgreementReportFileQuery {
	leadAcctId: string = undefined;
	agreementAcctVersion: string = undefined;
	agreementSeqNbr: string = undefined;
	reportType: string = undefined;
}

/**
 * Query parameters for listRatingTariffWeightBreaks
 */
export class ListRatingTariffWeightBreaksQuery {
	tariffCd: string[] = undefined;
	levelOfDetail: string = undefined;
	numberOfRows: number = undefined;
	startAt: number = undefined;
	fields: string[] = undefined;
	sortByFieldName: string[] = undefined;
	sortOrder: string[] = undefined;
}

/**
 * Query parameters for getSalesRfpReportFile
 */
export class GetSalesRfpReportFileQuery {
	reportType: string = undefined;
}

/**
 * Query parameters for listPricingAdminProposedStrategy
 */
export class ListPricingAdminProposedStrategyQuery {
	levelOfDetail: string = undefined;
	numberOfRows: number = undefined;
	startAt: number = undefined;
	fields: string[] = undefined;
	sortByFieldName: string[] = undefined;
	sortOrder: string[] = undefined;
}

/**
 * Query parameters for listPricingAdminProposedIncreaseLevel
 */
export class ListPricingAdminProposedIncreaseLevelQuery {
	levelOfDetail: string = undefined;
	numberOfRows: number = undefined;
	startAt: number = undefined;
	fields: string[] = undefined;
	sortByFieldName: string[] = undefined;
	sortOrder: string[] = undefined;
}

/**
 * Query parameters for listPricingAdminFakAdjustPct
 */
export class ListPricingAdminFakAdjustPctQuery {
	levelOfDetail: string = undefined;
	numberOfRows: number = undefined;
	startAt: number = undefined;
	fields: string[] = undefined;
	sortByFieldName: string[] = undefined;
	sortOrder: string[] = undefined;
}

/**
 * Query parameters for listPricingAdminFscPctLookup
 */
export class ListPricingAdminFscPctLookupQuery {
	levelOfDetail: string = undefined;
	numberOfRows: number = undefined;
	startAt: number = undefined;
	fields: string[] = undefined;
	sortByFieldName: string[] = undefined;
	sortOrder: string[] = undefined;
}

/**
 * Query parameters for listPricingAgreementMonitors
 */
export class ListPricingAgreementMonitorsQuery {
	levelOfDetail: string = undefined;
	numberOfRows: number = undefined;
	startAt: number = undefined;
	fields: string[] = undefined;
	sortByFieldName: string[] = undefined;
	sortOrder: string[] = undefined;
	startYearMonth: string = undefined;
	endYearMonth: string = undefined;
	pricingTypeCd: string = undefined;
	leadAcctId: string = undefined;
	agreementCustVersion: string = undefined;
	agreementSeqNbr: string = undefined;
	salesRegion: string = undefined;
	salesDistrict: string = undefined;
	salesOwnerEmployeeId: string = undefined;
	statusCd: string = undefined;
	typeCd: string = undefined;
}

/**
 * Query parameters for getPricingAgreementMonitorCounts
 */
export class GetPricingAgreementMonitorCountsQuery {
	levelOfDetail: string = undefined;
	ownerId: string = undefined;
}

/**
 * Query parameters for listPricingAgreementMonitorParameters
 */
export class ListPricingAgreementMonitorParametersQuery {
	levelOfDetail: string = undefined;
	numberOfRows: number = undefined;
	startAt: number = undefined;
	fields: string[] = undefined;
	sortByFieldName: string[] = undefined;
	sortOrder: string[] = undefined;
}

/**
 * Query parameters for getPricingAgreementMonitorRegionRollupReport
 */
export class GetPricingAgreementMonitorRegionRollupReportQuery {
	yearMonth: string = undefined;
	statusCd: string = undefined;
}

/**
 * Query parameters for listPricingAgreementMonitorValidActions
 */
export class ListPricingAgreementMonitorValidActionsQuery {
	actionSource: string = undefined;
	levelOfDetail: string = undefined;
	numberOfRows: number = undefined;
	startAt: number = undefined;
	fields: string[] = undefined;
	sortByFieldName: string[] = undefined;
	sortOrder: string[] = undefined;
}

/**
 * Query parameters for listPraAgreementMonitorRunDate
 */
export class ListPraAgreementMonitorRunDateQuery {
	levelOfDetail: string = undefined;
	numberOfRows: number = undefined;
	startAt: number = undefined;
	fields: string[] = undefined;
	sortByFieldName: string[] = undefined;
	sortOrder: string[] = undefined;
}

/**
 * Query parameters for listPricingAgreementAudits
 */
export class ListPricingAgreementAuditsQuery {
	employeeId: string = undefined;
	levelOfDetail: string = undefined;
	numberOfRows: number = undefined;
	startAt: number = undefined;
	fields: string[] = undefined;
	sortByFieldName: string[] = undefined;
	sortOrder: string[] = undefined;
	fromDate: string = undefined;
	toDate: string = undefined;
	custAcctId: string = undefined;
	auditStatus: string = undefined;
	salesRfpId: string = undefined;
}

/**
 * Query parameters for listPricingAgreementRecentAudits
 */
export class ListPricingAgreementRecentAuditsQuery {
	levelOfDetail: string = undefined;
	numberOfRows: number = undefined;
	startAt: number = undefined;
	fields: string[] = undefined;
	sortByFieldName: string[] = undefined;
	sortOrder: string[] = undefined;
}

/**
 * Query parameters for listPricingAgreementNextRevisionAudits
 */
export class ListPricingAgreementNextRevisionAuditsQuery {
	rfpReqDate: string = undefined;
	period: number = undefined;
	levelOfDetail: string = undefined;
	numberOfRows: number = undefined;
	startAt: number = undefined;
	fields: string[] = undefined;
	sortByFieldName: string[] = undefined;
	sortOrder: string[] = undefined;
}

/**
 * Query parameters for updatePricingAgreementAuditHeader
 */
export class UpdatePricingAgreementAuditHeaderQuery {
	updateType: string = undefined;
}

/**
 * Query parameters for updatePricingAgreementAuditIssue
 */
export class UpdatePricingAgreementAuditIssueQuery {
	agreementAuditEventLogId: string = undefined;
}

/**
 * Query parameters for createPricingAgreementAuditDocument
 */
export class CreatePricingAgreementAuditDocumentQuery {
	agreementAuditCommentId: string = undefined;
	agreementAuditIssueId: string = undefined;
}

/**
 * Query parameters for getPricingAgreementAuditDocument
 */
export class GetPricingAgreementAuditDocumentQuery {
	agreementAuditCommentId: string = undefined;
	agreementAuditIssueId: string = undefined;
}

/**
 * Query parameters for listPricingAgreementAuditParameters
 */
export class ListPricingAgreementAuditParametersQuery {
	startEffDate: string = undefined;
	endEffDate: string = undefined;
	levelOfDetail: string = undefined;
	numberOfRows: number = undefined;
	startAt: number = undefined;
	fields: string[] = undefined;
	sortByFieldName: string[] = undefined;
	sortOrder: string[] = undefined;
}

/**
 * Query parameters for listPrcNavigationBarCounts
 */
export class ListPrcNavigationBarCountsQuery {
	ownerId: string = undefined;
	levelOfDetail: string = undefined;
	numberOfRows: number = undefined;
	startAt: number = undefined;
	fields: string[] = undefined;
	sortByFieldName: string[] = undefined;
	sortOrder: string[] = undefined;
}

/**
 * Query parameters for listPricingAgreementDocHdrs
 */
export class ListPricingAgreementDocHdrsQuery {
	custAcctId: number = undefined;
	custAcctCd: string = undefined;
	pricingAgreementId: string = undefined;
	levelOfDetail: string = undefined;
	numberOfRows: number = undefined;
	startAt: number = undefined;
	fields: string[] = undefined;
	sortByFieldName: string[] = undefined;
	sortOrder: string[] = undefined;
}

/**
 * Query parameters for getPricingAgreementDocument
 */
export class GetPricingAgreementDocumentQuery {
	docTypeId: number = undefined;
	oracleDocId: number = undefined;
}

/**
 * Query parameters for getPricingAgreementDocuments
 */
export class GetPricingAgreementDocumentsQuery {
	docTypeId: number[] = undefined;
}

/**
 * Query parameters for searchPricingAgreementDocCustomer
 */
export class SearchPricingAgreementDocCustomerQuery {
	custAcctId: number = undefined;
	levelOfDetail: string = undefined;
	numberOfRows: number = undefined;
	startAt: number = undefined;
	fields: string[] = undefined;
	sortByFieldName: string[] = undefined;
	sortOrder: string[] = undefined;
}

/**
 * Query parameters for listPricingCustomerHierarchy
 */
export class ListPricingCustomerHierarchyQuery {
	custAcctId: number = undefined;
	custAcctCd: string = undefined;
	levelOfDetail: string = undefined;
	numberOfRows: number = undefined;
	startAt: number = undefined;
	fields: string[] = undefined;
	sortByFieldName: string[] = undefined;
	sortOrder: string[] = undefined;
}


/**
 * Path parameters for getSalesRfp
 */
export class GetSalesRfpPath {
	salesRfpId: string = undefined;
}

/**
 * Path parameters for validateSalesRfp
 */
export class ValidateSalesRfpPath {
	salesRfpId: string = undefined;
}

/**
 * Path parameters for getSalesRfpAuditHeader
 */
export class GetSalesRfpAuditHeaderPath {
	salesRfpId: string = undefined;
}

/**
 * Path parameters for listSalesRfpAccts
 */
export class ListSalesRfpAcctsPath {
	salesRfpId: string = undefined;
}

/**
 * Path parameters for listSalesRfpAccessorials
 */
export class ListSalesRfpAccessorialsPath {
	salesRfpId: string = undefined;
}

/**
 * Path parameters for listSalesRfpAuditLogs
 */
export class ListSalesRfpAuditLogsPath {
	salesRfpId: string = undefined;
}

/**
 * Path parameters for deleteSalesRfpSupportingDocs
 */
export class DeleteSalesRfpSupportingDocsPath {
	salesRfpId: string = undefined;
	rfpSupportingDocId: string = undefined;
}

/**
 * Path parameters for getSalesRfpSupportingDoc
 */
export class GetSalesRfpSupportingDocPath {
	salesRfpId: string = undefined;
	rfpSupportingDocId: string = undefined;
}

/**
 * Path parameters for listSalesRfpSupportingDocs
 */
export class ListSalesRfpSupportingDocsPath {
	salesRfpId: string = undefined;
}

/**
 * Path parameters for getUnmappedSupportingDocument
 */
export class GetUnmappedSupportingDocumentPath {
	salesRfpId: string = undefined;
	rfpSupportingDocId: string = undefined;
}

/**
 * Path parameters for getSalesRfpSupportingDocFile
 */
export class GetSalesRfpSupportingDocFilePath {
	salesRfpId: string = undefined;
	rfpSupportingDocId: string = undefined;
}

/**
 * Path parameters for getSalesRfpSupportingDocsFile
 */
export class GetSalesRfpSupportingDocsFilePath {
	salesRfpId: string = undefined;
}

/**
 * Path parameters for listSalesRfpComments
 */
export class ListSalesRfpCommentsPath {
	salesRfpId: string = undefined;
}

/**
 * Path parameters for deleteSalesRfpComments
 */
export class DeleteSalesRfpCommentsPath {
	salesRfpId: string = undefined;
}

/**
 * Path parameters for listSalesRfpObiPlans
 */
export class ListSalesRfpObiPlansPath {
	salesRfpId: string = undefined;
}

/**
 * Path parameters for deleteSalesRfpObiPlans
 */
export class DeleteSalesRfpObiPlansPath {
	salesRfpId: string = undefined;
}

/**
 * Path parameters for listSalesRfpGeoAreaPricingLanes
 */
export class ListSalesRfpGeoAreaPricingLanesPath {
	salesRfpId: string = undefined;
}

/**
 * Path parameters for deleteSalesRfpGeoAreaPricingLanes
 */
export class DeleteSalesRfpGeoAreaPricingLanesPath {
	salesRfpId: string = undefined;
	geoAreaPrcLaneId: string = undefined;
}

/**
 * Path parameters for listSalesRfpCommodities
 */
export class ListSalesRfpCommoditiesPath {
	salesRfpId: string = undefined;
}

/**
 * Path parameters for getSalesRfpAgrmntPerformance
 */
export class GetSalesRfpAgrmntPerformancePath {
	salesRfpId: string = undefined;
}

/**
 * Path parameters for deleteSalesRfpAgrmntPerformance
 */
export class DeleteSalesRfpAgrmntPerformancePath {
	salesRfpId: string = undefined;
}

/**
 * Path parameters for listSalesRfpLadingDimensions
 */
export class ListSalesRfpLadingDimensionsPath {
	salesRfpId: string = undefined;
}

/**
 * Path parameters for getSalesRfpContactDetails
 */
export class GetSalesRfpContactDetailsPath {
	salesRfpId: string = undefined;
}

/**
 * Path parameters for listSalesRfpConstraints
 */
export class ListSalesRfpConstraintsPath {
	salesRfpId: string = undefined;
}

/**
 * Path parameters for deleteSalesRfpConstraint
 */
export class DeleteSalesRfpConstraintPath {
	salesRfpId: string = undefined;
	rfpConstraintId: string = undefined;
}

/**
 * Path parameters for deleteSalesRfpCustomerShipments
 */
export class DeleteSalesRfpCustomerShipmentsPath {
	salesRfpId: string = undefined;
}

/**
 * Path parameters for listSalesRfpCustomerShipments
 */
export class ListSalesRfpCustomerShipmentsPath {
	salesRfpId: string = undefined;
}

/**
 * Path parameters for buildInputHierarchyShipmentFile
 */
export class BuildInputHierarchyShipmentFilePath {
	salesRfpId: string = undefined;
}

/**
 * Path parameters for getCrmAccount
 */
export class GetCrmAccountPath {
	accountId: string = undefined;
}

/**
 * Path parameters for getPricingProposalHeader
 */
export class GetPricingProposalHeaderPath {
	prcProposalInstId: string = undefined;
}

/**
 * Path parameters for getPricingProposal
 */
export class GetPricingProposalPath {
	prcProposalInstId: string = undefined;
}

/**
 * Path parameters for listPricingAnlysGeoGroupHeaders
 */
export class ListPricingAnlysGeoGroupHeadersPath {
	prcProposalInstId: string = undefined;
}

/**
 * Path parameters for deletePricingAnlysGeoGroupHeader
 */
export class DeletePricingAnlysGeoGroupHeaderPath {
	prcProposalInstId: string = undefined;
	prcAnalysGeoGroupId: string = undefined;
}

/**
 * Path parameters for deleteGeoPricingGroupLanes
 */
export class DeleteGeoPricingGroupLanesPath {
	geoPricingGroupLaneId: string = undefined;
}

/**
 * Path parameters for deleteGeoPricingGroup
 */
export class DeleteGeoPricingGroupPath {
	geoPricingGroupId: string = undefined;
}

/**
 * Path parameters for listPricingAnalysisWorkflowProgress
 */
export class ListPricingAnalysisWorkflowProgressPath {
	prcProposalInstId: string = undefined;
}

/**
 * Path parameters for getPricingExtractWorkflowStep
 */
export class GetPricingExtractWorkflowStepPath {
	prcExtractWorkflowId: string = undefined;
}

/**
 * Path parameters for deletePricingAnalysisWorkFlow
 */
export class DeletePricingAnalysisWorkFlowPath {
	prcProposalInstId: string = undefined;
}

/**
 * Path parameters for getPricingAnalystDetails
 */
export class GetPricingAnalystDetailsPath {
	pricingAnalystEmplId: string = undefined;
}

/**
 * Path parameters for listPricingAnalysisShipments
 */
export class ListPricingAnalysisShipmentsPath {
	prcProposalInstId: string = undefined;
}

/**
 * Path parameters for listPricingLeafShipments
 */
export class ListPricingLeafShipmentsPath {
	prcProposalInstId: string = undefined;
}

/**
 * Path parameters for deletePricingAnalysisShipments
 */
export class DeletePricingAnalysisShipmentsPath {
	prcProposalInstId: string = undefined;
}

/**
 * Path parameters for listPricingAnlysGeoGroupBIAmcs
 */
export class ListPricingAnlysGeoGroupBIAmcsPath {
	prcProposalInstId: string = undefined;
	prcAnalysGeoGroupId: string = undefined;
}

/**
 * Path parameters for deletePricingAnlysGeoGroupBIAmcs
 */
export class DeletePricingAnlysGeoGroupBIAmcsPath {
	prcProposalInstId: string = undefined;
	prcAnalysGeoGroupId: string = undefined;
}

/**
 * Path parameters for listGeoPricingDiscountAmcSummary
 */
export class ListGeoPricingDiscountAmcSummaryPath {
	prcProposalInstId: string = undefined;
}

/**
 * Path parameters for deleteGeoPricingDiscountAmcSummary
 */
export class DeleteGeoPricingDiscountAmcSummaryPath {
	prcProposalInstId: string = undefined;
}

/**
 * Path parameters for listPricingAnlysGeoGroupBiTariffs
 */
export class ListPricingAnlysGeoGroupBiTariffsPath {
	prcProposalInstId: string = undefined;
	prcAnalysGeoGroupId: string = undefined;
}

/**
 * Path parameters for deletePricingAnlysGeoGroupBiTariff
 */
export class DeletePricingAnlysGeoGroupBiTariffPath {
	prcProposalInstId: string = undefined;
	prcAnalysGeoGroupId: string = undefined;
}

/**
 * Path parameters for listPricingAnlysGeoGroupCmTariffs
 */
export class ListPricingAnlysGeoGroupCmTariffsPath {
	prcProposalInstId: string = undefined;
	prcAnalysGeoGroupId: string = undefined;
}

/**
 * Path parameters for deletePricingAnlysGeoGroupCmTariff
 */
export class DeletePricingAnlysGeoGroupCmTariffPath {
	prcProposalInstId: string = undefined;
	prcAnalysGeoGroupId: string = undefined;
}

/**
 * Path parameters for listPricingAnlysGeoGroupCmAmcs
 */
export class ListPricingAnlysGeoGroupCmAmcsPath {
	prcProposalInstId: string = undefined;
	prcAnalysGeoGroupId: string = undefined;
}

/**
 * Path parameters for deletePricingAnlysGeoGroupCmAmcs
 */
export class DeletePricingAnlysGeoGroupCmAmcsPath {
	prcProposalInstId: string = undefined;
	prcAnalysGeoGroupId: string = undefined;
}

/**
 * Path parameters for listPricingProposalAccessorials
 */
export class ListPricingProposalAccessorialsPath {
	prcProposalInstId: string = undefined;
}

/**
 * Path parameters for deletePricingProposalAccessorials
 */
export class DeletePricingProposalAccessorialsPath {
	prcProposalInstId: string = undefined;
	prcProposalAccsrlId: string = undefined;
}

/**
 * Path parameters for listPricingProposalGeoPricingLanes
 */
export class ListPricingProposalGeoPricingLanesPath {
	prcProposalInstId: string = undefined;
}

/**
 * Path parameters for deletePricingProposalGeoPricingLanes
 */
export class DeletePricingProposalGeoPricingLanesPath {
	prcProposalInstId: string = undefined;
}

/**
 * Path parameters for listGeoPricingLaneAmcs
 */
export class ListGeoPricingLaneAmcsPath {
	prcProposalInstId: string = undefined;
	geoPricingLaneId: string = undefined;
}

/**
 * Path parameters for deleteGeoPricingLaneAmcs
 */
export class DeleteGeoPricingLaneAmcsPath {
	prcProposalInstId: string = undefined;
	geoPricingLaneId: string = undefined;
}

/**
 * Path parameters for listGeoPricingLaneDiscounts
 */
export class ListGeoPricingLaneDiscountsPath {
	prcProposalInstId: string = undefined;
	geoPricingLaneId: string = undefined;
}

/**
 * Path parameters for deleteGeoPricingLaneDiscounts
 */
export class DeleteGeoPricingLaneDiscountsPath {
	prcProposalInstId: string = undefined;
	geoPricingLaneId: string = undefined;
}

/**
 * Path parameters for listPricingAnalysisShipmentAccessorials
 */
export class ListPricingAnalysisShipmentAccessorialsPath {
	prcProposalInstId: string = undefined;
	prcAnlysShipmentId: string = undefined;
}

/**
 * Path parameters for deletePricingAnalysisShipmentAccessorials
 */
export class DeletePricingAnalysisShipmentAccessorialsPath {
	prcProposalInstId: string = undefined;
	prcAnlysShipmentId: string = undefined;
}

/**
 * Path parameters for deletePricingAnalystAssignment
 */
export class DeletePricingAnalystAssignmentPath {
	analystAssignmentId: string = undefined;
}

/**
 * Path parameters for listPricingAnlysGeoGroupDiscAmcs
 */
export class ListPricingAnlysGeoGroupDiscAmcsPath {
	prcProposalInstId: string = undefined;
	prcAnalysGeoGroupId: string = undefined;
}

/**
 * Path parameters for deletePricingAnlysGeoGroupDiscAmcs
 */
export class DeletePricingAnlysGeoGroupDiscAmcsPath {
	prcProposalInstId: string = undefined;
	prcAnalysGeoGroupId: string = undefined;
}

/**
 * Path parameters for listPricingProposalCustHierarchy
 */
export class ListPricingProposalCustHierarchyPath {
	prcProposalInstId: string = undefined;
}

/**
 * Path parameters for deletePricingProposalCustHierarchy
 */
export class DeletePricingProposalCustHierarchyPath {
	prcProposalInstId: string = undefined;
	prcProposalCustHierarchyId: string = undefined;
}

/**
 * Path parameters for calculatePricingGroupShipmentAmc
 */
export class CalculatePricingGroupShipmentAmcPath {
	prcProposalInstId: string = undefined;
}

/**
 * Path parameters for getGeoPricingLaneFak
 */
export class GetGeoPricingLaneFakPath {
	prcProposalInstId: string = undefined;
	geoPrcLaneId: string = undefined;
}

/**
 * Path parameters for deleteGeoPricingLaneFaks
 */
export class DeleteGeoPricingLaneFaksPath {
	prcProposalInstId: string = undefined;
	geoPrcLaneId: string = undefined;
}

/**
 * Path parameters for deleteGeoPricingLaneFakOverrides
 */
export class DeleteGeoPricingLaneFakOverridesPath {
	prcProposalInstId: string = undefined;
	geoPrcLaneId: string = undefined;
	prcFakHdrId: string = undefined;
}

/**
 * Path parameters for listPricingProposalRulesetMatrix
 */
export class ListPricingProposalRulesetMatrixPath {
	prcProposalInstId: string = undefined;
}

/**
 * Path parameters for deletePricingProposalRulesetMatrix
 */
export class DeletePricingProposalRulesetMatrixPath {
	prcProposalInstId: string = undefined;
}

/**
 * Path parameters for listPricingProposalTmsFile
 */
export class ListPricingProposalTmsFilePath {
	prcProposalInstId: string = undefined;
}

/**
 * Path parameters for deletePricingProposalTmsFile
 */
export class DeletePricingProposalTmsFilePath {
	prcProposalInstId: string = undefined;
}

/**
 * Path parameters for listAggregateModelConstraintTemplates
 */
export class ListAggregateModelConstraintTemplatesPath {
	prcProposalInstId: string = undefined;
	aggrModelTemplateId: string = undefined;
}

/**
 * Path parameters for listPricingProposalAggrModelGeoStagings
 */
export class ListPricingProposalAggrModelGeoStagingsPath {
	prcProposalInstId: string = undefined;
}

/**
 * Path parameters for listGeoPricingGroupsForProposal
 */
export class ListGeoPricingGroupsForProposalPath {
	prcProposalInstId: string = undefined;
}

/**
 * Path parameters for listGeoPricingGroupLanesForProposal
 */
export class ListGeoPricingGroupLanesForProposalPath {
	prcProposalInstId: string = undefined;
}

/**
 * Path parameters for listGeoPricingGroupMembersForProposal
 */
export class ListGeoPricingGroupMembersForProposalPath {
	prcProposalInstId: string = undefined;
}

/**
 * Path parameters for listGeoPricingGroupExclMembersForProposal
 */
export class ListGeoPricingGroupExclMembersForProposalPath {
	prcProposalInstId: string = undefined;
}

/**
 * Path parameters for listPricingProposalAmcDiscounts
 */
export class ListPricingProposalAmcDiscountsPath {
	prcProposalInstId: string = undefined;
}

/**
 * Path parameters for deletePricingCode
 */
export class DeletePricingCodePath {
	pricingCodeId: string = undefined;
}

/**
 * Path parameters for listSalesRfpAcctsRuleset
 */
export class ListSalesRfpAcctsRulesetPath {
	salesRfpId: string = undefined;
}

/**
 * Path parameters for deleteSalesRfpAcctsRuleset
 */
export class DeleteSalesRfpAcctsRulesetPath {
	salesRfpId: string = undefined;
}

/**
 * Path parameters for listPricingAgreementExpectations
 */
export class ListPricingAgreementExpectationsPath {
	agreementId: string = undefined;
}

/**
 * Path parameters for getPricingAgreementExpectation
 */
export class GetPricingAgreementExpectationPath {
	agreementId: string = undefined;
	agreementExpectationId: string = undefined;
}

/**
 * Path parameters for listPricingAgreementShipmentSumm
 */
export class ListPricingAgreementShipmentSummPath {
	agreementId: string = undefined;
}

/**
 * Path parameters for getSalesRfpReportFile
 */
export class GetSalesRfpReportFilePath {
	salesRfpId: string = undefined;
}

/**
 * Path parameters for deletePricingAdminProposedStrategy
 */
export class DeletePricingAdminProposedStrategyPath {
	adminProposedStrategyPctId: string = undefined;
}

/**
 * Path parameters for deletePricingAdminProposedIncreaseLevel
 */
export class DeletePricingAdminProposedIncreaseLevelPath {
	adminProposedIncrLevelId: string = undefined;
}

/**
 * Path parameters for deletePricingAdminFakAdjustPct
 */
export class DeletePricingAdminFakAdjustPctPath {
	adminFakAdjustPctId: string = undefined;
}

/**
 * Path parameters for deletePricingAdminFscPctLookup
 */
export class DeletePricingAdminFscPctLookupPath {
	adminFscPctLookupId: string = undefined;
}

/**
 * Path parameters for getUserPreference
 */
export class GetUserPreferencePath {
	employeeId: string = undefined;
	appKey: string = undefined;
}

/**
 * Path parameters for getPricingAgreementMonitor
 */
export class GetPricingAgreementMonitorPath {
	pricingAgreementMonitorId: string = undefined;
}

/**
 * Path parameters for getPricingAgreementMonitorParameter
 */
export class GetPricingAgreementMonitorParameterPath {
	agreementMonitorParameterId: string = undefined;
}

/**
 * Path parameters for deletePricingAgreementMonitorParameter
 */
export class DeletePricingAgreementMonitorParameterPath {
	agreementMonitorParameterId: string = undefined;
}

/**
 * Path parameters for getPricingAgreementMonitorRegionRollupReport
 */
export class GetPricingAgreementMonitorRegionRollupReportPath {
	requesterId: string = undefined;
}

/**
 * Path parameters for listPricingAgreementMonitorValidActions
 */
export class ListPricingAgreementMonitorValidActionsPath {
	agreementMonitorId: string = undefined;
}

/**
 * Path parameters for listPricingAgreementRecentAudits
 */
export class ListPricingAgreementRecentAuditsPath {
	loginUserId: string = undefined;
}

/**
 * Path parameters for listPricingAgreementNextRevisionAudits
 */
export class ListPricingAgreementNextRevisionAuditsPath {
	leadAcctId: number = undefined;
}

/**
 * Path parameters for getPricingAgreementAuditDetail
 */
export class GetPricingAgreementAuditDetailPath {
	agreementAuditHdrId: string = undefined;
}

/**
 * Path parameters for deletePricingAgreementAuditIssue
 */
export class DeletePricingAgreementAuditIssuePath {
	agreementAuditHdrId: string = undefined;
	agreementAuditIssueId: string = undefined;
}

/**
 * Path parameters for deletePricingAgreementAuditEventLog
 */
export class DeletePricingAgreementAuditEventLogPath {
	agreementAuditEventLogId: string = undefined;
}

/**
 * Path parameters for getPricingAgreementAuditDocument
 */
export class GetPricingAgreementAuditDocumentPath {
	agreementAuditHdrId: string = undefined;
}

/**
 * Path parameters for deletePricingAgreementAuditParameter
 */
export class DeletePricingAgreementAuditParameterPath {
	agreementAuditParameterId: string = undefined;
}

/**
 * Path parameters for getPricingAgreementAuditParameter
 */
export class GetPricingAgreementAuditParameterPath {
	agreementAuditParameterId: string = undefined;
}

/**
 * Path parameters for getPricingAgreementDocument
 */
export class GetPricingAgreementDocumentPath {
	agreementDocHdrId: number = undefined;
}

/**
 * Path parameters for getPricingAgreementDocuments
 */
export class GetPricingAgreementDocumentsPath {
	agreementDocHdrId: number = undefined;
}

/**
 * Path parameters for getPricingAgreementDocDetails
 */
export class GetPricingAgreementDocDetailsPath {
	agreementDocHdrId: number = undefined;
}

/**
 * Path parameters for deletePricingAgreementDocument
 */
export class DeletePricingAgreementDocumentPath {
	agreementDocHdrId: number = undefined;
	docTypeId: number = undefined;
}

/**
 * Path parameters for deletePricingAgreementDocHeader
 */
export class DeletePricingAgreementDocHeaderPath {
	agreementDocHdrId: number = undefined;
}

