/**
 * ShipmentOds API
 * Version: 1.0
 * Build: Manual
 */

import {
	ListInfo,
	AuditInfo,
	AccessorialUnitOfMeasureCd,
	ActionCd,
	AdvanceBeyondTypeCd,
	ArchiveControlCd,
	BillClassCd,
	BillStatusCd,
	BillToRelationCd,
	BolPartyTypeCd,
	BolUsageTypeCd,
	ChargeToCd,
	CommodityClassCd,
	CommodityPackageCd,
	ConsigneeUnloadedTrailerCd,
	CountryCd,
	CurrencyCd,
	CustomsControlTypeCd,
	CustomsPortCd,
	DebtorCd,
	DeliveryInfoRequiredCd,
	DeliveryQualifierCd,
	EventLogSubTypeCd,
	EventLogTypeCd,
	ExemptReasonCd,
	FoodPoisonCd,
	HazmatSourceCd,
	InvoiceCurrencyCd,
	LateTenderCd,
	LineItemChargeToCd,
	MatchedPartySourceCd,
	MatchedPartyStatusCd,
	MatchedPartyTypeCd,
	MiscLineItemCd,
	MiscLineItemPaymentMethodCd,
	MovementStatusCd,
	NetExplosiveMassUomCd,
	NotificationCd,
	ProStatusCd,
	RadioactivityUomCd,
	RatingCurrencyCd,
	RouteTypeCd,
	RuleFailureReasonCd,
	RuleOverrideReasonCd,
	RuleShmEntityTypeCd,
	ServiceCalculationStatusCd,
	ServiceStatusCd,
	ShipmentAcquiredTypeCd,
	ShipmentCreditStatusCd,
	ShipmentRemarkTypeCd,
	ShipmentSourceCd,
	ShipmentVolumeMethodCd,
	ShipmentVolumeTypeCd,
	ShipperLoadedTrailerCd,
	WarrantyStatusCd
} from '@xpo-ltl/sdk-common';

/**
 * Request message to retrieve Shipment details from ODS.
 */
export class GetOdsShipmentRqst {
	shipmentInstId:number = undefined;
	proNbr:string = undefined;
	pickupDate:string = undefined;
}
/**
 * Response to request message to retrieve Shipment details from ODS.
 */
export class GetOdsShipmentResp {
	shipment:Shipment = undefined;
	asMatchedParty:AsMatchedParty[] = undefined;
	commodity:Commodity[] = undefined;
	accessorialService:AccessorialService[] = undefined;
	suppRefNbr:SuppRefNbr[] = undefined;
	remark:Remark[] = undefined;
	advanceBeyondCarrier:AdvanceBeyondCarrier[] = undefined;
	customsBond:CustomsBond[] = undefined;
	miscLineItem:MiscLineItem[] = undefined;
	timeDateCritical:TimeDateCritical = undefined;
	parentShipmentId:OdsShipmentId = undefined;
	childShipmentIds:OdsShipmentId[] = undefined;
	siblingShipmentIds:OdsShipmentId[] = undefined;
	/**
	 * Status of the Pro from the Pro Frt Bill Index
	 */
	proFrtBillIndexStatCd:ProStatusCd = undefined;
	/**
	 * Indicates the pickup for this shipment was a Shipper Loadd and count.
	 */
	shipperLoadAndCountInd:boolean = undefined;
	xpoRevenueAmt:number = undefined;
	custPricingAgreementId:string = undefined;
	/**
	 * Indicates the presence of ops exceptions on the shipment. True=no exceptions; False=Exceptions present.
	 */
	noOpsExceptionInd:boolean = undefined;
}
/**
 * Request message to retrieve shipments based on multiple PRO numbers that are provided.
 */
export class ListOdsShipmentsRqst {
	/**
	 * Business identifier for the shipment.
	 */
	proNbrs:string[] = undefined;
	/**
	 * Specifies the number of months to use in retrieving shipments by age, based on Pickup Date. If a value is supplied, only shipments will be returned that have a Pickup date prior to current date - the number of months value in filterByPickupDateAgeInMonths.  For Example, if input filterByPickupDateAgeInDays is 9, only shipments that have a Pickup Date within 9months ago from current date are returned.
	 */
	filterByPickupDateAgeInMonths:number = undefined;
	/**
	 * Pagination information to retrieve the list.
	 */
	listInfo:ListInfo = undefined;
}
export class ListOdsShipmentsResp {
	odsShipments:OdsShipment[] = undefined;
	/**
	 * Pagination information to retrieve the list.
	 */
	listInfo:ListInfo = undefined;
}
/**
 * Request message to retrieve Shipment details from ODS.
 */
export class ListOdsShipmentHistTraceEventsRqst {
	odsShipmentId:OdsShipmentId = undefined;
	listInfo:ListInfo = undefined;
}
/**
 * Response to request message to retrieve Shipment details from ODS.
 */
export class ListOdsShipmentHistTraceEventsResp {
	shipmentTraceHistoryEvents:ShipmentHistTraceEvent[] = undefined;
	listInfo:ListInfo = undefined;
}
/**
 * Request message to retrieve parent shipments based on multiple shipment instance ids that are provided.
 */
export class ListParentShipmentsForOdsShipmentIdsRqst {
	/**
	 * Shipment instance ids for parent shipment lookup.
	 */
	shipmentInstIds:number[] = undefined;
	/**
	 * Pagination information to retrieve the list.
	 */
	listInfo:ListInfo = undefined;
}
export class ListParentShipmentsForOdsShipmentIdsResp {
	parentOdsShipments:Shipment[] = undefined;
	/**
	 * Pagination information to retrieve the list.
	 */
	listInfo:ListInfo = undefined;
}
/**
 * Request message to restore the archived PRO from RPT1 into DB2 shm shipment
 */
export class RestoreArchivedShipmentRqst {
	/**
	 * Business identifier to the shipment
	 */
	proNbr:string = undefined;
}
export class RestoreArchivedShipmentResp {
	/**
	 * Expanded Name : Instance identifier
	 * Desc : System generated unique identifier (as per CBD standard).
	 */
	shipmentInstId:number = undefined;
	/**
	 * Business identifier to the shipment
	 */
	proNbr:string = undefined;
	/**
	 * Date on which the shipment was picked up
	 */
	pickupDate:Date = undefined;
}
/**
 * Request message to retrieve archived shipments based on multiple PRO numbers that are provided.
 */
export class ListArchivedShipmentsRqst {
	/**
	 * Business identifier for the shipment.
	 */
	proNbr:string[] = undefined;
	/**
	 * Pagination information to retrieve the list.
	 */
	listInfo:ListInfo = undefined;
}
export class ListArchivedShipmentsResp {
	archivedShipments:ArchivedShipment[] = undefined;
	/**
	 * Pagination information to retrieve the list.
	 */
	listInfo:ListInfo = undefined;
}
/**
 * Request message to retrieve archived shipments based on multiple PRO numbers that are provided.
 */
export class CreateRestoreArchivedShipmentRequestRecordRqst {
	/**
	 * Business identifier for the shipment.
	 */
	archivedShipments:ArchivedShipment[] = undefined;
	/**
	 * Standard audit trail information.
	 */
	auditInfo:AuditInfo = undefined;
}
export class CreateRestoreArchivedShipmentRequestRecordResp {
	archivedShipments:ArchivedShipment[] = undefined;
}
export class ListShipmentsByCustomerAccountRqst {
	/**
	 * Expanded Name : CIS Customer Number
	 * Desc : Identifier of the appropriate customer in CIS, determined by the matching sub-routine
	 */
	cisCustNbr:number[] = undefined;
	listInfo:ListInfo = undefined;
}
export class ListShipmentsByCustomerAccountResp {
	custShipment:OdsShipmentPartyBasic[] = undefined;
	listInfo:ListInfo = undefined;
}
/**
 * Information about an archived shipment.
 */
export class ArchivedShipment {
	/**
	 * PRO number for the shipment.
	 */
	proNbr:string = undefined;
	/**
	 * The pickup date for the archived shipment.
	 */
	pickupDate:Date = undefined;
	/**
	 * A message that specifies the recall status of the shipment.
	 */
	recallStatus:string = undefined;
}
/**
 * Shipment from ODS.
 */
export class OdsShipment {
	/**
	 * Shipment details
	 */
	shipment:Shipment = undefined;
	/**
	 * Shipper Name
	 */
	shipperName:string = undefined;
	/**
	 * Consignee Name
	 */
	consigneeName:string = undefined;
	/**
	 * Final delivery date of the shipment
	 */
	finalDeliveryDate:string = undefined;
}
/**
 * Shipment Id in ODS.
 */
export class OdsShipmentId {
	shipmentInstId:number = undefined;
	proNbr:string = undefined;
	pickupDate:string = undefined;
}
/**
 * Shipment event and trace history from ODS.
 */
export class ShipmentHistTraceEvent {
	/**
	 * Expanded Name : Instance identifier
	 * Desc : System generated unique identifier (as per CBD standard).
	 */
	shipmentInstId:number = undefined;
	/**
	 * Date/Time the event occurred.
	 */
	occurredTmst:Date = undefined;
	/**
	 * Elapsed time between the previous and current events.
	 */
	elapsedTm:Date = undefined;
	/**
	 * Service Center where the event occurred.
	 */
	occurredSic:string = undefined;
	/**
	 * Event description.
	 */
	description:string = undefined;
	/**
	 * Origin SIC of the shipment.
	 */
	originSic:string = undefined;
	/**
	 * Destination SIC of the shipment.
	 */
	destinationSic:string = undefined;
	/**
	 * Load release number of the trailer the shipment is on during the event.
	 */
	loadReleaseNbr:string = undefined;
	/**
	 * The Destination SIC of the trailer the shipment was loaded on during the event.
	 */
	loadDestinationSic:string = undefined;
	/**
	 * The trailer number the shipment was loaded on during the event.
	 */
	trailerNbr:string = undefined;
	/**
	 * The weight of the trailer the shipment was loaded on during the event.
	 */
	trailerWeightLbs:number = undefined;
	/**
	 * The cube of the trailer the shipment was loaded on during the event.
	 */
	trailerCubePct:number = undefined;
	/**
	 * The bill count of shipments on the trailer the shipment was loaded on during the event.
	 */
	shipmentsOnTrailerCnt:number = undefined;
	/**
	 * User that made the change associated with the event.
	 */
	updtBy:string = undefined;
	/**
	 * Service center where the update associated with the event occurred.
	 */
	updtAtSic:string = undefined;
}
export class OdsShipmentPartyBasic {
	/**
	 * Expanded Name :  Type code
	 * Desc : Specifies the nature of relationship of the customer with respect to the Service Item in question. For Shipments, a Customer can be the shipper, consignee, bill-to, requestor or contact. 
	 * Permitted Values:
	 * 										1=Shipper address
	 * 										2=Consignee address 
	 * 										3=Inbound bill-to address 
	 * 										4=Outbound bill-to address
	 * 										5=Requestor address
	 * 										6=Contact address 
	 * Source : Internal
	 */
	typeCd:MatchedPartyTypeCd = undefined;
	/**
	 * Expanded Name : CIS Customer Number
	 * Desc : Identifier of the appropriate customer in CIS, determined by the matching sub-routine
	 */
	cisCustNbr:number = undefined;
	shipment:OdsShipmentId = undefined;
}
/**
 * 
 */
export class AccessorialService {
	/**
	 * 
	 */
	shipmentInstId:number = undefined;
	/**
	 * Expanded Name:  Sequence Number
	 * Desc : Sequential number assigned to uniquely identify  each extra service for the shipment.
	 * Source : Generated
	 * Design Considerations :
	 */
	sequenceNbr:string = undefined;
	/**
	 * Expanded Name : Accessorial  code
	 * Desc : Indicates the type of accessorial service requested or performed
	 */
	accessorialCd:string = undefined;
	/**
	 * Expanded Name : Tariff rate
	 * Desc : This is the rate used to calculate the accessorial charges for an accessorial service . The rate is multiplied by the unit of measure to calculate the total charges. 
	 * Source : MBF Variable Segment -  MBCRATE
	 */
	tariffsRate:number = undefined;
	/**
	 * Expanded Name :  Charge Amount
	 * Desc : The dollar amount of the charge for the accessorial service.
	 */
	amount:number = undefined;
	/**
	 * Expanded Name: Percent Prepaid
	 * Desc: Percenatge of the charge amount for the accessorial is to be paid by the shipper. The rest of it is paid by the consignee.
	 */
	prepaidPercentage:number = undefined;
	/**
	 * Expanded Name : Minimum charge indicator
	 * Desc : Indicates if the charges associated with the accessorial are the minimum charges for the extra service. 
	 * Source : MBF: root: MBMINCHG (x 10  of MBFLAG7)
	 * Valid Values :  Y/N
	 */
	minimumChargeInd:boolean = undefined;
	/**
	 * Expanded Name : Description
	 * Desc : A textual description of the service.
	 * Source : MBF  Variable Segment :
	 */
	description:string = undefined;
	/**
	 * 
	 */
	archiveControlCd:string = undefined;
	/**
	 * Indication whether to add, update or delete this entry. This will be used for merging lists.
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 */
	listActionCd:ActionCd = undefined;
	/**
	 * Specifies the units for the quantity for which the accessorial fee is charged.
	 * Valid Values
	 * 1- Labour Hour
	 * 2- 1/4 hour
	 * 3- 1/2 hour
	 * 4- CWT
	 * 5- CWT/Day
	 * 6- Check
	 * 7- Correction
	 * 8- Day
	 * 9- Document
	 * A- Package
	 * B- Shipment
	 * C- Vehicle
	 * D- Mile
	 * E- Pound
	 * ' ' - Not applicable
	 */
	accessorialUnitOfMeasure:AccessorialUnitOfMeasureCd = undefined;
	/**
	 * Specifies the quantity of units (in AC_UOM) for which the accessorial fee is charged.
	 */
	accessorialQuantity:number = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 */
	auditInfo:AuditInfo = undefined;
	/**
	 * 
	 */
	chrgToCd:LineItemChargeToCd = undefined;
}
/**
 * 
 */
export class AdvanceBeyondCarrier {
	/**
	 * 
	 */
	shipmentInstId:number = undefined;
	/**
	 * Expanded Name : Sequence number
	 * Desc : A system assigned number to uniquely identify each carrier for a shipment. There can be at most two other carriers for a shipment one advance and one beyond.
	 */
	sequenceNbr:string = undefined;
	/**
	 * Expanded Name : Type code 
	 * Desc : Identifies the type movement carried out by the carrier.
	 * Valid Values:
	 * 1 = Advance carrier
	 * 2 = Beyond carrier
	 */
	typeCd:AdvanceBeyondTypeCd = undefined;
	/**
	 * Expanded Name: Standard Carrier Abbreviation Code
	 * Desc : Other Carrier s identification code for Advance and Beyond movements for the shipment.
	 * This is the identifying code for the carrier from whom CTS picked up (or transfers to) the freight. If the code is unknown, NONE is entered by the bill entry personnel. 
	 * Source : MBF root  -  MBADSCAC
	 */
	carrierScacCd:string = undefined;
	/**
	 * Expanded Name : PRO number (in text format) 
	 * Desc : This is  the other carrier s PRO number that identifies the shipment for the carrier in their systems.
	 */
	carrierProNbr:string = undefined;
	/**
	 * Expanded Name: Carrier pick up date
	 * Desc: This is the date the carrier picked up the shipment. 
	 * In case of an advance carrier this is the date the carrier picked it up from the customer.
	 * 	In case of beyond carriers, it is the date the carrier picked up the shipment from CTS.
	 */
	carrierPickupDate:Date = undefined;
	/**
	 * Expanded Name : From terminal SIC
	 * Desc : SIC where the shipment originates for the carrrier.  
	 * For advance carriers it is the sic that services the shipper and for beyond carrier this is CTS service center that delivers teh PRO to the beyond carrier. 
	 * This is mostly stored for Beyond carriers.
	 * Source :
	 */
	fromTerminalSicCd:string = undefined;
	/**
	 * Expanded Name : To terminal SIC
	 * Desc : This is CTS SIC where an advance carrier dropped the shipment for further movement. For beyond carrier this is the SIC code that services the consignee. 
	 * Source :
	 */
	toTerminalSicCd:string = undefined;
	/**
	 * Expanded Name :  Charge Amount
	 * Desc : The dollar amount of the advance or beyond charge.
	 */
	chargeAmount:number = undefined;
	/**
	 * 
	 */
	archiveControlCd:string = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 */
	listActionCd:ActionCd = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * The name and address printed on the paper BOL might be different from how the address validation and customer matching might produce.   
 * This entity records the name and address for various billing parties that the bill entry person enters from the paper BOL. 
 * Note, after Biller submits the page, address validtation and customer matching might change the name/address that was entered from the paper BOL.
 * 
 * Billing parties include shipper, consignee, and bill-to.  
 * 
 * The record is not expected to be updated after it is inserted into the table.
 */
export class AsEnteredBolParty {
	/**
	 * Foreign key: Parent SHM_SHIPMENT. Unique surrogate key that identifies a shipment
	 */
	shipmentInstId:number = undefined;
	/**
	 * S - shipper, C- Consignee, B-Bill-to.   Although there could be two bill-to parties for both bill, on paper BOL only one bill-to is mentioned.
	 */
	partyTypeCd:BolPartyTypeCd = undefined;
	/**
	 * Name  line 1 as printed on the paper BOL
	 */
	name1:string = undefined;
	/**
	 * Name  line 2 as printed on the paper BOL
	 */
	name2:string = undefined;
	/**
	 * Address as printed on the paper BOL
	 */
	address:string = undefined;
	/**
	 * City  as printed on the paper BOL
	 */
	city:string = undefined;
	/**
	 * State Code  as printed on the paper BOL
	 */
	stateCd:string = undefined;
	/**
	 * US , CA, MX. Country as specified on paper BOL
	 */
	countryCd:string = undefined;
	/**
	 * Zip code as specified on paper BOL
	 */
	zip6:string = undefined;
	/**
	 * Zip Extension for US zip codes
	 */
	zip4RestUs:string = undefined;
	/**
	 * Only if available on paper BOL for the party
	 */
	phoneNbr:string = undefined;
	/**
	 * Y= A valid address according to the Postal Service. The postal service recognizes that the numbers are within the valid range for the street name.  
	 * N= Address is not certified by Postal authority.
	 */
	PostalCertifiedInd:boolean = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 */
	listActionCd:ActionCd = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * 
 */
export class AsMatchedParty {
	/**
	 * 
	 */
	shipmentInstId:number = undefined;
	/**
	 * Expanded Name : Sequence Number
	 * Desc : A system assigned sequential number to uniquely identify each As Entered Customer for a Shipment.
	 * There are always at least two customers for a shipment and atmost 6 customers for a shipment.
	 */
	sequenceNbr:string = undefined;
	/**
	 * Expanded Name :  Type code
	 * Desc : Specifies the nature of relationship of the customer with respect to the Service Item in question. For Shipments, a Customer can be the shipper, consignee, bill-to, requestor or contact. 
	 * Permitted Values:
	 * 										1=Shipper address
	 * 										2=Consignee address 
	 * 										3=Inbound bill-to address 
	 * 										4=Outbound bill-to address
	 * 										5=Requestor address
	 * 										6=Contact address 
	 * Source : Internal
	 */
	typeCd:MatchedPartyTypeCd = undefined;
	/**
	 * Expanded Name : Debtor indicator
	 * Desc : Specifies whether the customer is also responsible for paying some or all of the charges for a Service Item. Invoices, allocations (if any) are created only for debtors. 
	 * Valid Values : Y/N
	 */
	debtorInd:boolean = undefined;
	/**
	 * Expanded Name : Name Line 1 (text format)
	 * Desc : Line 1 of the customer name
	 * Source : MBF variable segments
	 * 													AA  Shipper segment
	 * 													AB  Consignee segment
	 * 													AC  Bill to segment
	 */
	name1:string = undefined;
	/**
	 * Expanded Name : Name Line 2 (text format)
	 * Desc : Second line (if any) of customer name
	 * Source: MBF variable segments
	 * 	AA  - Shipper segment
	 * 	AB  - Consignee segment
	 * 	AC  - Bill to segment
	 */
	name2:string = undefined;
	/**
	 * Expanded Line : Address 
	 * Desc : Street address of the customer location.
	 * Source: MBF variable segments
	 * 	AA  Shipper segment
	 * 	AB  Consignee segment
	 * 	AC  Bill to segment
	 */
	address:string = undefined;
	/**
	 * Expanded Name : City
	 * Desc : City where the customer is located
	 * Source: MBF variable segments
	 * 	AA  - Shipper segment
	 * 	AB  - Consignee segment
	 * 	AC  - Bill to segment
	 */
	city:string = undefined;
	/**
	 * Expanded Name : State (code)
	 * Desc : State where the customer is located
	 * Source: MBF variable segments
	 * 	AA  - Shipper segment
	 * 	AB  - Consignee segment
	 * 	AC  - Bill to segment
	 */
	stateCd:string = undefined;
	/**
	 * Expanded Name : Country Code
	 * Desc : Specifies the code of the country where the customer is located
	 * Source: MBF variable segments
	 * 	AA  - Shipper segment
	 * 	AB  - Consignee segment
	 * 	AC  - Bill to segment
	 */
	countryCd:string = undefined;
	/**
	 * Expanded Name : Zip (code) first 5 (characters) (6 characters for Canadian zips)
	 * Desc : First 5 characters of the zip code for a US address or first 6 characters of the Canadian zip code of the address where the customer is located. 
	 * Source: MBF variable segments
	 * 	AA  - Shipper segment
	 * 	AB  - Consignee segment
	 * 	AC  - Bill to segment
	 */
	zip6:string = undefined;
	/**
	 * Expanded Name : Zip (Code) last 4 (characters)
	 * Desc : Last 4 characters of the zip code for a US address
	 * Source: MBF variable segments
	 * 	AA  - Shipper segment
	 * 	AB  - Consignee segment
	 * 	AC  - Bill to segment
	 */
	zip4RestUs:string = undefined;
	/**
	 * Expanded Name : Matching Status
	 * Desc : Match status from CIS matching.
	 * Valid Values :
	 * The following values ARE ACTIVE for Forward Sync:
	 * Spaces - Initial value, matching activity has not yet occurred.  
	 * 	70  - Full Match 
	 * 	60  - Un-matched (valid name / address but does not exist in CIS)
	 * The following values ARE NOT ACTIVE for Forward Sync:
	 * 	15  - Possible matches found
	 * 	30  - Invalid address (Bad US postal address as per Code-1)
	 * 	50  - Blank bill2 - no bill-to was entered
	 * The following was added by C.Doucet 10/12/2001:
	 * 	CM  - CIS Customer Merge correction changed the CIS cust nbr value
	 */
	matchedStatusCd:MatchedPartyStatusCd = undefined;
	/**
	 * Expanded Name : Matcher s Initials 
	 * Desc : Contains the initials of the Review Clerk who performed matching on a pro, or forced the MAD code on a pro to a different MAD using a Pro Override.  
	 * Source : Extended Master Bill File. These initials are the same as those stored on the EMB (JABCOB).
	 */
	matchedInitials:string = undefined;
	/**
	 * Expanded Name : CIS Customer Number
	 * Desc : Identifier of the appropriate customer in CIS, determined by the matching sub-routine
	 */
	cisCustNbr:number = undefined;
	/**
	 * Expanded Name : As-Matched MAD code
	 * Desc : Customer Account code.  This is the MAD code that came from CIS at the time of Customer Matching.
	 * Source : Customer Information System
	 */
	asMatchedMadCd:string = undefined;
	/**
	 * Expanded Name : Direct code
	 * Desc : Used to group customer oriented service reports and manifests for direct customer access via the online system.
	 * This is a de-normalisation as direct code is available only for CIS customers and it should be picked up from appropriate CIS table. 
	 * Source : MBF: root: MBSCUST
	 */
	dirCd:string = undefined;
	/**
	 * Expanded Name : Credit Status Code
	 * Desc : Specifies the credit rating / status for a Customer, if not set up in CIS. If the customer exists in CIS, it is copied here for performance reasons.
	 * Source : MBF: root: MBONOST (Out-bound)
	 * 														MBF: root: MBINOST (In-bound)
	 * Valid Values : 
	 * B=Bad debt/MBOBAD
	 * C=Credit/MBOCRED
	 * N=Non-credit/MBONOCRD
	 * P=PCO Control/MBOPCO
	 * V=Voluntary/MBOVOLON
	 * $=Cash only/MBOCASH
	 */
	creditedStatusCd:ShipmentCreditStatusCd = undefined;
	/**
	 * Expanded Name:  Bill to Relationship Code
	 * Desc: Indicates who the Bill to customer is related to (Shipper or Consignee) in the CIS system. This field applies only to BILL TO type of customers. 
	 * Valid Values:
	 * 				- Not Applicable
	 * S - Bill To is related to Shipper
	 * C - Bill To is related to Consignee
	 * B - Bill To is related to Both
	 * N - Bill To is related to None
	 */
	billToRelationshipCd:BillToRelationCd = undefined;
	/**
	 * Expanded Name : Use as entered indicator
	 * Desc : 
	 * Source : E-commerce
	 * Valid Values : Y/N
	 */
	useAsEntrdInd:boolean = undefined;
	/**
	 * Expanded Name : All shipments (must be) prepaid indicator
	 * Desc : Specifies whether all shipments from the customer have to be pre-paid
	 * Source : MBF: root: MBR4PPDA (x 02  of MBRGFLG4)
	 * Valid Values : Y/N
	 */
	allShipmentPrepaidInd:boolean = undefined;
	/**
	 * Expanded Name :   Proof Of Delivery  required indicator
	 * Desc : Indicates whether the customer (usually shipper and / or consignee) requires a Proof Of Delivery.
	 * Source : MBF: root: MBSPD (x 80  of MBFLAGD)
	 * Valid Values : Y/N
	 */
	proofOfDeliveryRequiredInd:boolean = undefined;
	/**
	 * Expanded Name :  Proof Of Delivery  image indicator
	 * Desc : Specifies that an image of the delivery receipt will suffice, instead of an original 
	 * Source : MBF: root: MBSMG (x 40  of MBFLAGD)
	 * Valid values : Y/N
	 */
	proofOfDeliveryImageInd:boolean = undefined;
	/**
	 * Expanded Name : Broker customer key number
	 * Desc : For international shipments, identifies the CIS customer number of any broker for the shipper or consignee. 
	 * Source : MBF: root: MBBRKKEY
	 */
	brokerCustKeyNbr:number = undefined;
	/**
	 * Expanded Name :  Customer E-mail Id
	 * Desc : E-mail address of the Customer on a bill, used as supplemental information in case that customer needs to be contacted via e-mail.
	 */
	eMailId:string = undefined;
	/**
	 * Expanded Name : Phone Country Code 
	 * Desc : Country code for the phone number of the customer (if any) for a Shipment. 
	 * Source : E-commerce
	 */
	phoneCountryCdNbr:string = undefined;
	/**
	 * Expanded Name :  Phone Area Code 
	 * Desc : Area Code for phone number of the customer (if any) for a Shipment. 
	 * Source : E-commerce
	 */
	phoneAreaCdNbr:string = undefined;
	/**
	 * Expanded Name : Customer Phone 
	 * Desc : Phone number of the customer (if any) for a Shipment. 
	 * Source : E-commerce
	 */
	phoneNbr:string = undefined;
	/**
	 * Expanded Name : Customer Phone Extension Number
	 * Desc : Phone Extension of the customer (if any) for a Shipment. 
	 * Source : E-commerce
	 */
	phoneExtensionNbr:string = undefined;
	/**
	 * Expanded Name : Last Match Timestamp
	 * Desc : Identifies the last time Customer Matching was performed against this particular customer for this Service Item.
	 * Source : MBF root: MBTMDTE
	 */
	lastMatchedDateTime:Date = undefined;
	/**
	 * 
	 */
	archiveControlCd:string = undefined;
	/**
	 * Expanded Name: Preferred Payment Currency Code
	 * Desc: The customer prefers to make payments in this currecny.
	 * Valid Values:
	 * USD - US Dollars
	 * CDN - Canadian Dollars
	 */
	preferredPaymentCurrencyCd:CurrencyCd = undefined;
	/**
	 * Expanded Name: Alternate Customer Number
	 * Desc: Contains the customer key to an uncoded customer in the customer component.  These are pickup and delivery locations that have been a part of at least one shipment in the Con-way system.  (Note that they are not currently stored in the P  table in the customer component.) They do not have an assigned MAD code (uncoded) and are therefore identified and matched separately so that we know when we service the same physical location.
	 * OR
	 * Contains the non-debtor P location where there were multiple full matches (89% and higher) at the same perentage.  (Changed meaning of attribute 3/1/2010)
	 * Added 2/2/2010 Cathy Doucet (for Hand Held Scorecard project)
	 */
	alternateCustNbr:number = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 */
	listActionCd:ActionCd = undefined;
	/**
	 * Identifies whether the system full matched the party, or if the Biller chose from a list of possibles. 
	 * Valid Values 
	 * S=System Matched
	 * U=User Selected
	 * F=User Selected from Search
	 * N=Not matched
	 */
	matchedSourceCd:MatchedPartySourceCd = undefined;
	/**
	 * Identifies if the invoice address should be read from the PICKUP_DELIVERY_LOCATION(self) versus from an associated Bill To.
	 */
	selfInvoiceInd:boolean = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * Typically there will be one row per shipment. This will be inserted when biller submits the form.
 * However, iIF the user clicks the Save button and then at later time resumes entry of the bill for that PRO and submits then there would be two rows. 
 * So a row is recorded for a PRO every time user either saves or submits the page.
 * The record is not expected to be updated after it is inserted into the table.
 */
export class BillEntryStats {
	/**
	 * Foreign key: Parent SHM_SHIPMENT. Unique surrogate key that identifies a shipment
	 */
	shipmentInstId:number = undefined;
	/**
	 * User that entered the bill. 
	 * Note, if bill entry clerk enters the bill and save it and then Later supervisor updates missing information and submits the bill, then there will be two rows for the same shipment. 
	 * The first row will have user id of the clerk and second will have user id of the supervisor.
	 */
	billEntryUserId:string = undefined;
	/**
	 * Time when bill entry person started the bill entry activity.
	 */
	billEntryStartDateTime:Date = undefined;
	/**
	 * Time when bill entry person either saved or submitted the page.
	 */
	billEntryEndDateTime:Date = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 */
	listActionCd:ActionCd = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * Desc: Identifies if one or m
 * ore attachments were included with the BOL to represent additional service.
 * not all attachments are scanned. Some are in paper form that travel with the shipment. Paper BOL indicates which attachments are available for the shipment 
 * ces.09/28/2017 Cathy Doucet Added for SpeedyG.
 */
export class BolAttachment {
	/**
	 * 
	 */
	shpInstId:number = undefined;
	/**
	 * Desc: Id
	 * entifies if Customer Invoice Document is attached to Bill of Lading for 
	 * shipment.
	 */
	custInvoiceInd:boolean = undefined;
	/**
	 * Desc: Id
	 * entifies if Packing List Document is attached to Bill of lading for ship
	 * ment.
	 */
	packingListInd:boolean = undefined;
	/**
	 * De
	 * sc: Identifies if Supplemental Pages Document is attached to Bill of lad
	 * ing for shipment.
	 */
	supplementalPagesInd:boolean = undefined;
	/**
	 * Desc
	 * : Identifies if CDP Form (Inbound) Document is attached to shipment.
	 */
	customsCdpFormInd:boolean = undefined;
	/**
	 * Expanded
	 * 	Name : Created by transaction codeDesc : Transaction code of the online
	 * 	screen or proxy (program module name if in batch mode) that created thi
	 * s record.
	 */
	updateByPgmId:string = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * 
 */
export class BolUsage {
	/**
	 * 
	 */
	shipmentInstId:number = undefined;
	/**
	 * 
	 */
	bolInstId:number = undefined;
	/**
	 * 
	 */
	bolBillingVersionNbr:string = undefined;
	/**
	 * 
	 */
	typeCd:BolUsageTypeCd = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * 
 */
export class CommodityDimension {
	/**
	 * 
	 */
	shipmentInstId:number = undefined;
	/**
	 * 
	 */
	commoditySequenceNbr:string = undefined;
	/**
	 * Expanded Name: Sequence Number
	 * Desc: A system generated sequential number to uniquely identify the recorded dimensions for a shipment.
	 */
	dimSequenceNbr:string = undefined;
	/**
	 * Expanded Name: Pieces Count
	 * Desc: This is the number of pieces the recorded dimensions apply to.
	 */
	piecesCount:number = undefined;
	/**
	 * Expanded Name: Length                               
	 * 																																																				
	 * Description: This is the length of the whole or part
	 * of the shipment. The number of pieces attribute     
	 * indicates how many pieces of the shipment the       
	 * dimensions apply to.
	 */
	lengthNbr:number = undefined;
	/**
	 * Expanded Name: Width                                   
	 * 																																																							
	 * Description: This is the width of the whole or part of 
	 * the shipment. The number of pieces attribute indicates 
	 * how many pieces of the shipment the dimensions apply   
	 * to.
	 */
	widthNbr:number = undefined;
	/**
	 * Expanded Name: Height                                
	 * 																																																								
	 * Description: This is the height of the whole or part of  
	 * the shipment. The number of pieces attribute indicates  
	 * how many pieces of the shipment the dimensions apply    
	 * to.
	 */
	heightNbr:number = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 */
	listActionCd:ActionCd = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * 
 */
export class Commodity {
	/**
	 * 
	 */
	shipmentInstId:number = undefined;
	/**
	 * Expanded Name : Sequence number
	 * Desc : A system assigned number to uniquely identify each commodity for a shipment.
	 */
	sequenceNbr:string = undefined;
	/**
	 * Expanded Name : (Commodity) class
	 * Desc : Identifies the type (class) of commodity being shipped.
	 * Examples:  Carpet, Matresses, Computer paper
	 * Valid Values : 50, 55, 60, 65, 70, 77.5, 85, 92.5, 100, 110, 125, 150, 175, 200, 250, 300, 350, 400, 500  
	 * Source: MBF Variable Segment : MBVDESC
	 */
	classType:CommodityClassCd = undefined;
	/**
	 * Expanded Name : NMFC item code
	 * Desc : This is the NMFC (National Motor Freight Classification) code for the commodity.
	 * Source: MBF Variable Segment - MBNMFC
	 */
	nmfcItemCd:string = undefined;
	/**
	 * Expanded Name : Source Code
	 * Desc : Code indicating the source of a particular Commodity Line.  Provides a way to distinguish between a Commodity Line on a bill that originated from the Common Shipper Bill of Lading (CSBOL) profile or a new Commodity Line that is added onto a bill where CSBOL Commodity Lines already exist.
	 * Source : FBES E-Commerce
	 * Valid Values :
	 * 1 - from CSBOL
	 * 2 - Not from CSBOL
	 */
	sourceCd:string = undefined;
	/**
	 * Expanded Name: Packaging code
	 * Desc : Type of packaging the commodity was originally picked up in. 
	 * Examples: Box, Skid, Case, Roll etc.
	 * Source: MBF Variable Segment - MBVDESC
	 */
	packageCd:CommodityPackageCd = undefined;
	/**
	 * Expanded Name : Pieces count
	 * Desc : Number of pieces of the commodity in the shipment.
	 * Source : MBF Variable Segment - MBPIECES
	 */
	piecesCount:number = undefined;
	/**
	 * Expanded Name : Volume in Cubic feet
	 * Desc : The volume of the freight; the height multiplied by the width multiplied by the depth
	 * Source :
	 */
	volumeCubicFeet:number = undefined;
	/**
	 * Expanded Name: Total weight (in pounds)
	 * Desc : Total weight of the commdity in pounds. 
	 * Source: MBF Root - MBTOTWT
	 */
	weightLbs:number = undefined;
	/**
	 * Expanded Name : Reduced weight
	 * Desc : This is the weight deducted from the actual weight of the commodity to get the net weight. This weight is normally the pallet or the container weight. This part of the weight gets a free ride.
	 * Source : MBF Variable Segment - MBWT
	 */
	reducedWeight:number = undefined;
	/**
	 * Expanded Name : Rating Unit of Measure
	 * Desc : A code to identify the units in terms of which the quantity is measured for calculating charges. For eg. weight (in pounds) is the most common unit of measure for commodities.
	 * Valid Values : 
	 * 1 - Weight (in pounds)
	 * 2 - Yardage (for carpets)
	 * 3 - Count
	 */
	ratingUnitOfMeasure:string = undefined;
	/**
	 * Expanded Name : Quantity
	 * Desc : Quantity (measured in terms of UOM) to be used when calculating charges for the commodity.
	 */
	ratingQuantity:number = undefined;
	/**
	 * Expanded Name : Freezable Indicator 
	 * Desc : Indicates whether shipment requires special handling to prevent freezing during harsh weather. 
	 * Source: MBF root - MBFZB (x 08  of MBTRAFIC)
	 * Valid Values : Y/N
	 * Y - Can be damaged by freezing
	 */
	freezableInd:boolean = undefined;
	/**
	 * Expanded Name : Hazardous material indicator
	 * Desc : Indicates whether the commodity is a hazardous material. 
	 * Source : MBF Variable Segment : MBNMHMX
	 * Valid Values :
	 * Y - Has hazardous materials
	 * N - No hazardous materials
	 */
	hazardousMtInd:boolean = undefined;
	/**
	 * Expanded Name : Tariff rate
	 * Desc : This is the rate used to calculate the total freight charges for a commodity. The rate is multiplied by the unit of measure (weight in most cases) to calculate the total charges. 
	 * Source : MBF Variable Segment -  MBCRATE, MBRATES
	 */
	tariffsRate:number = undefined;
	/**
	 * Expanded Name :  Charge Amount
	 * Desc : The dollar amount of the charge for the commodity.
	 */
	amount:number = undefined;
	/**
	 * Expanded Name: Percent Prepaid
	 * Desc: Percenatge of the charge amount for the accessorial is to be paid by the shipper. The rest of it is paid by the consignee.
	 */
	prepaidPercentage:number = undefined;
	/**
	 * Expanded Name : Minimum charge indicator
	 * Desc : Indicates if the charges associated with the line are the minimum charges for the commodity. 
	 * Source : MBF: root: MBMINCHG (x 10  of MBFLAG7)
	 * Valid Values :  Y/N
	 */
	minimumChargeInd:boolean = undefined;
	/**
	 * Expanded Name : Mixed class commodity exists indicator
	 * Desc :
	 * Source : MBF: root: MBMXCLS (x 01  of MBFLAGA)
	 * Valid Values : Y/N
	 */
	mixClassCommodityInd:boolean = undefined;
	/**
	 * Expanded Name : Description
	 * Desc : A textual description of the commodity
	 */
	description:string = undefined;
	/**
	 * Expanded Name : Archive Control Code
	 * Desc : Identifies a row of this table as archived or retrieved from archive.  The Princeton Archive tool sets the value upon archive or retrieval of data for selected PROs.
	 * FOR USE BY PRINCETON ARCHIVE/RETRIEVE PROCESSING ONLY!
	 * Valid Values:
	 * A - Row is flagged for Archive processing
	 * R - Row has been retrieved from archive
	 * blank - row is associated with a currently active PRO (default)
	 */
	archiveControlCd:string = undefined;
	/**
	 * 
	 */
	asRatedClassCd:CommodityClassCd = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 */
	listActionCd:ActionCd = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 */
	auditInfo:AuditInfo = undefined;
	/**
	 * 
	 */
	chrgToCd:LineItemChargeToCd = undefined;
	/**
	 * 
	 */
	originalDescription:string = undefined;
	/**
	 * Indicates that the biller selected to have the commodity class default to 100 during bill entry since the class was not listed on the Bill of Lading.  This is to distinguish between commodity lines that actually have a true commodity class of 100 and commodity lines where the default situation occurs.  Valid Values :  Y/N
	 */
	defaultClassSelectedInd:boolean = undefined;
	/**
	 * 
	 */
	commodityDimension:CommodityDimension[] = undefined;
	/**
	 * 
	 */
	hazMat:HazMat = undefined;
}
/**
 * 
 */
export class CustomsBond {
	/**
	 * 
	 */
	shipmentInstId:number = undefined;
	/**
	 * Expanded Name : Sequence number
	 * Desc : System generated sequence number assigned to each instance to uniquely identify each customs bond information for a shipment
	 */
	sequenceNbr:string = undefined;
	/**
	 * Expanded Name : Bond number text
	 * Desc : This is the IT, TE or WDTE number (7,8 or a 9 digit number) on the in-bond document. It is used to identify the Shipment while it is in the customs warehouse.
	 * Source: MBF  Variable Segment - MBITNUM
	 */
	bondNbr:string = undefined;
	/**
	 * Expanded Name : City
	 * Desc : Name of the City where the customs inspection is to be made.
	 * Source: MBF Variable Segment - MBINBOND
	 */
	city:string = undefined;
	/**
	 * Expanded Name : State
	 * Desc : State of the city where the customs inspection is to be made.
	 * Source: MBF Variable Segment - MBINBOND
	 */
	stateCd:string = undefined;
	/**
	 * 
	 */
	archiveControlCd:string = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 */
	listActionCd:ActionCd = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * 
 */
export class CustomsControl {
	/**
	 * 
	 */
	shipmentInstId:number = undefined;
	/**
	 * Expanded Name:  Sequence Number
	 * Desc : Sequential number assigned to uniquely identify  each customs control for a Shipment.
	 * Source : Generated
	 */
	sequenceNbr:string = undefined;
	/**
	 * Expanded Name : Customs type code
	 * Desc : Indicates the type of customs crossing by identifying the two different countries involved. 
	 * Source :
	 * Valid Values : 
	 * 1 - Shipment crossing from the US to Canada (Canadian 
	 * 					customs information) 
	 * 2 - Shipment crossing from Canada to the US (US
	 * 					customs information) 
	 * 3 - Shipment crossing from the US to Mexico (Mexican
	 * 					customs information)
	 * 4 - Shipment crossing from Mexico to the US (US
	 * 					customs information)
	 */
	lineTypeCd:CustomsControlTypeCd = undefined;
	/**
	 * Expanded name: CSA status indicator
	 * Desc: Code that indicates whether this shipment is a CSA approved shipment.
	 * Valid values:
	 * Y = Yes the shipment is a CSA shipment
	 * N = No the shipment is not a CSA shipment
	 * *Note: CSA stands for Customer Self Accessment.  This is a Canadian Customs program that identifies shipments inwhich all of the parties involved are  trusted  by Canada.  Con-Way and certain of it s drivers are certified in the program.  The importer must also be certifed in the program.  However, not all shipments that have all certifed parties are CSA shipments.
	 */
	csaStatusInd:boolean = undefined;
	/**
	 * Expanded name: CSA status yes indicator
	 * Desc: Code that indicates whether this shipment ever had the CSA status indictor set to YES.  This is for audit purposes.
	 * Valid values:
	 * Y = Yes the shipment is or has been marked as CSA
	 * 						approved.
	 * N = No the shipment has never been marked as CSA
	 * 						approved.
	 */
	csaStatusYesInd:boolean = undefined;
	/**
	 * Expanded name: CSA Number Text
	 * Desc: Number that the Canadian Customs agency assigns to  an importer.
	 */
	csaNbr:string = undefined;
	/**
	 * Expanded name: Port Code
	 * Description: This is a code supplied by the specified customs agency that identifies the location that shipments will cross the border.
	 */
	portCd:CustomsPortCd = undefined;
	/**
	 * Expanded name: Border Crossed Indicator
	 * Desc: Flag that indicates if this shipment has crossed this border yet.  Currently we only know that when the arrive happens.  With handhelds we may be able to know this sooner.
	 */
	borderCrossedInd:boolean = undefined;
	/**
	 * Expanded Name : Archive Control Code
	 * Desc : Identifies a row of this table as archived or retrieved from archive.  The Princeton Archive tool sets the value upon archive or retrieval of data for selected PROs.
	 * FOR USE BY PRINCETON ARCHIVE/RETRIEVE PROCESSING ONLY!
	 * Valid Values:
	 * A - Row is flagged for Archive processing
	 * R - Row has been retrieved from archive
	 * blank - row is associated with a currently active PRO (default)
	 */
	archiveControlCd:string = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 */
	listActionCd:ActionCd = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * 
 */
export class CustomsDocument {
	/**
	 * 
	 */
	shipmentInstId:number = undefined;
	/**
	 * 
	 */
	sequenceNbr:string = undefined;
	/**
	 * 
	 */
	proNbr:string = undefined;
	/**
	 * 
	 */
	pickupDate:string = undefined;
	/**
	 * 
	 */
	sentDateTime:Date = undefined;
	/**
	 * 
	 */
	bolDocSentInd:boolean = undefined;
	/**
	 * 
	 */
	customsDocSentInd:boolean = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * 
 */
export class CustomsDocumentPending {
	/**
	 * 
	 */
	proNbr:string = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * 
 */
export class EventLog {
	/**
	 * 
	 */
	shipmentInstId:number = undefined;
	/**
	 * 
	 */
	sequenceNbr:string = undefined;
	/**
	 * 
	 */
	typeCd:EventLogTypeCd = undefined;
	/**
	 * 
	 */
	subTypeCd:EventLogSubTypeCd = undefined;
	/**
	 * 
	 */
	proNbr:string = undefined;
	/**
	 * 
	 */
	transactionId:string = undefined;
	/**
	 * 
	 */
	programId:string = undefined;
	/**
	 * 
	 */
	reportingSicCd:string = undefined;
	/**
	 * 
	 */
	occurredDateTime:Date = undefined;
	/**
	 * 
	 */
	archiveControlCd:ArchiveControlCd = undefined;
	/**
	 * 
	 */
	totalPiecesCount:number = undefined;
	/**
	 * 
	 */
	totalWeightLbs:number = undefined;
	/**
	 * 
	 */
	totalChargeAmount:number = undefined;
	/**
	 * 
	 */
	trlrIdPrefix:string = undefined;
	/**
	 * 
	 */
	trlrIdSuffixNbr:number = undefined;
	/**
	 * 
	 */
	occurredSicCd:string = undefined;
	/**
	 * 
	 */
	originTerminalSicCd:string = undefined;
	/**
	 * 
	 */
	destinationTerminalSicCd:string = undefined;
	/**
	 * 
	 */
	enrouteInd:boolean = undefined;
	/**
	 * 
	 */
	shipperCustNbr:number = undefined;
	/**
	 * 
	 */
	consigneeCustNbr:number = undefined;
	/**
	 * 
	 */
	bil21StCustNbr:number = undefined;
	/**
	 * 
	 */
	bil22NdCustNbr:number = undefined;
	/**
	 * 
	 */
	shipperCountryCd:CountryCd = undefined;
	/**
	 * 
	 */
	consigneeCountryCd:CountryCd = undefined;
	/**
	 * 
	 */
	adminInstId:number = undefined;
	/**
	 * 
	 */
	pickupDate:Date = undefined;
	/**
	 * 
	 */
	estimatedDeliveryDate:Date = undefined;
	/**
	 * 
	 */
	chargeToCd:ChargeToCd = undefined;
	/**
	 * 
	 */
	billTo1StRelationshipCd:BillToRelationCd = undefined;
	/**
	 * 
	 */
	adminStatusCd:BillStatusCd = undefined;
	/**
	 * 
	 */
	movementSequenceNbr:string = undefined;
	/**
	 * 
	 */
	movementStatusCd:MovementStatusCd = undefined;
	/**
	 * 
	 */
	guaranteedInd:boolean = undefined;
	/**
	 * 
	 */
	hazmatInd:boolean = undefined;
	/**
	 * 
	 */
	parentInstId:number = undefined;
	/**
	 * 
	 */
	billClassCd:BillClassCd = undefined;
	/**
	 * 
	 */
	billTo2NdRelationshipCd:BillToRelationCd = undefined;
	/**
	 * 
	 */
	ediSenderId:string = undefined;
	/**
	 * 
	 */
	ediSenderShipmentId:string = undefined;
	/**
	 * 
	 */
	debtorCd:DebtorCd = undefined;
	/**
	 * 
	 */
	purInstId:number = undefined;
	/**
	 * 
	 */
	bolInstId:number = undefined;
	/**
	 * A unique identifier to correlate multiple transactions. This can be used by a log aggregator to tie events from multiple technologies such as UI, Middle ware and integration.
	 */
	correlationId:string = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * 
 */
export class HazMat {
	/**
	 * 
	 */
	shipmentInstId:number = undefined;
	/**
	 * 
	 */
	hazmatSequenceNbr:string = undefined;
	/**
	 * 
	 */
	sourceCd:HazmatSourceCd = undefined;
	/**
	 * 
	 */
	hazmatUnna:string = undefined;
	/**
	 * 
	 */
	hazmatClassCd:string = undefined;
	/**
	 * 
	 */
	hazmatWeightLbs:number = undefined;
	/**
	 * 
	 */
	hazmatBulkQuantityCd:string = undefined;
	/**
	 * 
	 */
	hazmatResidueInd:boolean = undefined;
	/**
	 * 
	 */
	zoneCd:string = undefined;
	/**
	 * 
	 */
	packingGroupCd:string = undefined;
	/**
	 * 
	 */
	overrideMethodNm:string = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 */
	listActionCd:ActionCd = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 */
	auditInfo:AuditInfo = undefined;
	/**
	 * 
	 */
	dotSpecialPermit:string = undefined;
	/**
	 * 
	 */
	limitedQuantityInd:boolean = undefined;
	/**
	 * 
	 */
	reportedQuantityInd:boolean = undefined;
	/**
	 * 
	 */
	explosiveInd:boolean = undefined;
	/**
	 * 
	 */
	netExplosiveMassNbr:number = undefined;
	/**
	 * 
	 */
	netExplosiveMassUnitOfMeasure:NetExplosiveMassUomCd = undefined;
	/**
	 * 
	 */
	marinePollutantInd:boolean = undefined;
	/**
	 * 
	 */
	marinePollutantChemical:string = undefined;
	/**
	 * 
	 */
	radioactiveInd:boolean = undefined;
	/**
	 * 
	 */
	radioactiveChemicalName:string = undefined;
	/**
	 * 
	 */
	radioactivityNbr:number = undefined;
	/**
	 * 
	 */
	radioactivityUnitOfMeasure:RadioactivityUomCd = undefined;
	/**
	 * 
	 */
	transportIndex:number = undefined;
	/**
	 * 
	 */
	fissileExceptedInd:boolean = undefined;
	/**
	 * 
	 */
	emergencyContactName:string = undefined;
	/**
	 * 
	 */
	emergencyContactPhoneNbr:string = undefined;
	/**
	 * 
	 */
	technicalName:string = undefined;
	/**
	 * 
	 */
	unnaInstId:number = undefined;
}
/**
 * 
 */
export class HazmatCdRef {
	/**
	 * Expanded Name : UN Number Text
	 * Desc : United Nations Identification Number.
	 */
	unNbr:string = undefined;
	/**
	 * Expanded Name : Packing Group Code
	 * Desc : Indicates degree of danger presented by material.
	 * Valid Values:
	 * 'I' = great degree of danger
	 * 'II' = medium degree of danger
	 * 'III' = minor degree of danger
	 * blank = not applicable
	 */
	packingGroupCd:string = undefined;
	/**
	 * Expanded Name : Haz Mat Class Code
	 * Desc : Hazardous Materials Classification Code.
	 */
	hazmatClassCd:string = undefined;
	/**
	 * Expanded Name : Haz Mat Level Code
	 * Desc : Hazardous Materials Level Code.
	 * Valid Values:
	 * 0 = Hazardous
	 * 1 = Extremely Hazardous
	 */
	hazmatLevelCd:string = undefined;
	/**
	 * Expanded Name : Haz Mat Zone Code
	 * Desc : Hazardous Materials Zone Code.
	 * Valid Values:
	 * A = Hazard Zone A
	 * B = Hazard Zone B
	 * C = Hazard Zone C
	 * D = Hazard Zone D
	 * blank = Not Applicable
	 */
	hazmatZoneCd:string = undefined;
	/**
	 * Expanded Name : Description Text
	 * Desc : Hazardous materials descriptions and proper shipping names.
	 */
	description:string = undefined;
	/**
	 * Expanded Name: Restricted indicator                                                            
	 * Desc:  Y  indicates a class of hazardous material that Con-way has chosen not to transport.
	 */
	restrictedInd:boolean = undefined;
	/**
	 * Expanded Name:  Class label          
	 * Desc: The DOT combined hazard class codes listed in order of importance required for labeling hazardous material.
	 */
	classLabel:string = undefined;
	/**
	 * Expanded Name:  Over-ride method name                        
	 * Desc: The override class code used by the Placarding wizard.  Under normal circumstances the HZMT_CLASS_CD is used by the Placarding wizard for its method call.  This attribute will only be populated when the HZMT_CLASS_CD contains the incorrect method for the Placarding wizard.
	 */
	overrideMethodNm:string = undefined;
	/**
	 * 
	 */
	unSequenceNbr:number = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * 
 */
export class InventoryGap {
	/**
	 * Expanded Name : Gap Tracking Number
	 * Desc : A sequential number that was missed in the main SHM_INVENTORY_TRACKING table.
	 */
	gapTrackingNbr:number = undefined;
	/**
	 * Expanded Name : Status Code
	 * Desc : Self-documenting value that describes the status of the Shipment from an Inventory Tracking perspective.
	 * VALID VALUES
	 * BCK=Transaction back-out
	 */
	statusCd:string = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * 
 */
export class InventoryTracking {
	/**
	 * Expanded Name : Instance identifier
	 * Desc : System generated unique identifier (as per CBD standard).
	 */
	shipmentInstId:number = undefined;
	/**
	 * Expanded Name : PRO number (in text format) 
	 * Desc : This number identifies a shipment. This is the identifier given to the customer as a reference.
	 * The numbers are printed on labels for each service center.  Labels are put on the Bill-Of-Lading and on the individual pieces of freight for identification.
	 * Internal PRO number format is Oppp-sssss-c where 'ppp' is the pro-prefix, 'ssss' is the pro-suffix and 'c' is the check digit (modulus 7). It is usually displayed in the format 'ppp-sssss-c'
	 * An expedited shipment may not always have a real PRO number. It may be given a dummy number in the same format and should not be written out to MBF.
	 * MBF: root MBPRO
	 */
	proNbr:string = undefined;
	/**
	 * Expanded Name : Pickup Date                       
	 * 																																																		
	 * Desc : Earliest available date that Shipment can be
	 * picked up from a customer.                        
	 * 																																																		
	 * Source : E-commerce
	 */
	pickupDate:Date = undefined;
	/**
	 * Expanded Name : Status Code
	 * Desc : Self-documenting value that describes the status of the Shipment from an Inventory Tracking perspective.
	 * VALID VALUES
	 * BIL= Billed
	 * RES=Reset
	 * PRG=Purged
	 */
	statusCd:string = undefined;
	/**
	 * 
	 */
	shipmentTrackingNbr:number = undefined;
	/**
	 * Expanded Name : Tracking Exception Code
	 * Desc: Tracks discrepancies between SHM_INVENTORY_TRACKING and SHM_SHIPMENT. 
	 * VALID VALUES
	 * OREP=Overage Report Bill
	 */
	trackingExceptionCd:string = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * 
 */
export class MiscLineItem {
	/**
	 * 
	 */
	shipmentInstId:number = undefined;
	/**
	 * Expanded Name : Line type code
	 * Desc : Indicates the type of  line item. 
	 * Source : MBF Variable Segment - MBWEIGHT
	 * Valid Values : 
	 * 1 - As weight 
	 * 2 - Deficit weight 
	 * 3 - COD Amount
	 * 4 - Discount line (Reduced charge)
	 * 5 - Part PPD Line
	 * 6 - Part Collect Line
	 * 7- Cash Prepaid Line
	 * 8 - Cash Collected line
	 * Note: As weight - Often the commodities are rated at a higher weight bracket than their net weight,  to get lower freight charges. In these cases the 'as weight'  is the weight used in calculating the freight charges. Most of the time this is sytem generated.
	 */
	lineTypeCd:MiscLineItemCd = undefined;
	/**
	 * Expanded Name : Tariff rate
	 * Desc : This is the rate used to calculate the  charges for the line item. Not all line items have a charge associated with them. The rate when present is multiplied by the unit of measure  to calculate the total charges. 
	 * Source : MBF Variable Segment -  MBCRATE, MBRATES
	 */
	tariffsRate:number = undefined;
	/**
	 * Expanded Name : Unit of Measure
	 * Desc : A code to identify the units in terms of which the quantity is measured for calculating charges. For eg. weight (in pounds) is the unit of measure for the AS WEIGHT and  DEFICIT WEIGHT line items. Not all line items have an UOM. 
	 * Valid Values :
	 * space - Where UOM is n/a to the Charge. 
	 * 1 - Weight (in pounds)
	 * 2 - Yardage (for carpets)
	 * 3 - Count
	 */
	unitOfMeasure:string = undefined;
	/**
	 * Expanded Name : Quantity
	 * Desc : Quantity (measured in terms of UOM) to be used in calculating charges
	 * It represents As Weight on an AS WEIGHT line. Often the commodities are rated at a higher weight bracket than their net weight,  to get lower freight charges. In these cases the 'as weight'  is the weight used in calculating the freight charges. Most of the time this is sytem generated. 
	 * For some Charge lines where it does not make sense to have a QTY, such as a COD, zeros may be formatted; UOM must be a space in this case.
	 * Source: MBF Variable Segment - MBWEIGHT
	 */
	quantity:number = undefined;
	/**
	 * Expanded Name :  Charge Amount
	 * Desc : The dollar amount of the charge associated with the line item.
	 */
	amount:number = undefined;
	/**
	 * Expanded Name: Percent Prepaid
	 * Desc: Percenatge of the charge amount for the accessorial is to be paid by the shipper. The rest of it is paid by the consignee.
	 */
	prepaidPercentage:number = undefined;
	/**
	 * Expanded Name : Minimum charge indicator
	 * Desc : Indicates if the charges associated with the line are the minimum charges for the line item. 
	 * Source : MBF: root: MBMINCHG (x 10  of MBFLAG7)
	 * Valid Values :  Y/N
	 */
	minimumChargeInd:boolean = undefined;
	/**
	 * Expanded Name :  Description
	 * Desc : A textual description for the line item.
	 * Source : MBF  Variable Segment :
	 */
	description:string = undefined;
	/**
	 * Expanded Name:  Sequence Number
	 * Desc : Sequential number assigned to uniquely identify  each miscelleneous charge for a Shipment. This entity stores all the differnt types of charge lines that appear on the invoice for the shipment. Not all of these lines contribute to the CTS revenue.Some examples are COD, As Weight, Deficit Weight lines etc. 
	 * Source : Generated
	 * Design Considerations :
	 */
	sequenceNbr:string = undefined;
	/**
	 * 
	 */
	archiveControlCd:string = undefined;
	/**
	 * 
	 */
	paymentMethodCd:MiscLineItemPaymentMethodCd = undefined;
	/**
	 * 
	 */
	checkNbr:string = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 */
	listActionCd:ActionCd = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 */
	auditInfo:AuditInfo = undefined;
	/**
	 * 
	 */
	chrgToCd:LineItemChargeToCd = undefined;
}
/**
 * 
 */
export class PackageCdRef {
	/**
	 * 
	 */
	aliasCd:string = undefined;
	/**
	 * 
	 */
	packageCd:string = undefined;
	/**
	 * 
	 */
	displayDescription:string = undefined;
	/**
	 * 
	 */
	displayCd:string = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * 
 */
export class Remark {
	/**
	 * 
	 */
	shipmentInstId:number = undefined;
	/**
	 * Expanded Name :  Type code
	 * Desc : This code can be used to categorize the remarks.  
	 * Valid Values : 
	 * 1 - Adhoc Hazardous Material Remarks
	 * 2 - Shipping Remarks
	 * 3 - Authority Line text Remarks
	 * 4 - Delivery Attachment Remarks
	 * 5 - Operational Freight Handling Remarks
	 * 6 - Final Delivery Date Correction Remarks
	 * Source: New/Entered
	 * Design Considerations : This can be used for subsetting purposes and for programatically pulling certain remarks to display and/or print.
	 */
	typeCd:ShipmentRemarkTypeCd = undefined;
	/**
	 * Expanded Name: Remark Text
	 * Desc: Remark Text
	 */
	remark:string = undefined;
	/**
	 * 
	 */
	archiveControlCd:string = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 */
	listActionCd:ActionCd = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * 
 */
export class RuleOverride {
	/**
	 * Shipment identifier for which the rule was overridden.
	 */
	shipmentInstId:number = undefined;
	/**
	 * Uniquely identifies multiple rule validation failures for a shipment.
	 */
	ruleOverrideSequenceNbr:string = undefined;
	/**
	 * Describes the type of shipment line that failed the rule validation. This identifies the shipment entity such as SHM_SR_NBR.SR = Supplemental Reference NumberCM = CommodityAC = Accessorial
	 */
	shmEntityTypeCd:RuleShmEntityTypeCd = undefined;
	/**
	 * Identifies the actual line item of the SHM ENTITY that failed rule validation. For example, if SRN stored in SHM_SR_NBR with SEQ_NBR=3 failed, then this will be recorded here.
	 */
	shmEntityTypeSequenceNbr:string = undefined;
	/**
	 * Idenfies the rule that failed validation. For example, the RULE_INST_ID in CBL_SRN_RULE.
	 */
	ruleInstId:number = undefined;
	/**
	 * Identifies the failure reason as determined by the logic executing the rule. Valid Values:
	 * 1 SRNRQ= Required SRN not entered Entered 2 SRNFMT = SRN format does not match expected format as defined in the rule.
	 */
	failureReasonCd:RuleFailureReasonCd = undefined;
	/**
	 * User supplied reason, chosen from drop down, for ignoring/overriding a Rule. Valid values: 1 SRNNA = SRN required as per the rule ((CBL_SRN_RULE)) but not available on paper Bill of Lading. 2 SRNFMT = SRN Number on the BOL does not match with the format mask specified by the rule (CBL_SRN_RULE)
	 */
	overrideReasonCd:RuleOverrideReasonCd = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 */
	auditInfo:AuditInfo = undefined;
	/**
	 * The error message describing the rule that failed
	 */
	errorDescription:string = undefined;
}
/**
 * 
 */
export class Shipment {
	/**
	 * Expanded Name : Instance identifier
	 * Desc : System generated unique identifier (as per CBD standard).
	 */
	shipmentInstId:number = undefined;
	/**
	 * Expanded Name : Type code 
	 * Desc : Identifies the type of shipment.
	 * Valid Values:
	 * A = Normal Movement(NORM)
	 * B = Master Shipment(MSTR) 
	 * C = Segment of a Master shipment (MSEG)
	 * D = Revenue Bill for Split segments (PTLT)
	 * E = Partial Shipment Segment (PSEG)
	 * G = Claims overage Reporting Bill (OREP)
	 * H = Genral Claims Business Shipment (GCBZ)
	 * I =  Astray Freight Segment (MOVR)
	 * J = Company Business Shipment (COBZ) 
	 * K = Expedite
	 * Note:
	 * Overage Reporting Bill (OREP) is entered into the system just  to indicate to the claims system there is an overage shipment on dock. 
	 * Astray freight(MOVR) is used to move freight to it s correct destination after it accidentally ended up somewhere else and its original PRO number is known.
	 */
	billClassCd:BillClassCd = undefined;
	/**
	 * Expanded Name : PRO number (in text format) 
	 * Desc : This number identifies a shipment. This is the identifier given to the customer as a reference.
	 * The numbers are printed on labels for each service center.  Labels are put on the Bill-Of-Lading and on the individual pieces of freight for identification.
	 * Internal PRO number format is Oppp-sssss-c where 'ppp' is the pro-prefix, 'ssss' is the pro-suffix and 'c' is the check digit (modulus 7). It is usually displayed in the format 'ppp-sssss-c'
	 * An expedited shipment may not always have a real PRO number. It may be given a dummy number in the same format and should not be written out to MBF.
	 * MBF: root MBPRO
	 */
	proNbr:string = undefined;
	/**
	 * Expanded Name : Bill Status Code
	 * Desc : Current status of the Shipment from billing point of view. 
	 * Valid Values:
	 * 1 - Unbilled 
	 * 2 - In FBES Suspense Queue
	 * 3 - Billed
	 * 4 - Rated
	 * 5 - Paid
	 * 6 - Cancelled
	 * Source : MBF, E-commerce
	 */
	billStatusCd:BillStatusCd = undefined;
	/**
	 * Expanded Name :  Charge to code
	 * Desc : Identifies who is responsible for charges for the Shipment - either the Shipper or the Consignee or the responsibility may be split between BOTH parties. For BOTH bills, the split for each line item will be stored along with the line item in the commodity, ac_svc or the misc_line_item table in future. Currently the total PPD and total COLLECT charges are currently stored as separate line items in the misc_line_item entity.
	 * Valid Values : 
	 * 	P    -  Prepaid - shipper responsible for all charges
	 * 	C    -  Collect - consignee responsible for all charges
	 * 	B    -  Both - shipper and consignee responsible 
	 * 								-  Valid only for Non-Revenue bills.
	 * 			
	 * Source: MBF : MBPRTPPD, MBPRTCOL
	 */
	chargeToCd:ChargeToCd = undefined;
	/**
	 * Expanded Name : Original terminal SIC
	 * Desc : SIC where the Shipment originates.  Usually associated with PRO prefix and can be overriden.
	 * Source : MBF: root MBORGIN
	 */
	originTerminalSicCd:string = undefined;
	/**
	 * Expanded Name : Destination terminal SIC
	 * Desc : SIC that is scheduled to deliver the Shipment. 
	 * Source : MBF: root MBDEST
	 */
	destinationTerminalSicCd:string = undefined;
	/**
	 * Expanded Name : Current SIC
	 * Desc : SIC where the Shipment currently is.  
	 * This is a denormalization to avoid scanning all movements to find current location.
	 * Source : MBF: root MBLOCX
	 */
	currentSicCd:string = undefined;
	/**
	 * Expanded Name : Source Code
	 * Desc : Indicates the source or origination of the Pro.  This code is needed for ACOR processing to determine whether to roll back to the initial data.
	 * Valid Values:
	 * 	1  - Web        (Pickup Request web page)
	 * 	2  - EDI        (set 204 or set 211)    
	 * 	3  - FTP        (file transfer)
	 * 	4  - FBES    (manual Bill Entry)
	 * 	5  - TCON or LOAD    (i.e. skeleton TCON or non-TCON LOAD)
	 * 	6  - Pickup Request
	 * Source : E-commerce
	 */
	sourceCd:ShipmentSourceCd = undefined;
	/**
	 * Expanded Name : Pickup Date                       
	 * 																																																		
	 * Desc : Earliest available date that Shipment can be
	 * picked up from a customer.                        
	 * 																																																		
	 * Source : E-commerce
	 */
	pickupDate:Date = undefined;
	/**
	 * Expanded Name : Ready Time                        
	 * 																																																		
	 * Desc : Earliest available time that freight can be
	 * picked up from a customer.                        
	 * 																																																		
	 * Source : E-commerce
	 */
	readyTime:Date = undefined;
	/**
	 * Expanded Name : Close Time                      
	 * 																																																
	 * Desc : Latest available time that freight can be
	 * pickup up from a customer.                      
	 * 																																																
	 * Source : E-commerce
	 */
	closeTime:Date = undefined;
	/**
	 * Expanded Name : Total pieces count
	 * Desc : Total pieces from all commodities in the shipment.
	 * This can be derived from the individual commodities, but besides performance, there is a timing issue as well. SMART needs the information when freight is loaded on the trailer (TCON) and it may happen before the bill has been entered into the system. 
	 * Source : MBF: root: MBTOTPCS
	 */
	totalPiecesCount:number = undefined;
	/**
	 * Exapnded Name : Motorized piece count
	 * Desc : Identifies the number of moves or lifts made with a forklift or a pallet jack to move freight without manual intervention by the driver. This count may be equal to or less than the number of pieces in the shipment.  This is used by the costing system.  
	 * Source : MBF root -  MBMTRPCS
	 */
	motorizedPiecesCount:number = undefined;
	/**
	 * Expanded Name : Total weight (in pounds)
	 * Desc : Total weight of all the freight being moved as a unit.  If this is a  Mother  bill then this is the total of all of the  Daughter  bills. 
	 * Any rating weight allowances or variations (AW, DW, RW, NW) are not included.
	 * This can be derived from the individual commodities, but besides performance, there is a timing issue as well. SMART needs the information when freight is loaded on the trailer (TCON) and it may happen before the bill has been entered into the system. 
	 * Source : MBF: root: MBTOTWT
	 */
	totalWeightLbs:number = undefined;
	/**
	 * Expanded Name : Default Tariff
	 * Desc : This identifies the default qualifying tariff for the shipment. If the rating process does not identify any other tariffs for the customer from rate aids, this is used as the rating tariff. 
	 * 	
	 * Source : MBF root - MBTARIFF
	 */
	defaultTariffsId:string = undefined;
	/**
	 * Expanded Name : Total charges
	 * Desc : Includes all charges that contribute to the CTS revenue minus discount.  Includes advance+beyond carrier charges and COD amount.
	 * Source : MBF: root: MBTOTAL
	 */
	totalChargeAmount:number = undefined;
	/**
	 * Expanded Name : Total charges in US dollars
	 * Desc : Includes all charges that contribute to the CTS revenue minus discount in US dollars.  Includes advance+beyond carrier charges and COD amount.
	 * Source : MBF: root: MBTOTAL
	 * Design Notes: This field was added as a denorm to keep the total charges in USD. Normally this field is same as the tot charges field. However this field is differnt when the rating currncy is not USD.
	 */
	totalUsdAmount:number = undefined;
	/**
	 * Expanded Name: Split Indicator
	 * Desc: Indicates whether the shipment has been split or not. 
	 * Valid values: Y/N
	 * Source: MBF MBCLAS2 -  This actually replaces the BILL CLASS 2 field in the MBF.
	 */
	splitInd:boolean = undefined;
	/**
	 * Expanded Name : Hazardous Materials indicator
	 * 	
	 * Desc : Indicates whether or not a shipment includes  any commodities with hazardous materials. It is a denormalized attribute for performance puposes. 
	 * Valid Values : Y/N
	 */
	hazmatInd:boolean = undefined;
	/**
	 * Expanded Name : Freezable Indicator 
	 * Desc : Indicates whether shipment requires special handling to prevent freezing during harsh weather. 
	 * Ideally, it should be specified at the commodity level, but has been de-normalized for Shipment, since that is level at which it is currently captured
	 * Source: MBF root - MBFZB (x 08  of MBTRAFIC)
	 * Valid Values : Y/N
	 * Y - Can be damaged by freezing
	 */
	freezableInd:boolean = undefined;
	/**
	 * Expanded Name : Signature service indicator
	 * Desc : Indicates if anyone handling the freight is required to sign on a signature sheet. 
	 * Source : MBF: root: MBSGSRVC (x 04  of MBFLAG9)
	 * Valid Values : Y/N
	 */
	signatureServiceInd:boolean = undefined;
	/**
	 * Expanded Name : Revenue Bill Indicator
	 * Desc : Indicates whether the shipment will generate revenue for Con-way or not.
	 * Valid Values : 
	 * Y - generates revenue
	 * N - does not generate revenue
	 */
	revenueBillInd:boolean = undefined;
	/**
	 * Expanded Name : Manual rating indicator 
	 * Desc : Specifies whether the shipment  in question needs to be manually rated or not. If required, it may be changed to a code indicating the reason as well for manually rating it. 
	 * It is same as special instructions field on the FBES screen.
	 * ==NOTE==
	 * When this indicator is set, it means the rates and charges must be manully reviewed before the bill is marked as RATED. Howerver the auto rating system is allowed to put rates and charges on the bill prior to putting it in a manual entry or manual audit queue.
	 * This is different from the indicator AUTO_RATEABLE_IND which indicates whether the auto rating system is even allowed to put rates and charges on the bill.
	 * Source : MBF: root: MBSCHDI (x 80  of MBFLAGB)
	 * Valid Values : Y/N
	 */
	manualRatingRequiredInd:boolean = undefined;
	/**
	 * Expanded Name : Audit Required Indicator
	 * 																		
	 * Desc : Used to determine if the pro requires auditing after rating. 
	 * 																	
	 * Valid Values :
	 * Y - Pro does require auditing
	 * N - Pro does not require auditing
	 * 					
	 * Source : MBF:  Root: MBFBAUDT (X 01  of MBFLAGI)
	 */
	auditInd:boolean = undefined;
	/**
	 * Expanded Name : Cash indicator
	 * Desc : This indicates if cash was collected at the tim eof pick up or should be collected at the time of delivery.
	 * This information is also printed on the delivery set to inform the driver to collect cash.
	 * Source : MBF: root: MBCASH (x 10  of MBFLAG2)
	 * Valid Values : Y/N
	 */
	cashInd:boolean = undefined;
	/**
	 * Expanded Name : Cash collected code                    
	 * 																																																							
	 * Desc : A code indicating whether any cash was          
	 * collected at the time of pick up or delivery or not. Cash may be collected  regardless of whether it was required or not and if  so, the invoice may need to be stopped.                
	 * 																																																							
	 * It has been modelled as a code instead of an indicator 
	 * since it needs to be initialized to a value other than 
	 * NO.                                                    
	 * 																																																							
	 * Source : New                                           
	 * 																																																							
	 * Valid Values :                                         
	 * blank - Initial value                                  
	 * Y - Yes, cash was collected                            
	 * N - No cash collected on delivery
	 */
	cashCollectInd:boolean = undefined;
	/**
	 * Expanded Name : Government Bill of Lading indicator
	 * Desc : Indicates whether this is a government shipment or not.
	 * Source : MBF: root: MBGBLT (x 01  of MBTRAFIC)
	 * Valid Values : Y/N
	 */
	govtBolTrafficInd:boolean = undefined;
	/**
	 * Expanded Name : Guaranteed Indicator
	 * Desc : Indicates if the shipment is under the Con-Way Guaranteed Service Program.
	 * Values : Y/N
	 * Source : MBF, E-commerce
	 */
	guaranteedInd:boolean = undefined;
	/**
	 * Expanded Name : Guaranteed Coupon Indicator
	 * Desc : Indicates that a Coupon has been used to allow the shipment to participate in the Con-Way Guaranteed Service Program for free.
	 * Values : Y/N
	 * Source : BASE
	 */
	guaranteedCouponInd:boolean = undefined;
	/**
	 * Expanded Name : Purge blocked indicator
	 * Desc : Indicates that the details cannot be purged. Usually set when there are inconsistencies for Master / MSEG or Part lot/PSEG  shipments. 
	 * Valid values : Y/N
	 * Source : MBF: root: MBPUBLK (x 10  of MBFLAG3)
	 */
	purgeBlockInd:boolean = undefined;
	/**
	 * Expanded Name: Last MOVR PRO Number 
	 * Desc: The Pro number for the last MOVR PRO billed for this shipment.
	 */
	lastMoverPro:string = undefined;
	/**
	 * Expanded Name: Archive Indicator
	 * Desc: Indicates whether the record for the PRO is an active one or one retrieved from the archive.
	 */
	archiveInd:boolean = undefined;
	/**
	 * Expanded Name: COD Indicator
	 * Desc: Indictaes whether it is a COD shipment or not. It is a denormalized attribute for performance puposes. The actual COD information is in the MISC_LINE_ITEM entity.
	 */
	codInd:boolean = undefined;
	/**
	 * Expanded Name: Discount Code 
	 * Desc: This is the discount code that was applied to the shipment when it was rated. 
	 * Source: MBF - MBRCCODE
	 */
	discountCd:string = undefined;
	/**
	 * Expanded Name :Movement Reporting SIC Code
	 * Desc : SIC where the last movement event for the shipment  happened and reported.  This is a denormalized attribute from the MOVEMENT entity for performance purposes.
	 * Source : MBF: root MBMOVE
	 */
	lastMoveRptgSicCd:string = undefined;
	/**
	 * Expanded Name : Movement Timestamp
	 * Desc : Date and time, when the last movement for this shipment  occurred. This is a denormalized attribute from the MOVEMENT entity. 
	 * Source: MBF - MBMVTM
	 */
	lastMovementDateTime:Date = undefined;
	/**
	 * Expanded Name : Movement status
	 * Desc : The status of the latest movement for a Shipment from an operations point-of-view. This is a denormalized atribute from the MOVEMENT entity.
	 * Source: MBF
	 * Name: Various
	 * Valid Values : 
	 * Spaces - Not applicable
	 * 1 - On dock
	 * 2 - On trailer
	 * 3 - Out for delivery
	 * 4-  Interim Delivery
	 * 5 - Final Delivered
	 * 6 - Not Applicable
	 * 6 - No movements allowed
	 * 7 - Cancelled
	 */
	movementStatusCd:MovementStatusCd = undefined;
	/**
	 * Expanded Name : Delivery qualifier
	 * Desc : Code representing results of a specific delivery activity. The values are the same as what used to be  Event that stopped service).
	 * This has been denormalized from Movement for performance reasons and should always be kept in sync. 
	 * Source : MBF: root: MBD1FLG / MBD2FLG
	 * Valid Values :
	 * A= Over Shipment
	 * B=Trapped shipment/MBD1TRAP
	 * C=Hold for customs/MBD1CSTM
	 * D=Undelivered/MBD1UNDL
	 * E=Hold for appointment/MBD1APPT
	 * F=On spotted trailer/MBD1SPOT
	 * G=Cartage
	 * H=Attempted delivery/MBD1ATMP
	 * J=All short
	 * K=Partial short/MBD1PSRT
	 * L=Refused delivery/MBD1RFSD
	 * M=Damaged shipment/MBD1RDMG
	 * N=Suspense/MBD1SUSP     - Not Used
	 * O=Wait/MBD1WAIT              - Not Used
	 * P=Purge Blocked/MBD2PBLK
	 * R=Return Status/MBD2RTRN
	 * T=Transfer/MBD2TSFR
	 * Z=Final delivery/MBD1FINL
	 */
	deliveryQualifierCd:DeliveryQualifierCd = undefined;
	/**
	 * Expanded Name : Rating Currency Code
	 * Desc: The rates and charges for the shipment were calculated using this currency
	 * Valid values: 
	 * 	
	 * USD - US Dollars
	 * CDN - Canadian Dollars
	 */
	ratingCurrencyCd:RatingCurrencyCd = undefined;
	/**
	 * Expanded Name : Invoicing Currency Code
	 * Desc:  PLEASE DO NOT USE THIS ATTRIBUTE. THIS ATTRIBUTE WILL BE DROPPED IN NEAR FUTURE.
	 * The total charges for the shipment should be displayed using this currency
	 * Valid values: 
	 * 	
	 * USD - US Dollars
	 * CDN - Canadian Dollars
	 */
	invoicingCurrencyCd:InvoiceCurrencyCd = undefined;
	/**
	 * Expanded Name: Currency Converstion Factor
	 * Desc: PLEASE DO NOT USE THIS ATTRIBUTE. THIS ATTRIBUTE WILL BE DROPPED IN NEAR FUTURE.
	 * Provides the currency conversion factor on the day of shipment pick up between the rating currency and invoicing currency, if they are different.
	 */
	currencyConversionFctr:number = undefined;
	/**
	 * Expanded Name : Required Delivery Date
	 * Desc : Date the shipment is required to be delivered
	 * Source : E-commerce
	 */
	requiredDeliveryDate:Date = undefined;
	/**
	 * Expanded Name : Delivery Time Start (if any)
	 * Desc : The start time of the window specified by the customer for delivering the shipment.
	 * Source : E-commerce
	 */
	startDeliveryTime:Date = undefined;
	/**
	 * Expanded Name : Delivery Time End (if any)
	 * Desc : The end time of the window specified by the customer for delivering the shipment.
	 * Source : E-commerce
	 */
	endDeliveryTime:Date = undefined;
	/**
	 * Expanded Name : International manifest from (port) code
	 * Desc : Generated by rating. Identifies port of entry code through which freight will enter the US from another country. This may be associated with the destination city or the customer s broker.
	 * Source : MBF: root: MBFROMPT
	 */
	fromPortCd:string = undefined;
	/**
	 * Expanded Name : International  manifest to (port) code
	 * Desc : Generated by rating: Identifies the port of entry through which freight will enter another country.  This is either associated with the destination or with the customer s broker.
	 * Source : MBF: root: MBTOPORT
	 */
	toPortCd:string = undefined;
	/**
	 * Expanded Name : OBC pickup delivery route
	 * Desc : Pickup delivery route code from CAD used for sorting to print delivery sets in this order.
	 * Source : MBF: root: MBPDRTE
	 */
	obcPickupDlvRouteCd:string = undefined;
	/**
	 * Expanded Name : Customer Profile Instance Id
	 * Desc : A unique, randomly-generated number that identifies a registered customer in the Con-Way Customer Profile Component.
	 * Source : Customer Profile Component
	 */
	custProfitabilityInstId:number = undefined;
	/**
	 * Expanded Name :  Rating tariff
	 * Desc : This identifies the actual tariff used to rate the shipment. 
	 * Source : MBF root - MBRATTAR
	 */
	ratingTariffsId:string = undefined;
	/**
	 * Expanded Name : Rate auditor initials
	 * Desc : Initials of the rating auditor who last audited the shipment for rates. 
	 * Source : MBF: root: MBRTADID
	 */
	rateAuditorInitials:string = undefined;
	/**
	 * Expanded Name : Rate or rate audit queue name
	 * Desc : The name of the rating (or the rate audit) queue used when the shipment was last rated / audited. 
	 * Source : MBF: root: MBRATEQ
	 */
	rateOrRateAudtqNm:string = undefined;
	/**
	 * 
	 */
	heaviestCommoditySequenceNo:string = undefined;
	/**
	 * Expanded name:  Service type code.
	 * Description:  Defines the specific type of service provided for a shipment.
	 * Values:  1 - Normal LTL, 2 - Con-Way Deferred Express.
	 */
	serviceTypeCd:string = undefined;
	/**
	 * Expanded name: Estimated Delivery Date
	 * Description: Date that the shipment should be delivered based on standard lane days.
	 */
	estimatedDeliveryDate:Date = undefined;
	/**
	 * Expanded name: Freight Bill Delivery Set Print Count
	 * Description:
	 * The number of times that a FBDS has been printed for this shipment.
	 */
	fbdsPrintCount:string = undefined;
	/**
	 * Expanded Name : Archive Control Code
	 * Desc : Identifies a row of this table as archived or retrieved from archive.  The Princeton Archive tool sets the value upon archive or retrieval of data for selected PROs.
	 * FOR USE BY PRINCETON ARCHIVE/RETRIEVE PROCESSING ONLY!
	 * Valid Values:
	 * A - Row is flagged for Archive processing
	 * R - Row has been retrieved from archive
	 * blank - row is associated with a currently active PRO (default)
	 */
	archiveControlCd:string = undefined;
	/**
	 * Expanded name: Delivery Information Required Code
	 * Desc: This code indicates that there is a requirement to capture delivery information for this shipment because of customer special requirements.  This is usually an EDI customer requirement that must be met.  The name of the person that signed for delivery and the timestamp are also captured in this record.
	 * Values: 
	 * R - delivery info is required 
	 * C - delivery informaiton has been captured  and exists with this record
	 * blank - No delivery information required for this shipment
	 */
	deliveryInformationRqdCd:DeliveryInfoRequiredCd = undefined;
	/**
	 * Expanded name: Delivery Signature Name
	 * Desc: This is the name of the person that signed for the delivery of the freight.  This name is required by some customers and is passed along to them via EDI.  This name is captured if the DLVR_UNFO_RQD_CD is set.
	 * Since a shipment can be delivered in parts, (part short, etc) there can be more than one signature.  This field will hold the name of the last delivery movement.  The previous signatures can be retrieved from shipment history records.
	 */
	deliverySignatureNm:string = undefined;
	/**
	 * Expanded name: Delivery Signature TimeStamp
	 * Desc: This is the date and time recorded when the person named in DLVR_SIG_NM_TXT signed for the shipment.
	 */
	deliverySignatureDateTime:Date = undefined;
	/**
	 * Expanded Name : Standard Transit Days (in number of days)
	 * Desc : The standard number of days it should take to move a shipment from origin to destination, as defined in the OSS_SVC_STDS_A/B tables. 
	 * Source : OSS_SVC_STDS_A/B - TRNST_DAYS
	 */
	standardTransitDays:string = undefined;
	/**
	 * Expanded Name : Actual Transit Days (in number of days)
	 * Desc : The number of days it actually takes to move a shipment from shipper to consignee.
	 * Source : calculated by subtracting PKUP_DT from the date portion of the (LST_)MVMT_TMST of the qualifying delivery event / movement, adjusted appropriately for non-work days occuring between the two dates.  There are a number of interim delivery qualifiers (DLVRY_QALFR_CD) that can cause the calculation  (SPOT, PSRT?, RDMG?, RFSD?, FINL).
	 */
	actualTransitDays:string = undefined;
	/**
	 * Expanded Name : Transit Movement Sequence Number
	 * Desc : The sequence number identifying the movement (qualifying delivery event) for the calculation of ACTL_TRNST_DAYS.
	 * NOTE - VALUE CAN BE ZERO FOR PARTLOT (PTLT) PROS.
	 * Source : SHM_MOVEMENT - MVMT_SEQ_NBR
	 */
	transitMovementSequenceNbr:string = undefined;
	/**
	 * Expanded Name : Warranty Indicator
	 * Desc : Indicates whether the shipment is eligible for the money-back guarantee if moving between direct points under Tariff CNWY-599.
	 * Valid Values : 
	 * Y - eligible for warranty
	 * N - not eligible for warranty - default value
	 */
	warrantyInd:boolean = undefined;
	/**
	 * Expanded Name : Warranty Status Code
	 * Desc : Identifies the warranty status of a shipment
	 * For example, one value of this code would indicate that a warranty  claim has been filed  by the debtor if a shipment has failed service.
	 * Valid Values:
	 * 0 - default value; This means either the shipment is not eligible (Warranty flag =  N ) or it is eligible and no one has filed a claim.
	 * 1 - The customer has filed a claim and it has not been audited.
	 * 2 - The customer has filed a claim and it was approved.
	 * 3 - The customer has filed a claim and it was denied.
	 */
	warrantyStatusCd:WarrantyStatusCd = undefined;
	/**
	 * Expanded name: Notification Code
	 * Desc: Indicates whether or not an notification exists for this shipment.  If it exists, the details will exist in the SHM NOTFICATION table.  The notification is created in the SCO (Smart4) system.
	 * Values:
	 * A - Appointment exists
	 * N - Notification exists
	 * O -Appointment Not Required  
	 * R- Reappointment candidate.  PSRT/RDMG Pro  could be reappointment for delivery
	 * blank - Neither exist
	 */
	notificationCd:NotificationCd = undefined;
	/**
	 * Expanded Name: Absolute minimum charge indicator
	 * Desc: This inidcates if the charge for this shipment is the absolute minimum charge.
	 */
	absoluteMinimumChargeInd:boolean = undefined;
	/**
	 * Expanded Name: Discount Percentage
	 * Desc: This is the percentage discount applied to the freigt chrages and discountable accessorial charges of the shipment.
	 */
	discountPercentage:number = undefined;
	/**
	 * Expanded Name: Pricing Agreement Instance Id
	 * Desc: This is the instance id of the pricing agreement that was used to rate the shipment.
	 * ==NOTE== Link to BASE 5 PRC component
	 */
	priceAgreementId:number = undefined;
	/**
	 * Expanded Name: Rule set Number
	 * Desc: This identifies the rule set of the pricing agreement that was used to rate the shipment.
	 * ==NOTE== link to BASE 5 PRC component
	 */
	priceRulesetNbr:string = undefined;
	/**
	 * Expanded Name: Route prefix 
	 * Desc: Identifies the last Route the shipment was put on.
	 * ==NOTE== Link to SMART 4 SCO component
	 */
	routePrefix:string = undefined;
	/**
	 * Expanded Name: Route Suffix
	 * Desc: Identifies the last Route the shipment was put on.
	 * ==NOTE== Link to SMART 4 SCO component
	 */
	routeSuffix:string = undefined;
	/**
	 * Expanded Name: Route Type Code
	 * Desc: Identifies whether the shipment is currently on a  Route (either planning route or dispatched route) Staged (available) for Routing, or is Not on a route.
	 * Valid Values
	 * 				- Not Routed
	 * R - Routed
	 * S - Staged
	 */
	routeTypeCd:RouteTypeCd = undefined;
	/**
	 * Expanded Name : Volume in Cubic feet
	 * Desc : The volume of the freight; derived from the dimensions captured by driver, or entered by a CSR or dock worker for an SL  (Shipper Load and Count), or from one of a variety of profiles. For details, see the description of CFT_PRFL_MTHD_CD.
	 * 06/21/2016 Cathy Doucet modified description as per Ariel/Sunya.
	 */
	totalVolumeCubicFeet:number = undefined;
	/**
	 * Expanded Name: Total Pallet Count
	 * Desc: Total Number of Pallets in the Shipment
	 */
	totalPalletsCount:number = undefined;
	/**
	 * Expanded Name: Area instance Id
	 * Desc: Each service center is divided into multiple geographic areas for delivery. This identifies the delivery area of destination service center in which this shipment s consignee is located.
	 * Source: SMART 4 Requirement
	 * == NOTE ==  Ties back to the geo-area stored in Oracle spatial database for each service center.
	 */
	areaInstId:number = undefined;
	/**
	 * Expanded Name: Auto Rateable Indicator
	 * Desc: This indicates whether the auto rating system is allowed to put rates and charges on the bill. This is irrespective of whether the bill needs to be manually reviewed or not before being marked RATED. 
	 * Valid Values: 
	 * Y - Auto rating system may put rates and charges
	 * N - Auto Rating system should not out or change any rates and charges on the bill
	 */
	autoRateableInd:boolean = undefined;
	/**
	 * Expanded Name: Motorized-Move Pieces Known Indicator
	 * Desc: This indicates whether MTRZD_PCS_CNT is known or applicable.. 
	 * Valid Values: 
	 * Y - Yes, MTRZD_PCS_CNT is known/applicable
	 * N - No, MTRZD_PCS_CNT is NOT known/applicable
	 * Source:  FBES
	 */
	motorizedPiecesKnownInd:boolean = undefined;
	/**
	 * Expanded Name : Estimated Transit Days (in number of days)
	 * Desc : The standard number of days it should take to move a shipment from origin to destination, as defined in the OSS_SVC_STDS_A/B tables, adjusted by +1 if LATE_TENDER_IND =  Y 
	 * Source : STD_TRNST_DAYS +1, if LATE_TENDER_IND =  Y
	 */
	estimatedTransitDays:string = undefined;
	/**
	 * Expanded Name : Calculated Service Days (in number of days)
	 * Desc : The number of days it takes to move a shipment from shipper until it reaches a service stopping event.
	 * Source : calculated by subtracting PKUP_DT from the date portion of the (LST_)MVMT_TMST of the first service-stopping event / movement, adjusted appropriately for non-work days occuring between the two dates.  There are a number of interim delivery qualifiers (DLVRY_QALFR_CD) that can cause the calculation (ie. stop service).
	 */
	calculatedServiceDays:string = undefined;
	/**
	 * Expanded Name : Calculated Service Timestamp
	 * Desc : The timestamp of a service stopping event.
	 * Source : (LST_)MVMT_TMST of the first service-stopping event / movement.  There are a number of interim delivery qualifiers (DLVRY_QALFR_CD) that can cause the calculation (ie. stop service).
	 */
	calculatedServiceDateTime:Date = undefined;
	/**
	 * Expanded Name : Calculated Movement Sequence Number
	 * Desc : The sequence number identifying the movement (service-stopping event) for the calculation of CALC_SVC_DAYS.
	 * NOTE - VALUE CAN BE ZERO FOR PARTLOT (PTLT) PROS.
	 * Source : SHM_MOVEMENT - MVMT_SEQ_NBR
	 */
	calculatedMovementSequenceNbr:string = undefined;
	/**
	 * Expanded Name : Difference of Calculated Days
	 * Desc : Difference between CALC_SVC_DAYS and EST_TRNST_DAYS.
	 * Source : CALC_SVC_DAYS - EST_TRNST_DAYS.
	 */
	diffCalculatedDays:string = undefined;
	/**
	 * Expanded Name : Difference of Transit Days
	 * Desc : Difference between ACTL_TRNST_DAYS and EST_TRNST_DAYS.
	 * Source : ACTL_TRNST_DAYS - EST_TRNST_DAYS.
	 */
	diffTransitDays:string = undefined;
	/**
	 * Expanded Name : Late Tender Code
	 * Desc : Indicates whether shipment could not be picked up in time (due to late tender by shipper) to make service.
	 * Values: Y - Late Tender; blank or N - Not Late Tender
	 * Source : Bill Entry/FBES; can be populated at Pick-up Request create time if a skeleton PRO is available; may be changed in Corrections
	 */
	lateTenderCd:LateTenderCd = undefined;
	/**
	 * Expanded Name : Shipment Service Status Code
	 * Desc : Indicates whether shipment met service standards.
	 * Values: Fail, Success, Exempt, Unknown
	 * Source : Calculated
	 */
	shipmentServiceStatusCd:ServiceStatusCd = undefined;
	/**
	 * Expanded Name : Service Calculation Status Code
	 * Desc : Indicates whether shipment service calculations have been performed.
	 * Values:
	 * 			0 - Not Calculated Yet
	 * 			1 - Cannot Calculate (all variables not defined)
	 * 			? - Calculated from (service stopping event or appointment - replace '?' with DLVRY_QALFR_CD values)
	 * Source : Calculated
	 */
	serviceCalculatedStatusCd:ServiceCalculationStatusCd = undefined;
	/**
	 * Expanded Name : Exemption Reason Code
	 * Desc : Indicates why a shipment may be exempt from meeting service standards.
	 * Values:
	 * 			blank - Not Exempt;
	 * 			any valid exemption reason code
	 * Source : OPS_EXEMPT_SHPMT; shipment exemptions are created immediately upon full trailer and service center exemption events.
	 */
	exemptReasonCd:ExemptReasonCd = undefined;
	/**
	 * Expanded Name : Pickup Date Back-Date Indicator
	 * Desc : Indicates whether a shipment pickup date was backdated during bill entry.
	 * Values:
	 * 			Y - Shipment pickup date was backdated at bill entry
	 * 			N - Shipment pickup date = bill entry date
	 * Source : Bill Entry/FBES.
	 */
	pickupBackdateInd:boolean = undefined;
	/**
	 * Expanded Name: FOOD OR POISON CODE
	 * Desc; Indicates whether the shipment contains food or posion. Assumption: A shipment will never contain both.
	 * Valid Values:
	 * 1 - Neither
	 * 2 - Food
	 * 3 -  Poison
	 */
	foodPoisonCd:FoodPoisonCd = undefined;
	/**
	 * Expanded Name: Rating Override Code
	 * Desc: Indicates if a rating override has been indicated during billing. For example to use a spot quote or lineal feet rating.
	 * Valid Values:
	 * 1: Spot Quote Number Specified for rating
	 * 2: Lineal Feet Rating should be used
	 */
	ratingOverrideCd:string = undefined;
	/**
	 * Expanded Name : Pick-Up Time
	 * Desc : Time when the shipment was picked up. This will be required for Late Tender shipments and optional for other shipments.
	 * Source : Bill Entry/FBES; can be populated at Pick-up
	 * Request create time if a skeleton PRO is available;
	 * may be changed in Corrections
	 */
	pickupTime:Date = undefined;
	/**
	 * Expanded Name : Service Start Date
	 * Desc : Business date on which the service clock starts.  This value will be calculated automatically for all shipments, based on the values of PKUP_DT and LATE_TENDER_CD.  If a shipment is tendered late, the service start date will be set to the next business date after the pick-up date.
	 * Source : Will be populated at pkup request create time if a skeleton PRO is available, during bill entry, through the late tender correction screen.
	 */
	serviceStartDate:Date = undefined;
	/**
	 * Expanded Name: Inspected Indicator
	 * Desc: Indicates whether the shipment has been inspected or not.
	 */
	inspectedInd:boolean = undefined;
	/**
	 * Expanded Name: Spot Quote ID
	 * Desc: The Spot Quote ID that was entered during Billing. This may be different from the one that was used for rating.  For the latest Spot Quote ID that was used for raing, please look at the rating info record.
	 */
	spotQuoteId:number = undefined;
	/**
	 * Expanded Name: Shipper to Consignee Miles
	 * Desc: This captures the mileage from shipper to consignee as calculated by the Milemaker package (Rand McNally)
	 * Added by C.Doucet 05/06/09 as per Shibashis
	 */
	shipperToConsigneeMiles:number = undefined;
	/**
	 * Expanded name: Shipper loaded trailer code
	 * Desc: Identifies whether a shipper loaded a trailer, and if so, where Con-way took possession of trailer. 
	 * Values: 
	 * N ? Shipper did not load trailer (default)
	 * H ? Hook loaded; a trailer is loaded by shipper and picked up by Con-way at shipper s site 
	 * D ? Dock Drop; a trailer is loaded by shipper and dropped at Con-way dock by shipper
	 */
	shipperLoadedTrlrCd:ShipperLoadedTrailerCd = undefined;
	/**
	 * Expanded Name: Consignee unloaded trailer code
	 * Desc: Identifies whether a consignee unloaded a trailer, and if so, where consignee took possession of trailer. 
	 * Values:
	 * N ? Consignee did not unload trailer (default)
	 * D ? Dock Pickup; a loaded trailer is picked up at Con-way dock by consignee to be unloaded by consignee 
	 * S ? Spot loaded; a loaded trailer is left at the consignee site for consignee to unload
	 */
	consigneeUnloadTrlrCd:ConsigneeUnloadedTrailerCd = undefined;
	/**
	 * Expanded Name: Lineal Foot Total Number
	 * Desc: This is the length in feet that a shipment will take up in a trailer.  This dimension is typically used when the freight is long and narrow and cannot have anything stacked on top of it (e.g. carpet rolls).  For this reason it takes a larger portion of the trailer volume than a shipment of the same weight that is packaged on pallets.  This value can then be used in the planning of trailer loads and to rate shipments that have these characteristics.
	 */
	linealFootTotalNbr:string = undefined;
	/**
	 * Expanded Name: Single Shipment Acquired Indicator                                                                                                                                                Desc:                                                                                                      This indicates whether this was the only Shipment that we acquired from the Customer at the time that we received this one.
	 * Values:
	 * Y ? Yes:  This was the only Shipment Acquired at the time that we received this one.
	 * N ? No: There were other Shipments Acquired at the time that we received this one.
	 */
	singleShipmentAcquiredInd:boolean = undefined;
	/**
	 * Desc: This code identifies the method in which Con-way received the freight from the customer.
	 * Current values are: 
	 * PU ? Regular Pickup:  This is when the driver goes to the customer dock and loads the Shipment on the trailer to return to the service center.
	 * HL ? Hook Loaded: This is when the driver goes to the customer site and pickup a loaded trailer that this Shipment was on.
	 * DD ? Dock Drop:  This is when the customer delivers the Shipment to a Con-way Service Center, so no driver went to the Customer site
	 */
	shipmentAcquiredTypeCd:ShipmentAcquiredTypeCd = undefined;
	/**
	 * Expanded Name : Loose pieces count
	 * Desc : Total loose pieces from all commodities in the shipment.
	 * Count of non Motor Move pieces associated with a shipment. This count plus Motor Moves defines the total number of pieces moving within the Con-way system for Operations productivity.  This does not necessarily balance with the total pieces or commodity pieces entered during bill entry.
	 */
	loosePiecesCount:number = undefined;
	/**
	 * Expanded Name: PUP_VOLUME_PERCENTAGE
	 * Desc: This is the volume of the shipment expressed as the percentage of a full pup (28 feet long trailer).
	 */
	pupVolumePercentage:number = undefined;
	/**
	 * Desc: Instance id of the Pickup Request that is associated with this Shipment during the Confirm On-Board process.
	 */
	purInstId:number = undefined;
	/**
	 * Expanded Name: Bulk Liquid Indicator
	 * Desc: Identifies the shipment as containing bulk liquid.  If volume is known, this applies to shipments >119 gallons.
	 * Added 8/5/14 by Paul Bramhall for Tanker Endorsement Project.
	 * -------------------------------------------------------------
	 */
	bulkLiquidInd:boolean = undefined;
	/**
	 * 
	 */
	cubicFeetProfileMthdCd:ShipmentVolumeMethodCd = undefined;
	/**
	 * 
	 */
	cubicFeetProfileTypeCd:ShipmentVolumeTypeCd = undefined;
	/**
	 * In the event a customer requires a shipment to travel from origin to destination 
	 * without the loading of any additional shipments on the trailer(s), the shipment is considered Exclusive Use.
	 */
	exclusiveUseInd:boolean = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 */
	auditInfo:AuditInfo = undefined;
	/**
	 * Indicates that this shipment is marked as a Mexico Door to Door shipment.
	 */
	mexicoDoorToDoorInd:boolean = undefined;
	/**
	 * Indicates that this shipment is time date critical and operations needs to call for a delivery appointment.
	 */
	callForAppointmentInd:boolean = undefined;
	/**
	 * The value of the freight as declared by the customer.
	 */
	declaredValueAmount:number = undefined;
	/**
	 * Indicates that the customer would like to insure the shipment due to excessive value of the freight.
	 */
	excessiveValueInd:boolean = undefined;
	/**
	 * Indicates whether the payment terms were flipped on a bill after biller submit, based on input shipment party data.
	 */
	debtorTermFlipInd:boolean = undefined;
}
/**
 * 
 */
export class SuppRefNbr {
	/**
	 * 
	 */
	shipmentInstId:number = undefined;
	/**
	 * Expanded Name : Type code:
	 * Desc : A code describing what the Supplemental Reference is (for eg. PO# for Purchase Order number and GBL for Government Bill of Lading). These are entered by and have a meaning for the customer only. There is no list of valid values. 
	 * Examples :
	 * Purchase orders
	 * Shipper numbers
	 * GBL numbers
	 * SLI numbers 
	 * Bookclosing numbers
	 * Source: E-commerce
	 */
	typeCd:string = undefined;
	/**
	 * Expanded Name : Sequence number
	 * Desc : Sequentially assigned number to preserve the order in which various supplemental reference numbers are to be displayed / printed for a commodity / shipment. 
	 * Source : Generated
	 */
	sequenceNbr:string = undefined;
	/**
	 * Expanded Name : Number text
	 * Desc : This is the actual number provided by the customer (sometimes also known as COSTAR - (C)ustomer (O)wned (S)hipment and (T)racking numbers). This valid only when recording supplemental references for a shipment.
	 */
	refNbr:string = undefined;
	/**
	 * Expanded Name : Description
	 * Desc : A textual description of what the Supplemental Reference number is (Purchase order number, shipper number, etc.)
	 */
	description:string = undefined;
	/**
	 * Expanded Name : Commodity Sequence Number
	 * Desc : Sequence number of the specific Commodity (if any) to which the supplemental reference number applies. Should be 0 if the number applies to the Shipment in general.
	 */
	commoditySequenceNbr:string = undefined;
	/**
	 * 
	 */
	archiveControlCd:string = undefined;
	/**
	 * Desc: Source of the Supplemental Reference Number.
	 * Values:
	 * EDI
	 * WEB
	 * XML
	 * BIL - Billing
	 * COR - currently BE36 transaction (Aug.2011)
	 */
	sourceCd:string = undefined;
	/**
	 * Desc: Sender-ID of the EDI Customer.
	 */
	ediSenderId:string = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 */
	listActionCd:ActionCd = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 */
	auditInfo:AuditInfo = undefined;
	/**
	 * 
	 */
	suppRefExtendedDetail:SuppRefExtendedDetail[] = undefined;
}
/**
 * 
 */
export class SuppRefExtendedDetail {
	/**
	 * 
	 */
	shipmentInstId:number = undefined;
	/**
	 * 
	 */
	typeCd:string = undefined;
	/**
	 * 
	 */
	sequenceNbr:string = undefined;
	/**
	 * Expanded Name: Packaging Type Text
	 * 																																																							
	 * Desc : Type of packaging the commodity was originally    
	 * picked up in.                                          
	 * 																																																							
	 * Examples: Box, Skid, Case, Roll etc.                   
	 * 																																																							
	 * Source: Usually EDI, set 204/211 per VICS bill of lading.
	 */
	packageType:string = undefined;
	/**
	 * Expanded Name : Unit of Measure                             
	 * 																																																												
	 * Desc : A code to identify the units in terms of which       
	 * the quantity is measured. For       
	 * eg. weight (in pounds) is the most common unit of           
	 * measure for commodities.                                    
	 * 																																																												
	 * Valid Values :                                              
	 * 1 - Weight (in pounds)                                      
	 * 2 - Yardage (for carpets)                                   
	 * 3 - Count
	 */
	unitOfMeasure:string = undefined;
	/**
	 * Expanded Name : Quantify
	 * Desc : Commodity line item quantify associated with this specific supplemental reference number.  Usually expressed in pounds (reference the UOM - Unit of Measure to verify).
	 */
	quantity:number = undefined;
	/**
	 * Expanded Name : Pieces Count
	 * Desc : Commodity line pieces associated with this specific supplemental reference number.
	 */
	piecesCount:number = undefined;
	/**
	 * Expanded Name: Pallet or Slip Indicator
	 * 																																																							
	 * Desc : Used on a VICS bill of lading to indicate that the freight at time of pickup was loaded on either a pallet or a slip sheet.
	 * Valid Values:  Y/N
	 * 																																																							
	 * Source: Usually EDI, set 204/211 per VICS bill of lading.
	 */
	palletOrSlipInd:boolean = undefined;
	/**
	 * Expanded Name: Additional Shipper Information
	 * 																																																							
	 * Desc : Contains additional comments supplied from the shipper, usually regarding how the freight should be handled.
	 * 																																																							
	 * Source: Usually EDI, set 204/211 per VICS bill of lading.
	 */
	additionalShipperInformation:string = undefined;
	/**
	 * 
	 */
	archiveControlCd:string = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 */
	listActionCd:ActionCd = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * 
 */
export class TimeDateCritical {
	/**
	 * 
	 */
	shipmentInstId:number = undefined;
	/**
	 * Expanded: Time Date Critical Source Code
	 * Desc: Where the Time Date Critical information was entered.
	 * Valid Values:
	 * BIL=Bill Entered
	 * PUR=Pick Up Request
	 * EDI=EDI
	 * WEB=Web
	 * COR=Corrections
	 */
	tdcSourceCd:string = undefined;
	/**
	 * Expanded: Time Date Critical Date Type Code
	 * Desc: What kind the dates on the TDC shipment is/are.  There are 3 main flavors of date on Time Date Critical shipments: Deliver on a date, Deliver by a date, or a Deliver sometime within a date range  
	 * Valid Values:
	 * ON=On
	 * BY=By
	 * RNG=Range
	 */
	tdcDateTypeCd:string = undefined;
	/**
	 * Expanded: Time Date Critical Date #1
	 * Desc: There are 3 flavors of date for a TDC shipment.  This attribute captures the following flavor of date: ON, BY or FROM
	 */
	tdcDate1:Date = undefined;
	/**
	 * Expanded: Time Date Critical Date #2
	 * Desc: There are 3 flavors of date for a TDC shipment.  This attribute captures the TO date if the type is a date range.
	 */
	tdcDate2:Date = undefined;
	/**
	 * Expanded: Time Date Critical Time Type
	 * Desc: What kind the times on the TDC shipment is/are.  There are 4 main flavors of time for Time Date Critical shipments: Deliver at a time, Deliver before a time, Deliver after a time, or a Deliver sometime within a time range  
	 * Valid Values:
	 * AT=At
	 * BEF=Before
	 * AFT=After
	 * RNG=Range
	 */
	tdcTimeTypeCd:string = undefined;
	/**
	 * Expanded: Time Date Critical Time #1
	 * Desc: There are 4 flavors of time for a TDC shipment.  This attribute captures the following flavor of time: AT, AFTER, BEFORE, FROM
	 */
	tdcTime1:Date = undefined;
	/**
	 * Expanded: Time Date Critical Time #2
	 * Desc: There are 4 flavors of time for a TDC shipment.  This attribute captures the TO time if the type is a time range.
	 */
	tdcTime2:Date = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 */
	auditInfo:AuditInfo = undefined;
}

/**
 * Query parameters for getOdsShipment
 */
export class GetOdsShipmentQuery {
	proNbr: string = undefined;
	pickupDate: string = undefined;
	shipmentInstId: number = undefined;
}

/**
 * Query parameters for listShipmentsByCustomerAccount
 */
export class ListShipmentsByCustomerAccountQuery {
	cisCustNbr: number[] = undefined;
	levelOfDetail: string = undefined;
	numberOfRows: number = undefined;
	startAt: number = undefined;
	fields: string[] = undefined;
	sortByFieldName: string[] = undefined;
	sortOrder: string[] = undefined;
}


